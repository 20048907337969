import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import { TextField, Grid, ThemeProvider } from "@material-ui/core";
import { theme } from "../../theme";
import CTA from "../button/cta-button";
import { FaPlay,FaFastForward, FaPrint,FaSearch } from 'react-icons/fa'
import { MdViewAgenda, MdPageview } from "react-icons/md";
// import StyledTableCell from '@material-ui/core/StyledTableCell'

const StyledTableCell = withStyles((theme) => ({
  head: {
    background: "rgba(255, 108, 0, 0.2)",
    fontFamily: "Montserrat",
    fontWeight: "bolder",
  },
  body: {
    fontSize: 14,
    borderRadius: "5px",
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(even)": {
      backgroundColor: "rgba(196, 196, 196, 0.2)",
    },
  },
}))(TableRow);

// Date, PR, Product, Amount,NS,PS,Actions
const columns = [
  { id: "Date", label: "Date Generated", maxWidth: 130 },
  { id: "PR", label: "Payment Ref", maxWidth: 130 },
  {
    id: "Product",
    label: "Product",
    maxWidth: 130,
    // align: 'right',
    // format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: "Amount",
    label: "Amount",
    maxWidth: 130,
    // align: 'right',
    // format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: "NS",
    label: "Notification Status",
    maxWidth: 130,
    // align: 'right',
    format: (value) => value.toFixed(2),
  },
  {
    id: "PS",
    label: "Payment Status",
    maxWidth: 130,
    // align: 'right',
    format: (value) => value.toFixed(2),
  },
  {
    id: "Action",
    label: "Action",
    maxWidth: 50,
  },
  
];

function createData(Date, PR, Product, Amount,NS,PS,Action,) {
  return { Date, PR, Product, Amount,NS,PS,Action, };
}

const handlePlay=(e)=> {
  e.preventDefault();
  alert('welcome peter')
};
const handlePrint=(e)=> {
  e.preventDefault();
  alert('Print Clicked')
};
const handleSearch=(e)=> {
  e.preventDefault();
  alert('Search Clicked')
};
const handleForward=(e)=> {
  e.preventDefault();
  alert('Forward Clicked')
};

const rows = [
  createData('20th-Aug-2020', 123323498474, 'Airtel', 2000000,'Successful','Declined',<MdPageview style={{color:'#ff6c00',fontSize:'25px'}} onClick={handleSearch}/>,),
  createData('20th-Aug-2020', 123323498474, 'Airtel', 2000000,'Successful','Declined',<MdPageview style={{color:'#ff6c00',fontSize:'25px'}} onClick={handleSearch}/>),
  createData('20th-Aug-2020', 123323498474, 'Airtel', 2000000,'Successful','Declined',<MdPageview style={{color:'#ff6c00',fontSize:'25px'}} onClick={handleSearch}/>),
  createData('20th-Aug-2020', 123323498474, 'Airtel', 2000000,'Successful','Declined',<MdPageview style={{color:'#ff6c00',fontSize:'25px'}} onClick={handleSearch}/>),
  createData('20th-Aug-2020', 123323498474, 'Airtel', 2000000,'Successful','Declined',<MdPageview style={{color:'#ff6c00',fontSize:'25px'}} onClick={handleSearch}/>),
  createData('20th-Aug-2020', 123323498474, 'Airtel', 2000000,'Successful','Declined',<MdPageview style={{color:'#ff6c00',fontSize:'25px'}} onClick={handleSearch}/>),
  createData('20th-Aug-2020', 123323498474, 'Airtel', 2000000,'Successful','Declined',<MdPageview style={{color:'#ff6c00',fontSize:'25px'}} onClick={handleSearch}/>),
  createData('20th-Aug-2020', 123323498474, 'Airtel', 2000000,'Successful','Declined',<MdPageview style={{color:'#ff6c00',fontSize:'25px'}} onClick={handleSearch}/>),
  createData('20th-Aug-2020', 123323498474, 'Airtel', 2000000,'Successful','Declined',<MdPageview style={{color:'#ff6c00',fontSize:'25px'}} onClick={handleSearch}/>),
 
];

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  overallContainer: {
    marginLeft: "20px",
    marginTop: 20,
  },
  container: {
    maxHeight: 'auto',
    "&::-webkit-scrollbar": {
      width: "10px",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      outline: "1px solid slategrey",
    },
  },
  topForm: {
    borderRadius: "5px 5px 0px 0px",
  },
  inputPadding: {
    paddingLeft: "40px",
    marginTop: "40px",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "0px",
    },
  },
  bulkTransactionTitle:{
    background: "#FF6C00",
    borderRadius: "5px",
    // position: "absolute",
    width: "400px",
    height: "60px",
    marginTop: "-20px",
    marginLeft: "20px",
    color: "#fff",
    paddingLeft: "15px",
    paddingTop:'20px',
    paddingBottom:'15px',
    // fontWeight:'bolder',
    fontFamily:'Montserrat',
  }
});

export default function BulkTransactionHistory(style) {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(3);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <div className={classes.overallContainer} style={style}>
      <Paper className={classes.topForm}>
        <div className={classes.bulkTransactionTitle}>
          <h5 style={{fontWeight:'600'}}>Bulk Airtime Transaction History</h5>
        </div>
        <div style={{ padding: 20 }}>
          <ThemeProvider theme={theme}>
            <Grid container spacing={1}>
              <Grid
                className={classes.inputPadding}
                padding={20}
                xs={12}
                sm={6}
                md={3}
              >
                <TextField
                  id="standard-full-width"
                  label="Start date"
                  style={{ margin: 8 }}
                  placeholder="22/09/2020"
                  fullWidth
                  type="date"
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid className={classes.inputPadding} xs={12} sm={6} md={3}>
                <TextField
                  id="standard-full-width"
                  label="End Date"
                  style={{ margin: 8 }}
                  placeholder="Amount"
                  type="date"
                  fullWidth
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid className={classes.inputPadding} xs={12} sm={6} md={3}>
                <TextField
                  id="standard-full-width"
                  label="Payment Reference"
                  style={{ margin: 8 }}
                  placeholder="233445565434"
                  fullWidth
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid xs={12} sm={6} md={3}>
                <CTA
                  small
                  green
                  text="Search"
                  style={{
                    borderRadius: "5px",
                    marginTop: "70px",
                    marginLeft: "40px",
                  }}
                />
              </Grid>
            </Grid>
          </ThemeProvider>
        </div>
      </Paper>
      <Paper className={classes.root}>
        <TableContainer className={classes.container}>
          <Table  aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <StyledTableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <StyledTableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.code}
                    >
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.format && typeof value === "number"
                              ? column.format(value)
                              : value}
                          </TableCell>
                        );
                      })}
                    </StyledTableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[3, 5, 10, 15, 20, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}
