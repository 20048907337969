import configuration from "../config";
import { alertActions } from ".";
import { handleResponse } from "../helpers";
import { authHeader } from "../_helpers";

export const fetchEvents = () => {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  var _url = `${configuration.apiUrl}/Events`;
  return fetch(_url, requestOptions).then(handleResponse);
};
export const logTransaction = (_req) => {
  const req = {
    requestData: _req,
  };
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(req),
  };
  var _url = `${configuration.apiUrl}/Events/LogTransaction`;
  return fetch(_url, requestOptions).then(handleResponse);
};
export const notifyBiller = (_req) => {
  const req = {
    requestData: _req,
  };
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(req),
  };
  var _url = `${configuration.apiUrl}/Events/NotifyBiller`;
  return fetch(_url, requestOptions).then(handleResponse);
};
export const fetchTransaction = (id, success, failure) => (dispatch) => {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  return fetch(
    `${configuration.apiUrl}/Events/FetchTransactionValues?TranId=${id}`,
    requestOptions
  ).then(handleResponse);
};
export const getTotalAmount = (_req) => {
  const req = {
    requestData: _req,
  };
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(req),
  };
  var _url = `${configuration.apiUrl}/Events/GetTotalAmount`;
  return fetch(_url, requestOptions).then(handleResponse);
};
