import React, { createContext, useReducer, useEffect } from "react";
import { transactionReducer } from "../_reducers/transaction.reducer";
import { fetchDashboardTransactionHistory } from "../_actions/product.actions";
import { transactionConstants } from "../_constants";

const initialState = {
  loading: false,
  transactions: [],
};

export const TransactionContext = createContext(initialState);

export const TransactionProvider = ({ children }) => {
  const [state, transactionDispatch] = useReducer(
    transactionReducer,
    initialState
  );

  const getDashboardTransaction = () => {
    transactionDispatch({ type: transactionConstants.SENDING_REQUEST });
    fetchDashboardTransactionHistory().then(
      (resp) => {
        transactionDispatch({
          type: transactionConstants.FETCH_DASHBOARD_TRANSACTION,
          payload: resp,
        });
      },
      (error) => {
        transactionDispatch({
          type: transactionConstants.FETCH_DASHBOARD_TRANSACTION,
          payload: [],
        });
      }
    );
  };

  return (
    <TransactionContext.Provider
      value={{
        transactions: state.transactions,
        loadingActivity: state.loading,
        getDashboardTransaction,
        transactionDispatch,
      }}
    >
      {children}
    </TransactionContext.Provider>
  );
};
