import React, { createContext, useReducer, useState, useEffect } from "react";
import { productConstants } from "../_constants";
import {
  fetchCategories,
  fetchProducts,
  logTransaction,
  notifyBiller,
  rateTransaction,
} from "../_actions/product.actions";
import productReducer from "../_reducers/product.reducer";

const initialState = {
  categories: [],
  products: [],
  items: [],
  fields: [],
  lookup: {},
  transactionId: "",
  currentCategory: "",
  loading: true,
  fetchProducts: false,
  notifyBillerResponse: "",
  loadingNotification: true,
  filteredPproducts: [],
};

export const ProductContext = createContext(initialState);

export const ProductProvider = (props) => {
  const [state, productDispatch] = useReducer(productReducer, initialState);

  const getProductCategories = () => {
    const categoriesAndProducts = [];
    try {
      productDispatch({ type: productConstants.SENDING_REQUEST });
      fetchCategories().then(
        (data) => {
          productDispatch({
            type: productConstants.FETCH_CATEGORIES,
            payload: data,
          });
          fetchProducts().then(
            (data1) => {
              data1 &&
                data1.forEach((product) => {
                  data &&
                    data.forEach((category) => {
                      if (product.CategoryCode == category.CategoryCode) {
                        categoriesAndProducts.push({
                          ...product,
                          ...category,
                        });
                      }
                    });
                });
              productDispatch({
                type: productConstants.FETCH_CATEGORY_PRODUCTS,
                payload: categoriesAndProducts,
              });
              productDispatch({ type: productConstants.REQUEST_FINISH });
            },
            (error) => {
              productDispatch({
                type: productConstants.REQUEST_FINISH,
              });
            }
          );
        },
        (error) => {
          productDispatch({
            type: productConstants.REQUEST_FINISH,
          });
        }
      );
    } catch (error) {
      // dispatch({
      //   type: alertConstants.ERROR,
      //   message: error.toString(),
      // });
      productDispatch({ type: productConstants.REQUEST_FINISH });
    }
  };

  const rateTransactions = (req) => {
    rateTransaction(req).then(
      (data) => {},
      (error) => {}
    );
  };

  const notifyBillers = (req) => {
    productDispatch({ type: productConstants.SENDING_NOTIFICATION_REQUEST });

    notifyBiller(req).then(
      (data) => {
        productDispatch({
          type: productConstants.NOTIFY_BILLER_RESPONSE,
          payload: data,
        });
        productDispatch({ type: productConstants.REQUEST_NOTIFICATION_FINISH });
      },
      (error) => {
        productDispatch({ type: productConstants.REQUEST_NOTIFICATION_FINISH });
      }
    );
  };

  const searchBillers = (searchText) => {
    const lowerCaseSearchText = searchText.toLowerCase();
    const filterProducts = state.products.filter(({ ProductName }) => {
      const lowerCaseProductName = ProductName.toLowerCase();
      return lowerCaseProductName.includes(lowerCaseSearchText);
    });
    productDispatch({
      type: productConstants.SEARCH_FETCH_CATEGORY_PRODUCTS,
      payload: filterProducts,
    });
  };

  const clearSearchResult = () => {
    productDispatch({
      type: productConstants.SEARCH_FETCH_CATEGORY_PRODUCTS,
      payload: [],
    });
  };
  useEffect(() => {
    getProductCategories();
  }, []);

  return (
    <ProductContext.Provider
      value={{
        categories: state.categories,
        products: state.products,
        items: state.items,
        notifyBillerResponse: state.notifyBillerResponse,
        loadingNotification: state.loadingNotification,
        getProductCategories,
        searchBillers,
        loading: state.loading,
        fetchProducts: state.fetchProducts,
        filteredPproducts: state.filteredPproducts,
        notifyBillers,
        clearSearchResult,
        rateTransactions,
        productDispatch,
      }}
    >
      {props.children}
    </ProductContext.Provider>
  );
};
