import React from "react";
import styles from "./input.module.css";

const PasswordInput = ({
  text,
  icon,
  placeholder,
  style,
  name,
  value,
  onChange,
  onBlur,
  type,
}) => {
  return (
    <div>
      <span
        style={{
          //   position: "relative",
          position: "absolute",
          zIndex: "20",
          marginLeft: "10px",
          marginTop: "5px",
          color: "#000",
          fontSize: "18px",
          opacity: ".4",
        }}
      >
        {icon}
      </span>
      <input
        className={styles.passwordInput}
        type={type}
        text={text}
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        placeholder={placeholder}
        style={style}
      />
      {/* <span style={{position:'relative',zIndex:'20',marginLeft:'-390px', color:'#000', fontSize:'18px',opacity:'.4'}}>{icon}</span> */}
    </div>
  );
};
export default PasswordInput;
