import {
  productConstants
} from '../_constants';

// const initialState = {
//   categories: [],
//   products:[],
//   items:[],
//   fields:[],
//   lookup:{},
//   transactionId:'',
//   currentCategory:''
// }
// = initialState
// export function product(state, action){
//   switch(action.type){
//     case productConstants.FETCH_CATEGORIES:
//       return {
//         ...state,
//         categories: action.payload
//       }
//     case productConstants.FETCH_CATEGORY_PRODUCTS:
//       return {
//         ...state,
//         items: action.payload
//       }
//     case productConstants.FILTER_CATEGORY_PRODUCTS:
//       return {
//         ...state,
//         products: action.payload,
//         currentCategory:action.category
//     }
//     case productConstants.FETCH_PRODUCT_FIELDS:
//       return {
//         ...state,
//         fields: action.payload
//       }
//     case productConstants.CUSTOMER_LOOKUP_ACTION:
//       return {
//         ...state,
//         lookup: action.payload
//       }
//     case productConstants.TRANSACTION_INITIATION:
//       return {
//         ...state,
//         transactionId: action.payload
//       }
//     case productConstants.CLEAR_CATEGORY:
//       return {
//         ...state,
//         currentCategory: ''
//       }
//     default:
//       return state;
//   }
// }

export default (state, action) => {
  switch (action.type) {
    case productConstants.FETCH_CATEGORIES:
      return {
        ...state,
        categories: action.payload
      }
    case productConstants.FETCH_CATEGORY_PRODUCTS:
      return {
        ...state,
        products: action.payload
      }
    case productConstants.SEARCH_FETCH_CATEGORY_PRODUCTS:
      return {
        ...state,
        filteredPproducts: action.payload
      }
    case productConstants.FETCH_CATEGORY_MAPPED_PRODUCTS:
      return {
        ...state,
        mappedCategoriesAndProducts: action.payload
      }
    case productConstants.FILTER_CATEGORY_PRODUCTS:
      return {
        ...state,
        // products: action.payload,
        // currentCategory: action.category
      }
    case productConstants.FETCH_PRODUCT_FIELDS:
      return {
        ...state,
        fetchProducts: true
      }
    case productConstants.FETCH_PRODUCT_FIELDS_COMPLETED:
      return {
        ...state,
        fetchProducts: false
      }
    case productConstants.CUSTOMER_LOOKUP_ACTION:
      return {
        ...state,
        lookup: action.payload
      }
    case productConstants.TRANSACTION_INITIATION:
      return {
        ...state,
        transactionId: action.payload
      }
    case productConstants.CLEAR_CATEGORY:
      return {
        ...state,
        currentCategory: ''
      }
    case productConstants.REQUEST_FINISH:
      return {
        ...state,
        loading: false
      }

    case productConstants.SENDING_REQUEST:
      return {
        ...state,
        loading: true
      }
    case productConstants.SENDING_NOTIFICATION_REQUEST:
      return {
        ...state,
        loadingNotification: true
      }
    case productConstants.REQUEST_NOTIFICATION_FINISH:
      return {
        ...state,
        loadingNotification: false
      }
    case productConstants.NOTIFY_BILLER_RESPONSE:
      return {
        ...state,
        notifyBillerResponse: action.payload
      }
    default:
      return state;
  }

}