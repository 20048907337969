import React from "react";
import { Grid } from "@material-ui/core";
import { Link } from "react-router-dom";

export default function CookiesPolicy() {
  return (
    <div className="cookies-container">
      <Grid container>
        <h1 className="title">Cookies Policy</h1>
        <div className="cookies-policy">
          <Grid item lg={12} sm={12} md={12}>
            <p>
              At Xpress Payment Solution Limited (hereinafter called “Xpress
              Payments”), we treat your personal information as private and
              confidential. We are dedicated to protecting your privacy and
              providing you with the highest level of security at any point of
              interaction with us. <br />
              This Cookies Policy (Policy) explains how we use cookies and the
              choices you have. Except as otherwise stated in this Policy, our
              Privacy Policy will apply to our processing of data that we
              collect via cookies. You can access our Privacy Policy via this
              <Link
                to="/privacy-policy"
                style={{ color: "green", fontWeight: "bold" }}
              >
                {" "}
                link.
              </Link>{" "}
              <br />
              By using our website (by clicking, navigating or scrolling), you
              are consenting to our use of cookies in accordance with this
              Policy. If you do not agree to our use of cookies, you can prevent
              the installation of cookies through the settings of your browser
              or not use our website at all. However, if you disable the use of
              cookies, this might cause some parts of this website not to
              function properly for you and it may impact your user experience
              on this site.
            </p>
          </Grid>
          <Grid item lg={12} md={12} sm={12}>
            <Grid container>
              <Grid item lg={6} md={6} sm={12}>
                <div className="cookies-definition">
                  <h6>What are cookies?</h6>
                  <br />
                  <p>
                    Cookies are messages or small files that are placed on your
                    web browser when you visit an internet site. Cookies are
                    useful and do a lot of different things, including allowing
                    a website to recognize a user’s device, improving online
                    experience etc.
                  </p>
                </div>
              </Grid>
              <Grid item lg={6} md={6} sm={12}>
                <div className="why-cookies">
                  <h6>Why do we use cookies?</h6>
                  <p>
                    We use cookies when you access our websites and our
                    touchpoints to make the site work better and to help us
                    understand how you use our site. Cookies enable us to offer
                    tailored products and to understand the information we
                    receive about you, including information about your use of
                    other websites and apps. Cookies help us provide, protect
                    and improve our products and services, by personalizing,
                    tailoring and measuring the services and products we provide
                    for satisfactory and safe experience. We also use them to
                    help authentication of user, assess performance and
                    functionality of our online products and services, and
                    analytical research.
                  </p>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Grid>
    </div>
  );
}
