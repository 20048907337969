import { userConstants } from "../_constants";

export function registration(state = {}, action) {

  switch (action.type) {
    case userConstants.REGISTER_REQUEST:
      return { registering: true };
    case userConstants.REGISTER_SUCCESS:
      return {
        ...state,
        signUpMessage: action.payload,
        registering: false,
      };
    case userConstants.REGISTER_FAILURE:
      return {
        ...state,
        signUpMessage: action.payload,
        registering: false,
      };
    case userConstants.LOGIN_IN_REQUEST:
      return {
        loginIn: true
      };
    case userConstants.LOGIN_IN_REQUEST_FINISH:
      return {
        loginIn: false
      };
    case userConstants.LOGIN_REQUEST:
      return {
        loggingIn: false,
        user: action.payload
      };
    case userConstants.LOGIN_SUCCESS:
      return {
        // ...state,
        loggedIn: true,
        user: action.payload
      };
    case userConstants.UPDATE_BACKUP_REQUEST:
      return {
        // ...state,
        updating: true
      };
    case userConstants.UPDATE_BACKUP_SUCCESS:
      return {
        // ...state,
        updating: false,
        backupUpdateMessage: action.payload
      };
    case userConstants.UPDATE_BACKUP_FAILURE:
      return {
        // ...state,
        updating: false,
        errorMessage: action.payload
      };

      case userConstants.BACKUP_REQUEST:
      return {
        ...state,
        updatingBackupEmail: true
      };
    case userConstants.BACKUP_SUCCESS:
      return {
        ...state,
        updatingBackupEmail: false
      };
    case userConstants.BACKUP_FAILURE:
      return {
        ...state,
        updatingBackupEmail: false
      };
    case userConstants.UPDATE_FAILURE:
      return {};
    case userConstants.UPDATE_SUCCESS:
      return {
        ...state,
        loggedIn: true,
        user: action.payload
        // user: {
        //   ...user,
        //   ...action.user
        // }
      };
    case userConstants.LOGIN_FAILURE:
      return {};
    case userConstants.REGISTER_CLEAR:
      return {
        ...state,
        signUpMessage: "",

      }
    case userConstants.LOGOUT:
      return {};
    case userConstants.ACTIVATE_REQUEST:
      return {
        activating: true
      };
    case userConstants.ACTIVATE_SUCCESS:
      return {
        activating: false,
        activationMessage: action.payload
      };
    case userConstants.ACTIVATE_FAILURE:
      return {
        activating: false,
        errorMessage: action.payload
      }
    case userConstants.RESET_REQUEST:
      return {
        forgottenPassword: true,
      };

    case userConstants.RESET_SUCCESS:
      return {
        forgottenPassword: false,
        user: action.user
      };
    case userConstants.RESET_FAILURE:
      return {
        forgottenPassword: false,
        user: action.user
      };
    case userConstants.VERIFY_REQUEST:
      return {
        ressetting: true,
        user: action.user
      };
    case userConstants.VERIFY_SUCCESS:
      return {
        ...state,
        ressetting: false,
        user: action.payload
      };
    case userConstants.VERIFY_FAILURE:
      return {
        ...state,
        ressetting: false,
        errorMessage: action.errorMessage
      };

    case userConstants.CHANGE_REQUEST:
      return {
        ...state,
        ressetting: true,
      };
    case userConstants.CHANGE_SUCCESS:
      return {
        ...state,
        ressetting: false,
        // user: action.payload
      };
    case userConstants.CHANGE_FAILURE:
      return {
        ...state,
        ressetting: false,
        changeErrorMessage: action.changeErrorMessage
      };

    default:
      return state;
  }
}
