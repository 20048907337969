export const userConstants = {
    REGISTER_REQUEST: 'USERS_REGISTER_REQUEST',
    REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
    REGISTER_FAILURE: 'USERS_REGISTER_FAILURE',
    REGISTER_CLEAR: 'REGISTER_CLEAR',

    ACTIVATE_REQUEST: 'USERS_ACTIVATE_REQUEST',
    ACTIVATE_SUCCESS: 'USERS_ACTIVATE_SUCCESS',
    ACTIVATE_FAILURE: 'USERS_ACTIVATE_FAILURE',

    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',
    LOGIN_IN_REQUEST: 'LOGIN_IN_REQUEST',
    LOGIN_IN_REQUEST_FINISH: 'LOGIN_IN_REQUEST_FINISH',
    
    LOGOUT: 'USERS_LOGOUT',

    GETALL_REQUEST: 'USERS_GETALL_REQUEST',
    GETALL_SUCCESS: 'USERS_GETALL_SUCCESS',
    GETALL_FAILURE: 'USERS_GETALL_FAILURE',

    GET_REQUEST: 'USERS_GET_REQUEST',
    GET_SUCCESS: 'USERS_GET_SUCCESS',
    GET_FAILURE: 'USERS_GET_FAILURE',

    UPDATE_REQUEST: 'USERS_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'USERS_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'USERS_UPDATE_FAILURE',

    DELETE_REQUEST: 'USERS_DELETE_REQUEST',
    DELETE_SUCCESS: 'USERS_DELETE_SUCCESS',
    DELETE_FAILURE: 'USERS_DELETE_FAILURE',
    
    RESET_REQUEST: 'USERS_RESET_REQUEST',
    RESET_SUCCESS: 'USERS_RESET_SUCCESS',
    RESET_FAILURE: 'USERS_RESET_FAILURE',

    VERIFY_REQUEST: 'USERS_VERIFY_REQUEST',
    VERIFY_SUCCESS: 'USERS_VERIFY_SUCCESS',
    VERIFY_FAILURE: 'USERS_VERIFY_FAILURE',

    CHANGE_REQUEST: 'USERS_CHANGE_REQUEST',
    CHANGE_SUCCESS: 'USERS_CHANGE_SUCCESS',
    CHANGE_FAILURE: 'USERS_CHANGE_FAILURE',

    BACKUP_REQUEST: 'BACKUP_REQUEST',
    BACKUP_SUCCESS: 'BACKUP_SUCCESS',
    BACKUP_FAILURE: 'BACKUP_FAILURE',

    UPDATE_BACKUP_REQUEST: 'UPDATE_BACKUP_REQUEST',
    UPDATE_BACKUP_SUCCESS: 'UPDATE_BACKUP_SUCCESS',
    UPDATE_BACKUP_FAILURE: 'UPDATE_BACKUP_FAILURE',

    PAGE_TITLE: 'PAGE_TITLE',
};
