import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import { MdPageview } from "react-icons/md";
import { Grid, Paper, Typography, Divider } from "@material-ui/core";
import { FaPlay, FaFastForward, FaPrint } from "react-icons/fa";
import CTA from "../button/cta-button";
import Print from "../print-transaction/print";
import ContentToPrint from "../print-transaction/print";

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      position: "absolute",
      width: 500,
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      [theme.breakpoints.down("sm")]: {
        padding: "20px",
        width: "310px",
        marginLeft: "0px",
      },
      // padding: theme.spacing(2, 4, 3),
    },
    paperForm: {
      margin: "20px auto 0px auto",
      padding: "3px  40px 10px 40px",
      // width: '450px',
      width: 500,
      [theme.breakpoints.down("sm")]: {
        padding: "20px",
        width: "310px",
        marginLeft: "0px",
      },
    },
    previewTitle: {
      fontWeight: "600",
      marginBottom: "10px",
      marginTop: "20px",
      fontFamily: "Montserrat",
      [theme.breakpoints.down("sm")]: {
        fontSize: "16px",
        fontWeight: "bold",
        marginTop: "0px",
      },
    },
    summaryFontBold: {
      fontWeight: "600",
      [theme.breakpoints.down("sm")]: {
        fontSize: "11px",
        fontWeight: "bold",
      },
    },
    formButtons: {
      marginTop: "20px",
      paddingLeft: "20px",
      marginLeft: "150px",
      [theme.breakpoints.down("sm")]: {
        display: "flex",
        marginLeft: "0px",
      },
      [theme.breakpoints.down("xs")]: {
        marginLeft: "-18px",
      },
    },
    titleProduct: {
      fontFamily: "Montserrat",
      fontWeight: "bold",
      fontSize: "25px",
      marginTop: "50px",
      marginLeft: "-30px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "18px",
        fontWeight: "600px",
        marginLeft: "-20px",
        marginTop: "30px",
      },
    },
    typegraphyStyles: {
      textAlign: "right",
      fontSize: "11px",
    },
  })
);

export default function PrintModal() {
  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleProceed = () => {};

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <ContentToPrint />
      <Grid className={classes.formButtons}>
        <CTA noBackground text="close" action={handleClose} />
      </Grid>
    </div>
  );

  return (
    <div>
      <FaPrint onClick={handleOpen} />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        disableBackdropClick="true"
        disableScrollLock="true"
      >
        {body}
      </Modal>
    </div>
  );
}
