import React, { createContext, useReducer, useContext } from "react";
import { userActions } from "../_actions/user.actions";
import { registration } from "../_reducers/registration.reducer";
import { users } from "../_reducers/users.reducer";
import { userConstants, alertConstants } from "../_constants";
import { AlertContext } from "./alert-context";
import { alertActions } from "../_actions";

let userDetails = JSON.parse(localStorage.getItem("user"));

const initialState = {
  user: !!userDetails ? userDetails : {},
  loggedIn: !!userDetails ? true : false,
  signUpMessage: "",
  registering: false,
  loginIn: false,
  changePasswordMessage: "",
  changingPassword: false,
  updateRegisterMessage: "",
  updatingRegister: false,
  activationMessage: "",
  activating: false,
  forgottenPassword: false,
  forgotPasswordMessage: "",
  updatingBackupEmail: false,
  updateBackupEmailMessage: "",
};

export const UserContext = createContext(initialState);

export const UserProvider = (props) => {
  const [state, userDispatch] = useReducer(registration, initialState);

  //,authentication

  function signUp(user, dispatch) {
    userDispatch({
      type: userConstants.REGISTER_REQUEST,
    });
    userActions.register(user, "").then((resp) => {
      if (resp.responseCode === "00") {
        userDispatch({
          type: userConstants.REGISTER_SUCCESS,
          payload: resp.message,
        });
        dispatch({
          type: alertConstants.SUCCESS,
          message: resp.message,
        });
      } else {
        dispatch({
          type: alertConstants.ERROR,
          message: resp.message,
        });
        userDispatch({
          type: userConstants.REGISTER_FAILURE,
        });
      }
    });
  }

  function login(username, password, history, dispatch) {
    userDispatch({
      type: userConstants.LOGIN_IN_REQUEST,
    });
    userActions.login(username, password).then((resp) => {
      userDispatch({
        type: userConstants.LOGIN_IN_REQUEST_FINISH,
      });
      if (resp.access_token) {
        userDispatch({
          type: userConstants.LOGIN_SUCCESS,
          payload: resp,
        });
        history.push("/dashboard");
      } else {
        dispatch({
          type: alertConstants.ERROR,
          message: resp.message,
        });
        userDispatch({
          type: userConstants.LOGIN_FAILURE,
          payload: resp.message,
        });
      }
    });
  }

  function logout(history) {

    history.push("/");
    userActions.logout();
    userDispatch({
      type: userConstants.LOGOUT,
    });
  }

  function changePassword(user, dispatch) {
    userDispatch({
      type: userConstants.RESET_REQUEST,
    });
    userActions.changePassword(user, dispatch).then(
      (resp) => {
        var msg = "Password Changed Successfully";
        dispatch(alertActions.clear());
        userDispatch({
          type: userConstants.RESET_SUCCESS,
          payload: msg,
        });
        dispatch({
          type: alertConstants.SUCCESS,
          message: msg,
        });
      },
      (error) => {
        dispatch({
          type: alertConstants.ERROR,
          message: error.message,
        });
        userDispatch({
          type: userConstants.RESET_FAILURE,
        });
      }
    );
  }

  function updateRegister(user, dispatch) {
    userDispatch({
      type: userConstants.UPDATE_REQUEST,
    });
    userActions.update(user, userDispatch).then((resp) => {
      if (resp.responseCode === "00") {
        userDispatch({
          type: userConstants.UPDATE_SUCCESS,
          payload: {
            ...state.user,
            ...user,
          },
        });
        dispatch({
          type: alertConstants.SUCCESS,
          message: resp.message,
        });
      } else {
        dispatch({
          type: alertConstants.ERROR,
          message: resp.message,
        });
        userDispatch({
          type: userConstants.UPDATE_FAILURE,
        });
      }
    });
  }

  function forgotPassword(user, dispatch) {
    userDispatch({
      type: userConstants.RESET_REQUEST,
    });
    userActions.sendResetLink(user, dispatch).then((resp) => {
      if (resp.responseCode === "00") {
        userDispatch({
          type: userConstants.RESET_SUCCESS,
          payload: resp.message,
        });
        dispatch({
          type: alertConstants.SUCCESS,
          message: resp.message,
        });
      } else {
        dispatch({
          type: alertConstants.ERROR,
          message: resp.message,
        });
        userDispatch({
          type: userConstants.RESET_FAILURE,
        });
      }
    });
  }

  function submitBackEmail(user, dispatch) {
    userDispatch({
      type: userConstants.BACKUP_REQUEST,
    });
    userActions.submitBackEmail(user).then(
      (resp) => {
        var msg = "Mail sent successfully to your registered email address!!!";
        dispatch(alertActions.clear());
        userDispatch({
          type: userConstants.BACKUP_SUCCESS,
          payload: {
            ...state.user,
            ...user,
          },
        });
        dispatch({
          type: alertConstants.SUCCESS,
          message: msg,
        });
      },
      (error) => {
        dispatch({
          type: alertConstants.ERROR,
          message: error.toString(),
        });
        userDispatch({
          type: userConstants.BACKUP_FAILURE,
        });
      }
    );
  }

  function clearMessage() {
    userDispatch({
      type: userConstants.REGISTER_CLEAR,
    });
  }

  return (
    <UserContext.Provider
      value={{
        user: state.user,
        login,
        loggedIn: state.loggedIn,
        loginIn: state.loginIn,
        signUp,
        signUpMessage: state.signUpMessage,
        registering: state.registering,
        changePassword,
        changingPassword: state.changingPassword,
        changePasswordMessage: state.changePasswordMessage,
        updateRegister,
        updatingRegister: state.updatingRegister,
        updateRegisterMessage: state.updateRegisterMessage,
        forgotPassword,
        forgottenPassword: state.forgottenPassword,
        forgotPasswordMessage: state.forgotPasswordMessage,
        submitBackEmail,
        updatingBackupEmail: state.updatingBackupEmail,
        updateBackupEmailMessage: state.updateBackupEmailMessage,
        logout,
        clearMessage,
        userDispatch,
      }}
    >
      {props.children}{" "}
    </UserContext.Provider>
  );
};
