import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import { ProductContext } from "../../context/product-context";
import configuration from "../../config";
import { authHeader } from "../../_helpers";
import { handleResponse } from "../../helpers";
import {
  Grid,
  TextField,
  ButtonBase,
  Paper,
  Button,
  Container,
  CssBaseline,
  ThemeProvider,
  Typography,
  Link,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  Divider,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { theme } from "../../theme";
import { useHistory } from "react-router-dom";
import CTA from "../../components/button/cta-button";
import { useEffect } from "react";
import { productService } from "../../_services/product.service";
import * as Yup from "yup";
import { useFormik } from "formik";
import { alertConstants, productConstants } from "../../_constants";
import InputAdornment from "@material-ui/core/InputAdornment";
import MoneyIcon from "@material-ui/icons/Money";
import NumberFormat from "react-number-format";
import { validatePhone } from "../../helpers";
import Spinner from "../../components/spinner";
import FormReview from "./form-review";
import GateWay from "./gate-way";
import { UserContext } from "../../context/user-context";
import queryString from "query-string";
import { MdReportProblem } from "react-icons/md";
import { fetchTransaction } from "../../_actions/product.actions";
import { AlertContext } from "../../context/alert-context";

const useStyles = makeStyles((theme) => ({
  root: {
    borderBottom: "1px solid red",
    borderRadius: 3,
    color: "white",
    height: 48,
    padding: "0 30px",
    flexGrow: 1,
  },
  searchField: {
    width: "400px",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  paperForm: {
    marginTop: "40px",
    padding: "40px",
    boxShadow: "0 7px 30px -10px rgba(150,170,180,0.5)",

    [theme.breakpoints.down("sm")]: {
      padding: "20px",
      marginLeft: "-10px",
    },
  },
  image: {
    width: 90,
    height: 90,
    marginTop: "5px",
    boxShadow: "0 7px 30px -10px rgba(150,170,180,0.5)",

    [theme.breakpoints.down("sm")]: {
      width: 90,
      height: 90,
    },
  },
  formTitle: {
    fontFamily: "Montserrat",
    fontWeight: "bold",
    fontSize: "25px",
    marginTop: "50px",
    marginLeft: "-30px",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "-30px",
      fontSize: "20px",
      fontWeight: "bold",
      marginTop: "40px",
    },
    [theme.breakpoints.down("xs")]: {
      marginLeft: "-20px",
      fontSize: "20px",
      fontWeight: "bold",
      marginTop: "40px",
    },
  },
  img: {
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
  },
  inputPadding: {
    paddingLeft: "40px",
    marginTop: "20px",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "0px",
    },
  },
  button: {
    display: "block",
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  formButtons: {
    marginTop: "20px",
    paddingLeft: "20px",
    marginLeft: "200px",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      marginLeft: "0px",
    },
  },
  productName: {
    fontSize: "25px",
    fontWeight: "bold",
    marginTop: "50px",
    marginLeft: "-40px",
    fontFamily: "Montserrat",
  },
  summaryFontBold: {
    fontWeight: "600",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      fontWeight: "bold",
    },
  },
}));

const PurchaseForm = () => {
  const pathUrl = decodeURI(window.location.pathname);
  const categoryName = decodeURI(pathUrl.split("/")[1]);
  const billerCode = decodeURI(pathUrl.split("/")[2]);
  const tranId = decodeURI(pathUrl.split("/")[3]);
  const {
    categories,
    products,
    fetchProducts,
    logTransactions,
    productDispatch,
  } = useContext(ProductContext);
  const classes = useStyles();
  const [product, setProduct] = React.useState({});
  const [category, setCategory] = React.useState({});
  const [formFields, setFormFields] = React.useState([]);
  const { loggedIn, user } = useContext(UserContext);
  const { dispatch } = useContext(AlertContext);
  const [validationsSchema, setValidationsSchema] = React.useState([]);
  const [formValue, setFormValue] = React.useState({});
  let _formStatus = productConstants.FORM_STATUS_INPUTTING;
  let amountField = "";
  let caption = "";
  const [state, setState] = React.useState({
    active: null,
    loading: false,
    listId: null,
    values: [],
    formValues: {},
    formStatus: _formStatus,
    gateWay: "PayXpress",
    user: user,
    transactionDetail: {},
    tranId: tranId,
    approvalStatus: "",
    billerCode: billerCode,
    loggedIn: loggedIn,
    redirect: false,
    fields: [],
    parameters: {},
  });

  const handleClose = () => {
    //setOpen(false);
  };

  const handleOpen = () => {
    //setOpen(state);
  };

  const handleBack = () => {
    setState({
      ...state,
      formStatus: productConstants.FORM_STATUS_INPUTTING,
    });
  };

  const loadTransaction = (tranId, fields, product) => {
    let _values = state.values;
    fetchTransaction(tranId).then((response) => {
      if (response && response.length > 0) {
        response.forEach((c) => {
          _values = {
            ..._values,
            [c.ControlId]: c.ControlValue,
          };
        });
      }

      setFormValue(_values);

      const params = queryString.parse(window.location.search);
      let _status = params["status"];
      let _formStatus = state.formStatus;
      if (typeof _status !== "undefined" && _status === "forwarded") {
        _formStatus = productConstants.FORM_STATUS_CONFIRMING;
      }
      setState({
        ...state,
        fields: fields,
        active: product,
        loading: false,
        formValues: _values,
        values: _values,
        formStatus: _formStatus,
      });
    });
  };

  useEffect(() => {
    const filterdCategory =
      !!categories &&
      categories.length > 0 &&
      categories.filter(function (obj) {
        return obj.UrlPath == categoryName;
      })[0];

    productDispatch({ type: productConstants.FETCH_PRODUCT_FIELDS });

    const fetchProduct = () => {
      !!products &&
        Object.entries(products).length !== 0 &&
        productService
          .fetchProduct(filterdCategory.CategoryCode, billerCode)
          .then(
            (data) => {
              productDispatch({
                type: productConstants.FETCH_PRODUCT_FIELDS_COMPLETED,
              });
              let _values = [];
              if (data) {
                const fields = data.Fields;
                fields.forEach((f) => {
                  _values = {
                    ..._values,
                    [f.Id]: "",
                  };
                });
                const product = data.Product;
                const validationSchema = getValidationSchema(fields);
                setValidationsSchema(validationSchema);
                setProduct(product);
                setFormFields(fields);
                setState({
                  loading: true,
                  productName: null,
                  formStatus: null,
                  customised: null,
                  fields: [],
                  user: user,
                });
                let _values = [];
                fields.forEach((f) => {
                  _values = {
                    ..._values,
                    [f.Id]: "",
                  };
                });
                // const { user, loggedIn } = this.props;
                if (!!state.loggedIn && state.user) {
                  //Email
                  var _email = fields.find(
                    (f) => f.FieldType === productConstants.FIELD_TYPE_EMAIL
                  );
                  if (_email) {
                    _values = {
                      ..._values,
                      [_email.Id]: state.user.ConsumerID,
                    };
                  }
                  var _phone = fields.find(
                    (f) => f.FieldType === productConstants.FIELD_TYPE_PHONE
                  );
                  if (_phone) {
                    _values = {
                      ..._values,
                      [_phone.Id]: state.user.PhoneNumber,
                    };
                  }
                }
                setFormValue(_values);
                setState({
                  ...state,
                  loading: false,
                  values: _values,
                  formStatus: productConstants.FORM_STATUS_INPUTTING,
                  active: product,
                  fields: fields,
                });

                const transId = state.tranId;
                if (transId !== "undefined") {
                  loadTransaction(transId, fields, product);
                }
              }
            },
            (error) => {
              dispatch({
                type: alertConstants.ERROR,
                message: error.toString(),
              });
              productDispatch({
                type: productConstants.FETCH_PRODUCT_FIELDS_COMPLETED,
              });
            }
          );
    };

    fetchProduct();
  }, [categories, products]);

  Yup.addMethod(Yup.string, "phone", function (msg) {
    return validatePhone(this, msg);
  });

  const getValidations = (flds) => {
    let _validations = {};
    flds.forEach((f) => {
      let _validation = null;
      let shouldValidate = false;
      if (f.InputSource === productConstants.CONTROL_INPUT_TYPE_USER) {
        switch (f.ControlType) {
          case productConstants.CONTROL_TYPE_SELECT:
            if (!!f.Required) {
              _validation = Yup.string("Enter " + f.FieldName).required(
                f.FieldName + " is required"
              );
              shouldValidate = true;
            }
            break;
          default:
            if (f.AmountField && !!f.AmountField) {
              let _minAmount = f.MinAmount > 0 ? f.MinAmount : 1;
              if (!!f.Required) {
                _validation = Yup.number("Enter " + f.FieldName).min(
                  _minAmount,
                  f.FieldName +
                    " should be greater than or equal to N" +
                    _minAmount
                );
                shouldValidate = true;
              }
              if (f.MaxAmount !== 0) {
                _validation = Yup.number("Enter " + f.FieldName)
                  .min(
                    _minAmount,
                    f.FieldName +
                      " should be greater than or equal to N" +
                      _minAmount
                  )
                  .max(
                    f.MaxAmount,
                    "Only amount less than or equal to N" +
                      f.MaxAmount.toLocaleString(navigator.language, {
                        minimumFractionDigits: 2,
                      }) +
                      " can be done"
                  );
                shouldValidate = true;
              }
            } else if (
              f.FieldType === productConstants.FIELD_TYPE_DECIMAL ||
              f.FieldType === productConstants.FIELD_TYPE_NUMBER
            ) {
              _validation = Yup.number("Enter a valid number").typeError(
                f.FieldName + " must be a number"
              );
              if (!!f.Required) {
                _validation = Yup.number("Enter a valid number")
                  .typeError(f.FieldName + " must be a number")
                  .min(1, f.FieldName + " is required");
              }
              shouldValidate = true;
            } else if (f.FieldType === productConstants.FIELD_TYPE_PHONE) {
              _validation = Yup.string("Enter " + f.FieldName)
                .typeError("Enter " + f.FieldName)
                .phone("Enter a valid telephone number");
              if (!!f.Required) {
                _validation = Yup.string("Enter " + f.FieldName)
                  .typeError("Enter " + f.FieldName)
                  .phone("Enter a valid telephone number")
                  .required(f.FieldName + " is required");
              }
              shouldValidate = true;
            } else if (f.FieldType === productConstants.FIELD_TYPE_EMAIL) {
              _validation = Yup.string("Enter " + f.FieldName)
                .typeError("Enter " + f.FieldName)
                .email("Enter a valid email address");
              if (!!f.Required) {
                _validation = Yup.string("Enter " + f.FieldName)
                  .typeError("Enter " + f.FieldName)
                  .email("Enter a valid email address")
                  .required(f.FieldName + " is required");
              }
              shouldValidate = true;
            } else {
              _validation = Yup.string("Enter " + f.FieldName).max(
                f.Size,
                f.FieldName + " can not be more than " + f.Size + " characters"
              );
              if (!!f.Required) {
                _validation = Yup.string("Enter " + f.FieldName)
                  .required(f.FieldName + " is required")
                  .max(
                    f.Size,
                    f.FieldName +
                      " can not be more than " +
                      f.Size +
                      " characters"
                  );
              }
              shouldValidate = true;
            }
            break;
        }
      }
      if (!!shouldValidate)
        _validations = {
          ..._validations,
          [f.Id]: _validation,
        };
      shouldValidate = false;
    });
    return _validations;
  };

  const getValidationSchema = (fields) => {
    if (fields) {
      const _validation = getValidations(fields);
      return Yup.object().shape(_validation);
    }
  };

  const {
    handleSubmit,
    handleChange,
    setFieldValue,
    setFieldTouched,
    dirty,
    touched,
    isValid,
    isSubmitting,
    resetForm,
    setSubmitting,
    values,
    errors,
    amountRef,
  } = useFormik({
    initialValues: formValue,
    enableReinitialize: true,
    validationSchema: validationsSchema,
    onSubmit(values) {
      switch (state.formStatus) {
        case productConstants.FORM_STATUS_INPUTTING:
          if (!!state.active.DoCustomerLookup) {
            var lookupInputs = state.fields.filter(
              (f) => !!f.CustomerLookupInput
            );
            if (lookupInputs && lookupInputs.length > 0) {
              var _req = {
                billerCode: state.active.BillerCode,
              };
              lookupInputs.forEach((l) => {
                var prop = l.CustomerLookupInputKey;
                var _value = values[l.Id];
                if (l.ControlType === productConstants.CONTROL_TYPE_SELECT) {
                  var _option = l.ListItems.find(
                    (item) => item.ItemValue === _value
                  );
                  if (_option) _value = _option.ItemDataValue;
                }
                _req = {
                  ..._req,
                  [prop]: _value,
                };
              });
              setState({
                ...state,
                loading: true,
              });
              productService.customerLookup(_req).then(
                (resp) => {
                  if (resp.ResponseCode === "00") {
                    setState({
                      ...state,
                      loading: false,
                    });
                    lookupCallBack(resp, values);
                  }
                },
                (error) => {
                  setState({
                    ...state,
                    loading: false,
                  });
                  dispatch({
                    type: alertConstants.ERROR,
                    message: error.toString(),
                  });
                }
              );
            } else {
              setState({
                ...state,
                formValues: values,
                values: values,
                formStatus: productConstants.FORM_STATUS_CONFIRMING,
              });
            }
          } else {
            setState({
              ...state,
              formValues: values,
              values: values,
              formStatus: productConstants.FORM_STATUS_CONFIRMING,
            });
          }
          break;
        case productConstants.FORM_STATUS_LOOKEDUP:
          setState({
            ...state,
            formValues: values,
            values: values,
            formStatus: productConstants.FORM_STATUS_CONFIRMING,
          });
          break;
        default:
          break;
      }
    },
  });

  const change = (name, e) => {
    e.target.name = name;
    setFieldTouched(name, true, false);
    handleChange(name, e.target.value);
    setFieldValue(name, e.target.value);
  };

  const lookupCallBack = (lookup, values) => {
    var _values = values;
    var toPopulate = state.fields.filter(
      (f) => f.InputSource === productConstants.CONTROL_INPUT_TYPE_LOOKUP
    );
    if (toPopulate && toPopulate.length > 0) {
      toPopulate.forEach((l) => {
        var prop = l.InputSourceKey.toLowerCase();
        _values = {
          ..._values,
          [l.Id]: lookup.Properties[prop],
        };
      });
      let _formStatus = productConstants.FORM_STATUS_CONFIRMING;
      let amountFld = state.fields.find((f) => !!f.AmountField);
      if (
        amountFld &&
        amountFld.AmountEditable &&
        amountFld.InputSource === productConstants.CONTROL_INPUT_TYPE_LOOKUP
      )
        _formStatus = productConstants.FORM_STATUS_LOOKEDUP;
      setState({
        ...state,
        loading: false,
        values: _values,
        formStatus: _formStatus,
      });
    }
  };

  const populateAmount = (name, e) => {
    const amount = e._targetInst.pendingProps["data-amount"];
    setFieldValue(amountField, amount);
    change(name, e);
  };

  const getFee = (amount, billerCode) => {
    var fee = 0;
    let product = products.find((p) => p.BillerCode === billerCode);
    if (product) {
      switch (product.ChargeType) {
        case productConstants.CHARGE_TYPE_FIXED:
          fee = product.Charge;
          break;
        case productConstants.CHARGE_TYPE_PERCENTAGE:
          fee = (product.Charge * amount) / 100;
          break;
        default:
          break;
      }
    }
    return fee;
  };

  const isDisabled = (f) => {
    var _isDisabled = true;
    if (!!f.AmountField) {
      switch (state.formStatus) {
        case productConstants.FORM_STATUS_LOOKEDUP:
          if (
            f.InputSource === productConstants.CONTROL_INPUT_TYPE_LOOKUP &&
            !!f.AmountEditable
          ) {
            _isDisabled = false;
          }
          break;
        case productConstants.FORM_STATUS_INPUTTING:
          var populator = state.fields.filter(
            (f) => f.LookupListAmountFieldId > 0
          );
          if (populator.length === 0) {
            _isDisabled = false;
          } else if (!!f.AmountEditable && populator.length > 0) {
            _isDisabled = false;
          }
          break;
        default:
          break;
      }
    } else {
      _isDisabled =
        f.InputSource === productConstants.CONTROL_INPUT_TYPE_LOOKUP;
    }
    return _isDisabled;
  };

  const handleClear = () => {
    setFormValue({});
    resetForm({});
  };
  const logCallBack = (response) => {
    setState({
      formStatus: productConstants.FORM_STATUS_CONFIRMED,
      parameters: response,
    });
  };

  const pay = (reviewValues) => {
    const { fields, values, active, tranId, approvalStatus, gateWay } = state;
    var _controls = [];
    fields.forEach((f) => {
      var _value = values[f.Id];
      if (_value) {
        var _control = {
          ControlId: f.Id,
          ControlValue: _value,
        };
        _controls.push(_control);
      }
    });

    var _req = {
      WControls: _controls,
      BillCode: active.BillerCode,
      TranId: tranId,
      ApprovalStatus: approvalStatus,
      PaymentMode: gateWay,
      ProductName: active.ProductName,
      ...reviewValues,
    };
    setState({
      ...state,
      loading: true,
    });
    productService.logTransaction(_req).then(
      (resp) => {
        setState({
          ...state,
          loading: false,
        });
        logCallBack(resp);
      },
      (error) => {
        setState({
          ...state,
          loading: false,
        });
      }
    );
  };

  return (
    <div style={{ padding: 20 }}>
      {fetchProducts || state.loading ? (
        <>
          <Spinner message="Loading...please wait" />
        </>
      ) : (
        <>
          <Grid container spacing={1}>
            <Grid item xs={6} sm={3} md={2}>
              <Paper className={(classes.paper, classes.image)}>
                <img
                  className={classes.img}
                  alt="mtn"
                  src={"data:image/png;base64," + product.ProductImage}
                />
              </Paper>
            </Grid>
            <Grid item xs={6} sm={9} md={10}>
              <Typography
                variant="h4"
                component="h2"
                className={classes.productName}
              >
                {product.ProductName}
              </Typography>
            </Grid>
          </Grid>
          {state.formStatus == productConstants.FORM_STATUS_INPUTTING && (
            <>
              <Grid>
                <Paper className={classes.paperForm}>
                  {formFields &&
                    formFields.map((f) => {
                      if (
                        f.AmountField &&
                        !!f.AmountField &&
                        f.MaxAmount !== 0
                      ) {
                        return (
                          <p
                            style={{
                              background: "#ffa000",
                              padding: "10px",
                              color: "white",
                              borderRadius: "3px",
                              boxShadow:
                                "0 0px 8px rgba(0,0,0,0.1), 0 1px 10px rgba(0,0,0,0.1)",
                            }}
                          >
                            <MdReportProblem /> Please note that only
                            transactions worth less than or equal to N
                            {f.MaxAmount.toLocaleString(navigator.language, {
                              minimumFractionDigits: 2,
                            })}{" "}
                            can be done for {product.ProductName} on this
                            platform at a time.
                          </p>
                        );
                      }
                    })}
                  <form onSubmit={handleSubmit}>
                    <Grid container spacing={1}>
                      {formFields &&
                        formFields.map((f) => {
                          if (f.AmountField && !!f.AmountField) {
                            amountField = f.Id;
                          }
                          var _inputProps = {};
                          if (f.AmountField !== null) {
                            _inputProps = {
                              ..._inputProps,
                              editable: f.AmountEditable.toString().toUpperCase(),
                            };
                          }
                          const controlId = f.Id;
                          if (
                            f.ListItems &&
                            f.ListItems.length === 1 &&
                            values[controlId] === ""
                          ) {
                            values[controlId] = f.ListItems[0].ItemValue;
                          }
                          switch (f.ControlType) {
                            case "LOOKUP":
                              if (
                                (f.InputSource ===
                                  productConstants.CONTROL_INPUT_TYPE_USER &&
                                  (state.formStatus ===
                                    productConstants.FORM_STATUS_INPUTTING ||
                                    state.formStatus ===
                                      productConstants.FORM_STATUS_LOOKEDUP)) ||
                                (f.InputSource ===
                                  productConstants.CONTROL_INPUT_TYPE_LOOKUP &&
                                  state.formStatus ===
                                    productConstants.FORM_STATUS_LOOKEDUP)
                              ) {
                                return (
                                  <Grid
                                    item
                                    className={classes.inputPadding}
                                    xs={12}
                                    sm={6}
                                    md={4}
                                    key={f.Id}
                                  >
                                    <FormControl
                                      fullWidth
                                      className={classes.formControl}
                                    >
                                      <InputLabel shrink id={f.Id}>
                                        {/* {f.FieldName} */}
                                        {f.Required
                                          ? f.FieldName + " *"
                                          : f.FieldName}
                                      </InputLabel>
                                      <Select
                                        key={controlId}
                                        id={controlId}
                                        name={controlId}
                                        // labelId={f.FieldName}
                                        label={
                                          f.Required
                                            ? f.FieldName + " *"
                                            : f.FieldName
                                        }
                                        // helperText={touched.name ? errors.name : ""}
                                        helperText={
                                          touched[controlId]
                                            ? errors[controlId]
                                            : ""
                                        }
                                        error={
                                          touched[controlId] &&
                                          Boolean(errors[controlId])
                                        }
                                        value={values[controlId]}
                                        onChange={
                                          f.LookupListAmountFieldId &&
                                          f.LookupListAmountFieldId > 0
                                            ? (e) =>
                                                populateAmount(controlId, e)
                                            : (e) => change(controlId, e)
                                        }
                                        selectProps={{
                                          displayEmpty: true,
                                          MenuProps: {
                                            classes: {
                                              paper: classes.dropdownStyle,
                                            },
                                          },
                                        }}
                                        InputProps={{
                                          startAdornment: (
                                            <InputAdornment position="start">
                                              {f.AmountField &&
                                                !!f.AmountField && (
                                                  <MoneyIcon />
                                                )}
                                            </InputAdornment>
                                          ),
                                        }}
                                        margin="normal"
                                        disabled={isDisabled(f)}
                                      >
                                        {f.ListItems &&
                                          f.ListItems.length !== 1 && (
                                            <MenuItem
                                              key="initial"
                                              value=""
                                              data-amount=""
                                            >
                                              -- Select --
                                            </MenuItem>
                                          )}
                                        {f.ListItems &&
                                          f.ListItems.length > 0 &&
                                          f.ListItems.map(
                                            ({
                                              Id,
                                              ItemDesc,
                                              Amount,
                                              ItemValue,
                                            }) => {
                                              return (
                                                <MenuItem
                                                  key={Id}
                                                  value={ItemValue}
                                                  data-amount={Amount}
                                                >
                                                  {ItemDesc}
                                                </MenuItem>
                                              );
                                            }
                                          )}
                                      </Select>
                                    </FormControl>
                                  </Grid>
                                );
                              }
                            default:
                              if (
                                (f.InputSource ===
                                  productConstants.CONTROL_INPUT_TYPE_USER &&
                                  (state.formStatus ===
                                    productConstants.FORM_STATUS_INPUTTING ||
                                    state.formStatus ===
                                      productConstants.FORM_STATUS_LOOKEDUP)) ||
                                (f.InputSource ===
                                  productConstants.CONTROL_INPUT_TYPE_LOOKUP &&
                                  state.formStatus ===
                                    productConstants.FORM_STATUS_LOOKEDUP)
                              ) {
                                return (
                                  <Grid
                                    item
                                    className={classes.inputPadding}
                                    xs={12}
                                    sm={6}
                                    md={4}
                                    key={f.Id}
                                  >
                                    {f.AmountField ? (
                                      <TextField
                                        key={controlId}
                                        id={controlId}
                                        name={controlId}
                                        // labelId={f.FieldName}
                                        required={f.Required}
                                        helperText={
                                          touched[controlId]
                                            ? errors[controlId]
                                            : ""
                                        }
                                        error={
                                          touched[controlId] &&
                                          Boolean(errors[controlId])
                                        }
                                        label={f.FieldName}
                                        value={values[controlId] || ""}
                                        onChange={change.bind(null, f.Id)}
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                        style={{ margin: 8 }}
                                        placeholder={"Enter " + f.FieldName}
                                        fullWidth
                                        margin="normal"
                                        autoComplete="off"
                                        type={
                                          !!f.AmountField ? "text" : f.FieldType
                                        }
                                        inputRef={
                                          !!f.AmountField
                                            ? amountRef
                                            : React.createRef()
                                        }
                                        InputProps={{
                                          inputComponent: NumberFormatCustom,
                                          startAdornment: (
                                            <InputAdornment position="start">
                                              {f.AmountField &&
                                                !!f.AmountField && (
                                                  <MoneyIcon />
                                                )}
                                            </InputAdornment>
                                          ),
                                        }}
                                        inputProps={_inputProps}
                                        disabled={isDisabled(f)}
                                      />
                                    ) : (
                                      <TextField
                                        key={controlId}
                                        id={controlId}
                                        name={controlId}
                                        helperText={
                                          touched[controlId]
                                            ? errors[controlId]
                                            : ""
                                        }
                                        error={
                                          touched[controlId] &&
                                          Boolean(errors[controlId])
                                        }
                                        label={
                                          f.Required
                                            ? f.FieldName + " *"
                                            : f.FieldName
                                        }
                                        // value={values}
                                        value={values[controlId] || ""}
                                        onChange={change.bind(null, f.Id)}
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                        style={{ margin: 8 }}
                                        placeholder={"Enter " + f.FieldName}
                                        fullWidth
                                        margin="normal"
                                        autoComplete="off"
                                        type={
                                          !!f.AmountField ? "text" : f.FieldType
                                        }
                                        inputRef={
                                          !!f.AmountField
                                            ? amountRef
                                            : React.createRef()
                                        }
                                        inputProps={_inputProps}
                                        disabled={isDisabled(f)}
                                      />
                                    )}
                                  </Grid>
                                );
                              }
                          }
                        })}
                    </Grid>
                    <Grid className={classes.formButtons}>
                      <CTA
                        type="button"
                        noBackground
                        text="CLEAR"
                        action={handleClear}
                      />
                      <CTA
                        medium
                        disabled={!(isValid && dirty)}
                        text="PROCEED"
                        type="submit"
                      />
                    </Grid>
                  </form>
                </Paper>
              </Grid>
            </>
          )}
          {state.formStatus === productConstants.FORM_STATUS_CONFIRMING && (
            <>
              <FormReview
                user={state.user}
                fields={state.fields}
                handleBack={handleBack}
                values={state.values}
                formValues={state.formValues}
                getFee={(amt) => getFee(amt, state.active.BillerCode)}
                handleSubmit={pay}
              />
            </>
          )}
          {state.formStatus === productConstants.FORM_STATUS_CONFIRMED && (
            <GateWay classes parameters={state.parameters} />
          )}
        </>
      )}
    </div>
  );
};

export default PurchaseForm;

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      thousandSeparator
      prefix="&#8358; "
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};
