//import config from 'react-global-configuration';
import configuration from "../config";

import { productConstants } from "../_constants";
import { alertActions } from ".";
import { handleResponse } from "../helpers";
import { authHeader } from "../_helpers";
import { productService } from "../_services";

export const fetchCategories = async () => {
  const requestOptions = {
    method: "GET",
    headers: {
      ...authHeader(),
      "Content-Type": "application/json",
    },
  };
  return fetch(
    `${configuration.apiUrl}/Products/Categories`,
    requestOptions
  ).then(handleResponse);
};

export const fetchProducts = async () => {
  const requestOptions = {
    method: "GET",
    headers: {
      ...authHeader(),
      "Content-Type": "application/json",
    },
  };
  return fetch(`${configuration.apiUrl}/Products`, requestOptions).then(
    handleResponse
  );
};

export const fetchProduct = (categoryCode, billerCode) => {
  return productService.fetchProduct(categoryCode, billerCode).then(
    (resp) => {
      return resp;
    },
    (error) => {
      return error;
    }
  );
};

export const fetchCategoryAndProducts = (category, items) => {
  let products = [];
  if (items && items.length > 0) {
    return {
      type: productConstants.FILTER_CATEGORY_PRODUCTS,
      payload: products,
      category,
    };
  } else {
    return {
      type: productConstants.FILTER_CATEGORY_PRODUCTS,
      payload: [],
      category: [],
    };
  }
};

export const fetchServerProducts = async (category) => {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  fetch(
    `${configuration.apiUrl}/Products/GetCategoryProducts/?CategoryCode=${category}`,
    requestOptions
  )
    .then(handleResponse)
    .then(
      (resp) => {
        return resp;
      },
      (error) => {}
    );
};

export const fetchAllProducts = async () => {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  fetch(`${configuration.apiUrl}/Products`, requestOptions)
    .then(handleResponse)
    .then(
      (resp) => {
        return resp;
      },
      (error) => {}
    );
};

export const fetchDashboardTransactionHistory = () => {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  let _url = `${configuration.apiUrl}/Reports/FetchDashBoardTransactionHistory`;
  return fetch(_url, requestOptions).then(handleResponse);
};

export const clearCategory = () => ({
  type: productConstants.CLEAR_CATEGORY
});

export const fetchFields = async (billerCode, category) => {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  let _url = `${configuration.apiUrl}/Products/Fields/?BillerCode=${billerCode}&Category=${category}`;
  fetch(_url, requestOptions)
    .then(handleResponse)
    .then(
      (resp) => {
        return resp;
      },
      (error) => {}
    );
};

export const customerLookup = (_req, _success, _failure) => (dispatch) => {
  dispatch(request());
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(_req),
  };
  var _url = `${configuration.apiUrl}/Products/Lookup`;
  fetch(_url, requestOptions)
    .then((response) => {
      return response.text().then((text) => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
          const error = (data && data.ResponseMsg) || response.statusText;
          return Promise.reject(error);
        }
        return data;
      });
    })
    .then(
      (resp) => {
        dispatch(success(resp));
        if (resp !== null && resp.ResponseCode === "00") {
          if (!!_success) {
            _success();
          }
        } else {
          dispatch(alertActions.error(resp.ResponseMsg));
          if (!!_failure) {
            _failure();
          }
        }
      },
      (error) => {
        dispatch(failure());
        dispatch(alertActions.error(error.toString()));
        if (!!_failure) {
          _failure();
        }
      }
    );

  function request() {
    return { type: productConstants.CUSTOMER_LOOKUP_ACTION, payload: {} };
  }
  function success(data) {
    return { type: productConstants.CUSTOMER_LOOKUP_ACTION, payload: data };
  }
  function failure() {
    return { type: productConstants.CUSTOMER_LOOKUP_ACTION, payload: {} };
  }
};

export const logTransaction = (_req, _success, _failure) => {
  const req = {
    requestData: _req,
  };
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(req),
  };
  var _url = `${configuration.apiUrl}/Products/LogTransaction`;
  return fetch(_url, requestOptions).then(handleResponse);

  function request() {
    return { type: productConstants.TRANSACTION_INITIATION, payload: {} };
  }
  function success(data) {
    return { type: productConstants.TRANSACTION_INITIATION, payload: data };
  }
  function failure() {
    return { type: productConstants.TRANSACTION_INITIATION, payload: {} };
  }
};

export const logWalletTransaction = (_req, _success, _failure) => (
  dispatch
) => {
  dispatch(request());
  const req = {
    requestData: _req,
  };
  const requestOptions = {
    method: "POST",
    headers: {
      ...authHeader(),
      "Content-Type": "application/json",
    },
    body: JSON.stringify(req),
    // mode: 'no-cors',
  };
  var _url = `${configuration.apiUrl}/Products/LogTransaction`;
  fetch(_url, requestOptions)
    .then(handleResponse)
    .then(
      (resp) => {
        dispatch(success(resp));
        if (!!_success) {
          _success(resp);
        }
      },
      (error) => {
        dispatch(failure());
        dispatch(alertActions.error(error.toString()));
        if (!!_failure) {
          _failure();
        }
      }
    );

  function request() {
    return {
      type: productConstants.TRANSACTION_INITIATION,
      payload: {},
    };
  }

  function success(data) {
    return {
      type: productConstants.TRANSACTION_INITIATION,
      payload: data,
    };
  }

  function failure() {
    return {
      type: productConstants.TRANSACTION_INITIATION,
      payload: {},
    };
  }
};

export const notifyBiller = (_req) => {
  let req = {};
  if (!!_req.url && _req.url !== "") {
    req = {
      requestData: {
        PaymentMethodString: _req.PaymentMethodString,
        transId: _req.TranId,
      },
    };
  } else {
    req = {
      requestData: {
        PaymentMethodString: _req.PaymentMethodString,
        TranId: _req.TranId,
      },
    };
  }
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(req),
  };

  const path = _req.url ? _req.url : "Products/NotifyBiller";
  var _url = `${configuration.apiUrl}/${path}`;
  return fetch(_url, requestOptions).then(handleResponse);
};

export const fetchTransaction = (id) => {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  return fetch(
    `${configuration.apiUrl}/Products/FetchTransactionValues?TranId=${id}`,
    requestOptions
  ).then(handleResponse);
};

export const rateTransaction = (_req) => {
  const req = {
    requestData: _req,
  };
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(req),
  };
  var _url = `${configuration.apiUrl}/Ratings/RateTransaction`;
  return fetch(_url, requestOptions).then(handleResponse);
};

export const fetchDashboardItems = (_success, _failure) => (dispatch) => {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  let _url = `${configuration.apiUrl}/Reports/FetchDashboardItems`;
  fetch(_url, requestOptions)
    .then(handleResponse)
    .then(
      (resp) => {
        if (!!_success) {
          _success(resp);
        }
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
        if (!!_failure) {
          _failure();
        }
      }
    );
};
export const searchProducts = (q, _success, _failure) => (dispatch) => {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  let _url = `${configuration.apiUrl}/Products/Search?q=${q}`;
  fetch(_url, requestOptions)
    .then(handleResponse)
    .then(
      (resp) => {
        if (!!_success) {
          _success(resp);
        }
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
        if (!!_failure) {
          _failure();
        }
      }
    );
};
export const getReceipt = async (Id) => {
  let data = {};
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  let _url = `${configuration.apiUrl}/Reports/FetchReceipt?Id=${Id}`;
  await fetch(_url, requestOptions)
    .then(handleResponse)
    .then(
      (resp) => {
        data = resp;
      },
      (error) => {}
    );
  return data;
};
export const searchTransaction = (req, _success, _failure) => (dispatch) => {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  let _url = `${configuration.apiUrl}/Reports/FetchTransactionByDate?datefrom=${req.startDate}&dateto=${req.endDate}`;
  fetch(_url, requestOptions)
    .then(handleResponse)
    .then(
      (resp) => {
        if (!!_success) {
          _success(resp);
        }
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
        if (!!_failure) {
          _failure();
        }
      }
    );
};

export const fetchTransactionHistory = () => {
  const requestOptions = {
    method: "GET",
    headers: {
      ...authHeader(),
      "Content-Type": "application/json",
    },
  };
  let _url = `${configuration.apiUrl}/Reports/FetchTransactionHistory`;
  return fetch(_url, requestOptions).then(handleResponse);
};

export const fetchTransactionHistorySearch = (req) => {
  const requestOptions = {
    method: "GET",
    headers: {
      ...authHeader(),
      "Content-Type": "application/json",
    },
  };
  let startDate = req.startDate ? req.startDate : "";
  let endDate = req.startDate ? req.endDate : "";
  let paymentRef = req.paymentRef ? req.paymentRef : "";
  let _url = `${configuration.apiUrl}/Reports/FetchTransactionHistorySearch?datefrom=${startDate}&dateto=${endDate}&paymentRef=${paymentRef}`;
  return fetch(_url, requestOptions).then(handleResponse);
};

export const fetchWalletBalance = (_req) => {
  const requestOptions = {
    method: "POST",
    headers: {
      ...authHeader(),
      "Content-Type": "application/json",
    },
    body: JSON.stringify(_req),
  };
  var _url = `${configuration.apiUrl}/Products/GetWalletBalance`;
  return fetch(_url, requestOptions).then((response) => response.json());
};

export const fetchFieldsForTopUp = () => {
  const requestOptions = {
    method: "GET",
    headers: {
      ...authHeader(),
      "Content-Type": "application/json",
    },
  };
  let _url = `${configuration.apiUrl}/Products/FieldsForTopUp`;
  return fetch(_url, requestOptions).then(handleResponse);
};
