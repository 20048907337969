import React, { useContext, useEffect } from "react";
import { InputLabel, Input, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { theme } from "../../theme";
import Modal from "@material-ui/core/Modal";
import CTA from "../button/cta-button";
import styles from "../../pages/login/login.module.css";
import { Row, Col } from "react-bootstrap";
import { Button } from "@material-ui/core";
import { TextField, ThemeProvider } from "@material-ui/core";
import OnboardingInput from "../inputs/onboarding-input";
import { MdRestore, MdEmail, MdSettingsBackupRestore } from "react-icons/md";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import Spinner from "../../components/spinner";
import { UserContext } from "../../context/user-context";
import { AlertContext } from "../../context/alert-context";

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "fixed",
    width: 500,
    border: "none",
    outline: "none",
    padding: theme.spacing(2, 4, 3),
    background: "none",
    [theme.breakpoints.down("sm")]: {
      position: "absolute",
      width: 270,
      left: "-70px",
      top: "10%",
    },
    [theme.breakpoints.between("md")]: {
      position: "absolute",
      width: 270,
      left: "-100px",
      top: "10%",
    },
    [theme.breakpoints.up("lg")]: {
      // backgroundColor: 'orange',
    },
  },
  modal: {
    // background: "rgba(237, 219, 219, 0.9)",
    // background:'rgba(240, 218, 213, 0.9)'
    background: "rgba(32, 28, 27, 0.8)",
  },
  button: {
    marginLeft: "90%",
    marginBottom: "40px",
    background: "none",
    "&:hover": {
      background: "none",
    },
    "&:focus": {
      outline: "none",
      background: "none",
    },
  },
  reset: {
    marginLeft: "100px",
    fontSize: "40px",
    color: "#FF6C00",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "20px",
      marginRight: "10px",
    },
  },
  backupEmail: {
    transition: "all .3s ease-in",
    "&:focus": {
      outline: "none",
    },
    "&:hover": {
      color: "green",
    },
  },
}));

const AddBackupEmail = (style) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const history = useHistory();

  const {
    user,
    submitBackEmail,
    clearMessage,
    updatingBackupEmail,
    updateBackupEmailMessage,
  } = useContext(UserContext);
  const { dispatch } = useContext(AlertContext);

  const validationSchema = Yup.object().shape({
    backupEmail: Yup.string("Enter Your Email")
      .email("Enter a Valid Email")
      .typeError("Enter Your Email")
      .required("Email is Required"),
  });

  const clearForm = () => {
    this.setState({
      backupEmail: user.BackupEmail,
    });
  };
  useEffect(() => {
    if (updateBackupEmailMessage) {
      history.push("/");
    }
  });

  const {
    handleSubmit,
    handleChange,
    setFieldTouched,
    dirty,
    touched,
    isValid,
    isSubmitting,
    resetForm,
    setSubmitting,
    values,
    errors,
  } = useFormik({
    initialValues: {
      email: user.ConsumerID,
      backupEmail: user.BackupEmail,
    },
    validationSchema,
    onSubmit(values) {
      submitBackEmail(values, dispatch);
    },
  });

  const change = (name, e) => {
    e.persist();
    handleChange(e);
    setFieldTouched(name, true, false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <>
        <div style={style}>
          <button
            onClick={handleOpen}
            style={{ background: "none", border: "none" }}
            className={classes.backupEmail}
          >
            <h6 style={{ fontSize: "14px" }}>Add Backup Email</h6>
          </button>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            className={classes.modal}
            disableBackdropClick="true"
          >
            <form onSubmit={handleSubmit}>
              <div
                style={{ marginLeft: "35%", marginTop: "8%" }}
                className={classes.paper}
              >
                {updatingBackupEmail && (
                  <Spinner message="Loading...please wait" />
                )}
                <Button
                  style={{}}
                  className={classes.button}
                  onClick={handleClose}
                >
                  <div className={styles.right}></div>
                  <div className={styles.left}></div>
                </Button>
                <h2 className={styles.loginTitle} style={{}}>
                  Add Backup Email
                </h2>
                <Grid container>
                  <Grid item sm={12} md={12} lg={12}>
                    <OnboardingInput
                      placeholder="Add Backup Email"
                      name="backupEmail"
                      type="email"
                      value={values.backupEmail}
                      onChange={change.bind(null, "backupEmail")}
                      style={{ marginBottom: "20px" }}
                      icon={<MdEmail />}
                    />
                    {errors.backupEmail && touched.backupEmail ? (
                      <div className="pt-error">{errors.backupEmail}</div>
                    ) : null}
                  </Grid>
                  <Grid item sm={12} md={12} lg={12}>
                    <Grid container>
                      <Grid item md={12} lg={12} sm={12}>
                        <CTA
                          text="continue"
                          large
                          disabled={!(isValid && dirty) || isSubmitting}
                          style={{ marginTop: "10px" }}
                          type="submit"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </form>
          </Modal>
        </div>
      </>
    </ThemeProvider>
  );
};

export default AddBackupEmail;
