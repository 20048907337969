export const transactionConstants = {
    FETCH_DASHBOARD_TRANSACTION: 'FETCH_DASHBOARD_TRANSACTION',
    SENDING_REQUEST: 'SENDING_REQUEST',

    FETCH_TRANSACTION_HISTORY: 'FETCH_TRANSACTION_HISTORY',
    TRANSACTION_HISTORY_REQUEST: 'TRANSACTION_HISTORY_REQUEST',

    FETCH_TRANSACTION_HISTORY_SEARCH: 'FETCH_TRANSACTION_HISTORY_SEARCH',
    TRANSACTION_HISTORY_SEARCH_REQUEST: 'TRANSACTION_HISTORY_SEARCH_REQUEST',
};
