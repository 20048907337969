import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Divider from "@material-ui/core/Divider";
// import NineMobile from "../../assets/images/billers/01bb8ce9ff3f1fec98ec7b51bc7672d3.jpg";
// import MtnAirtime from "../../assets/images/billers/79bd69cc7328f8be6dd7afea13ce7816.jpg";
// import MtnAwuf from "../../assets/images/billers/mtn-awuf.png";
// import Glo from "../../assets/images/billers/glo.jpg";
// import Airtel from "../../assets/images/billers/airtel.jpg";
// import Ntel from "../../assets/images/billers/ntel-airtime.png";
// import AirtelSmart from "../../assets/images/billers/airtel-10x.png";
// import BulkUpload from "../../assets/images/billers/bulk-airtime.png";
// import AirtelData from "../../assets/images/billers/airtel-10x.png";
// import GloData from "../../assets/images/billers/glo data.png";
import Typography from "@material-ui/core/Typography";
import { Paper } from "@material-ui/core";
import { FaAngleDown, FaReplyAll } from "react-icons/fa";
import { TransactionContext } from "../../context/transaction-context";
import { getMonthName, truncate } from "../../helpers";
import { useHistory } from "react-router";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 460,
    backgroundColor: theme.palette.background.paper,
    borderRadius: " 0px 0px 5px 5px",
    // boxShadow:
    // "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
    boxShadow: "0 7px 30px -10px rgba(150,170,180,0.5)",
    maxHeight: "62vh",
    overflowY: "scroll",
    marginRight: "0 !important",
    padding: "0 !important",
    "&::-webkit-scrollbar": {
      width: "10px",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#006D01",
      borderRadius: " 0px 0px 5px 5px",
    },
  },
  listItem: {
    height: "85px",
    paddingLeft: "5px",

    borderLeft: "5px solid green",
    borderBottomLeftRadius: "6px",
    borderTopLeftRadius: "3px",
  },
  listItem2: {
    height: "85px",
    paddingLeft: "5px",
    borderLeft: "5px solid red",
    borderBottomLeftRadius: "6px",
    borderTopLeftRadius: "3px",
  },
  headerContainer: {
    marginBottom: "-18px",
    background: " #C4C4C4",
    borderRadius: "none !important",
    padding: "2px",
  },
  header: {
    textAlign: "center",
    textTransform: "uppercase",
    fontSize: "12px",
    letterSpacing: "0.2em",
  },
}));

export default function ActivityLog(props) {
  const classes = useStyles();
  const history = useHistory();
  const { transactions, loading } = props;

  const [phoneNumberLebels, setPhoneNumberLebel] = useState([]);
  const getData = () => {
    fetch("../resources/activtity_log.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        setPhoneNumberLebel(data);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const getPhoneNumber = (receiptItems) => {
    let b = receiptItems.filter(function (item) {
      if (phoneNumberLebels.includes(item.Label.toLowerCase())) return item;
    })[0];
    if (!!b) {
      return b.Value;
    }
  };

  return (
    <div>
      <Paper elevation={0} className={classes.headerContainer}>
        <p className={classes.header}>{getMonthName()}</p>
      </Paper>
      <List className={classes.root}>
        {/* {loading && <div>... loading</div>} */}
        {!!transactions &&
          transactions.length > 0 &&
          transactions.map(
            ({
              ProductImage,
              ProductName,
              Date,
              Amount,
              Id,
              ApprovalStatus,
              UrlPath,
              BillerCode,
              ReceiptItems,
            }) => {
              return (
                <div key={Id} className="activity-log">
                  {ApprovalStatus !== "Failed" ? (
                    <ListItem key={Id} className={classes.listItem}>
                      <ListItemAvatar>
                        <img
                          src={"data:image/png;base64," + ProductImage}
                          alt={ProductName}
                          style={{ width: "50px", height: "50px" }}
                        />
                      </ListItemAvatar>
                      {/* <ListItem key={2}> */}
                      <Grid container alignItems="center">
                        <Grid item xs={8} sm={8} md={8} lg={8}>
                          <div className="primary-text">
                            <p>{truncate(`${ProductName}`, 20, "...")}</p>
                          </div>
                          <div className="tertiary-text">
                            <small>{getPhoneNumber(ReceiptItems)}</small>
                          </div>
                          <div className="secondary-text">
                            <small>{Date}</small>
                          </div>
                        </Grid>
                        <Grid item xs={4} sm={4} md={4} lg={4}>
                          <div className="amount">
                            <p style={{ color: "green" }}>
                              N
                              {Amount.toLocaleString(navigator.language, {
                                minimumFractionDigits: 2,
                              })}
                            </p>
                            <button
                              className="replay-btn"
                              onClick={() => {
                                history.push(
                                  `/${UrlPath}/${BillerCode}/${Id}?status=forwarded`
                                );
                              }}
                            >
                              Replay <FaReplyAll />
                            </button>
                          </div>
                        </Grid>
                      </Grid>
                      {/* </ListItem> */}
                    </ListItem>
                  ) : (
                    <ListItem key={Id} className={classes.listItem2}>
                      <ListItemAvatar>
                        <img
                          src={"data:image/png;base64," + ProductImage}
                          alt={ProductName}
                          style={{ width: "50px", height: "50px" }}
                        />
                      </ListItemAvatar>
                      {/* <ListItem key={2}> */}
                      <Grid container alignItems="center">
                        <Grid item xs={8} sm={8} md={8} lg={8}>
                          <div className="primary-text">
                            <p>{truncate(`${ProductName}`, 20, "...")}</p>
                          </div>
                          <div className="tertiary-text">
                            <small>{getPhoneNumber(ReceiptItems)}</small>
                          </div>
                          <div className="secondary-text">
                            <small>{Date}</small>
                          </div>
                        </Grid>
                        <Grid item xs={4} sm={4} md={4} lg={4}>
                          <div className="amount">
                            <p style={{ color: "red" }}>
                              N
                              {Amount.toLocaleString(navigator.language, {
                                minimumFractionDigits: 2,
                              })}
                            </p>
                            <button
                              className="replay-btn"
                              onClick={() => {
                                history.push(
                                  `/${UrlPath}/${BillerCode}/${Id}?status=forwarded`
                                );
                              }}
                            >
                              Replay <FaReplyAll />
                            </button>
                          </div>
                        </Grid>
                      </Grid>
                      {/* </ListItem> */}
                    </ListItem>
                  )}
                  <Divider variant="inset" component="li" />
                </div>
              );
            }
          )}
        {loading && <div>Loading...</div>}
        {!loading && !!transactions && transactions.length == 0 && (
          <div>No record found for this month</div>
        )}
      </List>
    </div>
  );
}
