import { createMuiTheme } from "@material-ui/core/styles";

const breakpointValues = {
  xs: 0,
  sm: 450,
  md: 600,
  lg: 1040,
  xl: 1300,
};

// Create a theme instance.
export const theme = createMuiTheme({
  breakpoints: {
    values: breakpointValues,
  },
  palette: {
    primary: {
      main: "#ff6c00",
    },
    secondary: {
      main: "#006d01",
    },
  },
  typography: {
    fontFamily: "century gothic",
    body2: {
      fontFamily: "century gothic",
      // fontSize: "1.1rem"
    },
  },
  shape: {
    borderRadius: 3,
  },
  spacing: 8,
  overrides: {
    MuiFilledInput: {
      root: {
        backgroundColor: "white",
      },
    },
    MuiInputLabel: {
      root: {
        backgroundColor: "transparent",
      },
    },
    MuiTextField: {
      root: {},
    },
    MuiButton: {
      root: {
        textTransform: "none",
        padding: "20px",
      },
      fullWidth: {
        maxWidth: "300px",
      },
    },
    MuiBackdrop: {
      backgroundColor: "rgba(0,0,0,0.2)",
    },
  },
  props: {
    MuiButton: {
      disableRipple: true,
      variant: "contained",
      color: "primary",
    },
    MuiCheckbox: {
      disableRipple: true,
    },
    MuiTextField: {
      // variant: "filled",
    },
    MuiPaper: {
      elevation: 12,
      boxShadow: "0 7px 30px -10px rgba(150,170,180,0.5)",
    },
    MuiCard: {
      elevation: 12,
    },
  },
});
