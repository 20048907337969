import React from "react";
import {
  Grid,
  TextField,
  ThemeProvider,
  Typography,
  Paper,
  Link,
  Tooltip,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { theme } from "../../theme";
import Spinner from "../../components/spinner";
import { useHistory, useRouteMatch } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  searchField: {
    width: "400px",
    marginTop: "10px",
    marginBottom: "10px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "15px",
      // display:'none'
    },
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  categoryName: {
    fontFamily: "Montserrat",
    fontWeight: "bold",
    fontSize: "25px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "17px",
      fontWeight: "bold",
    },
  },
  image: {
    width: 120,
    height: 120,
    borderRadius: 10,
    // boxShadow: "2px 2px 20px rgba(0, 0, 0, 0.25)",
    boxShadow: "0 7px 30px -10px rgba(150,170,180,0.5)",

    [theme.breakpoints.down("sm")]: {
      width: 90,
      height: 90,
      // display:'none'
    },
  },
  img: {
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
    [theme.breakpoints.down("sm")]: {
      width: 90,
      height: 90,
      // display:'none'
    },
  },
  tooltip: {
    textAlign: "center",
  },
}));

// const handleProduct = () => {
//   history.push(`/buy-tickets/events`);
// };
const url = "wallet/ewallet";
const preventDefault = (url) => {
  window.history.push(url);
};

export default function XpressWallet() {
  const pathUrl = window.location.pathname;
  const history = useHistory();
  const classes = useStyles();
  const pathName = decodeURI(pathUrl.split("/")[1]);
  return (
    <div style={{ padding: 20 }}>
      <Grid container spacing={6}>
        <Grid item xs={6} sm={3} md={6}>
          <form noValidate autoComplete="off">
            <TextField
              className={classes.searchField}
              placeholder="search for billers e.g mtn"
              fullWidth
              id="standard-basic"
            />
          </form>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item md={12}>
          <Typography variant="h4" component="h2">
            Xpress Wallet
          </Typography>
        </Grid>
        <Grid item xs={6} sm={3} md={2}>
          <Paper className={(classes.paper, classes.image)}>
            <Link
              onClick={() => {
                history.push(`${url}`);
              }}
            >
              <Tooltip
                //   key={Id}
                className={classes.tooltip}
                title="Xpress Wallet"
                aria-label="xpress-wallet"
              >
                <img
                  className={classes.img}
                  alt={""}
                  src="/resources/images/wallet.png"
                />
              </Tooltip>
            </Link>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}
