import React from "react";
import styles from "./footer.module.css";
import {
  FaFacebookSquare,
  FaInstagram,
  FaLinkedin,
  FaTwitter,
} from "react-icons/fa";
import { Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
import Policy from "../policy-popover/policy-popover";

const Footer = () => {
  return (
    <div className={styles.footer}>
      <div className={styles.about}>
        {/* <p>&copy; Xpress Payments Solutions Limited</p> */}
        <a href="https://www.xpresspayments.com/" target="_blank">
          &copy; Xpress Payments Solutions Limited
        </a>
      </div>

      <div className={styles.socialIcons}>
        <a
          href="https://www.facebook.com/PayXpress-1272115002926656/"
          target="_blank"
        >
          <FaFacebookSquare />
        </a>
        <a
          href="https://www.linkedin.com/company/xpresspayments/"
          target="_blank"
        >
          <FaLinkedin />
        </a>
        <a href="https://twitter.com/PayXpressNG" target="_blank">
          <FaInstagram />
        </a>
        <a href="https://www.instagram.com/payxpressng/" target="_blank">
          <FaTwitter />
        </a>
      </div>
      <div className={styles.info}>
        <h6>
          <Link to={"/about"} style={{ fontSize: "13px" }}>
            About Us
          </Link>
        </h6>
        <h6>
          <Link to={"/overview"} style={{ fontSize: "13px" }}>
            Overview
          </Link>
        </h6>
        <Policy />
      </div>
      <div className={styles.downloads}>
        <a
          href="https://play.google.com/store/apps/details?id=com.xpresspayments.xpresspay"
          target="_blank"
        >
          <img src="/resources/images/btn-google-play.png" alt="playstore" />
        </a>
        <a href="https://www.payxpress.com/_blank">
          <img src="/resources/images/btn-app-store.png" alt="appstore" />
        </a>
      </div>
    </div>
  );
};

export default Footer;
