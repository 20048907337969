import React from 'react';
import styles from './input.module.css';
import PropTypes from 'prop-types'
import InputLabel from '@material-ui/core/InputLabel'



const BorderlessInput = ({ icon,value,onChange,id, style,placeholder, onboarding}) => {
    BorderlessInput.propTypes ={
        onChange:PropTypes.func,
        text: PropTypes.string,
        // icon: PropTypes.symbol,
    };
    return (
        <div>
        <span
        style={{
        //   position: "relative",
        position:'absolute',
          zIndex: "20",
        //   marginLeft: "10px",
        left:"70%",
          top:"52%",
          color: "red",
          fontSize: "18px",
          opacity: ".4",
        }}
      >
        {icon}
      </span>
        <input className={styles.borderless}
             type="text" placeholder={placeholder}
            value={value} icon={icon}
            onChange={onChange} 
            style={style} 
            id={id}
            autofocus="true"
     />
        </div >
    )
}


       

export default BorderlessInput;


