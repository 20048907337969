import React, { useContext } from "react";
import {
  BrowserRouter as Router,
  // Switch,
  // Route,
  Link,
  Route,
} from "react-router-dom";
import UpdateProfile from "../profiles/update-profile";
import ChangePassword from "../profiles/change-password";
import AllTransactionHistory from "../../pages/Transaction-History";
import AddBackupEmail from "../profiles/backup-email";
import { UserContext } from "../../context/user-context";
import { Tooltip } from "@material-ui/core";
import { truncate } from "../../helpers";

const ProfileDropdown = (style) => {
  const { user } = useContext(UserContext);
  return (
    <div className="profile-dropdown" style={style}>
      <Tooltip
        key="1"
        title={`${user.Firstname} ${user.Surname}`}
        aria-label={`${user.Firstname} ${user.Surname}`}
      >
        <h5
          className="dropdown-toggle"
          id="dropdownMenuButton"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          style={{ textTransform: "capitalize", marginLeft: "-40px" }}
        >
          {/* {user.Firstname} {user.Surname} */}
          {truncate(`${user.Firstname}`, 10, "...")}
        </h5>
      </Tooltip>
      {/* <Router> */}
      <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
        <UpdateProfile />

        <ChangePassword />
        <AddBackupEmail />
        <Link
          style={{
            color: "black",
            transition: "all .3s ease-in",
            textDecoration: "none",
            marginLeft: "8px",
            fontSize: "14px",
            "&:focus": { color: "green" },
          }}
          to="/transaction-history"
        >
          Transaction History{" "}
        </Link>
      </div>
    </div>
  );
};

export default ProfileDropdown;
