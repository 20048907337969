import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import EnhancedTableHead from "./EnhanceTableHead"
import EnhancedTableToolbar from "./EnhancedTableToolbar"
import TablePagination from '@material-ui/core/TablePagination';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { downloadPdf, print, formatDate, truncate} from '../../helpers'

const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing.unit * 1,
        height:'auto'
    },
    table: {
    //minWidth: 1020,
    },
    tableWrapper: {
        overflowX: 'auto',
    },
    tableCell : {
        whiteSpace: 'nowrap',
        padding: '0px 8px 0px 8px !important',
        fontSize: '0.75rem !important'
    },
    pdfTable:{ 
        //maxHeight: '400px', 
        margin: theme.spacing.unit * 3,    
    },
    pdfTableHeader:{
        backgroundColor:'#eeeeee' ,
        fontWeight:700
    }
  });
  //const rowHeight = 35;
  function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
  
  function stableSort(array, cmp) {
      const stabilizedThis = array.map((el, index) => [el, index]);
      stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
      });
      return stabilizedThis.map(el => el[0]);
    }
    
    function getSorting(order, orderBy) {
      return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
    }
  

class EnhancedTable extends React.Component {
    constructor(props) {
        super(props);
        const { data } = props;
        this.state = {
            order: this.props.order,
            orderBy: this.props.orderBy,
            selected: [],
            selectedData:[],
            data: this.processData(data),
            page: 0,
            rowsPerPage: 5,
            mainData:data
        };
    }
    processData(data){    
        let _data = [];
        if(data && data.length > 0){
            _data = data.map((el, i) => {
                let item = {
                    ...el, 
                    rowId:i
                };
                return item
            });
        }
        return _data;
    }
    formatCellValue(v, f){
        switch(f.type){
            case 'date':
                v = formatDate(v,'DD-MMM-YYYY');
                break;
            case 'currency':
                v = v.toLocaleString(navigator.language, { minimumFractionDigits: 0 });
                break;
            default:
                break;
        }
        return v;
    }
    componentWillReceiveProps(props) {
        const { mainData } = this.props;
        if (props.data !== mainData) {
            this.setState(
            {
                data:this.processData(props.data),
                mainData:props.data
            });
        }
    }
    handleRequestSort = (event, property) => {
        const orderBy = property;
        let order = 'desc';
    
        if (this.state.orderBy === property && this.state.order === 'desc') {
          order = 'asc';
        }
    
        this.setState({ order, orderBy });
      };
    
      handleSelectAllClick = event => {
        if (event.target.checked) {
          this.setState(state => ({ selected: state.data.map(n => n.rowId), selectedData:state.data }));
          return;
        }
        this.setState({ selected: [], selectedData:[] });
      };

    _exporter;

    handleDownloadExcel = evt  => {
        this._exporter.save();
    }

    getPrintableContent = () => {
        const { selectedData } = this.state;
        const { columns, classes } = this.props;
        let content = (
        <Table className={classes.pdfTable} aria-labelledby="tableTitle">
            <TableHead>
                <TableRow className={classes.pdfTableHeader}>
                    {columns.map(column => {
                    return (
                        <TableCell
                        key={column.Id}
                        numeric={column.Numeric}
                        align={column.Numeric?'right':'left'}
                        padding={column.DisablePadding ? 'none' : 'default'}
                        >
                            {column.Label}
                        </TableCell>
                    );
                    }, this)}
                </TableRow>
            </TableHead>
            <TableBody>
            {selectedData.map(row => {
                return (
                <TableRow
                    key={row.id}
                >
                    {columns.map(column => {
                        return (
                            <TableCell
                                key={column.Id}
                                align={column.Numeric?'right':'left'}
                                padding={column.DisablePadding ? 'none' : 'default'}
                                className={classes.tableCell}
                                style={{whiteSpace: 'nowrap !important'}}
                            >
                               {column.Format ? this.formatCellValue(row[column.Id], column.Format)  : row[column.Id]}
                            </TableCell>
                        );}, this)}
                </TableRow>
            );
            })}
            </TableBody>
        </Table>
        );
       return content;
    }

    handleDownloadPdf = () => {
        const { pdfFileName } = this.props;
        let content = this.getPrintableContent();
        downloadPdf(content, pdfFileName);
    }

    handlePrint = () => {
        let content = this.getPrintableContent();
        print(content);
    }
    
      handleClick = (event, id) => {
        const { selected } = this.state;
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];
    
        if (selectedIndex === -1) {
          newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
          newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
          newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
          newSelected = newSelected.concat(
            selected.slice(0, selectedIndex),
            selected.slice(selectedIndex + 1),
          );
        }
        let _selectedData = this.state.data.filter( f => newSelected.indexOf(f.rowId) > -1);
        this.setState({ 
            selected: newSelected,
            selectedData:_selectedData
        });
      };
    
      handleChangePage = (event, page) => {
        this.setState({ page });
      };
    
      handleChangeRowsPerPage = event => {
        this.setState({ rowsPerPage: event.target.value });
      };
    isSelected = id => this.state.selected.indexOf(id) !== -1;

    render() {
        const { selectedData , order, orderBy, selected, rowsPerPage, page, data } = this.state;
        const { classes, columns, excelFileName, 
                showToolbar, paging, showAction,
                actionOption, onCreateAction  } = this.props;
        //const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

        return (
        <React.Fragment>
            <ExcelExport
                data={selectedData}
                headerPaddingCellOptions={{ background: '#ff0000' }}
                fileName={excelFileName}
                ref={(exporter) => { this._exporter = exporter; }}
            >
                {columns.map(column => {
                    return (
                        <ExcelExportColumn 
                            key={column.Id}
                            field={column.Id}
                            title={column.Value} 
                        />                           
                    );
                    }, this)}
            </ExcelExport>
        <Paper className={classes.root}>
        { !!showToolbar && <EnhancedTableToolbar 
            numSelected={selected.length} 
            onExportExcel={this.handleDownloadExcel}
            onExportPdf={this.handleDownloadPdf}
            onPrint={this.handlePrint}
        />}
        <div className={classes.tableWrapper}>
        <Table className={classes.table} aria-labelledby="tableTitle">
            <EnhancedTableHead
                classes={classes}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={this.handleSelectAllClick}
                onRequestSort={this.handleRequestSort}
                rowCount={data.length}
                columns={columns}
                showCheckbox={!!showToolbar}
                showAction={!!showAction}
            />
            <TableBody>
            {stableSort(data, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(n => {
                const isSelected = this.isSelected(n.rowId);
                return (
                    <TableRow
                        hover
                        onClick={event => this.handleClick(event, n.rowId)}
                        role="checkbox"
                        aria-checked={isSelected}
                        tabIndex={-1}
                        key={n.rowId}
                        selected={isSelected}
                    >
                        {!!showToolbar && <TableCell padding="checkbox">
                            <Checkbox checked={isSelected} />
                        </TableCell>}
                        {columns.map(column => {
                            return (
                                <TableCell
                                    key={column.Id}
                                    numeric={column.Numeric}
                                    padding={column.DisablePadding ? 'none' : 'default'}
                                    className={classes.tableCell}
                                >
                                    {column.Format ? truncate(this.formatCellValue(n[column.Id], column.Format), 35)  :  truncate(n[column.Id], 35)}
                                </TableCell>
                            );}, this)}
                        {!!showAction &&    <TableCell
                                                className={classes.tableCell}
                                            >
                            {onCreateAction(actionOption, n)}
                        </TableCell>}
                    </TableRow>
                );
                })}
            {data.length === 0 && (
                // <TableRow style={{ height: rowHeight * emptyRows }}>
                <TableRow>
                    <TableCell align='center' colSpan={columns.length + (!!showToolbar ? 1 : 0) + (!!showAction ? 1 : 0)}>
                    No records
                    </TableCell>
                </TableRow>
            )}
            </TableBody>
        </Table>
        </div>
        {!!paging &&<TablePagination
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
                'aria-label': 'Previous Page',
            }}
            nextIconButtonProps={{
                'aria-label': 'Next Page',
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
        />}
        </Paper>
    </React.Fragment>
    )
    }
}
  
    EnhancedTable.propTypes = {
        classes: PropTypes.object.isRequired,
        columns: PropTypes.array.isRequired,
        data: PropTypes.array.isRequired,
  };
export default withStyles(styles)(EnhancedTable);