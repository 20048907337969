import { transactionConstants } from '../_constants';

export function transactionReducer(state = {}, action) {
    switch (action.type) {
        case transactionConstants.SENDING_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case transactionConstants.FETCH_DASHBOARD_TRANSACTION:
            return {
                loading: false,
                transactions: action.payload
            }; 

        case transactionConstants.TRANSACTION_HISTORY_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case transactionConstants.FETCH_TRANSACTION_HISTORY:
            return {
                loading: false,
                transactions: action.payload
            };
        case transactionConstants.TRANSACTION_HISTORY_SEARCH_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case transactionConstants.FETCH_TRANSACTION_HISTORY_SEARCH:
            return {
                loading: false,
                transactions: action.payload
            };

        default:
            return state
    }
}