import React, { useState } from "react";
import {
  Grid,
  TextField,
  Paper,
  ThemeProvider,
  Typography,
  MenuItem,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { theme } from "../../theme";
import { useHistory } from "react-router-dom";
import CTA from "../../components/button/cta-button";
import { FaArrowLeft } from "react-icons/fa";
import * as Yup from "yup";
import { useFormik } from "formik";
import { validatePhone } from "../../helpers";
import { useEffect } from "react";
import { productConstants } from "../../_constants";
import { getTotalAmount } from "../../_actions/event.actions";
import TicketSummary from "./event-ticket-summary";
import Spinner from "../../components/spinner";
import { logTransaction } from "../../_actions/event.actions";
import GateWay from "../../components/gate-way";
const useStyles = makeStyles((theme) => ({
  root: {
    borderBottom: "1px solid red",
    borderRadius: 3,
    color: "white",
    height: 48,
    padding: "0 30px",
    flexGrow: 1,
  },
  searchField: {
    width: "400px",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  paperForm: {
    marginTop: "20px",
    padding: "40px",
    boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.3)",
    [theme.breakpoints.down("sm")]: {
      padding: "20px",
      marginLeft: "-10px",
    },
  },
  image: {
    width: 90,
    height: 90,
    marginTop: "5px",
    boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.3)",
    [theme.breakpoints.down("sm")]: {
      width: 90,
      height: 90,
    },
  },
  formTitle: {
    fontFamily: "Montserrat",
    fontWeight: "bold",
    fontSize: "25px",
    marginTop: "50px",
    marginLeft: "-30px",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "-30px",
      fontSize: "20px",
      fontWeight: "bold",
      marginTop: "40px",
    },
    [theme.breakpoints.down("xs")]: {
      marginLeft: "-20px",
      fontSize: "20px",
      fontWeight: "bold",
      marginTop: "40px",
    },
  },
  img: {
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
  },
  inputPadding: {
    paddingLeft: "40px",
    marginTop: "10px",
    width: "90%",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "0px",
    },
  },
  button: {
    display: "block",
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  formButtons: {
    marginTop: "20px",
    paddingLeft: "20px",
    marginLeft: "200px",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      marginLeft: "0px",
    },
  },
  productName: {
    fontSize: "25px",
    fontWeight: "bold",
    marginTop: "40px",
    marginLeft: "-80px",
    fontFamily: "Montserrat",
    textTransform: "uppercase",

    [theme.breakpoints.down("sm")]: {
      marginLeft: "-50px",
      fontSize: "15px",
    },
  },
  summaryFontBold: {
    fontWeight: "600",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      fontWeight: "bold",
    },
  },
  buttonDiv: {
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "20px",
    width: "250px",
  },
}));

Yup.addMethod(Yup.string, "phone", function (msg) {
  return validatePhone(this, msg);
});
const validationSchema = Yup.object().shape({
  phone: Yup.string("Enter Your Phone Number")
    .phone("Enter a Valid Number")
    .typeError("Enter Your Phone Number")
    .required("Phone Number is Required"),
  email: Yup.string("Enter Your Email")
    .email("Enter a Valid Email")
    .typeError("Enter Your Email")
    .required("Email is Required"),
  fullName: Yup.string("Enter Your Full Name")
    .typeError("Enter Your Full Name")
    .min(5, "Your Full Name must be at least 5 Characters long")
    .required("Full Name is Required"),
  ticket: Yup.string("Select ticket type")
    .typeError("Select ticket type")
    .required("Select ticket type"),
  amount: Yup.number("Enter valid amount")
    .typeError("Enter valid amount")
    .required("Amount is Required"),
  quantity: Yup.number("Enter valid quantity")
    .min(1, "Quantity must be at least 1")
    .typeError("Enter valid quantity")
    .required("Quantity is Required"),
});
const EventTicketForm = (props) => {
  const {
    user,
    loggedIn,
    event,
    handleGoBack,
    handleStatus,
    formStatus,
    products,
  } = props;

  const history = useHistory();
  const classes = useStyles();

  const [state, setState] = useState({
    amount: "",
    TranId: "",
    gateWay: "PayXpress",
    event: event,
    values: "",
    billerCode: "",
    formStatus: formStatus,
    parameters: {},
    products: products,
  });

  const handleProceed = () => {
    history.push("/tickets/events/order-summary");
  };
  useEffect(() => {
    const pathUrl = decodeURI(window.location.pathname);
    const billerCode = decodeURI(pathUrl.split("/")[2]);
    const tranId = decodeURI(pathUrl.split("/")[3]);
    let _values = {};
    // if (event) {
    // if (typeof tranId !== "undefined" && tranId !== "" && values) {
    //   //alert(tranId);
    //   setState({
    //     ...state,
    //     TranId: tranId,
    //   });
    //   _values = values;
    // } else 
    
    if (event.ticketClassses && event.ticketClassses.length > 0) {
      let _class = event.ticketClassses[0];
      _values = {
        quantity: 1,
        ticket: _class.classid,
        venue: _class.venue,
        amount: _class.price,
        price: _class.price,
      };
    }
    // }
    if (!!loggedIn && user) {
      _values = {
        ..._values,
        email: user.ConsumerID,
        phone: user.PhoneNumber,
        fullName: `${user.Firstname} ${user.Surname}`,
      };
    }

    setState({
      ...state,
      loading: false,
      values: _values,
      billerCode: billerCode,
    });
  }, [event]);
  const {
    handleSubmit,
    handleChange,
    setFieldValue,
    setFieldTouched,
    dirty,
    touched,
    isValid,
    isSubmitting,
    resetForm,
    setSubmitting,
    values,
    errors,
    amountRef,
  } = useFormik({
    initialValues: {
      ...state.values,
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit(values) {
      setState({
        ...state,
        values: values,
        formStatus: productConstants.FORM_STATUS_CONFIRMING,
      });
    },
  });

  const getAmt = (_values) => {
    const { values, billerCode } = state;
    let _amount = "";
    const _val = _values ? _values : values;
    if (_val.price && _val.quantity) {
      _amount = parseFloat(_val.price) * parseFloat(_val.quantity);
    }
    if (_amount > 0) {
      setState({
        ...state,
        loading: true,
      });
      let ProductId = 0;
      const product = products.find((p) => p.BillerCode === billerCode);
      if (product) ProductId = product.Id;
      let _req = {
        TotalAmount: _amount,
        ProductId: ProductId,
      };
      getTotalAmount(_req).then(
        (response) => {
          if (response && !isNaN(response)) {
            _amount = response;
          }
          setState({
            ...state,
            values: {
              ...values,
              ..._val,
              amount: _amount,
            },
          });
        },
        (error) => {
          setState({
            // loading: false,
          });
        }
      );
    }
  };

  const handleProdceed = (reviewValueObject) => {
    const { tranId, gateWay, billerCode, values } = state;

    var _req = {
      TranId: tranId,
      PaymentMode: gateWay,
      BillerCode: billerCode,
      ...reviewValueObject.reviewValues,
    };
    logTransaction(_req).then(
      (response) => {
        setState({
          ...state,
          parameters: response,
          formStatus: productConstants.FORM_STATUS_CONFIRMED,
        });
      },
      (error) => {}
    );
  };

  const change = async (name, e) => {
    e.target.name = name;
    setFieldValue(name, e.target.value);
    await handleChange(name, e.target.value);
    setFieldTouched(name, true, false);
  };

  // const onTicketChange = async (name, e) => {
  //   e.target.name = name;
  //   setFieldValue(name, e.target.value);
  //   await handleChange(name, e.target.value);
  //   setFieldTouched(name, true, false);

  //   const price = e._targetInst.pendingProps["data-price"];
  //   const venue = e._targetInst.pendingProps["data-venue"];
  //   setFieldValue("price", price);
  //   setFieldValue("venue", venue);
  //   await handleChange("price", price);
  //   await handleChange("venue", venue);
  //   getAmt();
  // };

  const onTicketChange = async (name, e) => {
    e.target.name = name;
    let _values = state.values;
    setFieldValue(name, e.target.value);
    await handleChange(name, e.target.value);
    setFieldTouched(name, true, false);

    const price = e._targetInst.pendingProps["data-price"];
    const venue = e._targetInst.pendingProps["data-venue"];
    setFieldValue("price", price);
    setFieldValue("venue", venue);
    await handleChange("price", price);
    await handleChange("venue", venue);
    _values = {
      ..._values,
      [name]: e.target.value,
      price: price,
    };
    setState(
      {
        ...state,
        values: _values,
        price: price,
      },
      getAmt(_values)
    );
  };

  const onQtyChange = async (name, e) => {
    e.target.name = name;
    let _values = state.values;
    _values = {
      ..._values,
      [name]: e.target.value,
    };

    setFieldValue(name, e.target.value);
    await handleChange(name, e.target.value);
    setFieldTouched(name, true, false);
    setState(
      {
        ...state,
        values: _values,
      },
      getAmt(_values)
    );
  };
  return (
    <>
      {state.loading && (
        <ThemeProvider theme={theme}>
          <Spinner message="Loading...please wait" />
        </ThemeProvider>
      )}
      {event && state.formStatus === productConstants.FORM_STATUS_INPUTTING && (
        <div style={{ padding: 20 }}>
          <ThemeProvider theme={theme}>
            <Grid container spacing={1}>
              <Grid item xs={6} sm={3} md={2}>
                <Paper className={(classes.paper, classes.image)}>
                  <img
                    className={classes.img}
                    alt={event.title}
                    src={event.eventArtwork}
                  />
                </Paper>
              </Grid>
              <Grid item xs={6} sm={9} md={10}>
                <Typography
                  variant="h4"
                  component="h2"
                  className={classes.productName}
                >
                  {event.title}
                </Typography>
              </Grid>
            </Grid>
            <Grid>
              <Paper className={classes.paperForm}>
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={3}>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      className={classes.inputPadding}
                    >
                      <TextField
                        //className={classes.textField}
                        autoComplete="off"
                        fullWidth
                        required
                        id="fullName"
                        name="fullName"
                        label="Full Name"
                        placeholder="Full Name"
                        helperText={touched.fullName ? errors.fullName : ""}
                        error={touched.fullName && Boolean(errors.fullName)}
                        onChange={(e) => change("fullName", e)}
                        margin="normal"
                        value={values.fullName}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      className={classes.inputPadding}
                    >
                      <TextField
                        className={classes.textField}
                        autoComplete="off"
                        required
                        fullWidth
                        id="email"
                        name="email"
                        label="Email Address"
                        placeholder="Email Address"
                        helperText={touched.email ? errors.email : ""}
                        error={touched.email && Boolean(errors.email)}
                        onChange={(e) => change("email", e)}
                        margin="normal"
                        value={values.email}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      className={classes.inputPadding}
                    >
                      <TextField
                        className={classes.textField}
                        required
                        fullWidth
                        autoComplete="off"
                        value={values.phone}
                        id="phone"
                        name="phone"
                        label="Mobile Number"
                        placeholder="Mobile Number"
                        helperText={touched.phone ? errors.phone : ""}
                        error={touched.phone && Boolean(errors.phone)}
                        onChange={(e) => change("phone", e)}
                        margin="normal"
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      className={classes.inputPadding}
                    >
                      <TextField
                        id="ticket"
                        select
                        required
                        fullWidth
                        className={classes.textField}
                        value={values.ticket}
                        name="ticket"
                        label="Ticket Class"
                        helperText={touched.ticket ? errors.ticket : ""}
                        error={touched.ticket && Boolean(errors.ticket)}
                        onChange={(e) => onTicketChange("ticket", e)}
                        SelectProps={{
                          displayEmpty: true,
                          MenuProps: {
                            classes: { paper: classes.dropdownStyle },
                          },
                        }}
                        margin="normal"
                      >
                        {event.ticketClassses.map((option) => (
                          <MenuItem
                            key={option.classid}
                            value={option.classid}
                            data-price={option.price}
                            data-venue={option.venue}
                          >
                            {option.title}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      className={classes.inputPadding}
                    >
                      <TextField
                        autoComplete="off"
                        fullWidth
                        className={classes.textField}
                        value={values.quantity}
                        type="number"
                        required
                        id="quantity"
                        name="quantity"
                        label="Quantity"
                        placeholder="Quantity"
                        helperText={touched.quantity ? errors.quantity : ""}
                        error={touched.quantity && Boolean(errors.quantity)}
                        onChange={(e) => onQtyChange("quantity", e)}
                        margin="normal"
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      className={classes.inputPadding}
                    >
                      <TextField
                        className={classes.textField}
                        required
                        fullWidth
                        disabled
                        label="Total Amount"
                        value={values.amount}
                        id="amount"
                        name="amount"
                        helperText={touched.amount ? errors.amount : ""}
                        error={touched.amount && Boolean(errors.amount)}
                        onChange={(e) => change("amount", e)}
                        margin="normal"
                        autoComplete="off"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <div className={classes.buttonDiv}>
                        <CTA
                          noBackground
                          type="button"
                          text="Back"
                          icon={<FaArrowLeft />}
                          action={(e) => {
                            e.preventDefault();
                            handleGoBack();
                          }}
                        />
                        <CTA
                          medium
                          text="proceed"
                          type="submit"
                          // action={handleProceed}
                        />
                      </div>
                    </Grid>
                  </Grid>
                </form>
              </Paper>
            </Grid>
          </ThemeProvider>
        </div>
      )}
      {event &&
        state.formStatus === productConstants.FORM_STATUS_CONFIRMING && (
          <TicketSummary {...state} handleProdceed={handleProdceed} />
        )}

      {state.formStatus == productConstants.FORM_STATUS_CONFIRMED && (
        <>
          <GateWay classes parameters={state.parameters} />
        </>
      )}
    </>
  );
};

export default EventTicketForm;
