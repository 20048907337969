import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const dashboardSliders = [
  {
    id: 0,
    img: "/resources/images/lagos.jpg",
  },
  {
    id: 1,
    img: "/resources/images/lagos.jpg",
  },
  {
    id: 2,
    img: "/resources/images/lagos.jpg",
  },
  {
    id: 3,
    img: "/resources/images/lagos.jpg",
  },
];

export default class SimpleSlider extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 10000,
      adaptiveHeight: true,
    };
    return (
      <div>
        <Slider {...settings}>
          {dashboardSliders.map(({ id, img }) => {
            return (
              <div>
                <img src={img} alt={id} />
              </div>
            );
          })}
        </Slider>
      </div>
    );
  }
}
