import { Grid } from "@material-ui/core";
import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import { AlertContext } from "../../context/alert-context";
import { ActivationContext } from "../../context/activation-context";
import Spinner from "../../components/spinner";

export default function ConfirmAccount() {
  const history = useHistory();
  const {
    activate,
    activationMessage,
    errorMessage,
    resetMessage,
    activating,
  } = useContext(ActivationContext);
  const { dispatch } = useContext(AlertContext);

  const param = window.location.search;
  const _token = param.split("?")[1]
  useEffect(() => {
    activate(_token);
  }, []);

  return (
    <div className="confirm-account">
      <Grid container>
        <Grid item lg={7} md={7} sm={12}>
          <div className="activate-account-info">
            <h1>Account Activation</h1>
            {activating && <Spinner message="Loading...please wait" />}
            {activationMessage ? (
              <>
                <p
                  style={{
                    fontSize: "20px",
                    fontWeight: "700",
                    color: "green",
                  }}
                >
                  {activationMessage}
                </p>
                <i>
                  You can start making payments. Online transaction is easy with
                  payxpress.
                </i>
                <Link to="/" style={{ display: "block" }}>
                  Click here to continue
                </Link>
              </>
            ) : (
              <>
                <p
                  style={{ fontSize: "20px", fontWeight: "700", color: "red" }}
                >
                  {errorMessage}
                </p>
                <Link to="/" style={{ display: "block" }}>
                  Click here to continue
                </Link>
              </>
            )}
          </div>
        </Grid>
        <Grid item lg={5} md={5} sm={12}>
          <div className="activation-illustration">
            <img
              src="/resources/images/access-activation.svg"
              alt="illustration"
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
