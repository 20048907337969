import React, { useContext, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Input from "../inputs/input";
import CTA from "../button/cta-button";
import PasswordInput from "../inputs/password-input";
import styles from "../../pages/login/login.module.css";
import { Row, Col } from "react-bootstrap";
import { Button } from "@material-ui/core";
import OnboardingInput from "../inputs/onboarding-input";
import { FaUserLock } from "react-icons/fa";
import { MdAccountCircle, MdEmail } from "react-icons/md";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { useFormik, Formik, Field, Form, ErrorMessage } from "formik";
import Spinner from "../../components/spinner";
import { UserContext } from "../../context/user-context";
import { AlertContext } from "../../context/alert-context";

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "fixed",
    width: 500,
    border: "none",
    outline: "none",
    padding: theme.spacing(2, 4, 3),
    background: "none",
    [theme.breakpoints.down("sm")]: {
      position: "absolute",
      width: 270,
      left: "-70px",
      top: "10%",
    },
    [theme.breakpoints.between("md")]: {
      position: "absolute",
      width: 270,
      left: "-100px",
      top: "10%",
    },
    [theme.breakpoints.up("lg")]: {
      // backgroundColor: 'orange',
    },
  },
  modal: {
    // background: "rgba(237, 219, 219, 0.9)",
    // background:'rgba(240, 218, 213, 0.9)'
    background: "rgba(32, 28, 27, 0.8)",
  },
  button: {
    marginLeft: "90%",
    marginBottom: "40px",
    background: "none",
    "&:hover": {
      background: "none",
    },
    "&:focus": {
      outline: "none",
      background: "none",
    },
  },
  changePassword: {
    transition: "all .3s ease-in",
    "&:focus": {
      outline: "none",
    },
    "&:hover": {
      color: "green",
    },
  },
}));
// style
export default function ChangePassword() {
  const classes = useStyles();
  const history = useHistory();
  const [open, setOpen] = React.useState(false);

  const {
    user,
    changePassword,
    clearMessage,
    changingPassword,
    logout,
    changePasswordMessage,
  } = useContext(UserContext);
  const { dispatch } = useContext(AlertContext);

  Yup.addMethod(Yup.string, "password", function () {
    return this.test({
      name: "password",
      exclusive: true,
      message:
        "Please choose a strong password. Password must meet the following criteria; 1. At least one block letter. 2. At least one small letter. 3. At least one number. 4. At least one special character",
      test: (value) => {
        try {
          //Regular Expressions.
          var regex = [];
          regex.push("[A-Z]"); //Uppercase Alphabet.
          regex.push("[a-z]"); //Lowercase Alphabet.
          regex.push("[0-9]"); //Digit.
          regex.push("[$@$!%*#?&]"); //Special Character.
          var passed = 0;
          //Validate for each Regular Expression.
          for (var i = 0; i < regex.length; i++) {
            if (new RegExp(regex[i]).test(value)) {
              passed++;
            }
          }
          //Validate for length of Password.
          if (passed > 2 && value.length > 8) {
            passed++;
          }
          return passed >= 2;
        } catch (e) {
          return false;
        }
      },
    });
  });

  const validationSchema = Yup.object().shape({
    oldpwd: Yup.string("Enter Your Old Password")
      .typeError("Enter Your Old Password")
      //.password("Enter a Valid Password")
      .required("Password is Required"),
    password: Yup.string("Enter Your New Password")
      .typeError("Enter Your New Password")
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        "Please choose a strong password. Password must meet the following criteria; 1. At least one block letter. 2. At least one small letter. 3. At least one number. 4. At least one special character"
      )
      .required("Password is Required"),
    confirmpwd: Yup.string("Confirm Your New Password")
      .typeError("Confirm Your New Password")
      .oneOf([Yup.ref("password")], "Passwords do not match")
      .required("Confirm Password is Required"),
  });

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const change = (name, e) => {
    e.persist();
    handleChange(e);
    setFieldTouched(name, true, false);
  };
  const clearForm = () => {
    this.setState({
      //email: "",
      oldpwd: "",
      password: "",
      confirmpwd: "",
    });
  };
  useEffect(() => {
    if (changePasswordMessage && changingPassword == false) {
      logout()
      // history.push("/");
    }
  }, []);

  const {handleSubmit, handleChange, setFieldTouched, dirty, touched, isValid, isSubmitting, resetForm, setSubmitting, values, errors} = useFormik({
    initialValues: {
      // email: user.ConsumerID,
      oldpwd: "",
      password: "",
      confirmpwd: "",
    },
    validationSchema,
    onSubmit(values, { setSubmitting }) {
      values.email = user.ConsumerID;
      changePassword(values, dispatch);
      setSubmitting(false);
      resetForm({
        oldpwd: "",
        password: "",
        confirmpwd: "",
      });
    },
  });

  // }
  const body = (
    <div
      style={{ marginLeft: "35%", marginTop: "8%" }}
      className={classes.paper}
    >
      <Button style={{}} className={classes.button} onClick={handleClose}>
        <div className={styles.right}></div>
        <div className={styles.left}></div>
      </Button>
      <h2
        className={styles.loginTitle}
        style={{
          color: "#fff",
          marginLeft: "90px",
        }}
      >
        CHANGE PASSWORD
      </h2>
      <Row>
        <Col sm={12} md={12} lg={12}>
          <PasswordInput
            placeholder="Enter Old Password"
            style={{ marginBottom: "20px" }}
            icon={<FaUserLock />}
          />
        </Col>
        <Col sm={12} md={12} lg={12}>
          {" "}
          <PasswordInput
            placeholder="Enter New Password"
            style={{ marginBottom: "20px" }}
            // onClick={handleChange}
            icon={<FaUserLock />}
          />
          <small
            style={{ display: "none", color: "rgba(0,0,0,0.3)" }}
            className="passwordHint"
          >
            password must atleast one uppercase, one lowercase alphabeth and one
            special character
          </small>
        </Col>
        <Col sm={12} md={12} lg={12}>
          <PasswordInput
            placeholder="Confirm New Password"
            style={{ marginBottom: "20px" }}
            icon={<FaUserLock />}
          />
        </Col>
      </Row>
      <Row>
        <Col sm={12} md={12} lg={12}>
          <CTA
            large
            text="change password"
            green
            style={{ marginTop: "20px" }}
          />
        </Col>
      </Row>
    </div>
  );

  return (
    <div>
      {/* <CTA nav text="Login" green style={{}} action={handleOpen} /> */}
      <button
        onClick={handleOpen}
        style={{ background: "none", border: "none" }}
        className={classes.changePassword}
      >
        <h6 style={{ fontSize: "14px" }}>Change Password</h6>
      </button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className={classes.modal}
        disableBackdropClick="true"
      >
        <form onSubmit={handleSubmit}>
          <div
            style={{ marginLeft: "35%", marginTop: "8%" }}
            className={classes.paper}
          >
            {changingPassword && <Spinner message="Loading...please wait" />}
            <Button style={{}} className={classes.button} onClick={handleClose}>
              <div className={styles.right}></div>
              <div className={styles.left}></div>
            </Button>
            <h2
              className={styles.loginTitle}
              style={{
                color: "#fff",
                marginLeft: "90px",
              }}
            >
              CHANGE PASSWORD
            </h2>
            <Row>
              <Col sm={12} md={12} lg={12}>
                <PasswordInput placeholder="Enter Old Password"
                  style={{ }}
                  icon={<FaUserLock />}
                  name="oldpwd"
                  type="password"
                  value={values.oldpwd}
                  onChange={change.bind(null, "oldpwd")}
                />
                {errors.oldpwd && touched.oldpwd ? (
                  <div className="pt-error">{errors.oldpwd}</div>
                ) : null}
              </Col>
              <Col sm={12} md={12} lg={12}>
                {" "}
                <PasswordInput placeholder="Enter New Password"
                  style={{ marginTop: '20px' }}
                  name="password"
                  type="password"
                  value={values.password}
                  onChange={change.bind(null, "password")}
                  icon={<FaUserLock />}
                />
                {errors.password && touched.password ? (
                  <div className="pt-error">{errors.password}</div>
                ) : null}
                {/* <small style={{ display: 'none', color: 'rgba(0,0,0,0.3)' }} className="passwordHint">password must atleast one uppercase, one lowercase alphabeth and one special character</small> */}
              </Col>
              <Col sm={12} md={12} lg={12}>
                <PasswordInput placeholder="Confirm New Password"
                  style={{ marginTop: '20px' }}
                  icon={<FaUserLock />}
                  name="confirmpwd"
                  type="password"
                  value={values.confirmpwd}
                  onChange={change.bind(null, "confirmpwd")}
                />
                {errors.confirmpwd && touched.confirmpwd ? (
                  <div className="pt-error">{errors.confirmpwd}</div>
                ) : null}
              </Col>
            </Row>
            <Row>
              <Col sm={12} md={12} lg={12}>
                <CTA
                  large
                  text="change password"
                  disabled={!(isValid && dirty) || isSubmitting}
                  style={{ marginTop: "20px" }}
                  type="submit"
                />
              </Col>
            </Row>
          </div>
        </form>
      </Modal>
    </div>
  );
}
