import React from "react";
import styles from "./input.module.css";

const OnboardingInput = ({
  text,
  icon,
  placeholder,
  style,
  type,
  name,
  value,
  onChange,
  onBlur,
  error,
  disabled,
  onKeyUp,
}) => {
  return (
    <div>
      <span
        style={{
          //   position: "relative",
          position: "absolute",
          zIndex: "20",
          marginLeft: "10px",
          marginTop: "5px",
          color: "#000",
          fontSize: "18px",
          opacity: ".4",
        }}
      >
        {icon}
      </span>
      <input
        className={`${styles.onboarding}
        ${disabled ? styles.disabled: ""}`}
        type={type}
        text={text}
        value={value}
        name={name}
        onChange={onChange}
        onBlur={onBlur}
        placeholder={placeholder}
        style={style}
        disabled={disabled}
        onKeyUp={onKeyUp}
      />
    </div>
  );
};
export default OnboardingInput;
