import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import { MdPageview } from "react-icons/md";
import { Grid, Paper, Typography, Divider } from "@material-ui/core";
import CTA from "../button/cta-button";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      position: "absolute",
      width: 500,
      backgroundColor: theme.palette.background.paper,
      border: "none !important",
      // boxShadow: theme.shadows[5],
      [theme.breakpoints.down("sm")]: {
        padding: "20px",
        width: "310px",
        marginLeft: "0px",
      },
      // padding: theme.spacing(2, 4, 3),
    },
    paperForm: {
      margin: "20px auto 0px auto",
      padding: "3px  40px 10px 40px",
      border: "none !important",
      // width: '450px',
      width: 500,
      [theme.breakpoints.down("sm")]: {
        padding: "20px",
        width: "310px",
        marginLeft: "0px",
      },
    },
    previewTitle: {
      fontWeight: "600",
      marginBottom: "10px",
      marginTop: "20px",
      fontFamily: "Montserrat",
      [theme.breakpoints.down("sm")]: {
        fontSize: "16px",
        fontWeight: "bold",
        marginTop: "0px",
      },
    },
    summaryFontBold: {
      fontWeight: "600",
      [theme.breakpoints.down("sm")]: {
        fontSize: "12px",
        fontWeight: "bold",
      },
    },
    formButtons: {
      marginTop: "20px",
      paddingLeft: "20px",
      marginLeft: "150px",
      [theme.breakpoints.down("sm")]: {
        display: "flex",
        marginLeft: "0px",
      },
      [theme.breakpoints.down("xs")]: {
        marginLeft: "-18px",
      },
    },
    titleProduct: {
      fontFamily: "Montserrat",
      fontWeight: "bold",
      fontSize: "25px",
      marginTop: "50px",
      marginLeft: "-30px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "18px",
        fontWeight: "600px",
        marginLeft: "-20px",
        marginTop: "30px",
      },
    },
    typegraphyStyles: {
      textAlign: "right",
      fontSize: "14px",
    },
  })
);

export default function ViewTransactionModal() {
  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleProceed = () => {};

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <Grid>
        <Paper
          className={classes.paperForm}
          alignItems="center"
          justifyContent="center"
        >
          <Typography align="center" className={classes.previewTitle}>
            Transaction Details
          </Typography>
          <Grid container className={classes.previewMain} spacing={0}>
            {/* <Grid item xs={12} sm={12} md={12}>
                  
                </Grid> */}
          </Grid>
          <Grid container>
            <Grid container>
              <Grid
                className={classes.summaryFontBold}
                item
                xs={4}
                sm={4}
                md={4}
              >
                Product
              </Grid>
              <Grid
                item
                xs={8}
                sm={8}
                md={8}
                className={classes.typegraphyStyles}
              >
                Airtimes
              </Grid>
            </Grid>
            <Divider
              style={{
                borderBottom: "1px solid rgba(255,255,255,0.8)",
                width: "100%",
              }}
            />
            <br />
            <Grid container>
              <Grid
                className={classes.summaryFontBold}
                item
                xs={4}
                sm={4}
                md={4}
              >
                Email
              </Grid>
              <Grid item xs={8} sm={8} md={8}>
                <Typography className={classes.typegraphyStyles}>
                  text@email.com
                </Typography>
              </Grid>
            </Grid>
            <Divider
              style={{
                borderBottom: "1px solid rgba(255,255,255,0.8)",
                width: "100%",
              }}
            />
            <br />
            <Grid container>
              <Grid
                className={classes.summaryFontBold}
                item
                xs={4}
                sm={4}
                md={4}
              >
                Phone Number
              </Grid>
              <Grid
                itemxs={8}
                sm={8}
                md={8}
                className={classes.typegraphyStyles}
              >
                0801 2345 678
              </Grid>
            </Grid>
            <Divider
              style={{
                borderBottom: "1px solid rgba(255,255,255,0.8)",
                width: "100%",
              }}
            />
            <br />
            <Grid container>
              <Grid
                className={classes.summaryFontBold}
                item
                xs={6}
                xs={4}
                sm={4}
                md={4}
              >
                Convenience fee
              </Grid>
              <Grid
                item
                xs={8}
                sm={8}
                md={8}
                className={classes.typegraphyStyles}
              >
                0.00
              </Grid>
            </Grid>
            <Divider
              style={{
                borderBottom: "1px solid rgba(255,255,255,0.8)",
                width: "100%",
              }}
            />
            <br />
            <Grid container>
              <Grid
                className={classes.summaryFontBold}
                item
                xs={4}
                sm={4}
                md={4}
              >
                Total Amount
              </Grid>
              <Grid
                item
                xs={8}
                sm={8}
                md={8}
                className={classes.typegraphyStyles}
              >
                100.00
              </Grid>
            </Grid>
            <Divider
              style={{
                borderBottom: "1px solid rgba(255,255,255,0.8)",
                width: "100%",
              }}
            />
            <br />
          </Grid>
          <Grid className={classes.formButtons}>
            <CTA medium text="close" action={handleClose} />
          </Grid>
        </Paper>
      </Grid>
    </div>
  );

  return (
    <div>
      <MdPageview
        style={{ color: "#ff6c00", fontSize: "25px" }}
        onClick={handleOpen}
      />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        disableBackdropClick="true"
        disableScrollLock="true"
      >
        {body}
      </Modal>
    </div>
  );
}
