import React from "react";
import {
  Grid,
  TextField,
  ThemeProvider,
  Typography,
  Paper,
  Link,
  Tooltip,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { theme } from "../../theme";
import Spinner from "../../components/spinner";
import { useHistory, useRouteMatch } from "react-router-dom";
import { UserContext } from "../../context/user-context";
import { AlertContext } from "../../context/alert-context";
import { alertConstants } from "../../_constants";
import Events from "./events";
import Movies from "./movies";

const useStyles = makeStyles((theme) => ({
  searchField: {
    width: "400px",
    marginTop: "10px",
    marginBottom: "10px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "15px",
      // display:'none'
    },
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    // boxShadow: "2px 2px 2px rgba(0, 0, 0, 0.25)",
  },
  categoryName: {
    fontFamily: "Montserrat",
    fontWeight: "bold",
    fontSize: "25px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "17px",
      fontWeight: "bold",
    },
  },
  image: {
    width: 120,
    height: 120,
    borderRadius: 10,
    // boxShadow: "2px 2px 20px rgba(0, 0, 0, 0.25)",
    // boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.3)",
    boxShadow: "0 7px 30px -10px rgba(150,170,180,0.5)",
    [theme.breakpoints.down("sm")]: {
      width: 90,
      height: 90,
      // display:'none'
    },
  },
  img: {
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
    // boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.3)",
    [theme.breakpoints.down("sm")]: {
      width: 90,
      height: 90,
      // display:'none'
    },
  },
  tooltip: {
    textAlign: "center",
  },
}));

const categories = [
  {
    id: 0,
    productName: "events",
    url: "/tickets/event",
    path: "/tickets",
    image: "/resources/images/events.png",
  },
  {
    id: 1,
    productName: "movies",
    url: "/tickets/movie",
    path: "/tickets",
    image: "/resources/images/movies.png",
  },
];

// const handleProduct = () => {
//   history.push(`/buy-tickets/events`);
// };
const preventDefault = (url) => {
  window.history.push(url);
};
export default function Tickets() {
  const pathUrl = window.location.pathname;
  const history = useHistory();
  const classes = useStyles();
  const pathName = decodeURI(pathUrl.split("/")[1]);
  return (
    <div style={{ padding: 20 }}>
      <Grid container spacing={6}>
        <Grid item xs={6} sm={3} md={6}>
          <form noValidate autoComplete="off">
            <TextField
              className={classes.searchField}
              placeholder="search for billers e.g mtn"
              fullWidth
              id="standard-basic"
            />
          </form>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item md={12}>
          <Typography variant="h4" component="h2">
            Tickets
          </Typography>
        </Grid>

        {categories.map(({ id, image, url, productName, path }) => {
          return (
            <Grid item xs={6} sm={3} md={2}>
              <Paper className={(classes.paper, classes.image)}>
                <Link
                  onClick={() => {
                    history.push(`${url}`);
                  }}
                >
                  <Tooltip
                    //   key={Id}
                    className={classes.tooltip}
                    title={productName}
                    aria-label={productName}
                  >
                    <img className={classes.img} alt={""} src={image} />
                  </Tooltip>
                </Link>
              </Paper>
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
}
