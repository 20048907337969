import React, { useState } from "react";
import {
  Grid,
  TextField,
  Paper,
  ThemeProvider,
  Typography,
  Divider,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { theme } from "../../theme";
import { useHistory } from "react-router-dom";
import CTA from "../../components/button/cta-button";
import { FaArrowLeft } from "react-icons/fa";
import { useEffect } from "react";
import { productConstants } from "../../_constants";

const useStyles = makeStyles((theme) => ({
  root: {
    borderBottom: "1px solid red",
    borderRadius: 3,
    color: "white",
    height: 48,
    padding: "0 30px",
    flexGrow: 1,
  },

  searchField: {
    width: "400px",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  paperForm: {
    margin: "20px auto 0px auto",
    padding: "3px  40px 10px 40px",
    // width: '450px',
    width: "700px",
    boxShadow: "0 7px 30px -10px rgba(150,170,180,0.5)",

    [theme.breakpoints.down("sm")]: {
      padding: "20px",
      width: "310px",
      marginLeft: "-8px",
    },
  },
  image: {
    width: 120,
    height: 120,
    marginTop: 5,
    boxShadow: "0 7px 30px -10px rgba(150,170,180,0.5)",

    [theme.breakpoints.down("sm")]: {
      width: 80,
      height: 80,
    },
  },
  img: {
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
  },

  button: {
    display: "block",
    marginTop: theme.spacing(2),
  },

  previewTitle: {
    fontWeight: "600",
    marginBottom: "10px",
    marginTop: "20px",
    fontFamily: "Montserrat",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
      fontWeight: "bold",
      marginTop: "0px",
    },
  },
  summaryFontBold: {
    fontWeight: "600",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      fontWeight: "bold",
    },
  },
  formButtons: {
    marginTop: "20px",
    paddingLeft: "20px",
    marginLeft: "150px",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      marginLeft: "0px",
    },
    [theme.breakpoints.down("xs")]: {
      marginLeft: "-18px",
    },
  },
  titleProduct: {
    fontFamily: "Montserrat",
    fontWeight: "bold",
    fontSize: "25px",
    marginTop: "50px",
    marginLeft: "-30px",
    textTransform: "uppercase",
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
      fontWeight: "600px",
      marginLeft: "-20px",
      marginTop: "30px",
    },
  },
  typegraphyStyles: {
    textAlign: "right",
    fontSize: "14px",
  },
  summary: {
    height: "200px",
    overflowX: "hidden",
    overflowY: "auto",

    "&::-webkit-scrollbar": {
      width: "0.5em",
      borderRadius: "3px",
      background: "rgba(255,255,255,0.2)",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
      borderRadius: "3px",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "green",
      borderRadius: "5px",
    },
  },
}));

const WalletTopupSummary = (props) => {
  const { values, billerCode, products, handleGoBack, handleStatus } = props;
  const [state, setState] = useState({
    items: [],
    reviewValues: [],
  });
  const pathUrl = window.location.pathname;
  const history = useHistory();
  const classes = useStyles();
  // const handleBack = () => {
  //   history.goBack();
  // };
  // const handleProceed = () => {};

  const handleProceed = () => {
    const reviewValueObject = {
      reviewValues: state.reviewValues,
    };
    handleStatus(reviewValueObject);
  };

  var items = [];
  var reviewValues = [];
  const setReviewValue = (name, value) => {
    reviewValues = {
      ...reviewValues,
      [name]: value,
    };
  };

  useEffect(() => {
    var convFee = 0,
      totalAmount = 0,
      _item = {};

    //Name
    _item = {
      name: "Acount Name",
      value: values.customerName,
    };
    items.push(_item);
    setReviewValue("name", values.customerName);
    //Name
    _item = {
      name: "Wallet ID",
      value: values.customerAccountNumber,
    };
    items.push(_item);
    setReviewValue("name", values.customerAccountNumber);
    //Email
    _item = {
      name: "Email",
      value: values.email,
    };
    items.push(_item);
    setReviewValue("email", values.email);

    //Phone Number
    _item = {
      name: "Phone Number",
      value: values.phone,
    };
    items.push(_item);
    setReviewValue("phone", values.phone);

    //Amount
    let _amt = parseFloat(values.amount);
    convFee = getFee(_amt, billerCode);
    totalAmount = _amt + convFee;
    _item = {
      name: "Amount",
      value: _amt.toLocaleString(navigator.language, {
        minimumFractionDigits: 2,
      }),
    };
    items.push(_item);
    setReviewValue("amount", _amt);

    //Convenience fee
    _item = {
      name: "Convenience fee",
      value: convFee.toLocaleString(navigator.language, {
        minimumFractionDigits: 2,
      }),
    };
    items.push(_item);
    setReviewValue("fee", convFee);

    //Total Amount
    _item = {
      name: "Total Amount",
      value: totalAmount.toLocaleString(navigator.language, {
        minimumFractionDigits: 2,
      }),
    };
    items.push(_item);
    setReviewValue("totalAmount", totalAmount);
    setState({
      ...state,
      items: items,
      reviewValues: reviewValues,
    });
  }, []);

  const getFee = (amount, billerCode) => {
    var fee = 0;
    let product = products.find((p) => p.BillerCode === billerCode);
    if (product) {
      switch (product.ChargeType) {
        case productConstants.CHARGE_TYPE_FIXED:
          fee = product.Charge;
          break;
        case productConstants.CHARGE_TYPE_PERCENTAGE:
          fee = (product.Charge * amount) / 100;
          break;
        default:
          break;
      }
    }
    return fee;
  };

  return (
    <div className="formContainer">
      <ThemeProvider theme={theme}>
        <Grid container spacing={1}>
          <Grid item xs={5} sm={3} md={2}>
            <Paper className={(classes.paper, classes.image)}>
              <img
                className={classes.img}
                alt="mtn"
                src="/resources/images/wallet.png"
              />
            </Paper>
          </Grid>
          <Grid item xs={6} sm={6} md={5}>
            <Typography
              className={classes.titleProduct}
              variant="h4"
              component="h2"
            >
              Topup Wallet
            </Typography>
          </Grid>
        </Grid>
        <Grid>
          <Paper
            className={classes.paperForm}
            alignItems="center"
            justifyContent="center"
          >
            <Typography align="center" className={classes.previewTitle}>
              Order Summary
            </Typography>
            <div className={classes.summary}>
              {state.items.map((row) => {
                return (
                  <>
                    <Grid key={row.name} container>
                      <Grid
                        className={classes.summaryFontBold}
                        item
                        xs={4}
                        sm={4}
                        md={4}
                      >
                        {row.name}
                      </Grid>
                      <Grid
                        item
                        xs={8}
                        sm={8}
                        md={8}
                        className={classes.typegraphyStyles}
                      >
                        {row.value}
                      </Grid>
                    </Grid>
                    <Divider
                      style={{
                        borderBottom: "1px solid rgba(255,255,255,0.8)",
                        width: "100%",
                      }}
                    />
                  </>
                );
              })}
            </div>
            <Grid className={classes.formButtons}>
              <CTA
                noBackground
                text="BACK"
                action={handleGoBack}
                icon={<FaArrowLeft />}
              />
              <CTA
                medium
                text="Proceed"
                style={{ width: "auto" }}
                action={handleProceed}
              />
            </Grid>
          </Paper>
        </Grid>
      </ThemeProvider>
    </div>
  );
};

export default WalletTopupSummary;
