import React, {
  createContext,
  useReducer,
} from "react";
import {
  userActions
} from "../_actions/user.actions";
import {
  registration
} from "../_reducers/registration.reducer";
import {
  userConstants,
  alertConstants
} from "../_constants";


const initialState = {
  user: {},
  errorMessage: "",
  changeErrorMessage: "",
  ressetting: false
};

export const AccountResetContext = createContext(initialState);

export const AccountResetContextProvider = ({
  children
}) => {

  const [state, verifyDispatch] = useReducer(registration, initialState);


  function verifyResetToken(token, dispatch) {
    verifyDispatch({
      type: userConstants.VERIFY_REQUEST
    })
    userActions.verifyResetToken(token).then(resp => {
      verifyDispatch({
        type: userConstants.VERIFY_SUCCESS,
        payload: resp,
      });
    }, error => {
      verifyDispatch({
        type: userConstants.VERIFY_FAILURE,
        errorMessage: error.toString(),
      });
      // dispatch({type: alertConstants.error, message: error.toString()})
      dispatch({ type: alertConstants.ERROR, message: error.toString() });
    });
  }

  function resetPassword(user, dispatch) {
    verifyDispatch({
      type: userConstants.CHANGE_REQUEST
    })
    userActions.resetPassword(user).then(resp => {
      var msg = "Password reset successfully";
      verifyDispatch({
        type: userConstants.CHANGE_SUCCESS,
        payload: resp,
      });
      dispatch({ type: alertConstants.SUCCESS, message: msg });
    }, error => {
      verifyDispatch({
        type: userConstants.CHANGE_FAILURE,
        changeErrorMessage: error.toString(),
      });
      dispatch({ type: alertConstants.ERROR, message: error.toString() });
    });
  }
  return (<AccountResetContext.Provider value={
    {
      verifyResetToken,
      resetPassword,
      user: state.user,
      errorMessage: state.errorMessage,
      changeErrorMessage: state.changeErrorMessage,
      ressetting: state.ressetting,
    }
  } >
    {
      children
    } </AccountResetContext.Provider>
  );
};