import React, { createContext, useReducer, useContext } from "react";
import { userActions } from "../_actions/user.actions";
import { alertActions } from "../_actions/alert.actions";
import { registration } from "../_reducers/registration.reducer";
import { userConstants, alertConstants } from "../_constants";
import { alert } from "../_reducers/alert.reducer";


const initialState = {
  message: "",
  type: "",
};

export const AlertContext = createContext(initialState);


export const AlertProvider = ({ children }) => {

  const [state, dispatch] = useReducer(alert, initialState);

  const clear = () => {
    dispatch({ type: alertConstants.CLEAR })
  }

  const error = (message) => {
    dispatch({type: alertConstants.ERROR, message: message})
  }

  const success = (message) => {
    dispatch({ type: alertConstants.SUCCESS, message: message })
  }
  return (
    <AlertContext.Provider
      value={{
        message: state.message,
        type: state.type,
        clear,
        error,
        success,
        dispatch
      }}
    >
      {children}
    </AlertContext.Provider>
  );
};
