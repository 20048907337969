import React, { useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import styles from "../../components/button/button.module.css";
import { Button, ThemeProvider, TextField } from "@material-ui/core";
import Row from "react-bootstrap/Row";
import { Col } from "react-bootstrap";
import "../../index.css";
import Close from "../../components/button/close-btn";
import OnboardingInput from "../../components/inputs/onboarding-input";
import PasswordInput from "../../components/inputs/password-input";
import { FaUser, FaEnvelope, FaUserLock } from "react-icons/fa";
import {
  MdEmail,
  MdAccountCircle,
  MdPhoneAndroid,
  MdSettingsEthernet,
} from "react-icons/md";
import CTA from "../../components/button/cta-button";
import * as Yup from "yup";
import { useFormik, Formik, Field, Form, ErrorMessage } from "formik";
import { UserContext } from "../../context/user-context";
import { AlertContext } from "../../context/alert-context";
import { userConstants, alertConstants } from "../../_constants";
import { ModalContext } from "../../context/modal-context";
import Spinner from "../../components/spinner";
import { useHistory } from "react-router";
import { validatePhone } from "../../helpers";
import { theme } from "../../theme";

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "fixed",
    width: 500,
    height: "90vh",
    overflowY: "scroll",
    border: "none",
    outline: "none",
    padding: theme.spacing(2, 4, 3),
    background: "none",
    [theme.breakpoints.down("sm")]: {
      // background:'green',
      position: "absolute",
      width: 270,
      left: "-70px",
      top: "10%",
    },
    [theme.breakpoints.up("md")]: {
      // backgroundColor: 'blue',
    },
    [theme.breakpoints.up("lg")]: {
      // marginTop:'100%'
    },
    // marginTop:'-5%'
  },
  modal: {
    // background: "rgba(237, 219, 219, 0.9)",
    background: "rgba(32, 28, 27, 0.8)",
  },
  [theme.breakpoints.up("lg")]: {
    // marginTop:'100%'
  },
  // marginTop:'-5%'
  // },
  modal: {
    background: "rgba(237, 219, 219, 0.9)",
  },
  button: {
    marginLeft: "90%",
    marginTop: "10%",
    background: "none",
    "&:hover": {
      background: "none",
    },
    "&:focus": {
      outline: "none",
      background: "none",
    },
  },
}));

export default function SignupModal({
  handleClose,
  handleForgotPassword,
  handleSignUp,
  openSignUp,
  handleSignin,
  handleLogin,
}) {
  const classes = useStyles();
  const { signUp, signUpMessage, registering, clearMessage } = useContext(
    UserContext
  );
  const { dispatch } = useContext(AlertContext);
  const history = useHistory();

  Yup.addMethod(Yup.string, "phone", function (msg) {
    return validatePhone(this, msg);
  });
  const validationSchema = Yup.object().shape({
    email: Yup.string("Enter Your Email")
      .email("Enter a Valid Email")
      .typeError("Enter Your Email")
      .required("Email is Required"),
    phone: Yup.string("Enter Your Phone Number")
      .phone("Enter a Valid Phone Number")
      .typeError("Enter Your Phone Number")
      .required("Phone Number is Required"),
    firstName: Yup.string("Enter Your First Name")
      .typeError("Enter Your First Name")
      .min(2, "Your Name must be at least 2 Characters long")
      .required("First Name is Required"),
    surname: Yup.string("Enter Your Last Name")
      .typeError("Enter Your Last Name")
      .min(2, "Your Name must be at least 2 Characters long")
      .required("Last Name is Required"),
    password: Yup.string("Enter Your Password")
      .typeError("Enter Your Password")
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        "Please choose a strong password. Password must meet the following criteria; 1. At least one block letter. 2. At least one small letter. 3. At least one number. 4. At least one special character 5. At least 8 characters"
      )
      .required("Password is Required"),
    confirmpwd: Yup.string("Confirm Your Password")
      .typeError("Confirm Your Password")
      .oneOf([Yup.ref("password")], "Passwords do not match")
      .required("Confirm Password is Required"),
  });

  const clearForm = () => {
    this.setState({
      email: "",
      firstName: "",
      surname: "",
      phone: "",
      password: "",
      confirmpwd: "",
    });
  };

  const change = (name, e) => {
    e.persist();
    handleChange(e);
    setFieldTouched(name, true, false);
  };

  useEffect(() => {
    if (signUpMessage && registering == false) {
      handleClose();
      // handleSignin();
      clearMessage();
      history.push("/");
    }
  });

  const {
    values,
    touched,
    errors,
    dirty,
    isValid,
    isSubmitting,
    handleChange,
    handleSubmit,
    setFieldTouched,
    setSubmitting,
    resetForm,
  } = useFormik({
    initialValues: {
      firstName: "",
      email: "",
      phone: "",
      password: "",
      confirmpwd: "",
    },
    validationSchema,
    onSubmit(values, { setSubmitting }) {
      signUp(values, dispatch);
      setSubmitting(false);
      resetForm({
        firstName: "",
        email: "",
        phone: "",
        password: "",
        confirmpwd: "",
      });
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <div
        style={{ marginLeft: "35%", marginTop: "0%", zIndex: 20 }}
        className={classes.paper}
      >
        {registering && <Spinner message="Loading...please wait" />}
        <form onSubmit={handleSubmit}>
          <Button style={{}} className={classes.button} onClick={handleClose}>
            <div className={styles.right}></div>
            <div className={styles.left}></div>
          </Button>
          <h2 className="signupTitle">SIGN UP</h2>
          <Row>
            <Col sm={12} md={12} lg={12}>
              <OnboardingInput
                placeholder="First Name"
                name="firstName"
                type="text"
                value={values.firstName}
                onChange={change.bind(null, "firstName")}
                style={{
                  marginBottom: "20px",
                }}
                icon={<MdAccountCircle />}
              />
              {errors.firstName && touched.firstName ? (
                <div className="pt-error">{errors.firstName}</div>
              ) : null}
            </Col>
            <Col sm={12} md={12} lg={12}>
              <OnboardingInput
                placeholder="Last Name"
                name="surname"
                type="text"
                value={values.surname}
                onChange={change.bind(null, "surname")}
                style={
                  {
                    // marginBottom: "20px"
                  }
                }
                icon={<MdAccountCircle />}
              />
              {errors.surname && touched.surname ? (
                <div className="pt-error">{errors.surname}</div>
              ) : null}
            </Col>
            <Col sm={12} md={12} lg={12}>
              <OnboardingInput
                placeholder="Email Address"
                name="email"
                type="email"
                value={values.email}
                onChange={change.bind(null, "email")}
                style={{ marginTop: "20px" }}
                icon={<MdEmail />}
              />
              {errors.email && touched.email ? (
                <div className="pt-error">{errors.email}</div>
              ) : null}
            </Col>
            <Col sm={12} md={12} lg={12}>
              <OnboardingInput
                placeholder="Mobile No"
                name="phone"
                type="tel"
                value={values.phone}
                onChange={change.bind(null, "phone")}
                style={{ marginTop: "20px" }}
                icon={<MdPhoneAndroid />}
              />
              {errors.phone && touched.phone ? (
                <div className="pt-error">{errors.phone}</div>
              ) : null}
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={12} lg={12}>
              <PasswordInput
                icon={<FaUserLock />}
                placeholder="password"
                name="password"
                type="password"
                value={values.password}
                onChange={change.bind(null, "password")}
                style={{ marginTop: "20px" }}
              />
              {errors.password && touched.password ? (
                <div className="pt-error">{errors.password}</div>
              ) : null}
            </Col>
            <Col sm={12} md={12} lg={12}>
              <PasswordInput
                style={{ marginTop: "20px" }}
                icon={<FaUserLock />}
                placeholder="Confirm Password"
                name="confirmpwd"
                type="password"
                value={values.confirmpwd}
                onChange={change.bind(null, "confirmpwd")}
              />
              {errors.confirmpwd && touched.confirmpwd ? (
                <div className="pt-error">{errors.confirmpwd}</div>
              ) : null}
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={12} lg={12}>
              <CTA
                large
                text="Sign Up"
                style={{ marginTop: "10px", marginLeft: "0px" }}
                type="submit"
                disabled={!(isValid && dirty) || isSubmitting}
                // action={handleSubmit}
              />
            </Col>
            <Col sm={12} md={12} lg={12}>
              <div className="alternative" style={{}}>
                <p>Already have an account?</p>
                <button style={{}} onClick={handleLogin}>
                  <a>Login</a>
                </button>
              </div>
            </Col>
          </Row>
        </form>
      </div>
    </ThemeProvider>
  );
}
