import React from "react";
import { FaWeight } from "react-icons/fa";

const Overview = () => {
  return (
    <div className="overview-container">
      <h1 className="title">Overview</h1>
      <section
        className="overview"
        style={{
         
        }}
      >
        <p>
          PayXpress is another value innovation from Xpress Payment Solutions
          LTD, now offering a value-added service platform to avail to
          customers, services such as airtime purchase (virtual top up, prepaid
          PINs etc.), bill payments, collection services for government
          parastatals/corporate organizations/donations for religious
          institutions. Other services offered include funds transfer to self
          and 3rd Party beneficiaries, recurring payments setup instructions.
          The platform provides a convenient alternative payment channel to
          customers and merchants accepting multiple payment options such as
          cards (Visa card, MasterCard etc.), also accessible on various
          channels including web (www.payxpress.com).
        </p>
        <p>
          Our current services are the beginning of a journey. Through our
          innovation /service delivery team and in conjunction with our
          partners, we will continue to develop and deliver valuable offerings
          to our customers. Together with our partners, we will achieve our
          vision which is to be a world-class provider of financial technology
          services.
        </p>
      </section>
    </div>
  );
};

export default Overview;
