import React, { useEffect, useContext } from "react";
import {
  Grid,
  Paper,
  Typography,
  Link,
  Card,
  CardContent,
  CardActionArea,
  ThemeProvider,
  TextField,
  CardActions,
  Button,
  CardMedia,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {alertConstants} from '../../_constants'
import { theme } from "../../theme";
import { MdMap, MdInfo } from "react-icons/md";
import { useHistory, useRouteMatch } from "react-router-dom";
import { FaEye } from "react-icons/fa";
import Modal from "@material-ui/core/Modal";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CTA from "../../components/button/cta-button";
import { fetchMovies, fetchTransaction } from "../../_actions/movie.actions";
import { AlertContext } from "../../context/alert-context";
import MovieTicketForm from "./movie-ticket-form";
import { UserContext } from "../../context/user-context";
import { productConstants } from "../../_constants";
import Spinner from "../../components/spinner";

const useStyles = makeStyles((theme) => ({
  searchField: {
    width: "400px",
  },
  containerDiv: {
    marginTop: "-20px",
  },
  paper: {
    position: "fixed",
    transform: "translate(50%,10%)",
    width: 700,
    height: "80vh",
    overflowY: "auto",
    border: "none",
    outline: "none",
    padding: theme.spacing(2, 4, 3),
    background: "#fff",
    [theme.breakpoints.down("sm")]: {
      // background:'green',
      position: "absolute",
      width: 380,
      left: "-48%",
      top: "2%",
    },
    [theme.breakpoints.down("xs")]: {
      width: 310,
      left: "-51%",
      top: "2%",
    },
    [theme.breakpoints.up("md")]: {
      // backgroundColor: 'blue',
    },
    [theme.breakpoints.up("lg")]: {
      // marginTop:'100%'
    },
    // marginTop:'-5%'
  },

  image: {
    width: 90,
    height: 90,
    marginTop: "5px",
    boxShadow: "0 7px 30px -10px rgba(150,170,180,0.5)",
    [theme.breakpoints.down("sm")]: {
      width: 90,
      height: 90,
    },
  },

  img: {
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
  },
  inputPadding: {
    paddingLeft: "40px",
    marginTop: "5px",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "0px",
    },
  },
  productName: {
    fontSize: "25px",
    fontWeight: "bold",
    marginTop: "40px",
    marginLeft: "-80px",
    fontFamily: "Montserrat",
    textTransform: "uppercase",
    [theme.breakpoints.only("xs")]: {
      marginLeft: "-45px",
      fontSize: "20px",
      fontWeight: "bold",
    },
  },
  card: {
    borderRadius: "5px 5px 0px 0px",
    // boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.3)",
    boxShadow: "0 7px 30px -10px rgba(150,170,180,0.5)",
    // height: "400px",
    // maxHeight: "400px",
  },
  cardImg: {
    height: "270px",
    borderRadius: "5px 5px 0px 0px",
  },
  title: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "15px",
    color: "#FF6C00",
    textTransform: "uppercase",
  },
  typography: {
    fontSize: "11px",
    fontFamily: "Montserrat",
  },
  bookTicketBtn: {
    width: "100%",
    background: "#FF6C00",
    margin: "0px",
    color: "#fff",
    height: "20px",
    fontFamily: "Montserrat",
    borderRadius: "0px",
    transition: "all 0.35s ease-in-out",
    "&:hover": {
      background: "#fff",
      color: "#000",
    },
    "&:focus": {
      outline: "none",
    },
  },
  movies: {
    marginTop: "10px",
    maxHeight: "465px",
    height: "460px",
    width: "100%",
    overflowY: "auto",
    overflowX: "hidden",

    "&::-webkit-scrollbar": {
      width: "0.5em",
      borderRadius: "3px",
      background: "rgba(255,255,255,0.6)",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "green",
      borderRadius: "5px",
    },
  },
  seeMore: {
    display: "flex",
  },
  movieDescription: {
    height: "350px",
    overflowY: "auto",
    fontFamily: "century gothic",
    fontSize: "12px",

    "&::-webkit-scrollbar": {
      width: "0.5em",
      borderRadius: "3px",
      background: "rgba(255,255,255,0.2)",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
      borderRadius: "3px",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "green",
      borderRadius: "5px",
    },
  },
  Img: {
    height: "400px",
    borderRadius: "5px 5px 0px 0px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "250px",
    },
  },
  movieModal: {
    width: "700px",

    position: "absolute",
    [theme.breakpoints.down("sm")]: {
      // background:'green',
      position: "absolute",
      width: "290px",
      height: "300px",
      left: "20%",
      top: "20%",
      zIndex: 100,
    },
  },
  movieTitle: {
    textAlign: "center",
    textTransform: "uppercase",
    fontFamily: "Montserrat",
    [theme.breakpoints.down("sm")]: {
      fontSize: "13px",
    },
  },
}));

const myMovies = [
  {
    id: 1,
    title: "Girls Trip",
    genre: "Drama | Action",
    url: "/tickets/movies/book-ticket",
    path: "/tickets",
    image: "/resources/images/girls-trip.jpg",
    synopsis:
      "Becoming a chef is Aiye's greatest desire. But. She is a young, struggling, single mother who has been abandoned by her family. To settle for defeat, or to fight against all odds to become the chef of her dreams? That is the question.",
    duration: "1hr 30min",
    youtubeid: "xDMP3i36naA",
  },
  {
    id: 2,
    title: "Body Language",
    genre: "Drama | Sci-Fi",
    url: "/tickets/movies/book-ticket",
    path: "/tickets",
    image: "/resources/images/body-language.jpg",
    synopsis:
      "Matured ladies decided to have a time out and they begandiscussing their individual sexual life",
    duration: "1hr 30min",
    youtubeid: "t2ypzz6gJm0",
  },
];

export default function Movies() {
  // const { onClose, open } = props;
  const { dispatch } = useContext(AlertContext);
  const { loggedIn, user } = useContext(UserContext);
  const [openTrailer, setOpenTrailer] = React.useState(false);
  const [state, setState] = React.useState({
    movies: [],
    movie: {},
    showView: "MOVIE_LIST",
    formStatus: productConstants.FORM_STATUS_INPUTTING,
    loading: true,
  });
  const [openMovieInfo, setOpenMovieInfo] = React.useState(false);
  const classes = useStyles();
  const pathUrl = window.location.pathname;
  const history = useHistory();
  const handleSearchChange = (event) => {
    const searchText = event.target.value;
   // searchBillers(searchText);
  };

  const handleProceed = () => {
    history.push(`/tickets/movies/book-ticket`);
  };

  const handleTrailerClickOpen = (movie) => {
    setState({
      ...state,
      movie: movie,
    });
    setOpenTrailer(true);
  };

  const handleTrailerClose = () => {
    setOpenTrailer(false);
  };

  const hanadleMovieForm = (movie) => {
    setState({
      ...state,
      movie: movie,
      user: user,
      loggedIn: loggedIn,
      showView: "MOVIE_FORM",
    });
  };

  const handleMovieInfoClickOpen = (movie) => {
    setState({
      ...state,
      movie: movie,
    });
    setOpenMovieInfo(true);
  };

  const handleMovieInfoClose = () => {
    setOpenMovieInfo(false);
  };

  const handleGoBack = () => {
    setState({
      ...state,
      showView: "MOVIE_LIST",
      loading: false,
    });
  };
  const handleStatus = (formvalues) => {};
  useEffect(() => {
    setState({
      ...state,
      loading: true,
    });
    fetchMovies().then((data) => {
      setState(
        {
          ...state,
          movies: data,
          loading: false,
        },
        (error) => {
          setState({
            ...state,
            loading: false,
          });
          dispatch({
            type: alertConstants.ERROR,
            message: error.toString(),
          });
        }
      );
    });
  }, [fetchMovies]);

  return (
    <>
      <div className={classes.containerDiv} style={{ padding: 20 }}>
        <ThemeProvider theme={theme}>
          {state.loading && <Spinner message="Loading...please wait" />}
          {state.showView == "MOVIE_LIST" && (
            <>
              <Grid container spacing={1}>
                <Grid item xs={6} sm={3} md={2}>
                  <Paper className={(classes.paper, classes.image)}>
                    <img
                      className={classes.img}
                      alt="events"
                      src="/resources/images/movies.png"
                    />
                  </Paper>
                </Grid>
                <Grid item xs={6} sm={9} md={10}>
                  <Typography
                    variant="h4"
                    component="h2"
                    className={classes.productName}
                  >
                    Movies
                  </Typography>
                </Grid>
              </Grid>
              <div className={classes.movies}>
                <Grid container spacing={3}>
                  {state.movies.length > 0 &&
                    state.movies.map((props) => {
                      return (
                        <Grid item md={3} sm={6} xs={12}>
                          <Card className={classes.card}>
                            <img
                              src={props.artwork}
                              alt=""
                              className={classes.cardImg}
                            />
                            <CardContent>
                              <h6 className={classes.title}>{props.title}</h6>
                              <Typography className={classes.typography}>
                                {props.genre}
                              </Typography>
                              <div className={classes.seeMore}>
                                <Typography className={classes.typography}>
                                  <FaEye />
                                  <CTA
                                    noBackground
                                    text="trailer"
                                    action={() => handleTrailerClickOpen(props)}
                                    style={{
                                      fontSize: "11px",
                                      textTransform: "capitalize",
                                      fontWeight: "normal",
                                      textDecoration: "underline",
                                      marginLeft: "-25px",
                                    }}
                                  />
                                </Typography>
                                <Typography className={classes.typography}>
                                  <MdInfo />
                                  <CTA
                                    noBackground
                                    text="movie info"
                                    action={() =>
                                      handleMovieInfoClickOpen(props)
                                    }
                                    style={{
                                      fontSize: "11px",
                                      textTransform: "capitalize",
                                      fontWeight: "normal",
                                      textDecoration: "underline",
                                      marginLeft: "-10px",
                                    }}
                                  />
                                </Typography>
                              </div>
                            </CardContent>
                            <CardActionArea>
                              <Button
                                className={classes.bookTicketBtn}
                                onClick={() => hanadleMovieForm(props)}
                              >
                                Book Tickets
                              </Button>
                            </CardActionArea>
                          </Card>
                        </Grid>
                      );
                    })}

                  <Dialog
                    open={openTrailer}
                    onClose={handleTrailerClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    maxWidth="lg"
                  >
                    <DialogTitle id="alert-dialog-title">
                      Watch Trailer
                    </DialogTitle>
                    <DialogContent>
                      <iframe
                        id="cartoonVideo"
                        title="Watch Trailer"
                        width="750"
                        height="350"
                        src={`https://www.youtube.com/embed/${state.movie.youtubeid}`}
                        frameBorder="0"
                        allowFullScreen=""
                      ></iframe>
                    </DialogContent>
                    <DialogActions>
                      <Button
                        onClick={handleTrailerClose}
                        variant="contained"
                        color="primary"
                        autoFocus
                      >
                        Close
                      </Button>
                    </DialogActions>
                  </Dialog>

                  <Modal
                    open={openMovieInfo}
                    onClose={handleMovieInfoClose}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    className={classes.movieModal}
                    disableBackdropClick={true}
                  >
                    <div className={classes.paper}>
                      <h3 className={classes.movieTitle}>
                        {state.movie.title}
                      </h3>
                      <Grid container spacing={1}>
                        <Grid item md={5} sm={12} xs={12}>
                          <img
                            src={state.movie.artwork}
                            alt=""
                            className={classes.Img}
                          />
                          <div className={classes.actions}>
                            <CTA
                              medium
                              green
                              action={() => hanadleMovieForm(state.movie)}
                              text="Buy Ticket"
                              style={{
                                fontSize: "12px",
                                marginTop: "10px",
                              }}
                            />{" "}
                            <CTA
                              noBackground
                              text="close"
                              action={handleMovieInfoClose}
                            />
                          </div>
                        </Grid>
                        <Grid item md={7} sm={12} xs={12}>
                          <div className={classes.movieDescription}>
                            <p>
                              {" "}
                              <strong>Duration:</strong>
                              {state.movie.duration}
                            </p>
                            <p>
                              <strong>Genre: </strong>
                              {state.movie.genre}
                            </p>
                            <p>
                              <strong>Synopsis: </strong>
                              {state.movie.description}
                            </p>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  </Modal>
                </Grid>
              </div>
            </>
          )}
        </ThemeProvider>
      </div>
      {state.showView == "MOVIE_FORM" && (
        <MovieTicketForm
          handleStatus={handleStatus}
          handleGoBack={handleGoBack}
          {...state}
        />
      )}
    </>
  );
}
