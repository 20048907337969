import React, { createContext, useReducer } from "react";
import { modalReducer } from "../_reducers/modal.reducer";


const initialState = {
  signInModal: true,
  signUpModal: false,
};

export const ModalContext = createContext();

export const ModalProvider = ({ children }) => {
  const [state, modalDispatch] = useReducer(modalReducer, initialState);
  function openSignInModal() {
    modalDispatch({
        type: 'SIGN_IN_MODAL_OPEN',
        signInModal: true,
      });
  }
  function closeSignInModal() {
    modalDispatch({
        type: 'SIGN_IN_MODAL_CLOSE',
        signInModal: false,
      });
  }

  function openSignUpModal() {
    modalDispatch({
        type: 'SIGN_UP_MODAL_OPEN',
        signUpModal: true,
      });
  }

  function closeSignUpModal() {
    modalDispatch({
        type: 'SIGN_UP_MODAL_CLOSE',
        signUpModal: false,
      });
  }

  return (
    <ModalContext.Provider
      value={{
        signInModal: state.signInModal,
        signUpModal: state.signUpModal,
        openSignInModal,
        closeSignInModal,
        openSignUpModal,
        closeSignUpModal,
        modalDispatch
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};
