import React, { useState } from "react";
import {
  Grid,
  TextField,
  Paper,
  ThemeProvider,
  Typography,
  Divider,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { theme } from "../../theme";
import { useHistory } from "react-router-dom";
import CTA from "../../components/button/cta-button";
import { FaArrowLeft } from "react-icons/fa";
import { useEffect } from "react";
import { formatDate } from "../../helpers";
import { productConstants } from "../../_constants";

const useStyles = makeStyles((theme) => ({
  root: {
    borderBottom: "1px solid red",
    borderRadius: 3,
    color: "white",
    height: 48,
    padding: "0 30px",
    flexGrow: 1,
  },

  searchField: {
    width: "400px",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  paperForm: {
    margin: "20px auto 0px auto",
    padding: "3px  40px 10px 40px",
    // width: '450px',
    width: "750px",
    boxShadow: "0 7px 30px -10px rgba(150,170,180,0.5)",
    [theme.breakpoints.down("sm")]: {
      padding: "20px",
      width: "310px",
      marginLeft: "-8px",
    },
  },
  image: {
    width: 120,
    height: 120,
    marginTop: 5,
    boxShadow: "0 7px 30px -10px rgba(150,170,180,0.5)",
    [theme.breakpoints.down("sm")]: {
      width: 80,
      height: 80,
    },
  },
  img: {
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
  },

  button: {
    display: "block",
    marginTop: theme.spacing(2),
  },

  previewTitle: {
    fontWeight: "600",
    marginBottom: "10px",
    marginTop: "20px",
    fontFamily: "Montserrat",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
      fontWeight: "bold",
      marginTop: "0px",
    },
  },
  summaryFontBold: {
    fontWeight: "600",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      fontWeight: "bold",
    },
  },
  formButtons: {
    marginTop: "20px",
    paddingLeft: "20px",
    marginLeft: "150px",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      marginLeft: "0px",
    },
    [theme.breakpoints.down("xs")]: {
      marginLeft: "-18px",
    },
  },
  titleProduct: {
    fontFamily: "Montserrat",
    fontWeight: "bold",
    fontSize: "25px",
    marginTop: "50px",
    marginLeft: "-30px",
    textTransform: "uppercase",
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
      fontWeight: "600px",
      marginLeft: "-20px",
      marginTop: "30px",
    },
  },
  typegraphyStyles: {
    textAlign: "right",
    fontSize: "14px",
  },
  summary: {
    height: "235px",
    overflowX: "hidden",
    overflowY: "auto",
    paddingRight: "5px",

    "&::-webkit-scrollbar": {
      width: "0.5em",
      borderRadius: "3px",
      background: "rgba(255,255,255,0.2)",
      marginLeft: "5px",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
      borderRadius: "3px",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "green",
      borderRadius: "5px",
    },
  },
}));

const TicketSummary = (props) => {
  const pathUrl = window.location.pathname;
  const history = useHistory();
  const classes = useStyles();

  const { event, values, handleProdceed, products, billerCode } = props;
  const [state, setState] = useState({
    items: [],
    reviewValues: [],
  });
  var items = [];
  var reviewValues = [];
  const setReviewValue = (name, value) => {
    reviewValues = {
      ...reviewValues,
      [name]: value,
    };
  };

  const getFee = (amount, billerCode) => {
    var fee = 0;
    let product = products.find((p) => p.BillerCode === billerCode);
    if (product) {
      switch (product.ChargeType) {
        case productConstants.CHARGE_TYPE_FIXED:
          fee = product.Charge;
          break;
        case productConstants.CHARGE_TYPE_PERCENTAGE:
          fee = (product.Charge * amount) / 100;
          break;
        default:
          break;
      }
    }
    return fee;
  };
  useEffect(() => {
    var convFee = 0,
      totalAmount = 0,
      _item = {};
    //Title
    _item = {
      name: "Title",
      value: event.title,
    };
    items.push(_item);
    setReviewValue("title", event.title);

    //Name
    _item = {
      name: "Name",
      value: values.fullName,
    };
    items.push(_item);
    setReviewValue("name", values.fullName);

    //Email
    _item = {
      name: "Email",
      value: values.email,
    };
    items.push(_item);
    setReviewValue("email", values.email);

    //Phone Number
    _item = {
      name: "Phone Number",
      value: values.phone,
    };
    items.push(_item);
    setReviewValue("phone", values.phone);

    //Venue
    let _class = event.ticketClassses.find((t) => t.classid === values.ticket);
    _item = {
      name: "Venue",
      value: _class.venue,
    };
    items.push(_item);
    setReviewValue("Venue", _class.venue);

    //Date/Time
    _item = {
      name: "Date/Time",
      value: formatDate(event.date, "LLLL"),
    };
    items.push(_item);
    setReviewValue("date", event.date);

    //Class
    _item = {
      name: "Class",
      value: _class.title,
    };
    items.push(_item);
    setReviewValue("classid", values.ticket);

    //Quantity
    _item = {
      name: "Quantity",
      value: values.quantity,
    };
    items.push(_item);
    setReviewValue("qty", values.quantity);

    //Amount
    let _amt = parseFloat(values.amount);
    convFee = getFee(_amt, billerCode);
    totalAmount = _amt + convFee;
    _item = {
      name: "Amount",
      value: _amt.toLocaleString(navigator.language, {
        minimumFractionDigits: 2,
      }),
    };
    items.push(_item);
    setReviewValue("amount", _amt);

    //Convenience fee
    _item = {
      name: "Convenience fee",
      value: convFee.toLocaleString(navigator.language, {
        minimumFractionDigits: 2,
      }),
    };
    items.push(_item);
    setReviewValue("fee", convFee);

    //Total Amount
    // _item = {
    //   name:   'Total Amount',
    //   value:  totalAmount.toLocaleString(navigator.language, { minimumFractionDigits: 2 })
    // }
    // items.push(_item);
    setReviewValue("totalAmount", totalAmount);

    setState({
      ...state,
      items: items,
      reviewValues: reviewValues,
    });
  }, []);

  const handleBack = () => {
    history.goBack();
  };
  const handleProceed = () => {
    const reviewValueObject = {
      reviewValues: state.reviewValues,
    };
    handleProdceed(reviewValueObject);
  };
  return (
    <div className="formContainer">
      <ThemeProvider theme={theme}>
        <Grid container spacing={1}>
          <Grid item xs={5} sm={3} md={2}>
            <Paper className={(classes.paper, classes.image)}>
              <img
                className={classes.img}
                alt={event.title}
                src={event.eventArtwork}
              />
            </Paper>
          </Grid>
          <Grid item xs={6} sm={6} md={5}>
            <Typography
              className={classes.titleProduct}
              variant="h4"
              component="h2"
            >
              {event.title}
            </Typography>
          </Grid>
        </Grid>
        <Grid>
          <Paper
            className={classes.paperForm}
            alignItems="center"
            justifyContent="center"
          >
            <Typography align="center" className={classes.previewTitle}>
              Order Summary
            </Typography>
            <div className={classes.summary}>
              {state.items.map((row) => {
                return (
                  <>
                    <Grid container>
                      <Grid
                        key={row.name}
                        className={classes.summaryFontBold}
                        item
                        xs={4}
                        sm={4}
                        md={4}
                      >
                        {row.name}
                      </Grid>
                      <Grid
                        key={row.name}
                        item
                        xs={8}
                        sm={8}
                        md={8}
                        className={classes.typegraphyStyles}
                      >
                        {row.value}
                      </Grid>
                    </Grid>
                    <Divider
                      style={{
                        borderBottom: "1px solid rgba(255,255,255,0.8)",
                        width: "100%",
                      }}
                    />
                  </>
                );
              })}
            </div>
            {/* <div className={classes.summary}>
              <Grid container>
                <Grid
                  className={classes.summaryFontBold}
                  item
                  xs={4}
                  sm={4}
                  md={4}
                >
                  Title
                </Grid>
                <Grid
                  item
                  xs={8}
                  sm={8}
                  md={8}
                  className={classes.typegraphyStyles}
                >
                  Sinach Live Concert
                </Grid>
              </Grid>
              <Divider
                style={{
                  borderBottom: "1px solid rgba(255,255,255,0.8)",
                  width: "100%",
                }}
              />
              <Grid container>
                <Grid
                  className={classes.summaryFontBold}
                  item
                  xs={4}
                  sm={4}
                  md={4}
                >
                  Name
                </Grid>
                <Grid item xs={8} sm={8} md={8}>
                  <Typography className={classes.typegraphyStyles}>
                    Sandra More
                  </Typography>
                </Grid>
              </Grid>
              <Divider
                style={{
                  borderBottom: "1px solid rgba(255,255,255,0.8)",
                  width: "100%",
                }}
              />
              <Grid container>
                <Grid
                  className={classes.summaryFontBold}
                  item
                  xs={4}
                  sm={4}
                  md={4}
                >
                  Email
                </Grid>
                <Grid item xs={8} sm={8} md={8}>
                  <Typography className={classes.typegraphyStyles}>
                    text@email.com
                  </Typography>
                </Grid>
              </Grid>
              <Divider
                style={{
                  borderBottom: "1px solid rgba(255,255,255,0.8)",
                  width: "100%",
                }}
              />
              <Grid container>
                <Grid
                  className={classes.summaryFontBold}
                  item
                  xs={4}
                  sm={4}
                  md={4}
                >
                  Phone Number
                </Grid>
                <Grid
                  itemxs={8}
                  sm={8}
                  md={8}
                  className={classes.typegraphyStyles}
                >
                  0801 2345 678
                </Grid>
              </Grid>
              <Divider
                style={{
                  borderBottom: "1px solid rgba(255,255,255,0.8)",
                  width: "100%",
                }}
              />
              <Grid container>
                <Grid
                  className={classes.summaryFontBold}
                  item
                  xs={6}
                  xs={4}
                  sm={4}
                  md={4}
                >
                  Venue
                </Grid>
                <Grid
                  item
                  xs={8}
                  sm={8}
                  md={8}
                  className={classes.typegraphyStyles}
                >
                  Eko Convention Centre
                </Grid>
              </Grid>
              <Divider
                style={{
                  borderBottom: "1px solid rgba(255,255,255,0.8)",
                  width: "100%",
                }}
              />
              <Grid container>
                <Grid
                  className={classes.summaryFontBold}
                  item
                  xs={4}
                  sm={4}
                  md={4}
                >
                  Date/Time
                </Grid>
                <Grid
                  item
                  xs={8}
                  sm={8}
                  md={8}
                  className={classes.typegraphyStyles}
                >
                  Sunday, October 18, 2020 5:00 PM
                </Grid>
              </Grid>
              <Divider
                style={{
                  borderBottom: "1px solid rgba(255,255,255,0.8)",
                  width: "100%",
                }}
              />
              <Grid container>
                <Grid
                  className={classes.summaryFontBold}
                  item
                  xs={4}
                  sm={4}
                  md={4}
                >
                  Class
                </Grid>
                <Grid
                  item
                  xs={8}
                  sm={8}
                  md={8}
                  className={classes.typegraphyStyles}
                >
                  Diamond Table
                </Grid>
              </Grid>
              <Divider
                style={{
                  borderBottom: "1px solid rgba(255,255,255,0.8)",
                  width: "100%",
                }}
              />
              <Grid container>
                <Grid
                  className={classes.summaryFontBold}
                  item
                  xs={4}
                  sm={4}
                  md={4}
                >
                  Quantity
                </Grid>
                <Grid
                  item
                  xs={8}
                  sm={8}
                  md={8}
                  className={classes.typegraphyStyles}
                >
                  1
                </Grid>
              </Grid>
              <Divider
                style={{
                  borderBottom: "1px solid rgba(255,255,255,0.8)",
                  width: "100%",
                }}
              />
              <Grid container>
                <Grid
                  className={classes.summaryFontBold}
                  item
                  xs={4}
                  sm={4}
                  md={4}
                >
                  Amount
                </Grid>
                <Grid
                  item
                  xs={8}
                  sm={8}
                  md={8}
                  className={classes.typegraphyStyles}
                >
                  3,022,000
                </Grid>
              </Grid>
              <Divider
                style={{
                  borderBottom: "1px solid rgba(255,255,255,0.8)",
                  width: "100%",
                }}
              />
              <Grid container>
                <Grid
                  className={classes.summaryFontBold}
                  item
                  xs={4}
                  sm={4}
                  md={4}
                >
                  Convenience Fee
                </Grid>
                <Grid
                  item
                  xs={8}
                  sm={8}
                  md={8}
                  className={classes.typegraphyStyles}
                >
                  100.00
                </Grid>
              </Grid>
              <Divider
                style={{
                  borderBottom: "1px solid rgba(255,255,255,0.8)",
                  width: "100%",
                }}
              />
            </div> */}

            <Grid className={classes.formButtons}>
              <CTA
                noBackground
                text="BACK"
                action={handleBack}
                icon={<FaArrowLeft />}
              />
              <CTA medium text="PROCEED" action={handleProceed} />
            </Grid>
          </Paper>
        </Grid>
      </ThemeProvider>
    </div>
  );
};

export default TicketSummary;
