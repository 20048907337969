import React, { useState } from "react";
import {
  Grid,
  TextField,
  ButtonBase,
  Paper,
  Button,
  Container,
  CssBaseline,
  ThemeProvider,
  Typography,
  Link,
  Box,
  MenuItem,
  Select,
  InputLabel,
  Divider,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
// import MtnAirtime from "../../assets/images/billers/79bd69cc7328f8be6dd7afea13ce7816.jpg";
import { useHistory } from "react-router-dom";
import CTA from "../../components/button/cta-button";
import { FaArrowLeft } from "react-icons/fa";

const useStyles = makeStyles((theme) => ({
  root: {
    borderBottom: "1px solid red",
    borderRadius: 3,
    color: "white",
    height: 48,
    padding: "0 30px",
    flexGrow: 1,
  },

  searchField: {
    width: "400px",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  paperForm: {
    margin: "20px auto 0px auto",
    padding: "3px  40px 10px 40px",
    // width: '450px',
    boxShadow: "0 7px 30px -10px rgba(150,170,180,0.5)",

    width: "700px",
    [theme.breakpoints.down("sm")]: {
      padding: "20px",
      width: "310px",
      marginLeft: "-8px",
    },
  },
  image: {
    width: 120,
    height: 120,
    marginTop: 5,
    boxShadow: "0 7px 30px -10px rgba(150,170,180,0.5)",

    [theme.breakpoints.down("sm")]: {
      width: 80,
      height: 80,
    },
  },
  img: {
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
  },

  button: {
    display: "block",
    marginTop: theme.spacing(2),
  },

  previewTitle: {
    fontWeight: "600",
    marginBottom: "10px",
    marginTop: "20px",
    fontFamily: "Montserrat",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
      fontWeight: "bold",
      marginTop: "0px",
    },
  },
  summaryFontBold: {
    fontWeight: "600",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      fontWeight: "bold",
    },
  },
  formButtons: {
    marginTop: "20px",
    paddingLeft: "20px",
    marginLeft: "150px",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      marginLeft: "0px",
    },
    [theme.breakpoints.down("xs")]: {
      marginLeft: "-18px",
    },
  },
  titleProduct: {
    fontFamily: "Montserrat",
    fontWeight: "bold",
    fontSize: "25px",
    marginTop: "50px",
    marginLeft: "-30px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
      fontWeight: "600px",
      marginLeft: "-20px",
      marginTop: "30px",
    },
  },
  typegraphyStyles: {
    textAlign: "right",
    fontSize: "14px",
  },
}));

const PurchaseStatus = () => {
  const pathUrl = window.location.pathname;
  const history = useHistory();
  const classes = useStyles();
  const handleProceed = () => {};

  return (
    <div className="formContainer">
      <Grid container spacing={1}>
        <Grid item xs={5} sm={3} md={2}>
          <Paper className={(classes.paper, classes.image)}>
            {/* <img className={classes.img} alt="mtn" src={MtnAirtime} /> */}
          </Paper>
        </Grid>
        <Grid item xs={6} sm={3} md={2}>
          <Typography
            className={classes.titleProduct}
            variant="h4"
            component="h2"
          >
            MTN VTU
          </Typography>
        </Grid>
      </Grid>
      <Grid>
        <Paper
          className={classes.paperForm}
          alignItems="center"
          justifyContent="center"
        >
          <Typography align="center" className={classes.previewTitle}>
            Order Summary
          </Typography>
          <Grid container className={classes.previewMain} spacing={0}>
            {/* <Grid item xs={12} sm={12} md={12}>
                  
                </Grid> */}
          </Grid>
          <Grid container>
            <Grid container>
              <Grid
                className={classes.summaryFontBold}
                item
                xs={4}
                sm={4}
                md={4}
              >
                Product
              </Grid>
              <Grid
                item
                xs={8}
                sm={8}
                md={8}
                className={classes.typegraphyStyles}
              >
                Airtimes
              </Grid>
            </Grid>
            <Divider
              style={{
                borderBottom: "1px solid rgba(255,255,255,0.8)",
                width: "100%",
              }}
            />
            <Grid container>
              <Grid
                className={classes.summaryFontBold}
                item
                xs={4}
                sm={4}
                md={4}
              >
                Email
              </Grid>
              <Grid item xs={8} sm={8} md={8}>
                <Typography className={classes.typegraphyStyles}>
                  text@email.com
                </Typography>
              </Grid>
            </Grid>
            <Divider
              style={{
                borderBottom: "1px solid rgba(255,255,255,0.8)",
                width: "100%",
              }}
            />
            <Grid container>
              <Grid
                className={classes.summaryFontBold}
                item
                xs={4}
                sm={4}
                md={4}
              >
                Phone Number
              </Grid>
              <Grid
                itemxs={8}
                sm={8}
                md={8}
                className={classes.typegraphyStyles}
              >
                0801 2345 678
              </Grid>
            </Grid>
            <Divider
              style={{
                borderBottom: "1px solid rgba(255,255,255,0.8)",
                width: "100%",
              }}
            />
            <Grid container>
              <Grid
                className={classes.summaryFontBold}
                item
                xs={6}
                xs={4}
                sm={4}
                md={4}
              >
                Convinience fee
              </Grid>
              <Grid
                item
                xs={8}
                sm={8}
                md={8}
                className={classes.typegraphyStyles}
              >
                0.00
              </Grid>
            </Grid>
            <Divider
              style={{
                borderBottom: "1px solid rgba(255,255,255,0.8)",
                width: "100%",
              }}
            />
            <Grid container>
              <Grid
                className={classes.summaryFontBold}
                item
                xs={4}
                sm={4}
                md={4}
              >
                Total Amount
              </Grid>
              <Grid
                item
                xs={8}
                sm={8}
                md={8}
                className={classes.typegraphyStyles}
              >
                100.00
              </Grid>
            </Grid>
            <Divider
              style={{
                borderBottom: "1px solid rgba(255,255,255,0.8)",
                width: "100%",
              }}
            />
          </Grid>
          <Grid className={classes.formButtons}>
            <CTA
              noBackground
              text="BACK"
              //action={handleBack}
              icon={<FaArrowLeft />}
            />
            <CTA medium text="PROCEED" action={handleProceed} />
          </Grid>
        </Paper>
      </Grid>
    </div>
  );
};

export default PurchaseStatus;
