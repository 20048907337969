import React, { useContext, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Input from "../inputs/input";
import CTA from "../button/cta-button";
import PasswordInput from "../inputs/password-input";
import styles from "../../pages/login/login.module.css";
import { Row, Col } from "react-bootstrap";
import { Button } from "@material-ui/core";
import OnboardingInput from "../inputs/onboarding-input";
import "../../index.css";
import {
  MdEmail,
  MdVerifiedUser,
  MdPhoneAndroid,
  MdCode,
  MdEnhancedEncryption,
  MdPermContactCalendar,
} from "react-icons/md";
import { FaUser } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { useFormik} from "formik";
import Spinner from "../../components/spinner";
import { UserContext } from "../../context/user-context";
import { AlertContext } from "../../context/alert-context";
import { validatePhone } from "../../helpers";

const useStyles = makeStyles((theme) => ({
  modal: {
    // background: "rgba(237, 219, 219, 0.9)",
    // background:'rgba(240, 218, 213, 0.9)'
    background: "rgba(32, 28, 27, 0.8)",
  },
  button: {
    marginLeft: "80%",
    marginBottom: "40px",
    background: "none",
    "&:hover": {
      background: "none",
    },
    "&:focus": {
      outline: "none",
      background: "none",
    },
  },
  updateBtn: {
    transition: "all 0.3s ease-in ",
    "&:focus": {
      outline: "none",
    },
    "&:hover": {
      color: "green",
    },
  },
}));

export default function UpdateProfile(style) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const history = useHistory();

  const {
    user,
    updateRegister,
    clearMessage,
    updatingRegister,
    updateRegisterMessage,
  } = useContext(UserContext);
  const { dispatch } = useContext(AlertContext);

  Yup.addMethod(Yup.string, "dob", function () {
    return this.test({
      name: "dob",
      exclusive: true,
      message: "Must be a valid birth month and year (MM/DD)",
      test: (value) => {
        try {
          // eslint-disable-next-line
          var regExp = new RegExp("^(0[1-9]|1[0-2])/(0[1-9]|[12][0-9]|3[01])$");
          return regExp.test(value);
        } catch (e) {
          return false;
        }
      },
    });
  });
  Yup.addMethod(Yup.string, "phone", function (msg) {
    return validatePhone(this, msg);
  });
  const formatString = (event) => {
    let code = event.keyCode;
    let allowedKeys = [8];
    if (allowedKeys.indexOf(code) !== -1) {
      return;
    }

    event.target.value = event.target.value
      .replace(
        /^([1-9]\/|[2-9])$/g,
        "0$1/" // 3 > 03/
      )
      .replace(
        /^(0[1-9]|1[0-2])$/g,
        "$1/" // 11 > 11/
      )
      .replace(
        /^([0-1])([3-9])$/g,
        "0$1/$2" // 13 > 01/3
      )
      .replace(
        /^(0?[1-9]|1[0-2])([0-9]{2})$/g,
        "$1/$2" // 141 > 01/41
      )
      .replace(
        /^([0]+)\/|[0]+$/g,
        "0" // 0/ > 0 and 00 > 0
      )
      .replace(
        //eslint-disable-next-line
        /[^\d\/]|^[\/]*$/g,
        "" // To allow only digits and `/`
      )
      .replace(
        /\/\//g,
        "/" // Prevent entering more than 1 `/`
      );
  };

  const validationSchema = Yup.object().shape({
    phone: Yup.string("Enter Your Phone Number")
      .phone("Enter a Valid Phone Number")
      .typeError("Enter Your Phone Number")
      .required("Phone Number is Required"),
    email: Yup.string("Enter Your Email")
      .email("Enter a Valid Email")
      .typeError("Enter Your Email")
      .required("Email is Required"),
    firstName: Yup.string("Enter Your First Name")
      .typeError("Enter Your First Name")
      .min(2, "Your Name must be at least 2 Characters long")
      .required("First Name is Required"),
    surname: Yup.string("Enter Your Last Name")
      .typeError("Enter Your Last Name")
      .min(2, "Your Name must be at least 2 Characters long")
      .required("Last Name is Required"),
    dob: Yup.string("Enter Birth Month and Day")
      .dob("Enter Birth Month and Day")
      .typeError("Enter Birth Month and Day")
      .required("Birth Month and Day is Required"),
  });

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  const clearForm = () => {
    this.setState({
      email: user.ConsumerID,
      surname: user.Surname,
      firstName: user.Firstname,
      phone: user.PhoneNumber,
      dob: user.DateOfBirth,
      referral: user.ReferralCode,
    });
  };
  useEffect(() => {
    if (updateRegisterMessage && updatingRegister == false) {
      //handleClose();
      // handleSignin();
      //clearMessage();
      history.push("/");
    }
  });

  const {handleSubmit, handleChange, setFieldTouched, dirty, touched, isValid, isSubmitting, resetForm, setSubmitting, values, errors} = useFormik({
    initialValues: {
      email: user.ConsumerID,
      surname: user.Surname,
      firstName: user.Firstname,
      phone: user.PhoneNumber,
      dob: user.DateOfBirth,
      referral: user.ReferralCode,
    },
    validationSchema,
    onSubmit(values, { setSubmitting }) {
      updateRegister(values, dispatch);
      setSubmitting(false);
      // resetForm();
    },
  });

  const change = (name, e) => {
    e.persist();
    handleChange(e);
    setFieldTouched(name, true, false);
  };

  return (
    <>
      <button onClick={handleOpen} style={{background:'none', border:'none'}} className={classes.updateBtn}>
          <h6 style={{fontSize: "14px"}}>Update Profile</h6>
      </button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className={classes.modal}
        disableBackdropClick="true"
      >
        <form onSubmit={handleSubmit}>
          <div
            style={{ marginLeft: "35%", marginTop: "1%" }}
            className="updateProfileModal"
          >
            {updatingRegister && <Spinner message="Loading...please wait" />}
            <Button style={{}} className={classes.button} onClick={handleClose}>
              <div className={styles.right}></div>
              <div className={styles.left}></div>
            </Button>

            <h2>UPDATE PROFILE</h2>
            <div className="updateForm">
              <Row>
                <Col sm={12} md={12} lg={12}>
                  <OnboardingInput
                    placeholder="Email Address"
                    name="email"
                    type="email"
                    disabled="disabled"
                    value={values.email}
                    onChange={change.bind(null, "email")}
                    style={{ marginBottom: "20px" }}
                    icon={<MdEmail />}
                  />
                  {errors.email && touched.email ? (
                    <div className="pt-error">{errors.email}</div>
                  ) : null}
                </Col>
                <Col sm={12} md={12} lg={12}>
                  <OnboardingInput
                    placeholder="Mobile No"
                    name="phone"
                    type="tel"
                    value={values.phone}
                    disabled="disabled"
                    onChange={change.bind(null, "phone")}
                    style={{ marginBottom: "20px" }}
                    icon={<MdPhoneAndroid />}
                  />
                  {errors.phone && touched.phone ? (
                    <div className="pt-error">{errors.phone}</div>
                  ) : null}
                </Col>
                <Col sm={12} md={12} lg={12}>
                  <OnboardingInput
                    placeholder="Surname"
                    name="surname"
                    type="text"
                    value={values.surname}
                    onChange={change.bind(null, "surname")}
                    style={{ marginBottom: "20px" }}
                    icon={<FaUser />}
                  />
                  {errors.surname && touched.surname ? (
                    <div className="pt-error">{errors.surname}</div>
                  ) : null}
                </Col>
                <Col sm={12} md={12} lg={12}>
                  <OnboardingInput
                    placeholder="First Name"
                    name="firstName"
                    type="text"
                    value={values.firstName}
                    onChange={change.bind(null, "firstName")}
                    style={{ marginBottom: "20px" }}
                    icon={<FaUser />}
                  />
                  {errors.firstName && touched.firstName ? (
                    <div className="pt-error">{errors.firstName}</div>
                  ) : null}
                </Col>
                <Col sm={12} md={12} lg={12}>
                  <OnboardingInput
                    placeholder="Date of Birth:mm/dd"
                    name="dob"
                    type="text"
                    value={values.dob}
                    onChange={change.bind(null, "dob")}
                    onKeyUp={(evt) => formatString(evt)}
                    icon={<MdPermContactCalendar />}
                    style={{ marginBottom: "20px" }}
                  />
                  {errors.dob && touched.dob ? (
                    <div className="pt-error">{errors.dob}</div>
                  ) : null}
                </Col>
                <Col sm={12} md={12} lg={12}>
                  <OnboardingInput
                    placeholder="Referer Code"
                    name="referral"
                    type="text"
                    disabled="disabled"
                    value={values.referral}
                    onChange={handleChange}
                    style={{ marginBottom: "20px" }}
                    icon={<MdEnhancedEncryption />}
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={12} md={12} lg={12}>
                  <CTA
                    large
                    text="Update Profile"
                    disabled={!(isValid && dirty) || isSubmitting}
                    style={{ marginTop: "5px" }}
                    type="submit"
                  />
                </Col>
              </Row>
            </div>
          </div>
        </form>
      </Modal>
    </>
  );
}
