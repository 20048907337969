import React, { useContext } from "react";
import {
  Grid,
  Paper,
  ThemeProvider,
  Typography,
  Divider,
} from "@material-ui/core";
import { useHistory } from "react-router";
import configuration from "../../config";
import { makeStyles } from "@material-ui/core/styles";
import { theme } from "../../theme";
import { FaArrowLeft } from "react-icons/fa";
import { Link } from "react-router-dom";
import queryString from "query-string";
// import PrintModal from "../../components/modal/print-modal";
import PrintReceiptModal from "./print-receipt-modal";
// import Rating from "./rating";
import { useEffect } from "react";
import { ProductContext } from "../../context/product-context";
import Spinner from "../../components/spinner";
import { convertUrl, splitResponse } from "../../helpers";
import { AlertContext } from "../../context/alert-context";
import RatingModal from "./rating";
import { alertConstants } from "../../_constants";

const useStyles = makeStyles((theme) => ({
  root: {
    borderBottom: "1px solid red",
    borderRadius: 3,
    color: "white",
    height: 48,
    padding: "0 30px",
    flexGrow: 1,
  },

  searchField: {
    width: "400px",
  },
  paperForm: {
    boxShadow: "4px 4px 10px rgba(0, 0, 0, 0.15)",
    borderRadius: "5px",
    marginTop: "-50px",
    padding: "3px  40px 10px 40px",
    // width: '450px',
    width: "900px",
    height: "430px",
    overflowY: "scroll",
    [theme.breakpoints.down("sm")]: {
      padding: "20px",
      width: "310px",
      marginLeft: "-8px",
    },
  },
  image: {
    width: 120,
    height: 120,
    marginTop: 5,
    [theme.breakpoints.down("sm")]: {
      width: 80,
      height: 80,
    },
  },
  img: {
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
  },

  button: {
    display: "block",
    marginTop: theme.spacing(2),
  },

  previewTitle: {
    // fontWeight: "600",
    marginBottom: "10px",
    marginTop: "20px",
    fontFamily: "century gothic",
    color: "#006D01",
    fontSize: "20px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
      fontWeight: "bold",
      marginTop: "0px",
    },
  },
  summaryFontBold: {
    fontWeight: "600",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      fontWeight: "bold",
    },
  },
  formButtons: {
    marginTop: "20px",
    paddingLeft: "20px",
    marginLeft: "150px",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      marginLeft: "0px",
    },
    [theme.breakpoints.down("xs")]: {
      marginLeft: "-18px",
    },
  },
  titleProduct: {
    fontFamily: "Montserrat",
    fontWeight: "bold",
    fontSize: "25px",
    marginTop: "50px",
    marginLeft: "-30px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
      fontWeight: "600px",
      marginLeft: "-20px",
      marginTop: "30px",
    },
  },
  // previewMain: {
  //   maxHeight: "270px",
  //   overflowY: "scroll",
  //   "&::-webkit-scrollbar": {
  //     width: "10px",
  //     background: "#fff",
  //     width: "10px",
  //     borderRadius: "5px",
  //   },
  //   "&::-webkit-scrollbar-track": {
  //     boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
  //     borderRadius: "5px",
  //   },
  //   "&::-webkit-scrollbar-thumb": {
  //     borderRadius: "5px",
  //     background: "green",
  //   },
  // },
  typographyStyles: {
    textAlign: "right",
    fontSize: "14px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  },
  yourInfo: {
    fontSize: "14px",
    textAlign: "left",
    marginTop: "0px",
    // marginLeft: "50px",
    marginBottom: "10px",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "0px",
    },
  },
  paper2: {
    boxShadow: "4px 4px 10px rgba(0, 0, 0, 0.15)",
    borderRadius: "5px",
    marginTop: "5px",
    padding: "3px  40px 10px 40px",
    // width: '450px',
    width: "900px",
    [theme.breakpoints.down("sm")]: {
      padding: "10px",
      width: "310px",
      marginLeft: "-8px",
    },
  },
  paragraph: {
    fontSize: "14px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  },
}));

export default function Notification() {
  const classes = useStyles();
  const { dispatch } = useContext(AlertContext);
  const history = useHistory();
  const {
    loadingNotification,
    notifyBillers,
    notifyBillerResponse,
    rateTransactions,
  } = useContext(ProductContext);

  const [open, setOpen] = React.useState(true);


  const onChange = (props) => {
    rating(props.target.value);
    setOpen(false);
  };

  const handleClose = (props) => {
    setOpen(false);
  };

  const rating = (rate) => {
    let _req = {
      UserId: notifyBillerResponse.EmailAddress,
      Rating: rate,
      Comment: "",
      TransactionId: notifyBillerResponse.Id,
      BillerCode: notifyBillerResponse.BillerCode,
      Channel: "Web",
    };
    dispatch({
      type: alertConstants.SUCCESS,
      message: "Thank you for your feedback",
    });
    rateTransactions(_req);
  };

  useEffect(() => {
    const params = queryString.parse(window.location.search);
    let tranId = params["transaction-id"];
    if (typeof tranId !== "undefined" && tranId !== "") {
      const arr = tranId.split("-");
      const _req = {
        PaymentMethodString: configuration.paymentMethod,
        TranId: arr.length === 1 ? arr[0] : arr[1],
      };
      notifyBillers(_req);
    }
  }, []);

  return (
    <div className="formContainer">
      {!!loadingNotification && loadingNotification ? (
        <Spinner message="Loading...please wait" />
      ) : (
        <>
          <Grid>
            <Grid container spacing={1}>
              <Grid item xs={6} sm={3} md={2}>
                <Typography
                  className={classes.titleProduct}
                  variant="h4"
                  component="h2"
                ></Typography>
              </Grid>
            </Grid>
            <Paper
              className={classes.paperForm}
              alignItems="center"
              justifyContent="center"
            >
              <Typography align="center" className={classes.previewTitle}>
                {notifyBillerResponse.TransactionStatus}
              </Typography>
              <Grid container spacing={0}>
                <Typography align="center" className={classes.yourInfo}>
                  {notifyBillerResponse.TransactionDescription}
                </Typography>
              </Grid>
              <Grid container className={classes.previewMain}>
                <Grid container>
                  <Grid
                    className={classes.summaryFontBold}
                    item
                    xs={6}
                    sm={6}
                    md={4}
                  >
                    Order Status
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sm={6}
                    md={8}
                    className={classes.typographyStyles}
                  >
                    {notifyBillerResponse.OrderStatus}
                  </Grid>
                </Grid>
                <Divider
                  style={{
                    borderBottom: "1px solid rgba(255,255,255,0.8)",
                    width: "100%",
                  }}
                />
                <Grid container>
                  <Grid
                    className={classes.summaryFontBold}
                    item
                    xs={6}
                    sm={6}
                    md={4}
                  >
                    Status Description
                  </Grid>
                  <Grid item xs={6} sm={6} md={8}>
                    <Typography className={classes.typographyStyles}>
                      {splitResponse(notifyBillerResponse.OrderDetails)}
                    </Typography>
                  </Grid>
                </Grid>
                <Divider
                  style={{
                    borderBottom: "1px solid rgba(255,255,255,0.8)",
                    width: "100%",
                  }}
                />
                <Grid container>
                  <Grid
                    className={classes.summaryFontBold}
                    item
                    xs={6}
                    sm={6}
                    md={4}
                  >
                    Order Number
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sm={6}
                    md={8}
                    className={classes.typographyStyles}
                  >
                    {notifyBillerResponse.OrderNumber}
                  </Grid>
                </Grid>
                <Divider
                  style={{
                    borderBottom: "1px solid rgba(255,255,255,0.8)",
                    width: "100%",
                  }}
                />
                <Grid container>
                  <Grid
                    className={classes.summaryFontBold}
                    item
                    xs={6}
                    sm={6}
                    md={4}
                  >
                    Order Amount
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sm={6}
                    md={8}
                    className={classes.typographyStyles}
                  >
                    {notifyBillerResponse.OrderAmount}
                  </Grid>
                </Grid>
                <Divider
                  style={{
                    borderBottom: "1px solid rgba(255,255,255,0.8)",
                    width: "100%",
                  }}
                />
                <Grid container>
                  <Grid
                    className={classes.summaryFontBold}
                    item
                    xs={6}
                    sm={6}
                    md={4}
                  >
                    Convenience Fee
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sm={6}
                    md={8}
                    className={classes.typographyStyles}
                  >
                    {notifyBillerResponse.Fee}
                  </Grid>
                </Grid>
                <Divider
                  style={{
                    borderBottom: "1px solid rgba(255,255,255,0.8)",
                    width: "100%",
                  }}
                />
                <Grid container>
                  <Grid
                    className={classes.summaryFontBold}
                    item
                    xs={6}
                    sm={6}
                    md={4}
                  >
                    Order Date
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sm={6}
                    md={8}
                    className={classes.typographyStyles}
                  >
                    {notifyBillerResponse.OrderDate}
                  </Grid>
                </Grid>
                <Divider
                  style={{
                    borderBottom: "1px solid rgba(255,255,255,0.8)",
                    width: "100%",
                  }}
                />
                {!!notifyBillerResponse.ReceiptItems &&
                  notifyBillerResponse.ReceiptItems.map((row) => {
                    return (
                      <>
                        <Grid container>
                          <Grid
                            className={classes.summaryFontBold}
                            item
                            xs={6}
                            sm={6}
                            md={4}
                          >
                            {row.Label}
                          </Grid>
                          <Grid item xs={6} sm={6} md={8}>
                            <Typography className={classes.typographyStyles}>
                              {splitResponse(row.Value)}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Divider
                          style={{
                            borderBottom: "1px solid rgba(255,255,255,0.8)",
                            width: "100%",
                          }}
                        />
                      </>
                    );
                  })}
              </Grid>

              <Grid className={classes.formButtons}>
                {/* <PrintReceiptModal  /> */}
                <RatingModal
                  openModal={open}
                  handleClose={handleClose}
                  onChange={onChange}
                />
              </Grid>
            </Paper>
          </Grid>
          <Grid container>
            <Paper className={classes.paper2}>
              <Grid item>
                <div>
                  <p className={classes.paragraph}>
                    Carry out{" "}
                    <Link
                      to={`/${notifyBillerResponse.CategoryPath}/${notifyBillerResponse.BillerCode}`}
                      style={{
                        color: "#FF6C00",
                        textDecoration: "underline",
                        fontFamily: "Century gothic",
                      }}
                    >
                      {" "}
                      another transaction
                    </Link>{" "}
                    or go{" "}
                    <Link
                      to="/"
                      style={{
                        color: "#FF6C00",
                        textDecoration: "underline",
                        fontFamily: "Century gothic",
                      }}
                    >
                      Back to home
                    </Link>
                  </p>
                </div>
              </Grid>
              <Grid item>
                <Typography className={classes.paragraph}>
                  For any enquiries please contact: info@xpresspayments.com
                </Typography>
              </Grid>
            </Paper>
          </Grid>
        </>
      )}
    </div>
  );
}
