import React from "react";
import ReactDOM from "react-dom";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { fade } from "@material-ui/core";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const Carousel = () => {
  const options = {
    items: 1,
    nav: true,
    rewind: true,
    autoplay: true,
  };
  return (
    //  <div class="item">
    //     <img alt="img1" src={Carousel1}/>
    // </div>
    //     <div className="container-fluid">
    //       <OwlCarousel items={3} margin={8} autoplay ={true} >
    //     </div>
    <OwlCarousel
      options={options}
      className="owl-carousel owl-theme"
      autoplay={true}
      items={1}
      loop={true}
      dots={false}
      autoHeight={false}
      autoWidth={false}
      cellPadding={10}
      margin={200}
      animateIn={true}
    >
      <div class="item">
        <img alt="img1" src="/resources/images/lagos.jpg" />
      </div>
      <div class="item">
        <img alt="img1" src="/resources/images/L-bg.jpg" />
      </div>
      <div class="item">
        <img alt="img1" src="/resources/images/L-img.jpg" />
      </div>
      <div class="item">
        <img alt="img1" src="/resources/images/L-bg.jpg" />
      </div>
    </OwlCarousel>
  );
};

export default Carousel;
