import React, { useContext } from "react";
import PropTypes from "prop-types";
import { lighten, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import { ThemeProvider } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { FaReplyAll } from "react-icons/fa";
import { formatDate } from "../../helpers";
import { repostTransaction } from "../../_actions/bulk-upload.actions";
import { AlertContext } from "../../context/alert-context";
import { alertConstants } from "../../_constants";
import Spinner from "../../components/spinner";
import { theme } from "../../theme";

function createData(pymt, details, provider, TS, PS, Amount, Action) {
  return { pymt, details, provider, TS, PS, Amount, Action };
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "date",
    numeric: true,
    disablePadding: true,
    label: "Tran. Date",
  },
  {
    id: "Pymt",
    numeric: true,
    disablePadding: true,
    label: "Payt. Ref.",
  },
  { id: "Details", numeric: true, disablePadding: false, label: "Details" },
  { id: "Provider", numeric: true, disablePadding: false, label: "Provider" },
  {
    id: "Trans. Status",
    numeric: true,
    disablePadding: false,
    label: "Trans. Status",
  },
  {
    id: "Payment Status",
    numeric: true,
    disablePadding: false,
    label: "Payment Status",
  },
  {
    id: "Amount",
    numeric: true,
    disablePadding: false,
    label: "Amount",
  },
  {
    id: "Action",
    numeric: true,
    disablePadding: false,
    label: "Action",
  },
];

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              style={{ fontWeight: "bold", fontFamily: "Montserrat" }}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
}));

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(1),
    padding: "10px",
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

export default function EnhancedTable(props) {
  const { bulkDetails } = props;
  const classes = useStyles();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [loading, setLoading] = React.useState(false);
  const { dispatch } = useContext(AlertContext);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const repost = (transaction) => {
    setLoading(true);
    repostTransaction(transaction.Id).then(
      (data) => {
        setLoading(false);
        if (data) {
          dispatch({ type: alertConstants.SUCCESS, message: data.toString() });
        }
      },
      (error) => {
        setLoading(false);
        dispatch({ type: alertConstants.ERROR, message: error.toString() });
      }
    );
  };

  const formatCellValue = (v, f) => {
    switch (f.type) {
      case "date":
        v = formatDate(v, "DD-MMM-YYYY");
        break;
      case "currency":
        v = v.toLocaleString(navigator.language, { minimumFractionDigits: 0 });
        break;
      default:
        break;
    }
    return v;
  };

  // const emptyRows =
  //   rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    <div className={classes.root}>
      <ThemeProvider theme={theme}>
        <>{loading && <Spinner message="Loading...please wait" />}</>
        {!!bulkDetails && (
          <Paper className={classes.paper}>
            {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
            <TableContainer>
              <Table
                className={classes.table}
                aria-labelledby="tableTitle"
                aria-label="enhanced table"
              >
                <EnhancedTableHead
                  classes={classes}
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  //   onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={bulkDetails.length}
                />
                <TableBody>
                  {stableSort(bulkDetails, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <TableRow
                          hover
                          // onClick={(event) => handleClick(event, row.name)}
                          tabIndex={-1}
                          key={row.name}
                        >
                          <TableCell component="th" scope="row" padding="none">
                            {row.Date}
                          </TableCell>
                          <TableCell component="th" scope="row" padding="none">
                            {row.PaymentReference}
                          </TableCell>
                          <TableCell align="right">
                            {formatCellValue("date", row.RequestData)}
                          </TableCell>
                          <TableCell align="right">{row.ProductName}</TableCell>
                          <TableCell align="right">
                            {row.ApprovalStatus}
                          </TableCell>
                          <TableCell align="right">
                            {row.PaymentStatus}
                          </TableCell>
                          <TableCell align="right">
                            {formatCellValue("currency", row.Amount)}
                          </TableCell>
                          <TableCell align="right">
                            {" "}
                            <FaReplyAll
                              onClick={() => repost(row)}
                              fontSize="small"
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {/* {emptyRows > 0 && (
                <TableRow style={{ height: "20px" }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )} */}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={bulkDetails.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Paper>
        )}
      </ThemeProvider>
    </div>
  );
}
