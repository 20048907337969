import configuration from "../config";

import {
  alertActions
} from ".";
import {
  handleResponse
} from "../helpers";
import {
  authHeader
} from "../_helpers";

export const fetchProviders = () => {
  const requestOptions = {
    method: "GET",
    headers: {
      ...authHeader(),
      "Content-Type": "application/json"
    },
  };
  var _url = `${configuration.apiUrl}/BulkUpload/Providers`;
  return fetch(_url, requestOptions)
    .then(handleResponse)
};
export const notifyBiller = (_req) => {
  const req = {
    requestData: _req,
  };
  const requestOptions = {
    method: "POST",
    headers: {
      ...authHeader(),
      "Content-Type": "application/json"
    },
    body: JSON.stringify(req),
  };
  var _url = `${configuration.apiUrl}/BulkUpload/NotifyBiller`;
  return fetch(_url, requestOptions)
    .then(handleResponse);
};
export const fetchTransaction = (id, success, failure) => (dispatch) => {
  const requestOptions = {
    method: "GET",
    headers: {
      ...authHeader(),
      "Content-Type": "application/json"
    },
  };
  fetch(
    `${configuration.apiUrl}/Events/FetchTransactionValues?TranId=${id}`,
    requestOptions
  )
    .then(handleResponse)
    .then(
      (resp) => {
        if (!!success) {
          success(resp);
        }
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
        if (!!failure) {
          failure();
        }
      }
    );
};

export const uploadFile = (data) => {
  const requestOptions = {
    method: "POST",
    body: data,
  };
  var _url = `${configuration.apiUrl}/BulkUpload/Upload`;
  return fetch(_url, requestOptions).then(handleResponse);
};

export const logTransaction = (_req) => {
  const requestOptions = {
    method: "POST",
    headers: {
      ...authHeader(),
      "Content-Type": "application/json"
    },
    body: JSON.stringify(_req),
  };
  var _url = `${configuration.apiUrl}/BulkUpload/LogTransaction`;
  return fetch(_url, requestOptions)
    .then(handleResponse)
};
export const fetchTransactions = (paymentReference, _success, _failure) => {
  const requestOptions = {
    method: "GET",
    headers: {
      ...authHeader(),
      "Content-Type": "application/json"
    },
  };
  let _url = `${configuration.apiUrl}/BulkUpload/GetBulkUploadTransactions?PaymentReference=${paymentReference}`;
  return fetch(_url, requestOptions)
    .then(handleResponse);
};
export const repostTransaction = (transactionId) => {
  const requestOptions = {
    method: "GET",
    headers: {
      ...authHeader(),
      "Content-Type": "application/json"
    },
  };
  let _url = `${configuration.apiUrl}/BulkUpload/RepostTransaction?transactionId=${transactionId}`;
  return fetch(_url, requestOptions)
    .then(handleResponse);
};