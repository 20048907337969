import configuration from "../config";

import { authHeader } from "../_helpers";
import { handleResponse } from "../helpers";

export const userService = {
  login,
  logout,
  register,
  activate,
  update: _update,
  changePassword,
  sendResetLink,
  verifyResetToken,
  resetPassword,
  tellAFriend,
  checkLogin,
  getCarouselImages,
  submitBackEmail,
  sendForgotUserame,
  sendForgotUserDetail,
  updateRegWithBackupEmail,

  //getGuessAndWinConfig
};
async function login(username, password) {
  let details = {
    username: btoa(username),
    password: btoa(password),
    grant_type: "password",
  };
  var formBody = [];
  for (var property in details) {
    var encodedKey = encodeURIComponent(property);
    var encodedValue = encodeURIComponent(details[property]);
    formBody.push(encodedKey + "=" + encodedValue);
  }
  formBody = formBody.join("&");
  const requestOptions = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: formBody,
  };
  return await fetch(
    `${configuration.apiUrl}/Users/Authenticate`,
    requestOptions
  ).then(handleLoginResponse);
}

function checkLogin() {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  return fetch(`${configuration.apiUrl}/Users/CheckLogin`, requestOptions).then(
    handleResponse
  );
}

function getCarouselImages() {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  return fetch(
    `${configuration.apiUrl}/Users/CarouselImages`,
    requestOptions
  ).then(handleResponse);
}

function logout() {
  // remove user from local storage to log user out
  // localStorage.removeItem("user");
}

function register(user) {
  const _req = {
    requestData: {
      ConsumerID: user.email,
      EmailAddress: user.email,
      Password: user.password,
      Surname: "",
      Firstname: user.firstName,
      PhoneNumber: user.phone,
      ConsumerStatus: "Disabled",
      ReferedBy: "",
    },
  };
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(_req),
  };

  return fetch(`${configuration.apiUrl}/Users/Register`, requestOptions).then(
    handleResponse
  );
}

function _update(user) {
  const _req = {
    requestData: {
      ConsumerID: user.email,
      EmailAddress: user.email,
      Surname: user.surname,
      Firstname: user.firstName,
      PhoneNumber: user.phone,
      DateOfBirth: user.dob,
      ReferralCode: user.referral,
      //BackupEmail:''
    },
  };
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(_req),
  };

  return fetch(
    `${configuration.apiUrl}/Users/UpdateRegister`,
    requestOptions
  ).then(handleResponse);
}

function changePassword(user) {
  const _req = {
    requestData: {
      ConsumerID: user.email,
      EmailAddress: user.email,
      OldPassword: btoa(user.oldpwd),
      Password: btoa(user.password),
      ConfirmPassword: btoa(user.confirmpwd),
    },
  };
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(_req),
  };

  return fetch(
    `${configuration.apiUrl}/Users/ChangePassword`,
    requestOptions
  ).then(handleResponse);
}

function resetPassword(user) {
  const _req = {
    requestData: {
      ConsumerID: user.email,
      EmailAddress: user.email,
      Password: btoa(user.password),
      ConfirmPassword: btoa(user.confirmpwd),
      token: decodeURIComponent(user.token),
    },
  };
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(_req),
  };
  return fetch(
    `${configuration.apiUrl}/Users/ResetPassword`,
    requestOptions
  ).then(handleResponse);
}

function sendResetLink(user) {
  const _req = {
    requestData: {
      EmailAddress: user.email,
    },
  };
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(_req),
  };

  return fetch(
    `${configuration.apiUrl}/Users/ForgotPassword`,
    requestOptions
  ).then(handleResponse);
}

function tellAFriend(req) {
  const _req = {
    requestData: {
      Teller: req.teller,
      Tellee: req.telle,
      TelleeName: req.telleeName,
    },
  };
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(_req),
  };

  return fetch(
    `${configuration.apiUrl}/Users/TellAFriend`,
    requestOptions
  ).then(handleResponse);
}

function activate(token) {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  return fetch(
    `${configuration.apiUrl}/Users/ActivateAccount/?encrToken=${token}`,
    requestOptions
  ).then(handleResponse);
}

function verifyResetToken(token) {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  return fetch(
    `${configuration.apiUrl}/Users/VerifyResetToken/?encrToken=${token}`,
    requestOptions
  ).then(handleResponse);
}

function handleLoginResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      const error = (data && data.error_description) || response.statusText;
      return Promise.reject(error);
    }
    return data;
  });
}

function submitBackEmail(user) {
  const _req = {
    requestData: {
      EmailAddress: user.email,
      ConsumerID: user.email,
      BackupEmail: user.backupEmail,
    },
  };
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(_req),
  };

  return fetch(
    `${configuration.apiUrl}/Users/SendBackUpEmail`,
    requestOptions
  ).then(handleResponse);
}

function sendForgotUserame(user) {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  return fetch(
    `${configuration.apiUrl}/Users/getMaskedBackupEmailWithPhoneNo?phoneNo=${user.phoneNumber}`,
    requestOptions
  ).then(handleResponse);
}

function sendForgotUserDetail(user) {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  return fetch(
    `${configuration.apiUrl}/Users/SendUserDetailstoBackUpEmail?phoneNo=${user.phoneNumber}`,
    requestOptions
  ).then(handleResponse);
}

function updateRegWithBackupEmail(user) {
  let user1 = JSON.parse(localStorage.getItem("user"));
  const _req = {
    requestData: {
      token: user.token,
      ConsumerID: user1.ConsumerID,
      BackupEmail: user.backupemail,
    },
  };
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(_req),
  };

  return fetch(
    `${configuration.apiUrl}/Users/UpdateRegWithBackupEmail`,
    requestOptions
  ).then(handleResponse);
}
