import React from "react";
import { InputLabel, Input } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { theme } from "../../theme";
import Modal from "@material-ui/core/Modal";
import CTA from "../button/cta-button";
import styles from "../../pages/login/login.module.css";
import { Row, Col } from "react-bootstrap";
import { Button } from "@material-ui/core";
import { TextField, ThemeProvider } from "@material-ui/core";
import OnboardingInput from "../inputs/onboarding-input";

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "fixed",
    width: 500,
    border: "none",
    outline: "none",
    padding: theme.spacing(2, 4, 3),
    background: "none",
    [theme.breakpoints.down("sm")]: {
      position: "absolute",
      width: 270,
      left: "-70px",
      top: "10%",
    },
    [theme.breakpoints.between("md")]: {
      position: "absolute",
      width: 270,
      left: "-100px",
      top: "10%",
    },
    [theme.breakpoints.up("lg")]: {
      // backgroundColor: 'orange',
    },
  },
  modal: {
    background: "rgba(237, 219, 219, 0.9)",
  },
  button: {
    marginLeft: "90%",
    marginBottom: "40px",
    background: "none",
    "&:hover": {
      background: "none",
    },
    "&:focus": {
      outline: "none",
      background: "none",
    },
  },
}));

const ForgotPswd = (style) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const body = (
    <div
      style={{ marginLeft: "35%", marginTop: "8%" }}
      className={classes.paper}
    >
      <Button style={{}} className={classes.button} onClick={handleClose}>
        <div className={styles.right}></div>
        <div className={styles.left}></div>
      </Button>
      <h2 className={styles.loginTitle} style={{}}>
        Recover my password
      </h2>
      <Row>
        <Col sm={12} md={12} lg={12}>
          <OnboardingInput
            placeholder="Name"
            style={{ marginBottom: "20px" }}
            // icon={<MdEmail />}
          />
        </Col>
        <Col sm={12} md={12} lg={12}>
          <Row>
            <Col sm={12} md={12} lg={12}>
              <CTA text="Continue" large green />
            </Col>
          </Row>
          <br />
          <Row>
            <Col>
              <p
                style={{
                  color: "#fff",
                  fontFamily: "Century gothic",
                  fontSize: "13px",
                  marginLeft: "25px",
                }}
              >
                I remember my Password
              </p>
            </Col>
            <Col>
              {" "}
              <CTA
                text="Sign In"
                medium
                style={{
                  background: "grey",
                  border: "none",
                  marginTop: "-10px",
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );

  return (
    <div style={style}>
      <h6 onClick={handleOpen} style={{ color: "#fff", cursor: "pointer" }}>
        Forgot Password?
      </h6>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className={classes.modal}
        // disableBackdropClick="true"
      >
        {body}
      </Modal>
    </div>
  );
};

// const ForgotPswd =() =>{
//     return(
//         <div>
//             <h5>I want to recover my account</h5>
//             <Input placeholder="enter your phone number" />
//         </div>

//     )
// }

export default ForgotPswd;
