
export function modalReducer(state = {}, action) {
  switch (action.type) {
    case "SIGN_IN_MODAL_OPEN":
      return {
        ...state,
        signInModal: true,
      };
    case "SIGN_IN_MODAL_CLOSE":
      return {
        ...state,
        signInModal: false,
      };
    case "SIGN_UP_MODAL_OPEN":
      return {
        ...state,
        signUpModal: true,
      };
    case "SIGN_UP_MODAL_CLOSE":
      return {
        ...state,
        signUpModal: true,
      };
    default:
      return ""
  }
}