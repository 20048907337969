import React from "react";
import { render } from "react-dom";
import { range } from "lodash/fp";
import moment from "moment";
import PrintPDF from "./components/print-pdf";
import { userActions } from "./_actions";
// import { store } from './_helpers';

export const delay = (ms) => new Promise((res) => setTimeout(res, ms));

export const checkStatus = (response) => {
  if (response.status >= 200 && response.status < 300) {
    return response;
  } else {
    const error = new Error(response.statusText);
    error.response = response;
    throw error;
  }
};
export const checkJsonStatus = (json) => {
  if (json.status === "success") {
    return json;
  }
  const error = new Error(json.data.message);
  error.payload = json;
  throw error;
};
export const checkStatusIs200 = (response) => {
  if (response.status === 200) {
    return response;
  } else {
    const error = new Error(response.statusText);
    error.response = response;
    throw error;
  }
};
export const check401 = (response) => {
  if (response.status === 401) {
    const error = new Error(response.statusText);
    throw error;
  }
  return;
};

export const checkContentType = (contentType, response) => {
  const responseContentType = response.headers.get("content-type");
  if (responseContentType && responseContentType.includes(contentType)) {
    return response;
  }
  const error = new Error("not json");
  error.response = response;
  throw error;
};

export const parseJSON = (response) => response.json();

export const createRequestWithToken = (url = "", config) => (token) => {
  const validMethods = ["GET", "POST", "HEAD", "PUT", "DELETE", "PATCH"];
  const defaultconfig = {
    mode: "cors",
    cache: "default",
    credentials: "same-origin",
  };
  const defaultHeaders = new Headers();
  defaultHeaders.set("Content-Type", "application/json");
  defaultHeaders.set("Authorization", `Bearer ${token}`);
  defaultHeaders.set("Accept", `application/json`);

  if (typeof config.method !== "string") {
    throw new TypeError("config method property must be a string.");
  }
  if (validMethods.indexOf(config.method.toUpperCase()) === -1) {
    throw Error(
      "config method property value most be one of ['GET','POST','HEAD','PUT','DELETE']"
    );
  }

  config.headers = config.headers || defaultHeaders;

  if (config.headers && !config.headers instanceof Headers) {
    throw new TypeError("config headers property must be of type Headers.");
  }

  const requestConfig = {
    ...defaultconfig,
    ...config,
  };
  return new Request(url, requestConfig);
};
export const createRequest = (url = "", config, token = "") => {
  const validMethods = ["GET", "POST", "HEAD", "PUT", "DELETE", "PATCH"];
  const defaultconfig = {
    mode: "cors",
    cache: "default",
    credentials: "same-origin",
  };
  const defaultHeaders = new Headers();
  defaultHeaders.set("Content-Type", "application/json");
  if (token) {
    defaultHeaders.set("Authorization", `Bearer ${token}`);
  }
  defaultHeaders.set("Accept", `application/json`);

  if (typeof config.method !== "string") {
    throw new TypeError("config method property must be a string.");
  }
  if (validMethods.indexOf(config.method.toUpperCase()) === -1) {
    throw Error(
      "config method property value most be one of ['GET','POST','HEAD','PUT','DELETE']"
    );
  }

  config.headers = config.headers || defaultHeaders;

  if (config.headers && !config.headers instanceof Headers) {
    throw new TypeError("config headers property must be of type Headers.");
  }

  const requestConfig = {
    ...defaultconfig,
    ...config,
  };
  return new Request(url, requestConfig);
};
export const is_Array = (value) =>
  value && typeof value === "object" && value.constructor === Array;

export const createPager = (
  { totalCount: totalItems, pageSize },
  currentPage
) => {
  if (!totalItems) {
    return false;
  }
  const totalPages = Math.ceil(totalItems / pageSize);
  let startPage, endPage;
  if (totalPages <= 10) {
    // less than 10 total pages so show all
    startPage = 1;
    endPage = totalPages;
  } else {
    // more than 10 total pages so calculate start and end pages
    if (currentPage <= 6) {
      startPage = 1;
      endPage = 10;
    } else if (currentPage + 4 >= totalPages) {
      startPage = totalPages - 9;
      endPage = totalPages;
    } else {
      startPage = currentPage - 5;
      endPage = currentPage + 4;
    }
  }
  // calculate start and end item indexes
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

  // create an array of pages to ng-repeat in the pager control
  const pages = range(startPage, endPage + 1);

  // return object with all pager properties required by the view
  return {
    totalItems: totalItems,
    currentPage: currentPage,
    pageSize: pageSize,
    totalPages: totalPages,
    startPage: startPage,
    endPage: endPage,
    startIndex: startIndex,
    endIndex: endIndex,
    pages: pages,
  };
};
export const removeTrailingSlash = (str) => {
  return str.split("")[str.length - 1] === "/"
    ? str
      .split("")
      .slice(0, str.length - 1)
      .join("")
    : str;
};
export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};
export const getFileNameFromPath = (filePath) => {
  const arr = filePath.split("/");
  return arr[arr.length - 1];
};

export const createArrayFromString = (string, limiter) => {
  const arr = string.split(limiter);
  return arr;
};
export const contains = (haystack, arr) =>
  arr.some((v) => haystack.indexOf(v) >= 0);

export const formatDate = (date, format = "DD-MM-YY h:mm: a") =>
  moment(date).format(format);


function reloadIt() {
  if (window.location.href.substr(-1) !== "#") {
    window.location = window.location.href + "#";
  }
}

export const handleResponse = (response) => {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        window.history.pushState("", "", "/logout");
        setTimeout(() => {
          reloadIt();
        }, 3000);
      }
      data.responseCode = "01";
      const error =
        (data && data.responseMessage) || (data && response.statusText);
      return Promise.reject(error);
    }
    return (data && data.responseData) || {};
  });
};

export const validatePhone = (control, _message) => {
  return control.test({
    name: "phone",
    exclusive: true,
    message: _message,
    test: (value) => {
      try {
        //var regExp =  new RegExp('^(234|0)([0-9]{8,10})$');
        //eslint-disable-next-line
        let regExp = new RegExp("^(0)([0-9]{10})$");
        return regExp.test(value);
      } catch (e) {
        return false;
      }
    },
  });
};

export const downloadPdf = (content, filename) => {
  filename = filename ? filename : "export.pdf";
  render(
    <PrintPDF filename={filename}>{content}</PrintPDF>,
    document.getElementById("pdf")
  );
};

export const print = (content) => {
  let winSet =
    "toolbar=no,location=no,directories=no,menubar=no,scrollbars=yes, resizable=yes",
    width = 800,
    height = 600;
  winSet +=
    ",width=" +
    width +
    ",height=" +
    height +
    ",left=" +
    (window.screen.availWidth - width) / 2 +
    ",top=" +
    (window.screen.availHeight - height) / 2;
  let _wndw = window.open("_blank", "print-window", winSet);
  let div = document.createElement("div");
  render(<React.Fragment>{content}</React.Fragment>, div);
  let _html = div.outerHTML;
  let headstr =
    "<html><title>Print</title><head><link href='/Resources/css/print.css' rel='stylesheet' type='text/css' media='print' /><title></title></head><body>";
  let footstr = "</body>";
  _wndw.document.write(headstr + _html + footstr);
  _wndw.print();
  return false;
};

export const renderHTML = (rawHTML) =>
  React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });
export const convertUrl = (text) => {
  // eslint-disable-next-line
  var exp = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
  var text1 = text.replace(
    exp,
    "<a class='link-color' onclick='javascript:window.open(\"$1\")' href='#'>$1</a>"
  );
  // eslint-disable-next-line
  var exp2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
  return renderHTML(
    text1.replace(
      exp2,
      "$1<a class='link-color' onclick='javascript:window.open(\"http://$2\")' href='#'>$2</a>"
    )
  );
};
export const splitResponse = (text) => {
  let response = text;
  if (text && typeof text != "undefined") {
    const records = text.split("|");
    if (records && records.length > 1) {
      response = "<table><tbody>";
      for (var i = 0; i < records.length; i++) {
        response += "<tr>";
        var str = records[i];
        var arr = splitOnFirst(str, ":");
        // var arr = _child.split(":");
        // var index = str.indexOf("_");
        // var arr = [str.slice(0, index), str.slice(index + 1)];

        //         var str = "good_luck_buddy";
        // var index = str.indexOf('_');
        // var arr = [str.slice(0, index), str.slice(index + 1)];
        if (records && records.length > 1) {
          response +=
            '<th style="text-align:left;">' +
            arr[0] +
            '</th><td style="text-align:left;">' +
            arr[1] +
            "</td>";
        } else {
          response += '<td colspan="2">' + str + "</td>";
        }
        response += "</tr>";
      }
      response += "</tbody></table>";
    }
    response = convertUrl(response);
  }
  return response;
};

function splitOnFirst(str, sep) {
  const index = str.indexOf(sep);
  return index < 0
    ? [str]
    : [str.slice(0, index), str.slice(index + sep.length)];
}
export const truncate = function (str, length, ending) {
  if (length == null) {
    length = 20;
  }
  if (ending == null) {
    ending = "...";
  }
  if (str.length > length) {
    return str.substring(0, length - ending.length) + ending;
  } else {
    return str;
  }
};

export const getMonthName = () => {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const d = new Date();
  return monthNames[d.getMonth()];
};
