import React, { createContext, useReducer, useEffect } from "react";
import { supportConstants, alertConstants } from "../_constants";
import {supportReducer}  from "../_reducers/support.reducer";
import { fetchSubjects, saveSupport } from "../_actions";


const initialState = {
  loading: true,
  subjects: [],
  message: ""
}; 

export const SupportContext = createContext(initialState);

export const SupportContextProvider = ({ children }) => {

  const [state, supportDispatch] = useReducer(supportReducer, initialState);

  const getSupportSubjects = (dispatch) => {
    supportDispatch({type: supportConstants.FETCH_SUBJECTS_REQUEST});
    fetchSubjects().then(resp => {
      supportDispatch({type: supportConstants.FETCH_SUBJECTS, payload: resp});
    }, error => {
      supportDispatch({type: supportConstants.FETCH_SUBJECTS, payload: []});
      dispatch({type: alertConstants.ERROR, message: error.toString()});
    })

  }


  const supportAction = (values, dispatch) => {
    supportDispatch({type: supportConstants.FETCH_SUBJECTS_REQUEST});
    saveSupport(values).then(resp => {
      const msg = 'Message sent successfully';
      supportDispatch({type: supportConstants.SUPPORT_SAVE, message: msg});
      dispatch({type: alertConstants.SUCCESS, message: msg});
    }, error => {
      supportDispatch({type: supportConstants.SUPPORT_SAVE, message: error.toString() });
      dispatch({type: alertConstants.ERROR, message: error.toString()});
    })
  }

  return (
    <SupportContext.Provider 
      value={{
        loading: state.loading,
        subjects: state.subjects,
        response: state.response,
        getSupportSubjects: getSupportSubjects,
        supportAction: supportAction,
        supportDispatch
      }}
    >
      {children}
    </SupportContext.Provider>
  );
};
