import React from "react";
import { Grid, Paper, Typography, ThemeProvider } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import CTA from "../../components/button/cta-button";
import { MdArrowBack } from "react-icons/md";
import { theme } from "../../theme";

const useStyles = makeStyles((theme) => ({
  root: {
    borderBottom: "1px solid red",
    borderRadius: 3,
    color: "white",
    height: 48,
    padding: "0 30px",
    flexGrow: 1,
  },
  searchField: {
    width: "400px",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  paperForm: {
    marginTop: "5px",
    padding: "20px",
    maxHeight: "450px",
    overflow: "scroll",
    boxShadow: "0 7px 30px -10px rgba(150,170,180,0.5)",
    [theme.breakpoints.down("sm")]: {
      padding: "20px",
      marginLeft: "-10px",
    },
    "&::-webkit-scrollbar": {
      width: "10px",
      borderRadius: "10px",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "green",
      borderRadius: "10px",
    },
  },
  paperForm1: {
    marginTop: "0px",
    padding: "10px",
    boxShadow: "0 7px 30px -10px rgba(150,170,180,0.5)",
    [theme.breakpoints.down("sm")]: {
      padding: "20px",
      marginLeft: "-10px",
    },
  },
  image: {
    width: 90,
    height: 90,
    marginTop: "5px",
    boxShadow: "0 7px 30px -10px rgba(150,170,180,0.5)",
    [theme.breakpoints.down("sm")]: {
      width: 90,
      height: 90,
    },
  },
  formTitle: {
    fontFamily: "Montserrat",
    fontWeight: "bold",
    fontSize: "25px",
    marginTop: "50px",
    marginLeft: "-30px",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "-30px",
      fontSize: "20px",
      fontWeight: "bold",
      marginTop: "40px",
    },
    [theme.breakpoints.down("xs")]: {
      marginLeft: "-20px",
      fontSize: "20px",
      fontWeight: "bold",
      marginTop: "40px",
    },
  },
  img: {
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
  },
  inputPadding: {
    paddingLeft: "40px",
    marginTop: "5px",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "0px",
    },
  },
  productName: {
    fontSize: "25px",
    fontWeight: "bold",
    marginTop: "40px",
    marginLeft: "-80px",
    fontFamily: "Montserrat",
    textTransform: "uppercase",
  },
  summaryFontBold: {
    fontWeight: "600",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      fontWeight: "bold",
    },
  },
  tableContent: {
    "&:nth-of-type(even)": {
      backgroundColor: "rgba(196, 196, 196, 0.2)",
    },
  },
  button: {
    display: "flex",
    marginTop: "20px",
    marginRight: "0px",
    marginBottom: "15px",
    float: "right",
  },
  total: {
    display: "flex",
    justifyContent: "space-between",
    width: "86%",
    marginTop: "10px",
    paddingLeft: "20px",
    fontFamily: "Montserrat",
  },
}));

const providers = [
  {
    id: 0,
    providerName: "MTN",
    providerCode: "mtn",
    productCode: "AIRTIME",
    amount: 1000,
  },
  {
    id: 1,
    providerName: "MTN",
    providerCode: "mtn",
    productCode: "AIRTIME",
    amount: 1000,
  },
  {
    id: 2,
    providerName: "MTN",
    providerCode: "mtn",
    productCode: "AIRTIME",
    amount: 1000,
  },
  {
    id: 3,
    providerName: "MTN",
    providerCode: "mtn",
    productCode: "AIRTIME",
    amount: 1000,
  },
  {
    id: 4,
    providerName: "MTN",
    providerCode: "mtn",
    productCode: "AIRTIME",
    amount: 1000,
  },
];

export default function BulkUploadPreview() {
  const history = useHistory();
  const classes = useStyles();

  const handleBack = () => {
    window.history.goBack();
  };
  const handleProceed = () => {
    window.history.push(`/bulkupload/notification`);
  };
  return (
    <div style={{ padding: 20 }}>
      <ThemeProvider theme={theme}>
        <Grid container spacing={1}>
          <Grid item xs={6} sm={3} md={2}>
            <Paper className={(classes.paper, classes.image)}>
              <img
                src="/resources/images/bulk-airtime.png"
                className={classes.img}
                alt="bulk upload"
              />
            </Paper>
          </Grid>
          <Grid item xs={6} sm={9} md={10}>
            <Typography
              variant="h4"
              component="h2"
              className={classes.productName}
            >
              Bulk Airtime Purchase
            </Typography>
          </Grid>
        </Grid>
        <Paper className={classes.paperForm}>
          <h6 style={{ color: "#FF6C00", fontFamily: "Montserrat" }}>
            UPLOAD PREVIEW
          </h6>
          <Paper className={classes.paperForm1}>
            <Table style={{ borderRadius: "10px" }}>
              <TableHead style={{ background: "rgba(0, 109, 1, 0.2)" }}>
                <TableRow>
                  <TableCell
                    style={{ fontWeight: 700, fontFamily: "Montserrat" }}
                  >
                    S/N
                  </TableCell>
                  <TableCell
                    style={{ fontWeight: 700, fontFamily: "Montserrat" }}
                  >
                    SubscriberNumber
                  </TableCell>
                  <TableCell
                    style={{ fontWeight: 700, fontFamily: "Montserrat" }}
                  >
                    Provider
                  </TableCell>
                  <TableCell
                    style={{ fontWeight: 700, fontFamily: "Montserrat" }}
                  >
                    Product
                  </TableCell>
                  <TableCell
                    style={{ fontWeight: 700, fontFamily: "Montserrat" }}
                  >
                    Amount
                  </TableCell>
                </TableRow>
              </TableHead>
              {providers.map(
                ({ id, providerName, providerCode, productCode, amount }) => {
                  return (
                    <TableBody className={classes.tableContent}>
                      <TableRow>
                        <TableCell style={{ fontFamily: "Montserrat" }}>
                          {id}
                        </TableCell>
                        <TableCell style={{ fontFamily: "Montserrat" }}>
                          {providerName}
                        </TableCell>
                        <TableCell style={{ fontFamily: "Montserrat" }}>
                          {providerCode}
                        </TableCell>
                        <TableCell style={{ fontFamily: "Montserrat" }}>
                          {productCode}
                        </TableCell>
                        <TableCell style={{ fontFamily: "Montserrat" }}>
                          {amount}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  );
                }
              )}
            </Table>
            <div className={classes.total}>
              <h6 style={{ fontWeight: "bold" }}>Total</h6>
              <p>5000</p>
            </div>
          </Paper>
          <div className={classes.button}>
            <CTA
              noBackground
              text="Back"
              icon={<MdArrowBack />}
              action={handleBack}
            />
            <CTA
              medium
              text="Proceed to Payment"
              style={{ width: "auto", fontSize: "12px", marginTop: "-10px" }}
              action={handleProceed}
            />
          </div>
        </Paper>
      </ThemeProvider>
    </div>
  );
}
