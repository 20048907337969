import React, { useState, modalDispatch, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import "../../index.css";
import Login from "../../pages/login/login";
import { BrowserRouter as Router, Link } from "react-router-dom";
import Signup from "../../pages/signup/signup";
// import logo from "../../assets/images/logo.png";
import { UserContext } from "../../context/user-context";
import CTA from "../button/cta-button";
// import { AlertContext } from "../../context/alert-context";
import { useHistory } from "react-router-dom";
import { userActions } from "../../_actions/user.actions";
import { userConstants } from "../../_constants";
import XpressWalletBalance from "../../pages/xpress-wallet/xpress-wallet-balance";
import ProfileDropdown from "../../components/profile-dropdown/profile-dropdown";
const useStyles = makeStyles((theme) => ({}));

const Header = (open) => {
  const [showLogo, setShowLogo] = useState(false);
  const [showBalance, setShowBalance] = useState(false);
  const { loggedIn, logout, userDispatch } = useContext(UserContext);
  const history = useHistory();

  useEffect(() => {
    setShowLogo(open);
  }, [open]);

  const showWalletBaln = () => {
    setShowBalance(true);
  };

  const handleLogout = () => {
    logout(history);
  };

  return (
    <div className="header">
      {/* {loggedIn && <ProfileDropdown />} */}

      {loggedIn ? (
        <>
          <ProfileDropdown />
          <XpressWalletBalance />
          <CTA
            nav
            text="LOGOUT"
            type="button"
            action={handleLogout}
            orange
            style={{}}
          />
        </>
      ) : (
        <>
          <Signup />
          <Login />
        </>
      )}
    </div>
  );
};

export default Header;
