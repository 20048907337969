import configuration from '../config';

import { alertActions } from '.';
import { handleResponse } from '../helpers';
import { authHeader } from '../_helpers';

export const fetchMovies = () => {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    };
    var _url = `${configuration.apiUrl}/Movies`;
    return fetch(_url, requestOptions)
        .then(handleResponse);
}
export const logTransaction = (_req) => {
    const req = {
        requestData: _req
    }
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(req)
    };
    var _url = `${configuration.apiUrl}/Movies/LogTransaction`;
    return fetch(_url, requestOptions)
        .then(handleResponse);
}
export const notifyBiller = (_req) => {
    const req = {
        requestData: _req
    }
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(req)
    };
    var _url = `${configuration.apiUrl}/Movies/NotifyBiller`;
    return fetch(_url, requestOptions)
        .then(handleResponse);
}
export const fetchTransaction = (id, success, failure) => dispatch => {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
    };
    fetch(`${configuration.apiUrl}/Movies/FetchTransactionValues?TranId=${id}`, requestOptions)
        .then(handleResponse)
        .then(
            resp => {
                if (!!success) {
                    success(resp);
                }
            },
            error => {
                dispatch(alertActions.error(error.toString()));
                if (!!failure) {
                    failure();
                }
            }
        );
}
export const fetchShowtimes = (movieId, cinemaId) => {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
    };
    return fetch(`${configuration.apiUrl}/Movies/GetShowtimes?movieid=${movieId}&cinemaid=${cinemaId}`, requestOptions)
        .then(handleResponse);
}