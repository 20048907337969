import configuration from "../../config";
import React from "react";
import Swal from "sweetalert2";
import Modal from "react-modal";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

import {
  FaFacebookF,
  FaLinkedinIn,
  FaTwitter,
  //FaSignOutAlt,
  //FaUserCog,
  //FaLock,
  //FaGooglePlus,
  //FaInstagram
  //FaAlignRight
  //FaBookOpen,
  //FaSignInAlt,
  //FaPhone,
  //FaUserAlt
  //FaRegAddressBook,
  //FaUserPlus
} from "react-icons/fa";

import "./index.css";

const customStyles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: "1000",
    // backgroundColor: "rgba(255, 255, 255, 0.75)",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    //backgroundColor: "papayawhip"
  },

  xpressPrimaryBtn: {
    backgroundColor: "#FF5800",
    "&:hover": {
      backgroundColor: "#C64500",
    },
  },

  xpressDefaultBtn: {
    backgroundColor: "#E1E1E1",
    marginLeft: "0.8rem",

    "&:hover": {
      backgroundColor: "#C64500",
    },
  },
};

// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement("#root");

let winSet =
    "toolbar=no,location=no,directories=no,menubar=no,scrollbars=yes, resizable=yes",
  width = 800,
  height = 600;
winSet +=
  ",width=" +
  width +
  ",height=" +
  height +
  ",left=" +
  (window.screen.availWidth - width) / 2 +
  ",top=" +
  (window.screen.availHeight - height) / 2;

class form extends React.Component {
  constructor() {
    super();

    this.state = {
      modalIsOpen: false,
      //responseCode: undefined,
      //responseMessage: undefined,
      disabled: false,
    };

    this.openModal = this.openModal.bind(this);
    this.afterOpenModal = this.afterOpenModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }
  openModal() {
    this.setState({ modalIsOpen: true });
  }

  afterOpenModal() {
    // references are now sync'd and can be accessed.
    this.subtitle.style.color = "#f00";
    this.subtitle.align = "center";
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  PostGuess = (event) => {
    event.preventDefault();
    //const data = new FormData(event.target);
    this.setState({ disabled: true });
    const email = event.target.elements.email.value;
    const phone = event.target.elements.phone.value;
    const guess = event.target.elements.guess.value;

    const data = {
      UserEmail: email,
      UserPhone: phone,
      UserGuess: guess,
    };

    fetch(`${configuration.apiUrl2}/api/GuessAndWin/Guess`, {
      method: "POST",
      body: JSON.stringify(data),
      headers: new Headers({
        Authorization: "Basic " + btoa(`${configuration.apiUrl2Credentials}`),
        "Content-Type": "application/json",
        mode: "cors",
        cache: "default",
        credentials: "same-origin",
      }),
    })
      .then((response) => response.json())
      .then(
        (response) => {
          if (response.responseCode === "00") {
            this.closeModal();

            Swal.fire({
              title: "Congratulations!!!",
              //text: response.responseMessage,
              type: "success",
              animation: true,
              customClass: "bounceInDown",

              html:
                response.responseMessage +
                "<form id = 'formUpdate'>" +
                "<br/><br/>" +
                '<input id="location" class="form_input" placeholder="Enter Location" name="location" required style = "width: 80%; padding: 12px 20px; margin: 8px 0; border: 1px solid #ccc; border-radius: 4px; box-sizing: border-box; ">' +
                "</input><br/>" +
                '<input id="phoneToCredit" class="form_input" placeholder="Enter Phone to credit" name="phoneToCredit" required style = "width: 80%; padding: 12px 20px; margin: 8px 0; border: 1px solid #ccc; border-radius: 4px; box-sizing: border-box; ">' +
                "</input><br/>" +
                '<input id="ageRange" class="form_input" placeholder="Enter age range" name="ageRange" style = "width: 80%; padding: 12px 20px; margin: 8px 0; border: 1px solid #ccc; border-radius: 4px; box-sizing: border-box; ">' +
                "</input><br/>" +
                '<input type="radio" class="form_input"  name="gender" value="Male" checked> Male</input><input class="form_input"  type="radio" name="gender" value="Female">Female</input>' +
                "</form>",

              inputAttributes: {
                autocapitalize: "off",
              },
              showCancelButton: false,
              confirmButtonText: "Save",
              showLoaderOnConfirm: true,
              focusConfirm: false,
              preConfirm: () => {
                var location = document.getElementById("location").value;
                var phoneToCredit = document.getElementById("phoneToCredit")
                  .value;
                var ageRange = document.getElementById("ageRange").value;
                var gender = Array.from(
                  document.getElementsByName("gender")
                ).find((r) => r.checked).value;

                if (location === "") {
                  Swal.showValidationMessage("please enter your location.");
                } else if (phoneToCredit === "") {
                  Swal.showValidationMessage("please enter phone to credit.");
                } else if (
                  phoneToCredit !== "" &&
                  !ValidatePhoneNo(phoneToCredit)
                ) {
                  Swal.showValidationMessage(
                    "please enter a valid phone number"
                  );
                }
                function ValidatePhoneNo(phone) {
                  var expr = /^[a-zA-Z0-9\s]{9,11}$/;
                  return expr.test(phone);
                }

                const data2 = {
                  Location: location,
                  PhoneNotoCredit: phoneToCredit,
                  AgeRange: ageRange,
                  Gender: gender,
                };

                return fetch(
                  `${configuration.apiUrl2}/api/GuessAndWin/GuessUpdate`,
                  {
                    method: "POST",
                    body: JSON.stringify(data2),
                    headers: new Headers({
                      Authorization:
                        "Basic " + btoa(`${configuration.apiUrl2Credentials}`),
                      "Content-Type": "application/json",
                      mode: "cors",
                      cache: "default",
                      credentials: "same-origin",
                    }),
                  }
                );
              },
              allowEscapeKey: false,
              allowOutsideClick: false, // () => !Swal.isLoading()
            })
              .then((result) => result.value.json())
              .then(
                (result) => {
                  if (result.responseCode === "00") {
                    this.setState({ disabled: false });
                    Swal.fire({
                      title: "Successful!!!",
                      //text: result.responseMessage,
                      type: "success",
                    });
                  } else {
                    Swal.fire({
                      title: "Oops!!!",
                      text: result.responseMessage,
                      type: "error",
                    });
                  }
                },
                (error) => {}
              );
          } else {
            this.setState({ disabled: false });
            Swal.fire({
              title: "Oops!!!",
              text: response.responseMessage,
              type: "error",
            });
          }
        },
        (error) => {}
      );
  };

  render() {
    // const { classes } = this.props;
    return (
      <div>
        <Tooltip title="Click to Guess and Win">
          <img
            src="/Resources/images/Gift_Box.gif"
            alt="Gift Item"
            className="swing"
            height="70"
            width="70"
            style={{
              height: "70px",
              width: "70px",
            }}
            onClick={this.openModal}
          />
        </Tooltip>
        <Modal
          isOpen={this.state.modalIsOpen}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={this.closeModal}
          style={customStyles}
          contentLabel="Example Modal"
          className="Modal"
          overlayClassName="Overlay"
          shouldCloseOnOverlayClick={false}
        >
          <div className="modal-content">
            <h2 ref={(subtitle) => (this.subtitle = subtitle)}>
              {this.props.CampaignName}
            </h2>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="formMessage"
            >
              You have 3 trials to guess a number between{" "}
              {this.props.GuessRange} for a chance to win amazing gifts.
            </div>
            <br />

            <form
              onSubmit={(event) => this.PostGuess(event)}
              className="formBody"
            >
              <label htmlFor="email">Email*</label>
              <input
                type="email"
                name="email"
                placeholder="Enter Email"
                id="email"
                required
              />
              <br />

              <label htmlFor="phoneNumber">Phone*</label>
              <input
                type="phone"
                name="phone"
                placeholder="Enter Phone"
                id="phoneNumber"
              />
              <br />

              <label htmlFor="guess">Guess*</label>
              <input
                type="number"
                name="guess"
                placeholder="Enter Guess"
                id="guess"
                required
              />
              <br />

              <Button
                type="submit"
                size="medium"
                variant="contained"
                color="primary"
                style={customStyles.xpressPrimaryBtn}
                disabled={this.state.disabled}
              >
                Submit Guess
              </Button>
              <Button
                type="submit"
                size="medium"
                variant="contained"
                color="default"
                style={customStyles.xpressDefaultBtn}
                onClick={this.closeModal}
              >
                close
              </Button>
            </form>

            <div style={{ textAlign: "right" }}>
              <Tooltip title="Share campaign on Facebook">
                <IconButton
                  onClick={(evt) => {
                    evt.preventDefault();
                    let url =
                      "https://www.facebook.com/sharer/sharer.php?u=" +
                      //window.location.href +
                      "https://www.payxpress.com" +
                      "/PayXpress%20Guess%20and%20Win%20Campaign!!!%20Click%20the%20below%20link%20to%20Play%20n%20Win%20Free%20Airtime%20Top-up,%20Movie%20Tickets%20and%20more...";
                    window.open(url, "Share on Facebook", winSet);
                  }}
                  aria-label="Facebook"
                  color="inherit"
                >
                  <FaFacebookF fontSize="medium" />
                </IconButton>
              </Tooltip>
              <Tooltip title="Share campaign on LinkedIn">
                <IconButton
                  onClick={(evt) => {
                    evt.preventDefault();
                    //let summary =
                    // "Xpress Payment Solutions Limited is a proudly owned Nigerian Company. A leading Financial Technology Solution Company that specializes in Electronic Payments, Collections, Bills Payment and Funds Disbursement.";
                    //summary = encodeURIComponent(summary);
                    let url =
                      "https://www.linkedin.com/shareArticle?mini=true&url=" +
                      //window.location.href +
                      "https://www.payxpress.com/" +
                      "&title=PayXpress&" +
                      "summary=%20Guess%20and%20Win%20Campaign!!!%20Click%20the%20below%20link%20to%20Play%20n%20Win%20Free%20Airtime%20Top-up,%20Movie%20Tickets%20and%20more...&" +
                      "source=LinkedIn";

                    window.open(url, "Share on LinkedIn", winSet);
                  }}
                  aria-label="LinkedIn"
                  color="inherit"
                >
                  <FaLinkedinIn fontSize="medium" />
                </IconButton>
              </Tooltip>
              <Tooltip title="Share campaign on Twitter">
                <IconButton
                  onClick={(evt) => {
                    evt.preventDefault();
                    let url =
                      "https://twitter.com/intent/tweet?source=sharethiscom&text=PayXpress%20Guess%20and%20Win%20Campaign!!!%20Click%20the%20below%20link%20to%20Play%20n%20Win%20Free%20Airtime%20Top-up,%20Movie%20Tickets%20and%20more...&url=" +
                      "https://www.payxpress.com"; // +window.location.href

                    window.open(url, "Share on Twitter", winSet);
                  }}
                  aria-label="Twitter"
                  color="inherit"
                >
                  <FaTwitter fontSize="medium" />
                </IconButton>
              </Tooltip>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default form;
