import React from 'react';
import styles from './styles.module.css';

const DesignBtn = ({style,small, big}) => {
    return(
        <button className={`${styles.btn}
        ${small ? styles.small : null}
        ${big ? styles.big : null}
        `} 
        
        style={style}
        ></button>
    )
}


export default DesignBtn;