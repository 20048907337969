import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
export default ({message}) => (
    <Dialog open={true}>
        <DialogContent>
            <Typography component="p">{message ? message : 'Processing...please wait'}</Typography>
            <LinearProgress color="primary" />
        </DialogContent>
    </Dialog>
)