import React,{useContext,useEffect} from "react";
import { makeStyles } from "@material-ui/core/styles";
import styles from "../../components/button/button.module.css";
import { Button, ThemeProvider, TextField } from "@material-ui/core";
import Row from "react-bootstrap/Row";
import { Col } from "react-bootstrap";
import Close from "../../components/button/close-btn";
import OnboardingInput from "../../components/inputs/onboarding-input";
import PasswordInput from "../../components/inputs/password-input";
import { FaUser, FaEnvelope, FaUserLock } from "react-icons/fa";
import { MdEmail, MdAccountCircle, MdSettingsEthernet } from "react-icons/md";
import CTA from "../../components/button/cta-button";
import { UserContext } from "../../context/user-context";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useHistory } from "react-router";
import { Redirect } from "react-router-dom";
import Spinner from "../../components/spinner";
import { AlertContext } from "../../context/alert-context";
import { theme } from "../../theme";


const useStyles = makeStyles((theme) => ({
  paper: {
    position: "fixed",
    width: 500,
    border: "none",
    outline: "none",
    padding: theme.spacing(2, 4, 3),
    background: "none",
    [theme.breakpoints.down("sm")]: {
      // background:'green',
      position: "absolute",
      width: 270,
      left: "-70px",
      top: "10%",
    },
    [theme.breakpoints.up("md")]: {
      // backgroundColor: 'blue',
    },
    [theme.breakpoints.up("lg")]: {
      // marginTop:'100%'
    },
    // marginTop:'-5%'
  },
  modal: {
    // background: "rgba(237, 219, 219, 0.9)",
  },
  button: {
    marginLeft: "90%",
    marginTop: "10%",
    background: "none",
    "&:hover": {
      background: "none",
    },
    "&:focus": {
      outline: "none",
      background: "none",
    },
  },
}));
export default function ForgotPasswordModal({ handleClose, handleSignin }) {
  const classes = useStyles();
  const {
    //user,
    forgotPassword,
    forgotPasswordMessage,
    forgottenPassword,
    clearMessage,
    //userDispatch,
  } = useContext(UserContext);
  const { dispatch } = useContext(AlertContext);
  const history = useHistory();

  const validationSchema = Yup.object().shape({
    email: Yup.string("Enter Your Email")
      .email("Enter a Valid Email")
      .typeError("Enter Your Email")
      .required("Email is Required"),
  });

  const clearForm = () => {
    this.setState({
      email: "",
    });
  };


  const change = (name, e) => {
    e.persist();
    handleChange(e);
    setFieldTouched(name, true, false);
  };

  const {handleSubmit, handleChange, setFieldTouched, dirty, touched, isValid, isSubmitting, resetForm, setSubmitting, values, errors} = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema,
    onSubmit(values, { setSubmitting }) {
      setSubmitting(false)
      forgotPassword(values, dispatch);
      setSubmitting(false)
      resetForm({
        email: "",
      })
      
    },
  });

  // const handleChange = () => {
  //     setHint(true);
  //   };

  return (
    <ThemeProvider theme={theme}>
    <div
      style={{ marginLeft: "35%", marginTop: "8%" }}
      className={classes.paper}
    >
      {forgottenPassword && <Spinner message="Loading...please wait" />}
      <form onSubmit={handleSubmit}>
        <Button style={{}} className={classes.button} onClick={handleClose}>
          <div className={styles.right}></div>
          <div className={styles.left}></div>
        </Button>
        <h2 className="loginTitle" style={{}}>
          Recover my password
      </h2>
        <Row>
          <Col sm={12} md={12} lg={12}>
            <OnboardingInput
              placeholder="Enter email address"
              name="email"
              type="email"
              value={values.email}
              onChange={change.bind(null, "email")}
              style={{ marginTop: "20px" }}
              // style={{ marginBottom: "20px" }}
              icon={<MdEmail />}
            />
            {errors.email && touched.email ? (
              <div className="pt-error">{errors.email}</div>
            ) : null}
          </Col>
          <Col sm={12} md={12} lg={12}>
            <Row>
              <Col sm={12} md={12} lg={12}>
                <CTA text="Continue" 
                large 
                disabled={!(isValid && dirty) || isSubmitting}
                style={{ marginTop: "20px",marginLeft:'0px' }}
                type="submit"
                />
              </Col>
            </Row>
            <br />
            <Row>
              <Col sm={6}>
                <p
                  style={{
                    color: "#fff",
                    fontFamily: "Century gothic",
                    fontSize: "13px",
                    marginLeft: "25px",
                  }}
                >
                  I remember my password
              </p>
              </Col>
              <Col sm={6}>
                {" "}
                <CTA
                  text="Sign In"
                  action={handleSignin}
                  medium
                  style={{
                    background: "none",
                    border: "2px solid grey",
                    marginTop: "-15px",
                  }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </form>
    </div>
    </ThemeProvider>
  );
}
