import React from 'react';
import styles from './button.module.css';


const CTA = ({text, icon, small, noBackground,green,orange, large, nav, rounded,medium,style, action, type, disabled }) =>{
    return (
            <button
              className={`${styles.btn}
               ${large ? styles.large :""} 
               ${nav ? styles.nav : ""}
               ${medium ? styles.medium : ""}
               ${noBackground ? styles.noBackground : ""}
               ${ small ? styles.small : ""}
               ${orange ? styles.orange: ""}
               ${green ? styles.green: "" }
               ${rounded ? styles.rounded: ""}
               ${disabled ? styles.disabled: ""}`
              }
              style={style}
              onClick={action}
              type={type} 
            >
              <span style={{color:'grey'}}>{icon}</span> 
              {text} 
            </button>
    )

}

export default CTA;