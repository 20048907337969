import React, { useRef } from "react";
import ReactToPrint from "react-to-print";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Grid, Paper, Typography, Divider } from "@material-ui/core";

// const useStyles = makeStyles((theme) =>
//   createStyles({
//     paper: {
//       position: "absolute",
//       width: 700,
//       backgroundColor: theme.palette.background.paper,
//       boxShadow: theme.shadows[5],
//       // padding: theme.spacing(2, 4, 3),
//     },
//     paperForm: {
//       margin: "20px auto 0px auto",
//       padding: "3px  40px 10px 40px",
//       // width: '450px',
//       width: 700,
//       [theme.breakpoints.down("sm")]: {
//         padding: "20px",
//         width: "310px",
//         marginLeft: "-8px",
//       },
//     },
//     previewTitle: {
//       fontWeight: "600",
//       marginBottom: "10px",
//       marginTop: "20px",
//       fontFamily: "Montserrat",
//       [theme.breakpoints.down("sm")]: {
//         fontSize: "16px",
//         fontWeight: "bold",
//         marginTop: "0px",
//       },
//     },
//     summaryFontBold: {
//       fontWeight: "600",
//       [theme.breakpoints.down("sm")]: {
//         fontSize: "12px",
//         fontWeight: "bold",
//       },
//     },
//     formButtons: {
//       marginTop: "20px",
//       paddingLeft: "20px",
//       marginLeft: "150px",
//       [theme.breakpoints.down("sm")]: {
//         display: "flex",
//         marginLeft: "0px",
//       },
//       [theme.breakpoints.down("xs")]: {
//         marginLeft: "-18px",
//       },
//     },
//     titleProduct: {
//       fontFamily: "Montserrat",
//       fontWeight: "bold",
//       fontSize: "25px",
//       marginTop: "50px",
//       marginLeft: "-30px",
//       [theme.breakpoints.down("sm")]: {
//         fontSize: "18px",
//         fontWeight: "600px",
//         marginLeft: "-20px",
//         marginTop: "30px",
//       },
//     },
//     typegraphyStyles: {
//       textAlign: "right",
//       fontSize: "14px",
//     },
//   })
// );

const handleProceed = () => {};
// const handleOpen = () => {
//   setOpen(true);
// };

// const handleClose = () => {
//   setOpen(false);
// };

class ComponentToPrint extends React.Component {
  render() {
    return (
      <div className="paper">
        <Grid>
          <Paper
            className="paperForm"
            alignItems="center"
            justifyContent="center"
          >
            <Grid container>
              <Grid container style={{ height: "80px", marginBottom: "15px" }}>
                <Grid item md={6} sm={6} xs={6} style={{ marginTop: "15px" }}>
                  <img
                    src="/resources/images/logo.png"
                    alt=""
                    style={{ width: "100px", height: "35px" }}
                  />
                </Grid>
                <Grid item md={6} sm={6} xs={6}>
                  <img
                    src="/resources/images/mtn.jpg"
                    style={{
                      width: "80px",
                      height: "80px",
                      marginLeft: "120px",
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container style={{ height: "40px" }}>
                <Grid className="summaryFontBold" item xs={6} sm={6} md={6}>
                  Product
                </Grid>
                <Grid item xs={6} sm={6} md={6} className="typegraphyStyles">
                  Airtimes
                </Grid>
              </Grid>

              <br />
              <Grid container style={{ height: "40px" }}>
                <Grid
                  className="classes.summaryFontBold"
                  item
                  xs={4}
                  sm={4}
                  md={4}
                >
                  E-mail
                </Grid>
                <Grid item xs={8} sm={8} md={8}>
                  <Typography className="typegraphyStyles">
                    me@email.com
                  </Typography>
                </Grid>
              </Grid>

              <br />
              <Grid container style={{ height: "40px" }}>
                <Grid className="summaryFontBold" item xs={4} sm={4} md={4}>
                  Phone Number
                </Grid>
                <Grid itemxs={8} sm={8} md={8} className="typegraphyStyles">
                  0801 2345 678
                </Grid>
              </Grid>

              <br />
              <Grid container style={{ height: "40px" }}>
                <Grid
                  className="summaryFontBold"
                  item
                  xs={6}
                  xs={4}
                  sm={4}
                  md={4}
                >
                  Convenience fee
                </Grid>
                <Grid item xs={8} sm={8} md={8} className="typegraphyStyles">
                  0.00
                </Grid>
              </Grid>

              <br />
              <Grid container style={{ height: "40px" }}>
                <Grid className="summaryFontBold" item xs={4} sm={4} md={4}>
                  Total Amount
                </Grid>
                <Grid item xs={8} sm={8} md={8} className="typegraphyStyles">
                  100.00
                </Grid>
              </Grid>
              <Grid container style={{ height: "40px" }}>
                <Grid className="summaryFontBold" item xs={4} sm={4} md={4}>
                  Total Amount
                </Grid>
                <Grid item xs={8} sm={8} md={8} className="typegraphyStyles">
                  100.00
                </Grid>
              </Grid>
              <Grid container style={{ height: "40px" }}>
                <Grid className="summaryFontBold" item xs={4} sm={4} md={4}>
                  Total Amount
                </Grid>
                <Grid item xs={8} sm={8} md={8} className="typegraphyStyles">
                  100.00
                </Grid>
              </Grid>

              <br />
            </Grid>
          </Paper>
        </Grid>
      </div>
    );
  }
}

const ContentToPrint = () => {
  const componentRef = useRef();
  //   const classes = useStyles();
  return (
    <div>
      <ComponentToPrint ref={componentRef} />
      <ReactToPrint
        trigger={() => <button className="print-btn">Print Receipt</button>}
        content={() => componentRef.current}
      />
    </div>
  );
};

export default ContentToPrint;
