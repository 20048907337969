import React, { useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import CTA from "../../components/button/cta-button";
import "../../index.css";
import LoginModal from "../../components/modal/loginModal";
import ForgotPasswordModal from "../../components/modal/forgotPasswordModal";
import SignupModal from "../../components/modal/signupModal";
import { ModalContext } from "../../context/modal-context";
import { theme } from "../../theme";
import { ThemeProvider } from "react-bootstrap";

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "fixed",
    width: 500,
    border: "none",
    outline: "none",
    padding: theme.spacing(2, 4, 3),
    background: "none",
    [theme.breakpoints.down("sm")]: {
      // background:'green',
      position: "absolute",
      width: 270,
      left: "-70px",
      top: "10%",
    },
    [theme.breakpoints.up("md")]: {
      // backgroundColor: 'blue',
    },
    [theme.breakpoints.up("lg")]: {},
  },
  modal: {
    background: "rgba(32, 28, 27, 0.8)",
  },
  button: {
    marginLeft: "90%",
    marginTop: "10%",
    background: "none",
    "&:hover": {
      background: "none",
    },
    "&:focus": {
      outline: "none",
      background: "none",
    },
  },
}));

export default function Signup() {
  const classes = useStyles();
  const [openSignUp, setOpenSignUp] = React.useState(false);
  const [login, setLogin] = React.useState(false);
  const [forgotPassword, setForgotPassword] = React.useState(false);
  const { signUpModal } = useContext(ModalContext);

  const handleOpen = () => {
    setLogin(false);
    setOpenSignUp(true);
  };

  function handleClose() {
    setOpenSignUp(false);
    setLogin(false);
    setForgotPassword(false);
  }

  const handleLogin = () => {
    setLogin(true);
  };

  const handleForgotPassword = () => {
    setForgotPassword(true);
    setLogin(false);
  };

  const handleSignin = () => {
    setForgotPassword(false);
    setLogin(true);
  };

  const handleSignUp = () => {
    setOpenSignUp(true);
    setLogin(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <div>
        <CTA nav text="Sign Up" orange style={{}} action={handleOpen} />
        <Modal
          open={openSignUp}
          // onClose={handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          className={classes.modal}
        >
          {login ? (
            <LoginModal
              handleClose={handleClose}
              handleForgotPassword={handleForgotPassword}
              handleSignUp={handleSignUp}
            />
          ) : forgotPassword ? (
            <ForgotPasswordModal
              handleClose={handleClose}
              handleSignin={handleSignin}
            />
          ) : (
            <SignupModal
              handleClose={handleClose}
              handleLogin={handleLogin}
              openSignUp={openSignUp}
              handleSignin={handleSignin}
            />
          )}
        </Modal>
      </div>
    </ThemeProvider>
  );
}
