import React, { useContext, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import styles from "../../components/button/button.module.css";
import { Button, ThemeProvider, TextField } from "@material-ui/core";
import Row from "react-bootstrap/Row";
import { Col } from "react-bootstrap";
import "../../index.css";
import Close from "../../components/button/close-btn";
import OnboardingInput from "../../components/inputs/onboarding-input";
import PasswordInput from "../../components/inputs/password-input";
import { FaUser, FaEnvelope, FaUserLock } from "react-icons/fa";
import { MdEmail, MdAccountCircle, MdSettingsEthernet } from "react-icons/md";
import CTA from "../../components/button/cta-button";
import { UserContext } from "../../context/user-context";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useHistory } from "react-router";
import { Redirect } from "react-router-dom";
import Spinner from "../../components/spinner";
import { AlertContext } from "../../context/alert-context";
import { theme } from "../../theme";



const useStyles = makeStyles((theme) => ({
  paper: {
    position: "fixed",
    width: 500,
    height: "90vh",
    overflowY: "scroll",
    border: "none",
    outline: "none",
    padding: theme.spacing(2, 4, 3),
    background: "none",
    [theme.breakpoints.down("sm")]: {
      // background:'green',
      position: "absolute",
      width: 270,
      left: "-70px",
      top: "10%",
    },
    [theme.breakpoints.up("md")]: {
      // backgroundColor: 'blue',
    },
    modal: {
      background: "rgba(32, 28, 27, 0.8)",
    },
    // marginTop:'-5%'
  },
  modal: {
    background: "rgba(237, 219, 219, 0.9)",
  },
  button: {
    marginLeft: "90%",
    marginTop: "10%",
    background: "none",
    "&:hover": {
      background: "none",
    },
    "&:focus": {
      outline: "none",
      background: "none",
    },
  },
}));

export default function LoginModal({
  handleClose,
  handleForgotPassword,
  handleSignUp,
}) {
  const classes = useStyles();
  const history = useHistory();
  const {
    user,
    login,
    loggedIn,
    loginIn,
    clearMessage,
    userDispatch,
  } = useContext(UserContext);
  const { dispatch } = useContext(AlertContext);

  Yup.addMethod(Yup.string, "password", function () {
    return this.test({
      name: "password",
      exclusive: true,
      message:
        "Please choose a strong password. Password must meet the following criteria; 1. At least one block letter. 2. At least one small letter. 3. At least one number. 4. At least one special character",
      test: (value) => {
        try {
          //Regular Expressions.
          var regex = [];
          regex.push("[A-Z]"); //Uppercase Alphabet.
          regex.push("[a-z]"); //Lowercase Alphabet.
          regex.push("[0-9]"); //Digit.
          regex.push("[$@$!%*#?&]"); //Special Character.

          var passed = 0;

          //Validate for each Regular Expression.
          for (var i = 0; i < regex.length; i++) {
            if (new RegExp(regex[i]).test(value)) {
              passed++;
            }
          }

          //Validate for length of Password.
          if (passed > 2 && value.length > 8) {
            passed++;
          }
          return passed >= 2;
        } catch (e) {
          return false;
        }
      },
    });
  });

  const validationSchema = Yup.object().shape({
    username: Yup.string("Enter Your Email")
      .email("Enter a Valid Email")
      .typeError("Enter Your Email")
      .required("Email is Required"),
    password: Yup.string("Enter Your Password")
      .typeError("Enter Your Password")
      .required("Password is Required"),
  });

  const clearForm = () => {
    this.setState({
      username: "",
      password: "",
    });
  };

  const change = (name, e) => {
    e.persist();
    handleChange(e);
    setFieldTouched(name, true, false);
  };

  useEffect(() => {
    if (user && user.access_token && loginIn == false) {
      handleClose();
    }
  }, [user]);

  const { handleSubmit, handleChange, setFieldTouched, dirty, touched, isValid, isSubmitting, resetForm, setSubmitting, values, errors } = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema,
    onSubmit(values, { setSubmitting }) {
      login(values.username, values.password, history, dispatch);
      setSubmitting(false)
      resetForm({
        username: "",
        password: "",
      });
    },
  });

  return (
    <ThemeProvider theme={theme}>
    <form onSubmit={handleSubmit}>
      <div
        style={{ marginLeft: "35%", marginTop: "8%" }}
        className={classes.paper}
      >
        {loginIn && <Spinner message="Loading...please wait" />}
        <Button style={{}} className={classes.button} onClick={handleClose}>
          <div className={styles.right}></div>
          <div className={styles.left}></div>
        </Button>
        <h2 className="loginTitle" style={{}}>
          Welcome Back!
        </h2>
        <Row>
          <Col sm={12} md={12} lg={12}>
            <OnboardingInput
              placeholder="Email Address"
              style={
                {
                  // marginBottom: "20px"
                }
              }
              icon={<MdAccountCircle />}
              name="username"
              value={values.username}
              onChange={change.bind(null, "username")}
            />
            {errors.username && touched.username ? (
              <div className="pt-error">{errors.username}</div>
            ) : null}
            {/* <div>{errors.username ? errors.username : null}</div> */}
          </Col>

          <Col sm={12} md={12} lg={12}>
            {" "}
            <PasswordInput
              placeholder="Password"
              style={{ marginTop: "20px" }}
              type="password"
              icon={<FaUserLock />}
              name="password"
              value={values.password}
              onChange={change.bind(null, "password")}
            />
            {errors.password && touched.password ? (
              <div className="pt-error">{errors.password}</div>
            ) : null}
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={12} lg={12}>
            <CTA
              disabled={!(isValid && dirty) || isSubmitting}
              large
              text="Login"
              style={{ marginTop: "20px" }}
              type="submit"
            />
          </Col>
          <Col sm={12} md={12} lg={12}>
            <Row>
              <Col sm={12} md={12} lg={12}>
                <div className="login-more">
                  <button style={{}} onClick={handleForgotPassword}>
                    <a>Forgot Password?</a>
                  </button>
                </div>
              </Col>
              <Col sm={12} md={12} lg={12}>
                <div className="other">
                  <h6>
                    New to <b>PayXpress?</b>
                  </h6>
                  <button style={{}} onClick={handleSignUp}>
                    <a>Sign up here</a>
                  </button>
                  {/* <a href="#">Sign Up here</a> */}
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </form>
    </ThemeProvider>
  );
}
