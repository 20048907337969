import React from 'react';
import TransactionHistory from '../../components/transaction-history/transaction-history';
import BulkTransactionHistory from '../../components/transaction-history/bulk-transaction-history';
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
// import classes from '*.module.css';



const useStyles = makeStyles({
    root: {
      width: "100%",
      
    },
    transactionContainer:{
      marginTop:'25px',
        maxHeight:'750px',
        overflowY:'scroll',
        backgroundColor:'#fff',
        "&::-webkit-scrollbar": {
            background: '#fff',
             width: '12px',
          },
          '&::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
          },
          "&::-webkit-scrollbar-thumb":{
            backgroundColor: 'green',
            borderRadius: '5px',
            outline: '1px solid slategrey'
          }
        
    }

  });

const AllTransactionHistory = () => {
    const classes = useStyles();
    return(
             <div  className={classes.transactionContainer}>
             <TransactionHistory />
             <br/>
        {/* <BulkTransactionHistory style={{marginTop:'10px'}}/> */}
        </div>
       
       
    )
}

export default AllTransactionHistory;