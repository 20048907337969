import React, { useState } from "react";
import {
  Grid,
  Paper,
  Button,
  Container,
  CssBaseline,
  ThemeProvider,
  Typography,
  Link,
  Box,
  MenuItem,
  Select,
  InputLabel,
  Divider,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { theme } from "../../theme";
// import MtnAirtime from "../../assets/images/billers/79bd69cc7328f8be6dd7afea13ce7816.jpg";
import { useHistory } from "react-router-dom";
import CTA from "../../components/button/cta-button";
import { FaArrowLeft } from "react-icons/fa";
import { productConstants } from "../../_constants";
import { splitResponse } from "../../helpers";
import { useEffect } from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    borderBottom: "1px solid red",
    borderRadius: 3,
    color: "white",
    height: 48,
    padding: "0 30px",
    flexGrow: 1,
  },

  searchField: {
    width: "400px",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  paperForm: {
    margin: "20px auto 0px auto",
    padding: "3px  40px 10px 40px",
    boxShadow: "0 7px 30px -10px rgba(150,170,180,0.5)",
    // width: '450px',
    width: "700px",
    [theme.breakpoints.down("sm")]: {
      padding: "20px",
      width: "310px",
      marginLeft: "-8px",
    },
  },
  image: {
    width: 120,
    height: 120,
    marginTop: 5,
    boxShadow: "0 7px 30px -10px rgba(150,170,180,0.5)",
    [theme.breakpoints.down("sm")]: {
      width: 80,
      height: 80,
    },
  },
  img: {
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
  },

  button: {
    display: "block",
    marginTop: theme.spacing(2),
  },

  previewTitle: {
    fontWeight: "600",
    marginBottom: "10px",
    marginTop: "20px",
    fontFamily: "Montserrat",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
      fontWeight: "bold",
      marginTop: "0px",
    },
  },
  summaryFontBold: {
    fontWeight: "600",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      fontWeight: "bold",
    },
  },
  formButtons: {
    marginTop: "20px",
    paddingLeft: "20px",
    marginLeft: "150px",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      marginLeft: "0px",
    },
    [theme.breakpoints.down("xs")]: {
      marginLeft: "-18px",
    },
  },
  titleProduct: {
    fontFamily: "Montserrat",
    fontWeight: "bold",
    fontSize: "25px",
    marginTop: "50px",
    marginLeft: "-30px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
      fontWeight: "600px",
      marginLeft: "-20px",
      marginTop: "30px",
    },
  },
  typegraphyStyles: {
    textAlign: "right",
    fontSize: "14px",
  },
}));

const categories = [
  {
    name: "atitime",
    path: "c",
    categoryName: "Airtime Purchase",
  },
  {
    name: "data",
    path: "/data",
    categoryName: "Buy Data",
  },
  {
    name: "data",
    path: "/pay-bills",
    categoryName: "Pay Bills",
  },
  {
    name: "data",
    path: "/ticket",
    categoryName: "ticket",
  },
];

const FormReview = ({
  user,
  fields,
  handleBack,
  values,
  formValues,
  getFee,
  handleSubmit,
}) => {
  const pathUrl = window.location.pathname;
  const history = useHistory();
  const classes = useStyles();
  const [state, setState] = useState({
    items: [],
    reviewValues: [],
  });
  let reviewValues = [];

  const setReviewValue = (name, value) => {
    reviewValues = {
      ...reviewValues,
      [name]: value,
    };
  };

  useEffect(() => {
    let customerEmail = "";
    let items = [];
    let convFee = 0,
      totalAmount = 0;
    var toDisplay = fields.filter(
      (f) => !!f.ShowOnPaymentConfirmation || !!f.AmountField
    );
    try {
      if (user) {
        customerEmail = user.ConsumerID;
      } else {
        var emailField = fields.find(
          (f) => f.FieldType === productConstants.FIELD_TYPE_EMAIL
        );
        customerEmail = values[emailField.Id];
      }
    } catch (e) {}
    if (toDisplay && toDisplay.length > 0) {
      toDisplay.forEach((l) => {
        var _value = values[l.Id];
        switch (l.ControlType) {
          case productConstants.CONTROL_TYPE_SELECT:
            var _option = l.ListItems.find((i) => i.ItemValue === _value);
            if (_option) _value = _option.ItemDesc;
            break;
          case productConstants.CONTROL_TYPE_TEXTBOX:
            var iValue = 0;
            if (!!l.AmountField) {
              iValue = parseFloat(_value);
              _value = iValue.toLocaleString(navigator.language, {
                minimumFractionDigits: 2,
              });
              convFee = getFee(iValue);
              totalAmount = iValue + convFee;
              setReviewValue("Amount", iValue.toString());
              setReviewValue("Fee", convFee.toString());
              setReviewValue("TotalAmount", totalAmount.toString());
            } else if (l.FieldType === productConstants.FIELD_TYPE_DECIMAL) {
              iValue = parseFloat(_value);
              _value = iValue.toLocaleString(navigator.language, {
                minimumFractionDigits: 2,
              });
            } else if (l.FieldType === productConstants.FIELD_TYPE_NUMBER) {
              iValue = parseFloat(_value);
              _value = iValue.toLocaleString(navigator.language, {
                minimumFractionDigits: 0,
              });
            } else if (
              l.FieldType === productConstants.FIELD_TYPE_EMAIL &&
              (!customerEmail || customerEmail === "")
            ) {
              customerEmail = _value;
            }
            break;
          default:
            break;
        }

        var _item = {
          name: l.FieldName,
          value: _value,
        };
        items.push(_item);
      });
      //Convenience fee
      var _item = {
        name: "Convenience fee",
        value: convFee.toLocaleString(navigator.language, {
          minimumFractionDigits: 2,
        }),
      };
      items.push(_item);
      //Total Amount
      _item = {
        name: "Total Amount",
        value: totalAmount.toLocaleString(navigator.language, {
          minimumFractionDigits: 2,
        }),
      };
      items.push(_item);
      //Set Email value
      setReviewValue("Email", customerEmail);

      setState({
        ...state,
        items: items,
        reviewValues: reviewValues,
      });
    }
  }, []);

  const _handleSubmit = (evt) => {
    evt.preventDefault();
    handleSubmit(state.reviewValues);
  };

  const handleProceed = () => {};

  return (
    <>
      <Grid>
        <Paper
          className={classes.paperForm}
          alignItems="center"
          justifyContent="center"
        >
          <Typography align="center" className={classes.previewTitle}>
            Order Summary
          </Typography>
          <Grid container className={classes.previewMain} spacing={0}></Grid>
          <form onSubmit={(evt) => _handleSubmit(evt)} autoComplete="off">
            <Grid container>
              {state.items.map((row) => {
                return (
                  <>
                    <Grid container key={row.name}>
                      <Grid
                        className={classes.summaryFontBold}
                        item
                        xs={4}
                        sm={4}
                        md={4}
                      >
                        {row.name}
                      </Grid>
                      <Grid
                        item
                        xs={8}
                        sm={8}
                        md={8}
                        className={classes.typegraphyStyles}
                      >
                        {splitResponse(row.value)}
                      </Grid>
                    </Grid>
                    <Divider
                      style={{
                        borderBottom: "1px solid rgba(255,255,255,0.8)",
                        width: "100%",
                      }}
                    />
                  </>
                );
              })}
            </Grid>
            <Grid className={classes.formButtons}>
              <CTA
                noBackground
                text="BACK"
                action={() => handleBack()}
                type="button"
                icon={<FaArrowLeft />}
              />
              <CTA medium text="PROCEED" type="submit" />
            </Grid>
          </form>
        </Paper>
      </Grid>
    </>
  );
};

export default FormReview;
