import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import { ProductContext } from "../../context/product-context";
import configuration from "../../config";
import { authHeader } from "../../_helpers";
import { handleResponse } from "../../helpers";
import {
  Grid,
  TextField,
  ButtonBase,
  Paper,
  Button,
  Container,
  CssBaseline,
  ThemeProvider,
  Typography,
  Link,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  Divider,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { theme } from "../../theme";
import { useHistory } from "react-router-dom";
import CTA from "../../components/button/cta-button";
import { MdAccountBalanceWallet } from "react-icons/md";
import { useEffect } from "react";
import { UserContext } from "../../context/user-context";
import {
  fetchWalletBalance,
  logTransaction,
} from "../../_actions/product.actions";
import { fetchFieldsForTopUp } from "../../_actions/product.actions";
import { productConstants } from "../../_constants";
import { useFormik } from "formik";
import Spinner from "../../components/spinner";
import * as Yup from "yup";
import InputAdornment from "@material-ui/core/InputAdornment";
import MoneyIcon from "@material-ui/icons/Money";
import NumberFormat from "react-number-format";
import { saveAs } from "@progress/kendo-drawing/pdf";
import WalletTopupSummary from "./wallet-topup-summary";
import GateWay from "../purchase/gate-way";

const useStyles = makeStyles((theme) => ({
  root: {
    borderBottom: "1px solid red",
    borderRadius: 3,
    color: "white",
    height: 48,
    padding: "0 30px",
    flexGrow: 1,
  },
  searchField: {
    width: "400px",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  paperForm: {
    marginTop: "20px",
    padding: "40px",
    height: "280px",
    width: "80%",
    fontFamily: "Montserrat",
    boxShadow: "0 7px 30px -10px rgba(150,170,180,0.5)",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "5px",
    },

    [theme.breakpoints.only("xs")]: {
      padding: "5px",
      marginLeft: "-10px",
      // height: "auto",
      height: "370px",
      width: "100%",
      marginRight: "0px",
    },
  },
  image: {
    width: 90,
    height: 90,
    marginTop: "5px",
    boxShadow: "0 7px 30px -10px rgba(150,170,180,0.5)",

    [theme.breakpoints.down("sm")]: {
      width: 90,
      height: 90,
    },
  },
  formTitle: {
    fontFamily: "Montserrat",
    fontWeight: "bold",
    fontSize: "25px",
    marginTop: "50px",
    marginLeft: "-30px",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "-30px",
      fontSize: "20px",
      fontWeight: "bold",
      marginTop: "40px",
    },
    [theme.breakpoints.down("xs")]: {
      marginLeft: "-20px",
      fontSize: "20px",
      fontWeight: "bold",
      marginTop: "40px",
    },
  },
  img: {
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
  },
  inputPadding: {
    paddingLeft: "40px",
    marginTop: "20px",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "0px",
    },
  },
  button: {
    display: "block",
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  formButtons: {
    marginTop: "20px",
    paddingLeft: "20px",
    marginLeft: "200px",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      marginLeft: "0px",
    },
  },
  productName: {
    fontSize: "25px",
    fontWeight: "bold",
    marginTop: "40px",
    marginLeft: "-80px",
    fontFamily: "Montserrat",
    textTransform: "uppercase",

    [theme.breakpoints.down("sm")]: {
      fontSize: "13px",
      marginLeft: "-15px",
    },
  },
  summaryFontBold: {
    fontWeight: "600",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      fontWeight: "bold",
    },
  },
  buttonDiv: {
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "50px",
    width: "250px",

    [theme.breakpoints.down("sm")]: {
      width: "200px",
      marginTop: "10px",
    },
  },
  accountInfo: {
    fontFamily: "Montserrat",
    fontSize: "13px",
    marginLeft: "50px",

    [theme.breakpoints.down("sm")]: {
      marginLeft: "5px",
    },
  },
  walletForm: {
    marginTop: "10px",

    [theme.breakpoints.down("sm")]: {
      marginLeft: "15px",
    },
  },
}));

const NumberFormatCustom = (props) => {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      thousandSeparator
      prefix="&#8358; "
    />
  );
};

const TopUpWallet = () => {
  const { loggedIn, user } = useContext(UserContext);
  const { products, getProductCategories } = useContext(ProductContext);
  const [state, setState] = useState({
    formStatus: productConstants.FORM_STATUS_INPUTTING,
    values: {},
    products: products,
    loading: true,
    field: {},
  });
  const [walltetBalance, setWalletBalance] = useState("0.00");
  const pathUrl = decodeURI(window.location.pathname);
  const billerCode = pathUrl.split("/")[1];
  const history = useHistory();
  const classes = useStyles();

  useEffect(() => {
    if (loggedIn) {
      fetchFieldsForTopUp().then((response) => {
        let _values = [];
        let fields = response.Fields;
        if (fields.length > 0) {
          fields.forEach((f) => {
            _values = {
              ..._values,
              [f.Id]: "",
            };
          });
          const { user, loggedIn } = state;
          if (!!loggedIn && user) {
            //Email
            var _email = fields.find(
              (f) => f.FieldType === productConstants.FIELD_TYPE_EMAIL
            );
            if (_email) {
              _values = {
                ..._values,
                [_email.Id]: user.ConsumerID,
              };
            }
            //Phone
            var _phone = fields.find(
              (f) => f.FieldType === productConstants.FIELD_TYPE_PHONE
            );
            if (_phone) {
              _values = {
                ..._values,
                [_phone.Id]: user.PhoneNumber,
              };
            }
            _values = {
              ..._values,
              amount: "",
            };
          }
          let field = {};
          field = fields.filter(
            (f) => f.FieldName === "Amount" && f.FieldType === "Number"
          )[0];
          getValidations(field);
          const { MaxAmount, MinAmount } = field;

          const { BillerCode, Charge, ProductName } = response.Product;
          setState({
            ...state,
            loading: false,
            fields: fields,
            field: field,
            values: _values,
            active: response.Product,
            max: MaxAmount,
            min: MinAmount,
            billerCode: BillerCode,
            PaymentMode: "PayXpress",
            productName: ProductName,
            products: products,
            Charge,
          });
        }
      });

      const req = {
        Parameter: user.WalletID,
        Pin: "",
      };
      fetchWalletBalance(req).then((response) => {
        if (response.ResponseCode === "00") {
          setWalletBalance(response.Balance);
        }
      });
    }
    // getWalletBalance();
  }, [products]);

  const getWalletBalance = () => {
    const req = {
      Parameter: user.WalletID,
      Pin: "",
    };
    fetchWalletBalance(req).then((response) => {
      if (response.ResponseCode === "00") {
        setState({
          ...state,
          walletBallance: response.Balance,
          products: products,
        });
      }
    });
  };

  const getFields = () => {
    fetchFieldsForTopUp().then((response) => {
      let _values = [];
      let fields = response.Fields;
      if (fields.length > 0) {
        fields.forEach((f) => {
          _values = {
            ..._values,
            [f.Id]: "",
          };
        });
        const { user, loggedIn } = state;
        if (!!loggedIn && user) {
          //Email
          var _email = fields.find(
            (f) => f.FieldType === productConstants.FIELD_TYPE_EMAIL
          );
          if (_email) {
            _values = {
              ..._values,
              [_email.Id]: user.ConsumerID,
            };
          }
          //Phone
          var _phone = fields.find(
            (f) => f.FieldType === productConstants.FIELD_TYPE_PHONE
          );
          if (_phone) {
            _values = {
              ..._values,
              [_phone.Id]: user.PhoneNumber,
            };
          }
          _values = {
            ..._values,
            amount: "",
          };
        }
        let field = {};
        field = fields.filter(
          (f) => f.FieldName === "Amount" && f.FieldType === "Number"
        )[0];
        getValidations(field);
        const { MaxAmount, MinAmount } = field;

        const { BillerCode, Charge, ProductName } = response.Product;
        setState({
          ...state,
          loading: false,
          fields: fields,
          field: field,
          values: _values,
          active: response.Product,
          max: MaxAmount,
          min: MinAmount,
          billerCode: BillerCode,
          PaymentMode: "PayXpress",
          productName: ProductName,
          products: products,
          Charge,
        });
      }
    });
  };

  const getValidations = async () => {
    // if (
    //   Object.keys(state.field).length === 0 &&
    //   state.field.constructor === Object
    // ) {
    return Yup.object().shape({
      amount: Yup.number("Enter valid amount")
        .typeError("Enter valid amount")
        .required("Amount is Required"),
      // .min(1000, "Amount should be greater than or equal to N" + 1000)
      // .max(
      //   1000000,
      //   "Only amount less than or equal to N" + 1000000 + " can be done"
      // ),
    });
    // }
  };

  const {
    handleSubmit,
    handleChange,
    setFieldValue,
    setFieldTouched,
    dirty,
    touched,
    isValid,
    isSubmitting,
    resetForm,
    setSubmitting,
    values,
    errors,
    amountRef,
  } = useFormik({
    initialValues: state.values,
    enableReinitialize: true,
    // validationSchema: getValidations,
    onSubmit(values) {
      // const { billerCode } = state;
      let _values = {};
      if (values.amount) {
        _values = {
          ..._values,
          customerAccountNumber: user.WalletID,
          billerCode: state.billerCode,
          customerAccountType: "xpslwallet",
          email: user.ConsumerID,
          phone: user.PhoneNumber,
          customerName: `${user.Firstname} ${user.Surname}`,
          amount: values.amount,
        };
        setState({
          ...state,
          verified: true,
          values: _values,
          formStatus: productConstants.FORM_STATUS_CONFIRMING,
        });
      }
    },
  });

  const handleProdceed = (reviewValueObject) => {
    const { tranId, PaymentMode, billerCode, fields, values } = state;

    let _controls = [];
    var _email = fields.find(
      (f) => f.FieldType === productConstants.FIELD_TYPE_EMAIL
    );
    if (_email) {
      let _control = {
        ControlId: _email.Id,
        ControlValue: user.ConsumerID,
      };
      _controls.push(_control);
    }
    //Phone
    var _phone = fields.find(
      (f) => f.FieldType === productConstants.FIELD_TYPE_PHONE
    );
    if (_phone) {
      let _control = {
        ControlId: _phone.Id,
        ControlValue: user.PhoneNumber,
      };
      _controls.push(_control);
    }
    //walletId
    var wId = fields.find((f) => f.FieldName === "Wallet ID");
    if (wId) {
      let _control = {
        ControlId: wId.Id,
        ControlValue: user.WalletID,
      };
      _controls.push(_control);
    }
    //amount
    let amount = fields.find((f) => f.FieldName === "Amount");
    if (amount) {
      let _control = {
        ControlId: amount.Id,
        ControlValue: values.amount,
      };
      _controls.push(_control);
    }
    let person = fields.find((f) => f.FieldName === "Account Name");
    if (person) {
      let _control = {
        ControlId: person.Id,
        ControlValue: `${user.Firstname} ${user.Surname}`,
      };
      _controls.push(_control);
    }
    let ws = fields.find((f) => f.FieldName === "Wallet Service");
    if (ws) {
      const getitemValue = ws.ListItems.find(
        (i) =>
          i.ItemDataValue === "xpslwallet" && i.ItemDesc === "Xpress Wallet"
      );
      let _control = {
        ControlId: ws.Id,
        ControlValue: getitemValue.ItemValue,
      };
      _controls.push(_control);
    }

    var _req = {
      WControls: _controls,
      ApprovalStatus: "",
      TranId: tranId,
      PaymentMode: PaymentMode,
      BillCode: billerCode,
      ...reviewValueObject.reviewValues,
      ProductName: state.productName,
    };
    logTransaction(_req).then(
      (response) => {
        setState({
          ...state,
          parameters: response,
          formStatus: productConstants.FORM_STATUS_CONFIRMED,
        });
      },
      (error) => {}
    );
  };

  const change = (name, e) => {
    //e.persist()
    e.target.name = name;
    setFieldValue(name, e.target.value);
    handleChange(name, e.target.value);
    setFieldTouched(name, true, false);
    let _values = state.values;
    _values = {
      ..._values,
      [name]: e.target.value,
    };
    setState({
      ...state,
      values: _values,
    });
  };

  const handleGoBack = () => {
    setState({
      ...state,
      formStatus: productConstants.FORM_STATUS_INPUTTING,
      loading: false,
    });
  };

  return (
    <>
      {state.formStatus == productConstants.FORM_STATUS_INPUTTING && (
        <div style={{ padding: 20 }}>
          <ThemeProvider theme={theme}>
            {state.loading ||
              (products.length === 0 && (
                <Spinner message="Loading...please wait" />
              ))}
            <Grid container spacing={1}>
              <Grid item xs={6} sm={3} md={2}>
                <Paper className={(classes.paper, classes.image)}>
                  <img
                    className={classes.img}
                    alt="wallet"
                    src="/resources/images/wallet.png"
                  />
                </Paper>
              </Grid>
              <Grid item xs={6} sm={9} md={10}>
                <Typography
                  variant="h4"
                  component="h2"
                  className={classes.productName}
                >
                  Top Up Wallet
                </Typography>
              </Grid>
            </Grid>
            <Grid>
              <Paper className={classes.paperForm}>
                <Grid container spacing={3}>
                  <Grid item sm={12} md={6} xs={12}>
                    <div className={classes.accountInfo}>
                      <p>
                        <strong>My Wallet Account Number</strong>
                      </p>
                      <p style={{ color: "#006d01" }}>
                        <strong>{user.WalletID}</strong>
                      </p>
                      <p>
                        <strong>My Wallet Balance</strong>
                      </p>
                      <p style={{ color: "#006d01" }}>
                        <MdAccountBalanceWallet
                          style={{ fontSize: "30px", margin: "5px" }}
                        />
                        <strong>{walltetBalance}</strong>
                      </p>
                    </div>
                  </Grid>
                  <form onSubmit={handleSubmit} className={classes.walletForm}>
                    <Grid item sm={12} md={12} xs={12}>
                      <h6 style={{ fontWeight: "600" }}>Top up my Wallet</h6>
                      <TextField
                        className={classes.textField}
                        required
                        label="Amount"
                        value={values.amount}
                        id="amount"
                        name="amount"
                        style={{ margin: 8 }}
                        helperText={touched.amount ? errors.amount : ""}
                        error={touched.amount && Boolean(errors.amount)}
                        onChange={(e) => change("amount", e)}
                        margin="normal"
                        autoComplete="off"
                        InputProps={{
                          inputComponent: NumberFormatCustom,
                          startAdornment: (
                            <InputAdornment position="start">
                              <MoneyIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <div className={classes.buttonDiv}>
                        <CTA noBackground text="clear" />
                        <CTA
                          medium
                          text="proceed"
                          // type="submit"
                          action={handleSubmit}
                        />
                      </div>
                    </Grid>
                  </form>
                </Grid>
              </Paper>
            </Grid>
          </ThemeProvider>
        </div>
      )}
      <>
        {state.formStatus == productConstants.FORM_STATUS_CONFIRMING && (
          <WalletTopupSummary
            {...state}
            handleStatus={handleProdceed}
            handleGoBack={handleGoBack}
          />
        )}
      </>
      <>
        {state.formStatus == productConstants.FORM_STATUS_CONFIRMED && (
          <>
            <GateWay classes parameters={state.parameters} />
          </>
        )}
      </>
    </>
  );
};

export default TopUpWallet;
