import React, { useState } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import { Button, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import Tooltip from "@material-ui/core/Tooltip";
import MenuIcon from "@material-ui/icons/ArrowDropDownSharp";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import AccountBalanceWalletTwoToneIcon from "@material-ui/icons/AccountBalanceWalletTwoTone";
import NumberFormat from "react-number-format";
import { useEffect } from "react";
import { useContext } from "react";
import { UserContext } from "../../context/user-context";
import { fetchWalletBalance } from "../../_actions/product.actions";

const useStyles = makeStyles((theme) => ({
  button: {
    background: "green",
    width: "120px",
    height: "40px",
    marginLeft: "-40px",
    marginTop: "-10px",
    transition: "all .35s ease-in-out",
    "&:hover": {
      background: "#ff6c00",
    },
    [theme.breakpoints.only("xs")]: {
      width: "80px",
      height: "25px",
      fontSize: "10px",
      marginLeft: "-10px",
    },
  },
  balance: {
    color: "#fff",
  },
  viewBalanceBtn: {
    marginTop: "-10px",
    width: "50px",
    height: "50px",
    borderRadius: "50%",
    fontSize: "50px",
    transition:
      "background-color 250ms cubic-bezier(0.4,0,0.2,1) 0ms, box-shadow 250ms cubic-beizer(0.4,0,0.2,1) 0ms, border 250ms cubic-bezier(0.4,0,0.2,1) 0ms",

    "&:focus": {
      outline: "none",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "10px",
    },
  },
  hideBalanceBtn: {
    marginTop: "-15px",
    color: "green",
    transition: "all 0.3s ease",

    "&:focus": {
      outline: "none",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "11px",
      // position: "absolute",
      top: "0px",
      right: "0px",
      left: "-5px",
    },
  },
}));

export default function XpressWalletBalance(props) {
  const classes = useStyles();
  const { loggedIn, user } = useContext(UserContext);
  const [showBalance, setShowBalance] = useState(false);
  const [state, setState] = useState({
    walletBallance: "0.00",
  });
  const showWalletBalance = () => {
    setShowBalance(true);
  };

  useEffect(() => {
    if (loggedIn) {
      const req = {
        Parameter: user.WalletID,
        Pin: "",
      };
      fetchWalletBalance(req).then((response) => {
        if (response.ResponseCode === "00") {
          setState({
            walletBallance: response.Balance,
          });
        }
      });
    }
  }, [user, fetchWalletBalance]);

  const hideWalletBalance = () => {
    setShowBalance(false);
  };

  return (
    <div>
      {/* {showBalance ? (
        <React.Fragment>
          <Button
            variant="contained"
            className={classes.button}
            component={Link}
            to="/topup-wallet"
            color="secondary"
          >
            <AccountBalanceWalletTwoToneIcon />
            <Typography
              align="left"
              component="h8"
              variant="subtitle1"
              gutterBottom
              className={classes.balance}
            >
              <NumberFormat
                value={state.walletBallance}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"₦"}
              />
            </Typography>
          </Button>
          <Tooltip title="Hide Balance" className={classes.hideBalanceBtn}>
            <IconButton
              aria-label="Hide Balance"
              color="primary"
              onClick={hideWalletBalance}
            >
              <AccountBalanceWalletTwoToneIcon
              // className={classes.hideBalanceBtn}
              />
              <ArrowDropUpIcon style={{ marginLeft: "-5px" }} />
            </IconButton>
          </Tooltip>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Tooltip title="View Wallet Balance">
            <Button
              variant="fab"
              color="secondary"
              aria-label="Add"
              className={classes.viewBalanceBtn}
              onClick={showWalletBalance}
            >
              <AccountBalanceWalletIcon
                style={{ color: "green", fontSize: "30px" }}
              />
              <ArrowDropDownIcon style={{ marginLeft: "-5px" }} />
            </Button>
          </Tooltip>
        </React.Fragment>
      )} */}
    </div>
  );
}
