import React from 'react';
import { PDFExport } from '@progress/kendo-react-pdf';
class PrintPDF extends React.Component {  
    constructor(props) {
        super(props);
        this.state = {
            download: true,
        };
    }
    pdfExportComponent;
    componentDidMount(){
        this.pdfExportComponent.save();
        this.setState({download:false});
    }

    componentWillUpdate(prevProps, nextProps){
        if(nextProps.download){
            if (this.pdfExportComponent){
                this.pdfExportComponent.save();
                this.setState({download:false});
            } 
            else{
                this.setState({download:true});
            }   
        }
    }

    componentWillReceiveProps(){
        if (!this.state.download) this.setState({download:true});
    }
    
    render(){
        const { children, filename } = this.props;
        return (
            <React.Fragment>
                { this.state.download && 
                    <PDFExport 
                        ref={(component) => this.pdfExportComponent = component} 
                        paperSize="A4"
                        fileName={filename}
                    >
                        {children}
                    </PDFExport>
                }
            </React.Fragment>
        );
    }
}
export default PrintPDF;