import React from "react";
import { theme } from "../theme";
import Spinner from "../components/spinner";
import { withStyles } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/core";

import XPay from "npm_xpresspay";

const styles = (theme) => ({
  root: {
    borderBottom: "1px solid red",
    borderRadius: 3,
    color: "white",
    height: 48,
    padding: "0 30px",
    flexGrow: 1,
  },

  searchField: {
    width: "400px",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  paperForm: {
    margin: "20px auto 0px auto",
    padding: "3px  40px 10px 40px",
    height: "500px",
    boxShadow: "0 7px 30px -10px rgba(150,170,180,0.5)",

    // width: '450px',
    width: "700px",
    [theme.breakpoints.down("sm")]: {
      padding: "20px",
      width: "310px",
      marginLeft: "-8px",
    },
  },
});

class GateWay extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  componentDidMount() {
    const { parameters } = this.props;
    const _this = this;
    try {
      XPay.InitialisePayment({
        amount: parameters.TotalAmount,
        transactionId: parameters.TransactionId,
        email: parameters.Email,
        publicKey: parameters.PublicKey,
        currency: "NGN",
        mode: "Live",
        callbackUrl: `${parameters.CallBackUrl}?transaction-id=${parameters.TransactionId}`,
        productId: parameters.ProductId,
        productDescription: parameters.ProductName,
        metadata: [
          {
            name: "Product Category Code",
            value: parameters.categoryCode,
          },
        ],
      }).then((response) => {
        if (response.success) {
          window.location.href = response.data?.authorizeUrl;
        } else {
          window.location.href = response.data?.authorizeUrl;
          _this.setState({
            loading: false,
          });
        }
      });
    } catch (error) {
      //handle error
      _this.setState({
        loading: false,
      });
      console.log("first", error);
    }
  }
  render() {
    const { loading } = this.state;

    return (
      <>
        <div>
          <ThemeProvider theme={theme}></ThemeProvider>
        </div>
        {loading && <Spinner message="Redireecting to payment gateway..." />}
      </>
    );
  }
}
export default withStyles(styles, {
  withTheme: true,
})(GateWay);
