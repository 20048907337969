// const config = {
//   apiUrl:
//     // "http://80.88.8.245:9007",
//     "https://cloned.payxpress.com/PayXpressAPI",
//   // "https://test.payxpress.com:9007",
//   //"http://localhost:64572",
//   apiUrl2: "https://cloned.payxpress.com/VASAPI2",
//   // "http://80.88.8.245:9006",
//   //"https://test.payxpress.com:9006",
//   // "http://80.88.8.245:9006",
//   // "http://localhost:54260",
//   apiUrl2Credentials: "web:password123",
//   siteTitle: "Xpress Payment Solutions Limited",
//   paymentMethod: "PAYXPRESS",
//   ghanaUrl: "https://test.payxpress.com:9001",
//   "https://test.payxpress.com:9000/XpressEvents/index.aspx": "events",
//   "https://test.payxpress.com:9000/XpressMovies/index.aspx": "movies",
//   "https://test.payxpress.com:9001/BulkUpload.aspx": "bulk-upload",
// };
// export default config;

const config = {
  apiUrl: "https://www.payxpress.com/PayXpressAPI",
  apiUrl2: "https://www.payxpress.com/VASAPI2",
  apiUrl2Credentials: "web:xLaa0fhhCsiSdpvOjXPWxw==",
  siteTitle: "Xpress Payment Solutions Limited",
  paymentMethod: "PAYXPRESS",
  oldUrl: "https://old.payxpress.com:9000",
  "https://payxpress.com/Events/index.aspx": "events",
  "https://payxpress.com/Movies/index.aspx": "movies",
  "https://old.payxpress.com:9000/BulkUpload1.aspx": "bulk-upload",
};
export default config;
