import React, { useContext } from "react";
import { Grid, TextField, ThemeProvider, Input } from "@material-ui/core";
import CTA from "../../components/button/cta-button";
import { theme } from "../../theme";
import * as Yup from "yup";
import { useFormik } from "formik";
import { TellAFriendContext } from "../../context/tell-a-friend-context";
import Spinner from "../../components/spinner";
import { AlertContext } from "../../context/alert-context";
import { UserContext } from "../../context/user-context";
import { green, amber } from "@material-ui/core/colors";
// import { green } from "@material-ui/core/colors";

export default function TAF() {
  const disabled = "grey";

  const { loading, tellAFriend } = useContext(TellAFriendContext);
  const { dispatch } = useContext(AlertContext);
  const { user } = useContext(UserContext);
  const validationSchema = Yup.object().shape({
    telle: Yup.string("Enter Friend's Email")
      .email("Enter a Valid Email")
      .typeError("Enter Friend's Email")
      .required("Friend's Email is Required"),
    teller: Yup.string("Enter Your Name")
      .typeError("Enter Your Name")
      .min(2, "Your Name must be at least 2 Characters long")
      .required("Your Name is Required"),
    telleeName: Yup.string("Enter Your Friend's Name")
      .typeError("Enter Your Friend's Name")
      .min(2, "Your Friend's Name must be at least 2 Characters long")
      .required("Friend's Name is Required"),
  });

  const handleReset = () => {
    resetForm({
      teller: "",
      telle: "",
      telleeName: "",
    });
  };

  const {
    handleSubmit,
    handleChange,
    values,
    setFieldValue,
    isValid,
    setFieldTouched,
    touched,
    dirty,
    errors,
    setSubmitting,
    resetForm,
  } = useFormik({
    initialValues: {
      teller:
        !!user && Object.entries(user).length !== 0
          ? `${user.Firstname} ${user.Surname}`
          : "",
      telle: "",
      telleeName: "",
    },
    validationSchema,
    onSubmit(values) {
      setSubmitting(true);
      tellAFriend(values, dispatch);
      setSubmitting(false);
      resetForm({
        teller: "",
        telle: "",
        telleeName: "",
      });
    },
  });

  const change = (name, e) => {
    //e.persist()
    e.target.name = name;
    setFieldValue(name, e.target.value);
    handleChange(name, e.target.value);
    setFieldTouched(name, true, false);
  };

  return (
    <ThemeProvider theme={theme}>
      <div className="Tell-a-Friend-Container">
        {loading && <Spinner message="Loading...please wait" />}

        <Grid container>
          <Grid item lg={7} md={7} sm={12} xs={12}>
            <div className="tell-a-friend-form-section">
              <div className="form-description">
                <h2>TELL A FRIEND</h2>
                <p>
                  Have you enjoyed using the payxpress platform? You can do well
                  to refer a friend to also enjoy, the ease, speed and security
                  you experienced using our platform; please fill the form below
                  to do so..
                </p>
              </div>
              <div className="taf-form">
                <form onSubmit={handleSubmit}>
                  <Grid item padding={20} xs={12} sm={12} md={9} lg={9}>
                    {user &&
                    typeof user.Firstname !== "undefined" &&
                    user.Firstname !== "" ? (
                      <Input
                        type="hidden"
                        name="teller"
                        id="teller"
                        value={values.teller}
                      />
                    ) : (
                      <TextField
                        style={{ margin: 8 }}
                        fullWidth
                        margin="normal"
                        required
                        id="teller"
                        name="teller"
                        placeholder="Your Name"
                        value={values.teller}
                        helperText={touched.teller ? errors.teller : ""}
                        error={touched.teller && Boolean(errors.teller)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={(e) => change("teller", e)}
                      />
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6} md={9} lg={9}>
                    <TextField
                      style={{ margin: 8 }}
                      required
                      id="telleeName"
                      name="telleeName"
                      placeholder="Enter friend's name"
                      helperText={touched.telleeName ? errors.telleeName : ""}
                      error={touched.telleeName && Boolean(errors.telleeName)}
                      value={values.telleeName}
                      fullWidth
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) => change("telleeName", e)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={9} lg={9}>
                    <TextField
                      style={{ margin: 8 }}
                      placeholder="Friend's Email"
                      fullWidth
                      required
                      id="telle"
                      name="telle"
                      placeholder="Enter friend's email address"
                      value={values.telle}
                      helperText={touched.telle ? errors.telle : ""}
                      error={touched.telle && Boolean(errors.telle)}
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) => change("telle", e)}
                    />
                  </Grid>
                  <div className="taf-actions">
                    <CTA
                      noBackground
                      text="reset"
                      type="reset"
                      action={handleReset}
                    />
                    <CTA
                      disabled={!(isValid && dirty)}
                      medium
                      text="send"
                      type="submit"
                      style={{
                        width: "120px",
                        height: "35px",
                        fontSize: "13px",
                      }}
                    />
                  </div>
                </form>
              </div>
            </div>
          </Grid>

          <Grid item lg={5} md={5} sm={12} xs={12}>
            <div className="taf-illustration">
              <img
                src="/resources/images/tell-friend2.svg"
                alt="illustration"
              />
            </div>
          </Grid>
        </Grid>
      </div>
    </ThemeProvider>
  );
}
