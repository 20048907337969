import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
// import Input from "../inputs/input";
import CTA from "../../components/button/cta-button";
import PasswordInput from "../../components/inputs/password-input";
import styles from "./login.module.css";
import { Row, Col, ThemeProvider } from "react-bootstrap";
import { Button } from "@material-ui/core";
import OnboardingInput from "../../components/inputs/onboarding-input";
import { FaUserLock } from "react-icons/fa";
import { MdAccountCircle, MdEmail } from "react-icons/md";
import ForgotPswd from "../../components/forgot-password/forgot-password";
import LoginModal from "../../components/modal/loginModal";
import ForgotPasswordModal from "../../components/modal/forgotPasswordModal";
import SignupModal from "../../components/modal/signupModal";
import { theme } from "../../theme";

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "fixed",
    width: 500,
    border: "none",
    outline: "none",
    padding: theme.spacing(2, 4, 3),
    background: "none",
    [theme.breakpoints.down("sm")]: {
      position: "absolute",
      width: 270,
      left: "-70px",
      top: "10%",
    },
    [theme.breakpoints.between("md")]: {
      position: "absolute",
      width: 270,
      left: "-100px",
      top: "10%",
    },
    [theme.breakpoints.up("lg")]: {
      // backgroundColor: 'orange',
    },
  },
  modal: {
    // background: "rgba(237, 219, 219, 0.9)",
    // background:'rgba(240, 218, 213, 0.9)'
    background: "rgba(32, 28, 27, 0.8)",
  },
  button: {
    marginLeft: "90%",
    marginBottom: "40px",
    background: "none",
    "&:hover": {
      background: "none",
    },
    "&:focus": {
      outline: "none",
      background: "none",
    },
  },
}));

export default function Login() {
  const classes = useStyles();
  // const [] = useContext();
  const [open, setOpen] = React.useState(false);
  const [forgotPassword, setForgotPassword] = React.useState(false);
  const [openSignUp, setOpenSignUp] = React.useState(false);
  const [login, setLogin] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
    setLogin(true);
  };

  const handleClose = () => {
    setOpen(false);
    setOpenSignUp(false);
    setLogin(false);
    setForgotPassword(false);
  };

  const handleForgotPassword = () => {
    setLogin(false);
    setForgotPassword(true);
  };

  const handleLogin = () => {
    setLogin(true);
  };

  const handleSignin = () => {
    setForgotPassword(false);
    setLogin(true);
  };

  const handleSignUp = () => {
    setOpenSignUp(true);
    setOpen(true);
    setLogin(false);
  };

  // handleChange=()=>{

  // }
  return (
    <div>
      <CTA nav text="Login" green style={{}} action={handleOpen} />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className={classes.modal}
        disableBackdropClick="true"
        disableScrollLock="true"
      >
        {login ? (
          <LoginModal
            handleClose={handleClose}
            handleForgotPassword={handleForgotPassword}
            handleSignUp={handleSignUp}
          />
        ) : forgotPassword ? (
          <ForgotPasswordModal
            handleClose={handleClose}
            handleSignin={handleSignin}
          />
        ) : (
          <SignupModal handleClose={handleClose} handleLogin={handleLogin} />
        )}
      </Modal>
    </div>
  );
}
