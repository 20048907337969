import { Grid } from "@material-ui/core";
import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import { AlertContext } from "../../context/alert-context";
import { BackupEmailUpdateContext } from "../../context/backupEmail-update-context";
import Spinner from "../../components/spinner";
import queryString from "query-string";
import { TextField, ThemeProvider } from "@material-ui/core";
import { theme } from "../../theme";

export default function BackUpEmailConfirmation() {
  const history = useHistory();
  const {
    updateRegWithBackupEmail,
    backupUpdateMessage,
    errorMessage,
    resetMessage,
    updating,
  } = useContext(BackupEmailUpdateContext);
  const { dispatch } = useContext(AlertContext);

  const params = queryString.parse(window.location.search);

  useEffect(() => {
    if (params.backupemail && params.token) {
      updateRegWithBackupEmail(params);
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <div className="confirm-account">
        <Grid container>
          <Grid item lg={7} md={7} sm={12}>
            <div className="activate-account-info ">
              <h1>Backup Email Update</h1>
              {updating && <Spinner message="Loading...please wait" />}
              {backupUpdateMessage ? (
                <>
                  <p
                    style={{
                      fontSize: "20px",
                      fontWeight: "700",
                      color: "green",
                    }}
                  >
                    {backupUpdateMessage}
                  </p>
                  {/* <i>
                  You can start making payments. Online transaction is easy with
                  payxpress.
                </i> */}
                  <Link to="/" style={{ display: "block" }}>
                    Click here to continue
                </Link>
                </>
              ) : (
                  <>
                    <p
                      style={{ fontSize: "20px", fontWeight: "700", color: "red" }}
                    >
                      {errorMessage}
                    </p>
                    <Link to="/" style={{ display: "block" }}>
                      Click here to continue
                </Link>
                  </>
                )}
            </div>
          </Grid>
          <Grid item lg={5} md={5} sm={12}>
            <div className="activation-illustration">
              <img
                src="/resources/images/access-activation.svg"
                alt="illustration"
              />
            </div>
          </Grid>
        </Grid>
      </div>
    </ThemeProvider>);
}
