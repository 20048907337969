import { tellAFriendConstant } from '../_constants';

export function tellAFriendreducer(state = {}, action) {
  switch (action.type) {
    case tellAFriendConstant.REQUEST:
      return {
        loading: true,
      };
    case tellAFriendConstant.RESPONSE:
      return {
        // type: 'error',
        loading: false,
        message: action.message.toString()
      };
    default:
      return state
  }
}