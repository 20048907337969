//import config from 'react-global-configuration';
import configuration from '../config';
import { handleResponse } from '../helpers';
import { authHeader } from '../_helpers';

export const fetchSubjects = () => {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    };
    return fetch(`${configuration.apiUrl}/Supports/Subjects`, requestOptions)
        .then(handleResponse);
}

export const saveSupport = (req) => {
    const _req = {
        requestData: {
            CustomerName: req.displayName,
            CustomerEmail: req.email,
            Comment: req.comment,
            Subject: req.subject
        }
    }
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(_req)
    };
    return fetch(`${configuration.apiUrl}/Supports/Save`, requestOptions)
        .then(handleResponse);
}
