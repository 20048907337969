import { Grid } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";

export default function PrivacyPolicy() {
  return (
    // <div className="privacy-policy">
    <Grid container>
      {/* <Grid item lg={12} md={12} sm={12}> */}
      <h1 className="title">Privacy Policy</h1>
      {/* </Grid> */}
      <div className="privacy-policy">
        <Grid item lg={12} md={12} sm={12}>
          <div className="info-collected">
            <h3>Information we collect</h3>
            <p>
              We may collect information about you from a variety of sources,
              including information we collect from you directly; information we
              collect about you when you visit our sites, perform business
              transactions with us and information we collect about you from
              other sources (as permitted by law).
            </p>
          </div>
        </Grid>
        <Grid item lg={12} md={12} sm={12}>
          <div className="info-collected">
            <h3>Information we collect directly from you</h3>
            <p>
              We collect information directly from you when you choose to
              participate in our offers and programs or otherwise provide
              information directly to us. The following are examples of
              information we may collect directly from you:
            </p>
            <ul>
              <li>Customer Care</li>
              <li>Card Details</li>
              <li>Account Details</li>
              <li>Phone Number</li>
              <li>E-mail</li>
            </ul>
          </div>
        </Grid>
        <Grid item lg={12} md={12} sm={12}>
          <div className="info-collected">
            <h3>Information We Collect From Other Sources</h3>
            <p>
              We may obtain information about you from other sources, including
              commercially available sources, such as data aggregators and
              public databases. The following are examples of information we may
              collect from other sources:
            </p>
            <ul>
              <li>Business Information</li>
              <li>Directors Information</li>
            </ul>
          </div>
        </Grid>
        <Grid item lg={12} md={12} sm={12}>
          <div className="info-collected">
            <h3>How we use these Information</h3>
            <p>
              We use the information we collect to manage agreements, process
              payments, to fulfil our obligations under applicable regulations,
              and to manage our sites and services. The following are ways we
              may use the information we collect about you:
            </p>
            <ul>
              <li>Business guide purpose</li>
              <li>Know your customer (KYC) purposes</li>
            </ul>
          </div>
        </Grid>
        <Grid item lg={12} md={12} sm={12}>
          <div className="info-collected">
            <h3>Your Choices And Access To Your Information</h3>
            <p>
              Choices: <br /> We give you choices about how we communicate with
              you.
            </p>
            <ul>
              <li>E-mail</li>
              <li>SMS</li>
              <li>Phone call</li>
              <li>Hard copy letter</li>
            </ul>
            <p>
              We will continue to use the above channels for business
              communication for the duration of our business relationship. We
              may need to retain the contact information and email
              communicationsfor record keeping, analysis or other reasons, to
              the extent permitted by law.
            </p>
          </div>
        </Grid>
        <Grid item lg={12} md={12} sm={12}>
          <div className="info-collected">
            <h3>Access</h3>
            <p>
              We take steps to keep your personal information accurate. You can
              view, correct, or update the personal contact information you
              provide to Xpress Payments.
              <br /> Contact our Data Privacy Officer to access and update the
              personal information youprovided to Xpress Payments. We will
              respond to valid requests in an appropriate timeframe.
            </p>
          </div>
        </Grid>
        <Grid item lg={12} md={12} sm={12}>
          <div className="info-collected">
            <h3>Information Sharing</h3>
            <p>
              We share your personal information only as described below and
              within Xpress Payments
            </p>
          </div>
        </Grid>
        <Grid item lg={12} md={12} sm={12}>
          <div className="info-collected">
            <h3>Third-Party Service Providers</h3>
            <p>
              We rely on third-party service providers to perform various
              services on our behalf. To perform these services, we may need to
              share your personal information with them. For example, we may
              rely on a service provider to:
            </p>
            <ul>
              <li>Deliver our email and mobile messages.</li>
              <li>Manage payments or take other actions on our behalf.</li>
              <li>
                Conduct research and analyze data, sometimes combined with other
                sources, to improve our products,services, and sites.
              </li>
            </ul>
            <p>
              We provide these companies with the minimum personal information
              they need to provide these services on our behalf. We require
              these companies to protect this personal information and tonot use
              the information for any other purpose.
            </p>
            <ul>
              <li>
                Sale of our brands or business - In connection with the sale of
                one or more of our brands or a part of our business to a
                different company, we reserve the right to transfer your
                personal information to a new owner that agrees to offer
                equivalent safeguards for the use and disclosure of your
                personal information.
              </li>
              <li>
                Legal action and obligations - We may disclose your personal
                information:
                <ul>
                  <li>
                    Where permitted by law, to protect and defend the rights and
                    property of Xpress Payments (including enforcement of valid
                    agreements).
                  </li>
                  <li>When required by law or public authorities.</li>
                </ul>
              </li>
            </ul>
          </div>
        </Grid>
        <Grid item lg={12} md={12} sm={12}>
          <div className="info-collected">
            <h3>Information Security</h3>
            <p>
              We are committed to keeping personal information secure. We have
              appropriate technical, administrative, and physical procedures in
              place to protect personal information from loss, misuse, or
              alteration.
              <br />
              We limit access to personal information to those who have a
              business need. We keep personal information only for a reasonably
              needed amount of time.
              <br />
              When we provide your personal information to our third-party
              service providers, we require those companies to protect the
              information and to not use the information for any other purpose.
              <br />
              When we collect or transmit sensitive information such as a credit
              card number or internet banking password, we use industry standard
              methods to protect that information.
            </p>
          </div>
        </Grid>
        <Grid item lg={12} md={12} sm={12}>
          <div className="info-collected">
            <h3>Information Transfer</h3>
            <p>
              Your personal information may be transferred to, stored, and
              processed in a country other than the one in which it was
              provided. When we do so, we take into consideration the applicable
              data protection laws for the transfer of personal information.
              <br />
              We take steps to protect personal information no matter what
              country it is stored in or transferred to.
            </p>
          </div>
        </Grid>
        <Grid item lg={12} md={12} sm={12}>
          <div className="info-collected">
            <h3>Changes To Our Privacy Notice</h3>
            <p>
              We will post changes to this privacy notice and update the
              effective date when this privacy notice is updated. We may modify
              this policy at any time, in our sole discretion and all
              modifications will be effective immediately upon our posting of
              the modifications.
            </p>
          </div>
        </Grid>
        <Grid item lg={12} md={12} sm={12}>
          <div className="info-collected">
            <h3>Data Minimalization</h3>
            <p>
              Xpress Payments limits Personal Information collection and usage
              to data that is relevant, adequate, and necessary for carrying out
              the purpose for which the data is processed.
              <br />
              Xpress Payments will evaluate whether and to what extent the
              processing of Personal Information is necessary and where the
              purpose allows, anonymized data will be used.
            </p>
          </div>
        </Grid>
        <Grid item lg={12} md={12} sm={12}>
          <div className="info-collected">
            <h3>Age Restriction</h3>
            <p>
              Our website and services are not directed to children under 18. We
              do not knowingly transact or provide any services to children
              under 18.
            </p>
          </div>
        </Grid>
        <Grid item lg={12} md={12} sm={12}>
          <div className="info-collected">
            <h3>Contact Us</h3>
            <p>
              If you have questions or concerns regarding Xpress Payments’
              privacy practices, please register them with us by sending an
              email to our Data Privacy Officer [dpo@Xpresspayments.com].
            </p>
          </div>
        </Grid>
        <Grid item lg={12} md={12} sm={12}>
          <div className="info-collected">
            <p>
              Thanks for taking your time to read through, please{" "}
              <Link to="/" style={{ color: "#ff6c00" }}>
                click this link to return to homepage
              </Link>
            </p>
          </div>
        </Grid>
      </div>
    </Grid>
    // </div>
  );
}
