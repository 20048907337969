import React, { useContext, useState, useEffect } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { withRouter, Link } from "react-router-dom";
import { TextField, Grid, ThemeProvider } from "@material-ui/core";
import { theme } from "../../theme";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
// import { FaPlay, FaFastForward, FaPrint, FaSearch } from "react-icons/fa";
// import { MdViewAgenda, MdPageview } from "react-icons/md";
import { FaPrint, FaPlay, FaFastForward } from "react-icons/fa"; //, FaRecycle
import { MdPageview } from "react-icons/md";
import CTA from "../button/cta-button";
import ViewTransactionModal from "../modal/view-transaction-modal";
import Print from "../print-transaction/print";
import PrintModal from "../modal/print-modal";
import { TranHistoryContext } from "../../context/transaction-history-context";
import Spinner from "../../components/spinner";
import moment from "moment";
import { useFormik, yupToFormErrors } from "formik";
import EnhancedTableHead from "./transaction-table-head";
import * as Yup from "yup";
import Typography from "@material-ui/core/Typography";
import EnhancedTable from "../../components/table/EnhancedTable";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import { getReceiptData } from "../../components/receipt";
import { print } from "../../helpers";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import classNames from "classnames";
import Button from "@material-ui/core/Button";
import { getReceipt } from "../../_actions/product.actions";
import { useHistory } from "react-router";

const StyledTableCell = withStyles((theme) => ({
  head: {
    background: "rgba(0, 109, 1, 0.2)",
    fontFamily: "Montserrat",
    fontWeight: "bolder",
  },
  body: {
    fontSize: 14,
    borderRadius: "5px",
  },
}))(TableCell);

const rowHeight = "30px";

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(even)": {
      backgroundColor: "rgba(196, 196, 196, 0.2)",
    },
  },
}))(TableRow);

const tranHistoryColumns = [
  {
    Id: "Date",
    Numeric: false,
    Format: { type: "date" },
    DisablePadding: true,
    Label: "Date Generated",
  },
  {
    Id: "PaymentReference",
    Numeric: false,
    DisablePadding: false,
    Label: "Payment Ref.",
  },
  {
    Id: "ProductName",
    Numeric: false,
    DisablePadding: false,
    Label: "Product",
  },
  {
    Id: "Amount",
    Numeric: true,
    Format: { type: "currency" },
    DisablePadding: false,
    Label: "Amount",
  },
  {
    Id: "ApprovalStatus",
    Numeric: false,
    DisablePadding: false,
    Label: "Not. Status",
  },
  {
    Id: "PaymentStatus",
    Numeric: false,
    DisablePadding: false,
    Label: "Pymt. Status",
  },
];

const useStyles = makeStyles({
  root: {
    width: "100%",
    // maxWidth:'1000px'
  },
  overallContainer: {
    marginLeft: "20px",
    marginTop: 20,
  },

  container: {
    maxHeight: "auto",
    "&::-webkit-scrollbar": {
      width: "10px",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      outline: "1px solid slategrey",
    },
  },
  play: {
    color: "FF6C00",
  },
  topForm: {
    borderRadius: "5px 5px 0px 0px",
  },
  inputPadding: {
    paddingLeft: "40px",
    marginTop: "40px",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "0px",
    },
  },
  dialogContent: {
    lineHeight: 0,
    color: "red !important",
  },
});

export default function TransactionHistory(style, props) {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [order, setOrder] = React.useState("asc");
  const [openDetails, setOpenDetails] = React.useState(false);
  const [orderBy, setOrderBy] = React.useState("");
  const [rowsPerPage, setRowsPerPage] = React.useState(3);
  const history = useHistory();

  const [transactionDetails, setTransactionDetails] = useState("");
  const {
    loading,
    transactions,
    getTransactionHistrory,
    getTransactionHistrorySearch,
  } = useContext(TranHistoryContext);

  const validationSchema = Yup.object().shape({
    endDate: Yup.date().when("startDate", {
      is: (val) => val !== undefined,
      then: Yup.date().required("Enter end date"),
    }),
  });

  useEffect(() => {
    getTransactionHistrory();
  }, []);

  const handleForward = (row) => {
    history.push(
      `/${row.UrlPath}/${row.BillerCode}/${row.Id}?status=forwarded`
    );
  }

  const handlePlay = (row) => {
    history.push(`/${row.UrlPath}/${row.BillerCode}/${row.Id}`);
  }

  const handleActionCreation = (option, row) => {
    return (
      <React.Fragment>
        <Tooltip title="Print">
          <IconButton
            onClick={() => {
              getReceiptData(row.Id, (_receipt) => {
                print(_receipt);
              });
            }}
            aria-label="Print"
            color="inherit"
          >
            <FaPrint fontSize="small" />
          </IconButton>
        </Tooltip>
        <Tooltip title="View Details">
          <IconButton
            onClick={(e) => {
              e.preventDefault();
              showDetails(row.Id);
            }}
            aria-label="View Details"
            color="inherit"
          >
            <MdPageview fontSize="medium" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Play">
          <IconButton
            // component={Link}
            aria-label="Play"
            // to={`/${row.UrlPath}/${row.BillerCode}/${row.Id}`}
            onClick={() => {
              history.push(`/${row.UrlPath}/${row.BillerCode}/${row.Id}`);
            }}
            color="primary"
          >
            <FaPlay fontSize="small" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Forward">
          <IconButton
            aria-label="Forward"
            // component={Link}
            //to={`/${row.UrlPath}/${row.BillerCode}/${row.Id}?status=forwarded`}
            onClick={() => {
              history.push(
                `/${row.UrlPath}/${row.BillerCode}/${row.Id}?status=forwarded`
              );
            }}
            // onClick={handleForward}
            color="secondary"
          >
            <FaFastForward fontSize="small" />
          </IconButton>
        </Tooltip>
      </React.Fragment>
    );
  };

  const showDetails = async (id) => {
    if (typeof id !== "undefined" && id !== "") {
      const transactionDetails = await getReceipt(id);

      setTransactionDetails(transactionDetails);
      setOpenDetails(true);
      // if (transactionDetails) { 
      //   this.setState({
      //     transactionDetails: transactionDetails,
      //     openDetails: true
      //   });
      // }
    }
  };
  const closeDetails = () => {
    setOpenDetails(false);
    setTransactionDetails(null);
    // this.setState({
    //   openDetails: false,
    //   //transactionId:''
    //   transactionDetails: null
    // });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const {
    handleSubmit,
    handleChange,
    setFieldTouched,
    dirty,
    touched,
    isValid,
    isSubmitting,
    resetForm,
    setSubmitting,
    values,
    errors,
  } = useFormik({
    initialValues: {
      startDate: "",
      endDate: "",
      paymentRef: "",
    },
    validationSchema,
    onSubmit(values, { setSubmitting }) {
      getTransactionHistrorySearch(values);
      setSubmitting(false);
      resetForm({
        startDate: "",
        endDate: "",
        paymentRef: "",
      });
    },
  });

  const change = (name, e) => {
    e.persist();
    handleChange(e);
    setFieldTouched(name, true, false);
  };

  return (
    <ThemeProvider theme={theme}>
      <form onSubmit={handleSubmit}>
        <div className={classes.overallContainer}>
          <h5 style={{ fontFamily: "Montserrat", fontWeight: "bolder" }}>
            Transaction History
          </h5>
          <React.Fragment>
            <Paper className={classes.topForm}>
              <div style={{ padding: 20 }}>
                <ThemeProvider theme={theme}>
                  <Grid container spacing={1}>
                    <Grid
                      className={classes.inputPadding}
                      padding={20}
                      xs={12}
                      sm={6}
                      md={3}
                    >
                      <TextField
                        id="standard-full-width"
                        label="Start Date"
                        style={{ margin: 8 }}
                        placeholder="22/09/2020"
                        fullWidth
                        type="date"
                        margin="normal"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        name="startDate"
                        value={values.startDate}
                        onChange={change.bind(null, "startDate")}
                      >
                        {errors.startDate && touched.startDate ? (
                          <div className="pt-error">{errors.startDate}</div>
                        ) : null}
                      </TextField>
                    </Grid>
                    <Grid
                      className={classes.inputPadding}
                      xs={12}
                      sm={6}
                      md={3}
                    >
                      <TextField
                        id="standard-full-width"
                        label="End Date"
                        style={{ margin: 8 }}
                        placeholder="Amount"
                        type="date"
                        fullWidth
                        margin="normal"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        name="endDate"
                        value={values.endDate}
                        onChange={change.bind(null, "endDate")}
                      >
                        {errors.endDate && touched.endDate ? (
                          <div className="pt-error">{errors.endDate}</div>
                        ) : null}
                      </TextField>
                    </Grid>
                    <Grid
                      className={classes.inputPadding}
                      xs={12}
                      sm={6}
                      md={3}
                    >
                      <TextField
                        id="standard-full-width"
                        label="Payment Reference"
                        style={{ margin: 8 }}
                        placeholder="233445565434"
                        fullWidth
                        margin="normal"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        name="paymentRef"
                        value={values.paymentRef}
                        onChange={change.bind(null, "paymentRef")}
                      />
                    </Grid>
                    <Grid xs={12} sm={6} md={3}>
                      <CTA
                        small
                        green
                        text="Search"
                        style={{
                          borderRadius: "5px",
                          marginTop: "70px",
                          marginLeft: "40px",
                        }}
                        type="submit"
                        disabled={!(isValid && dirty) || isSubmitting}
                      />
                    </Grid>
                  </Grid>
                </ThemeProvider>
              </div>
              {/* </Paper> */}

              {/* <Paper className={classes.root}> */}
              {loading && <Spinner message="Loading...please wait" />}
              <EnhancedTable
                className={classes.container}
                columns={tranHistoryColumns}
                data={transactions}
                order="desc"
                orderBy="Date"
                showAction={true}
                onCreateAction={handleActionCreation}
                paging={true}
              />
            </Paper>
            <br />
            <Dialog
              open={openDetails}
              onClose={closeDetails}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              maxWidth="md"
            >
              <DialogTitle id="alert-dialog-title">
                Transaction Details
              </DialogTitle>
              <DialogContent>
                {transactionDetails && (
                  <Table style={{ align: "left" }}>
                    <TableBody>
                      <TableRow style={{ height: rowHeight }}>
                        <TableCell scope="row" style={{ fontWeight: 700 }}>
                          Order Status
                        </TableCell>
                        <TableCell numeric>
                          {transactionDetails.OrderStatus}
                        </TableCell>
                      </TableRow>
                      {transactionDetails.ReceiptItems.map((row) => {
                        return (
                          <TableRow
                            key={row.Label}
                            style={{ height: rowHeight }}
                          >
                            <TableCell scope="row" style={{ fontWeight: 700 }}>
                              {row.Label}
                            </TableCell>
                            <TableCell numeric>{row.Value}</TableCell>
                          </TableRow>
                        );
                      })}
                      <TableRow style={{ height: rowHeight }}>
                        <TableCell scope="row" style={{ fontWeight: 700 }}>
                          Order Details
                        </TableCell>
                        <TableCell numeric>
                          {transactionDetails.OrderDetails}
                        </TableCell>
                      </TableRow>
                      <TableRow style={{ height: rowHeight }}>
                        <TableCell scope="row" style={{ fontWeight: 700 }}>
                          Order Number
                        </TableCell>
                        <TableCell numeric>
                          {transactionDetails.OrderNumber}
                        </TableCell>
                      </TableRow>
                      <TableRow style={{ height: rowHeight }}>
                        <TableCell scope="row" style={{ fontWeight: 700 }}>
                          Order Amount
                        </TableCell>
                        <TableCell numeric>
                          {transactionDetails.OrderAmount}
                        </TableCell>
                      </TableRow>
                      <TableRow style={{ height: rowHeight }}>
                        <TableCell scope="row" style={{ fontWeight: 700 }}>
                          Convenience Fee
                        </TableCell>
                        <TableCell numeric>{transactionDetails.Fee}</TableCell>
                      </TableRow>
                      <TableRow style={{ height: rowHeight }}>
                        <TableCell scope="row" style={{ fontWeight: 700 }}>
                          Order Date
                        </TableCell>
                        <TableCell numeric>
                          {transactionDetails.OrderDate}
                        </TableCell>
                      </TableRow>
                      <TableRow style={{ height: rowHeight }}>
                        <TableCell scope="row" style={{ fontWeight: 700 }}>
                          E-mail
                        </TableCell>
                        <TableCell numeric>
                          {transactionDetails.EmailAddress}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                )}
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={closeDetails}
                  variant="contained"
                  color="primary"
                  // autoFocus
                  style={{ height: "25px", border: "none" }}
                >
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          </React.Fragment>
        </div>
      </form>
    </ThemeProvider>
  );
}
