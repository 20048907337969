import React, {
  createContext,
  useReducer,
  useContext
} from "react";
import {
  userActions
} from "../_actions/user.actions";
import {
  alertActions
} from "../_actions/alert.actions";
import {
  registration
} from "../_reducers/registration.reducer";
import {
  userConstants,
  alertConstants
} from "../_constants";
import {
  alert
} from "../_reducers/alert.reducer";
import {
  users
} from "../_reducers/users.reducer";


const initialState = {
  activationMessage: "",
  errorMessage: "",
  activating: false
};

export const ActivationContext = createContext(initialState);

export const ActivationContextProvider = ({
  children
}) => {

  const [state, activationDispatch] = useReducer(registration, initialState);


  function activate(token) {
    activationDispatch({
      type: userConstants.ACTIVATE_REQUEST
    })
    userActions.activate(token).then(resp => {
      activationDispatch({
        type: userConstants.ACTIVATE_SUCCESS,
        payload: "Your account has been activated successfully",
      });
    }, error => {
      activationDispatch({
        type: userConstants.ACTIVATE_FAILURE,
        payload: error.toString(),
      });
    });
  }

  return (<ActivationContext.Provider value={
    {
      activate,
      activationMessage: state.activationMessage,
      errorMessage: state.errorMessage,
      activating: state.activating,
    }
  } >
    {
      children
    } </ActivationContext.Provider>
  );
};