import React, {
  createContext,
  useReducer,
  useContext
} from "react";
import {
  userActions
} from "../_actions/user.actions";
import {
  registration
} from "../_reducers/registration.reducer";
import {
  userConstants,
  alertConstants
} from "../_constants";


const initialState = {
  backupUpdateMessage: "",
  errorMessage: "",
  updating: false
};

export const BackupEmailUpdateContext = createContext(initialState);

export const BackupEmailUpdateContextProvider = ({
  children
}) => {

  const [state, profileUpdateDispatch] = useReducer(registration, initialState);


  function updateRegWithBackupEmail(user) {
    profileUpdateDispatch({
      type: userConstants.UPDATE_BACKUP_REQUEST
    })
    userActions.updateRegWithBackupEmail(user).then(resp => {
      profileUpdateDispatch({
        type: userConstants.UPDATE_BACKUP_SUCCESS,
        payload: "Backup email updated successfully!!!",
      });
    }, error => {
      profileUpdateDispatch({
        type: userConstants.UPDATE_BACKUP_FAILURE,
        payload: error.toString(),
      });

    });
  }

  return (<BackupEmailUpdateContext.Provider value={
    {
      updateRegWithBackupEmail,
      backupUpdateMessage: state.backupUpdateMessage,
      errorMessage: state.errorMessage,
      updating: state.updating,
    }
  } >
    {
      children
    } </BackupEmailUpdateContext.Provider>
  );
};