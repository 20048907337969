import React, { useContext, useState, useEffect } from "react";
import {
  Grid,
  Paper,
  ThemeProvider,
  Typography,
  Divider,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import configuration from "../../config";
import { makeStyles } from "@material-ui/core/styles";
import { theme } from "../../theme";
import { Link, useHistory } from "react-router-dom";
import queryString from "query-string";
import Spinner from "../../components/spinner";
import { AlertContext } from "../../context/alert-context";
import RatingModal from "../Notification/rating";
import CTA from "../../components/button/cta-button";
import BulkTransactionDetails from "./bulk-transaction-details";
import {
  notifyBiller,
  fetchTransactions,
} from "../../_actions/bulk-upload.actions";
import { alertConstants } from "../../_constants";
import { ProductContext } from "../../context/product-context";
const useStyles = makeStyles((theme) => ({
  root: {
    borderBottom: "1px solid red",
    borderRadius: 3,
    color: "white",
    height: 48,
    padding: "0 30px",
    flexGrow: 1,
  },

  searchField: {
    width: "400px",
  },
  paperForm: {
    // boxShadow: "4px 4px 10px rgba(0, 0, 0, 0.15)",
    boxShadow: "0 7px 30px -10px rgba(150,170,180,0.5)",
    borderRadius: "5px",
    marginTop: "-10px",
    padding: "3px  40px 10px 40px",
    // width: '450px',
    width: "900px",
    height: "380px",
    overflowY: "scroll",
    [theme.breakpoints.down("sm")]: {
      padding: "20px",
      width: "310px",
      marginLeft: "-8px",
    },
  },
  image: {
    width: 120,
    height: 120,
    marginTop: 5,
    boxShadow: "0 7px 30px -10px rgba(150,170,180,0.5)",
    [theme.breakpoints.down("sm")]: {
      width: 80,
      height: 80,
    },
  },
  img: {
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
  },

  button: {
    display: "block",
    marginTop: theme.spacing(2),
  },

  previewTitle: {
    // fontWeight: "600",
    marginBottom: "10px",
    marginTop: "30px",
    fontFamily: "century gothic",
    color: "#006D01",
    fontSize: "20px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
      fontWeight: "bold",
      marginTop: "0px",
      marginLeft: "-15px",
    },
  },
  previewMain: {
    marginTop: "10px",
    height: "170px",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      width: "0.5em",
      borderRadius: "3px",
      background: "rgba(255,255,255,0.6)",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "green",
      borderRadius: "5px",
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: "-15px",
    },
  },
  summaryFontBold: {
    fontWeight: "600",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      fontWeight: "bold",
    },
  },
  formButtons: {
    marginTop: "20px",
    paddingLeft: "20px",
    marginLeft: "150px",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      marginLeft: "0px",
    },
    [theme.breakpoints.down("xs")]: {
      marginLeft: "-18px",
    },
  },
  titleProduct: {
    fontFamily: "Montserrat",
    fontWeight: "bold",
    fontSize: "25px",
    marginTop: "50px",
    marginLeft: "-30px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
      fontWeight: "600px",
      marginLeft: "-20px",
      marginTop: "30px",
    },
  },
  typographyStyles: {
    textAlign: "right",
    fontSize: "14px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  },
  yourInfo: {
    fontSize: "14px",
    textAlign: "left",
    marginTop: "0px",
    // marginLeft: "50px",
    marginBottom: "10px",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "0px",
    },
  },
  paper2: {
    // boxShadow: "4px 4px 10px rgba(0, 0, 0, 0.15)",
    boxShadow: "0 7px 30px -10px rgba(150,170,180,0.5)",
    borderRadius: "5px",
    marginTop: "5px",
    padding: "3px  40px 10px 40px",
    // width: '450px',
    width: "900px",
    [theme.breakpoints.down("sm")]: {
      padding: "10px",
      width: "310px",
      marginLeft: "-8px",
    },
  },
  paragraph: {
    fontSize: "14px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  },
  dialog: {},
  compliment: {
    fontSize: "13px",

    [theme.breakpoints.down("sm")]: {
      fontSize: "11px",
      marginLeft: "-15px",
    },
  },
}));

export default function BulkUploadNotification() {
  const params = queryString.parse(window.location.search);
  let tranId = params["transaction-id"];
  const [state, setState] = useState({
    loading: false,
    response: null,
    bulkDetails: null,
  });
  const classes = useStyles();
  const { dispatch } = useContext(AlertContext);
  const history = useHistory();
  const { rateTransactions } = useContext(ProductContext);

  const [open, setOpen] = React.useState(true);
  const [openTransactionDetail, setOpenTransactionDetail] = React.useState(
    false
  );
  const handleTransactionDetailsOpen = () => {
    fecthBulkDetail(tranId);
  };

  const handleTransactionDetailsClose = () => {
    setOpenTransactionDetail(false);
  };

  const handleClose = (props) => {
    setOpen(false);
  };

  const fecthBulkDetail = (tranId) => {
    setState({
      ...state,
      loading: true,
    });
    fetchTransactions(tranId).then(
      (data) => {
        setState({
          ...state,
          loading: false,
          bulkDetails: data,
        });
        setOpenTransactionDetail(true);
      },
      (error) => {}
    );
  };

  const onChange = (props) => {
    rating(props.target.value);
    setOpen(false);
  };

  const rating = (rate) => {
    let _req = {
      UserId: state.response.EmailAddress,
      Rating: rate,
      Comment: "",
      TransactionId: state.response.Id,
      BillerCode: state.response.BillerCode,
      Channel: "Web",
    };
    rateTransactions(_req);

    dispatch({
      type: alertConstants.SUCCESS,
      message: "Thank you for your feedback",
    });
  };

  useEffect(() => {
    const params = queryString.parse(window.location.search);
    let tranId = params["transaction-id"];
    if (typeof tranId !== "undefined" && tranId !== "") {
      const arr = tranId.split("-");
      const _req = {
        PaymentMethodString: configuration.paymentMethod,
        PaymentReference: arr.length === 1 ? arr[0] : arr[1],
      };
      setState({
        ...state,
        loading: true,
      });
      notifyBiller(_req).then(
        (data) => {
          setState({
            ...state,
            response: data,
            loading: false
          });
        },
        (error) => {}
      );
    }
  }, []);
  return (
    <div className="formContainer">
      <ThemeProvider theme={theme}>
        {/* {loadingNotification ? (
          <Spinner message="Loading...please wait" />
        ) : ( */}

        {state.loading && (
          <>
            {" "}
            <Spinner message="Loading...please wait" />{" "}
          </>
        )}
        {state.response && (
          <>
            <Grid>
              <Paper
                className={classes.paperForm}
                alignItems="center"
                justifyContent="center"
              >
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12} md={12}>
                    <Typography
                      className={classes.previewTitle}
                      variant="h4"
                      component="h2"
                    >
                      {state.response.TransactionStatus}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <Typography
                      className={classes.compliment}
                      variant="p"
                      component="p"
                    >
                      {state.response.TransactionDescription}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container className={classes.previewMain}>
                  <Grid container>
                    <Grid
                      className={classes.summaryFontBold}
                      item
                      xs={6}
                      sm={6}
                      md={4}
                    >
                      Payment Status
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sm={6}
                      md={8}
                      className={classes.typographyStyles}
                    >
                      {state.response.TransactionStatus}
                    </Grid>
                  </Grid>
                  <Divider
                    style={{
                      borderBottom: "1px solid rgba(255,255,255,0.8)",
                      width: "100%",
                    }}
                  />
                  <Grid container>
                    <Grid
                      className={classes.summaryFontBold}
                      item
                      xs={6}
                      sm={6}
                      md={4}
                    >
                      Transaction Narration
                    </Grid>
                    <Grid item xs={6} sm={6} md={8}>
                      <Typography className={classes.typographyStyles}>
                        {state.response.Narration}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Divider
                    style={{
                      borderBottom: "1px solid rgba(255,255,255,0.8)",
                      width: "100%",
                    }}
                  />
                  <Grid container>
                    <Grid
                      className={classes.summaryFontBold}
                      item
                      xs={6}
                      sm={6}
                      md={4}
                    >
                      Payment Reference
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sm={6}
                      md={8}
                      className={classes.typographyStyles}
                    >
                      {state.response.TransactionId}
                    </Grid>
                  </Grid>
                  <Divider
                    style={{
                      borderBottom: "1px solid rgba(255,255,255,0.8)",
                      width: "100%",
                    }}
                  />
                  <Grid container>
                    <Grid
                      className={classes.summaryFontBold}
                      item
                      xs={6}
                      sm={6}
                      md={4}
                    >
                      Total Amount
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sm={6}
                      md={8}
                      className={classes.typographyStyles}
                    >
                      {state.response.OrderAmount}
                    </Grid>
                  </Grid>
                  <Divider
                    style={{
                      borderBottom: "1px solid rgba(255,255,255,0.8)",
                      width: "100%",
                    }}
                  />
                  <Grid container>
                    <Grid
                      className={classes.summaryFontBold}
                      item
                      xs={6}
                      sm={6}
                      md={4}
                    >
                      Count
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sm={6}
                      md={8}
                      className={classes.typographyStyles}
                    >
                      {state.response.TransactionCount}
                    </Grid>
                  </Grid>
                  <Divider
                    style={{
                      borderBottom: "1px solid rgba(255,255,255,0.8)",
                      width: "100%",
                    }}
                  />
                  <Grid container>
                    <Grid
                      className={classes.summaryFontBold}
                      item
                      xs={6}
                      sm={6}
                      md={4}
                    >
                      Order Date
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sm={6}
                      md={8}
                      className={classes.typographyStyles}
                    >
                      {state.response.OrderDate}
                    </Grid>
                  </Grid>
                  <Divider
                    style={{
                      borderBottom: "1px solid rgba(255,255,255,0.8)",
                      width: "100%",
                    }}
                  />
                </Grid>

                <Grid className={classes.formButtons}>
                  <CTA
                    text="View Details"
                    medium
                    style={{ width: "auto", padding: "10px", float: "right" }}
                    action={handleTransactionDetailsOpen}
                  />
                  {/* <PrintReceiptModal  /> */}
                  <RatingModal
                    openModal={open}
                    handleClose={handleClose}
                    onChange={onChange}
                  />
                </Grid>
              </Paper>
              <Dialog
                open={openTransactionDetail}
                onClose={handleTransactionDetailsClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="lg"
                className={classes.dialog}
              >
                <DialogTitle
                  id="alert-dialog-title"
                  style={{ fontWeight: "bold" }}
                >
                  Transaction Details
                </DialogTitle>
                <DialogContent>
                  <BulkTransactionDetails bulkDetails={state.bulkDetails} />
                </DialogContent>
                <DialogActions>
                  <CTA
                    text="close"
                    medium
                    action={handleTransactionDetailsClose}
                  />
                </DialogActions>
              </Dialog>
            </Grid>
            <Grid container>
              <Paper className={classes.paper2}>
                <Grid item>
                  <div>
                    <p className={classes.paragraph}>
                      Carry out another transaction
                      {/* <Link
                      to={`/${notifyBillerResponse.CategoryPath}/${notifyBillerResponse.BillerCode}`}
                      style={{
                        color: "#FF6C00",
                        textDecoration: "underline",
                        fontFamily: "Century gothic",
                      }}
                    > */}
                      {/* {" "}
                      another transaction
                    </Link>{" "} */}
                      or go{" "}
                      <Link
                        to="/"
                        style={{
                          color: "#FF6C00",
                          textDecoration: "underline",
                          fontFamily: "Century gothic",
                        }}
                      >
                        Back to home
                      </Link>
                    </p>
                  </div>
                </Grid>
                <Grid item>
                  <Typography className={classes.paragraph}>
                    For any enquiries please contact: info@xpresspayments.com
                  </Typography>
                </Grid>
              </Paper>
            </Grid>
          </>
        )}
        {/* )} */}
      </ThemeProvider>
    </div>
  );
}

