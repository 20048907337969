import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import FilterListIcon from '@material-ui/icons/FilterList';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import classNames from 'classnames';
import {FaPrint, FaFilePdf, FaFileExcel} from 'react-icons/fa';

const toolbarStyles = theme => ({
    root: {
      paddingRight: theme.spacing.unit,
      minHeight:'30px',
    },
    highlight:
      theme.palette.type === 'light'
        ? {
            color: theme.palette.secondary.main,
            backgroundColor: lighten(theme.palette.secondary.light, 0.85),
          }
        : {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.secondary.dark,
          },
    spacer: {
      flex: '1 1 100%',
    },
    actions: {
      color: theme.palette.text.secondary,
    },
    title: {
      flex: '0 0 auto',
    },

  });
  

class EnhancedTableToolbar extends React.Component {
    render() {
    const { numSelected, classes, onExportExcel, onExportPdf, onPrint } = this.props;
  
    return (
      <Toolbar
        className={classNames(classes.root, {
          [classes.highlight]: numSelected > 0,
        })}
      >
        <div className={classes.title}>
          {numSelected > 0 &&
            <Typography color="inherit" variant="body1">
              {numSelected} selected
            </Typography>
          }
        </div>
        <div className={classes.spacer} />
        <div className={classes.actions}>
          {numSelected > 0 ? (
              <div style={{whiteSpace: 'nowrap'}}>
                    <Tooltip title="Print">
                        <IconButton aria-label="Print" onClick={onPrint}>
                            <FaPrint fontSize='medium' />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Pdf">
                        <IconButton aria-label="Pdf" onClick={onExportPdf}>
                            <FaFilePdf fontSize='medium' />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Excel">
                        <IconButton aria-label="Excel" onClick={onExportExcel}>
                            <FaFileExcel fontSize='medium' />
                        </IconButton>
                    </Tooltip>
                </div>
          ) : (
            <Tooltip title="Filter list">
              <IconButton aria-label="Filter list">
                <FilterListIcon />
              </IconButton>
            </Tooltip>
          )}
        </div>
      </Toolbar>
    )
          }
}
  
  EnhancedTableToolbar.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
  };
export default withStyles(toolbarStyles)(EnhancedTableToolbar);