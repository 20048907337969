import configuration from "../config";
import {
    authHeader
} from "../_helpers";
import {
    handleResponse
} from "../helpers";

export const productService = {
    fetchProduct,
    logTransaction,
    customerLookup,
};

async function fetchProduct(categoryCode, billerCode) {
    const requestOptions = {
        method: "GET",
        headers: {
            ...authHeader(),
            "Content-Type": "application/json",
        },
    };
    return fetch(`${configuration.apiUrl}/Products/Fields/?BillerCode=${billerCode}&Category=${categoryCode}`, requestOptions)
        .then(handleResponse);
}


async function logTransaction(_req) {
    const req = {
        requestData: _req
    }
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(req)
    };
    var _url = `${configuration.apiUrl}/Products/LogTransaction`;
    return fetch(_url, requestOptions)
        .then(handleResponse);
}

async function customerLookup(_req) {
    const requestOptions = {
        method: 'POST',
        headers: {
            ...authHeader(),
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(_req)
    };
    var _url = `${configuration.apiUrl}/Products/Lookup`;
    return fetch(_url, requestOptions)
    .then(
        response => {
            return response.text().then(text => {
                const data = text && JSON.parse(text);
                if (!response.ok) {
                    const error = (data && data.ResponseMsg) || response.statusText;
                    return Promise.reject(error);
                }
                return data;
            })
        }
    )
}