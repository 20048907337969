export const productConstants = {
    FETCH_CATEGORIES: 'FETCH_PRODUCT_CATEGORIES',
    FETCH_CATEGORY_PRODUCTS: 'FETCH_CATEGORY_PRODUCTS',
    FILTER_CATEGORY_PRODUCTS: 'FILTER_CATEGORY_PRODUCTS',
    FETCH_PRODUCT_FIELDS: 'FETCH_PRODUCT_FIELDS',
    CLEAR_CATEGORY: 'CLEAR_CATEGORY',
    CUSTOMER_LOOKUP_ACTION: 'CUSTOMER_LOOKUP_ACTION',
    TRANSACTION_INITIATION: 'TRANSACTION_INITIATION',
    CONTROL_TYPE_SELECT: 'LOOKUP',
    CONTROL_TYPE_TEXTBOX: 'TEXTBOX',
    CONTROL_INPUT_TYPE_USER: 'user',
    CONTROL_INPUT_TYPE_LOOKUP: 'customer-lookup',
    FIELD_TYPE_EMAIL: 'Email',
    FIELD_TYPE_PHONE: 'Phone',
    FIELD_TYPE_NUMBER: 'Number',
    FIELD_TYPE_DECIMAL: 'Decimal',
    FORM_STATUS_INPUTTING: 'FORM_STATUS_INPUTTING',
    FORM_STATUS_LOOKEDUP: 'FORM_STATUS_LOOKEDUP',
    FORM_STATUS_CONFIRMING: 'FORM_STATUS_CONFIRMING',
    FORM_STATUS_CONFIRMED: 'FORM_STATUS_CONFIRMED',
    CHARGE_TYPE_FIXED: 'FIXED',
    CHARGE_TYPE_PERCENTAGE: 'PERCENTAGE',
    REQUEST_FINISH: 'REQUEST_FINISH',
    SENDING_REQUEST: 'SENDING_REQUEST',
    SENDING_PRODUCT_REQUEST: 'SENDING_PRODUCT_REQUEST',
    PRODUCT_REQUEST_FINISH: 'PRODUCT_REQUEST_FINISH',
    FETCH_CATEGORY_MAPPED_PRODUCTS: 'FETCH_CATEGORY_MAPPED_PRODUCTS',
    FETCH_PRODUCT_FIELDS_COMPLETED: 'FETCH_PRODUCT_FIELDS_COMPLETED',
    NOTIFY_BILLER_RESPONSE: 'NOTIFY_BILLER_RESPONSE',
    REQUEST_NOTIFICATION_FINISH: 'REQUEST_NOTIFICATION_FINISH',
    SENDING_NOTIFICATION_REQUEST: 'SENDING_NOTIFICATION_REQUEST',
    SEARCH_FETCH_CATEGORY_PRODUCTS: 'SEARCH_FETCH_CATEGORY_PRODUCTS',
};
