import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router";
import { Grid, TextField, ThemeProvider } from "@material-ui/core";
import CTA from "../../components/button/cta-button";
import { makeStyles } from "@material-ui/core/styles";
import { theme } from "../../theme";
import { AccountResetContext } from "../../context/account-reset-context";
import { AlertContext } from "../../context/alert-context";
import * as Yup from "yup";
import { useFormik } from "formik";
import Spinner from "../../components/spinner";

const useStyles = makeStyles((theme) => ({
  root: {
    borderBottom: "1px solid red",
    borderRadius: 3,
    color: "white",
    height: 48,
    padding: "0 30px",
    flexGrow: 1,
  },
  inputPadding: {
    paddingLeft: "5px",
    marginTop: "20px",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "0px",
    },
  },
}));
export default function ResetPassword() {
  const history = useHistory();
  const {
    ressetting,
    errorMessage,
    user,
    verifyResetToken,
    resetPassword,
  } = useContext(AccountResetContext);
  const { dispatch } = useContext(AlertContext);

  const param = window.location.search;
  const _token = param.substring(1, param.length);

  const validationSchema = Yup.object().shape({
    password: Yup.string("Enter Your New Password")
      .typeError("Enter Your New Password")
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        "Please choose a strong password. Password must meet the following criteria; 1. At least one block letter. 2. At least one small letter. 3. At least one number. 4. At least one special character"
      )
      .required("Password is Required"),
    confirmpwd: Yup.string("Confirm Your Password")
      .typeError("Confirm Your Password")
      .oneOf([Yup.ref("password")], "Passwords do not match")
      .required("Confirm Password is Required"),
  });

  useEffect(() => {
    verifyResetToken(_token, dispatch);
  }, []);

  const {
    handleSubmit,
    handleChange,
    setFieldTouched,
    touched,
    errors,
    resetForm,
  } = useFormik({
    initialValues: {
      password: "",
      confirmpwd: "",
    },
    validationSchema,
    onSubmit(values) {
      let req = {
        ...values,
        email: user.ConsumerID,
        token: _token,
      };
      resetPassword(req, dispatch);
      resetForm({
        password: "",
        confirmpwd: "",
      });
    },
  });

  const change = (name, e) => {
    e.persist();
    handleChange(e);
    setFieldTouched(name, true, false);
  };

  const classes = useStyles();
  console.log("first")
  return (
    <div className="reset-password">
      {ressetting && <Spinner message="Loading...please wait" />}
      {!errorMessage ? (
        <Grid container>
          <Grid item lg={7} md={7} sm={12}>
            <div className="reset-password">
              <h1>Password Reset</h1>
              <p>
                We've made reseting your password easy for you, just fill the
                form below
              </p>
            </div>
            {/* {changeErrorMessage &&  (<div style={{color: 'red'}}>{changeErrorMessage}</div>)} */}
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <form onSubmit={handleSubmit}>
                <div className="reset-form">
                  <ThemeProvider theme={theme}>
                    <Grid
                      className={classes.inputPadding}
                      padding={20}
                      xs={12}
                      sm={12}
                      md={8}
                    >
                      <TextField
                        required
                        id="password"
                        name="password"
                        label="New Password"
                        type="password"
                        style={{ margin: 8 }}
                        placeholder="enter new password"
                        fullWidth
                        margin="normal"
                        helperText={touched.password ? errors.password : ""}
                        error={touched.password && Boolean(errors.password)}
                        onChange={change.bind(null, "password")}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid
                      className={classes.inputPadding}
                      xs={12}
                      sm={12}
                      md={8}
                    >
                      <TextField
                        required
                        id="confirmpwd"
                        name="confirmpwd"
                        label="Confirm Password"
                        type="password"
                        style={{ margin: 8 }}
                        placeholder="confirm new password"
                        fullWidth
                        margin="normal"
                        helperText={touched.confirmpwd ? errors.confirmpwd : ""}
                        error={touched.confirmpwd && Boolean(errors.confirmpwd)}
                        onChange={change.bind(null, "confirmpwd")}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                  </ThemeProvider>

                  <CTA
                    type="submit"
                    large
                    text="Reset Password"
                    style={{ marginTop: "40px", marginLeft: "2%" }}
                  />
                </div>
              </form>
            </Grid>
          </Grid>
          <Grid item lg={5} md={5} sm={12}>
            <div className="reset-illustration">
              <img
                src="/resources/images/reset-password.svg"
                alt="illustration"
              />
            </div>
          </Grid>
        </Grid>
      ) : (
        <p>{errorMessage}</p>
      )}
    </div>
  );
}
