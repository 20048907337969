import { userConstants } from "../_constants";
import { userService } from "../_services";
import { alertActions } from "./";
import { common } from "@material-ui/core/colors";

export const userActions = {
  login,
  logout,
  register,
  activate,
  update: _update,
  changePassword,
  sendResetLink,
  verifyResetToken,
  resetPassword,
  tellAFriend,
  checkLogin,
  getCarouselImages,
  submitBackEmail,
  updateRegWithBackupEmail,
};

function login(username, password, callback) {
  return userService.login(username, password).then(
    (user) => {
      if (user.access_token) {
        localStorage.setItem("user", JSON.stringify(user));
        return user;
      }
    },
    (error) => {
      return { message: error, responseCode: "09" };
    }
  );
}

function checkLogin() {
  userService.checkLogin();
}

function getCarouselImages(success, failure) {
  userService.getCarouselImages().then(
    (images) => {
      if (!!success) {
        success(images);
      }
    },
    (error) => {
      if (!!failure) {
        failure();
      }
    }
  );
}

function logout() {
  // localStorage.removeItem("user");
  // userService.logout();
  localStorage.clear();
  // return { type: userConstants.LOGOUT };
}
function register(user, history) {
  return userService.register(user).then(
    (user) => {
      var msg =
        "An email notification has been sent to " +
        user.ConsumerID +
        "  click on the link to get verified.";
      return { message: msg, responseCode: "00" };
    },
    (error) => {
      return { message: error, responseCode: "09" };
    }
  );
}

function _update(user, userDispatch) {
  return userService.update(user).then(
    (user) => {
      // dispatch(success(user));
      // dispatch({ type: userConstants.UPDATE_SUCCESS, user });
      // history.push("/update-profile");
      var msg = "Records updated successfully";
      // dispatch(alertActions.success(msg));
      return { message: msg, responseCode: "00" };
    },
    (error) => {
      return { message: error, responseCode: "09" };
      // dispatch(failure(error.toString()));
      // dispatch(alertActions.error(error.toString()));
    }
  );
}

function changePassword(user, history) {
  //let _temp = { ConsumerID: user.email };
  return userService
    .changePassword(user)
    .then
    // (user) => {
    //   //history.push("/change-password");
    //
    //   return { message: msg, responseCode: "00" };
    // },
    // (error) => {
    //   return { message: error, responseCode: "09" };
    // }
    ();

  function request(user) {
    return { type: userConstants.RESET_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.RESET_SUCCESS, user };
  }
  function failure(user) {
    return { type: userConstants.RESET_FAILURE, user };
  }
}

function sendResetLink(user, history) {
  return userService.sendResetLink(user).then(
    (user) => {
      //history.push("/forgot-password");
      var msg = "Password Reset steps have been sent to your mailbox.";
      // dispatch(alertActions.success(msg));
      return { message: msg, responseCode: "00" };
    },
    (error) => {
      return { message: error, responseCode: "09" };
      // dispatch(failure(error.toString()));
      // dispatch(alertActions.error(error.toString()));
    }
  );

  function request(user) {
    return { type: userConstants.RESET_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.RESET_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.RESET_FAILURE, error };
  }
}

function resetPassword(user, history) {
  return userService.resetPassword(user);
}

function tellAFriend(req, success, failure) {
  return (dispatch) => {
    userService.tellAFriend(req).then(
      () => {
        if (!!success) success();
        var msg = "Your friend has been notified";
        dispatch(alertActions.success(msg));
      },
      (error) => {
        if (!!failure) failure();
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
}

function activate(token) {
  return userService.activate(token);
}

function verifyResetToken(token) {
  return userService.verifyResetToken(token);
}

function submitBackEmail(user) {
  return userService.submitBackEmail(user);
}

function updateRegWithBackupEmail(user) {
  return userService.updateRegWithBackupEmail(user);
}

// function register(user, history) {
//     //return dispatch => {
//         userService.register(user)
//             .then(
//                 (resp) => {
//                  var msg = "An email notification has been sent to " + user.ConsumerID +"  click on the link to get verified.";

//                     return msg;
//                 },
//                 (error) => {}
//                 // user => {
//                 //     dispatch(success(user));
//                 //     history.push('/');
//                 //     var msg = "An email notification has been sent to " + user.ConsumerID +"  click on the link to get verified.";
//                 //     dispatch(alertActions.success(msg));
//                 // },
//                 // error => {
//                 //     dispatch(failure(error.toString()));
//                 //     dispatch(alertActions.error(error.toString()));
//                 // }
//             );
//    // };
//    }

// function login(username, password, callback) {
//     return dispatch => {
//         dispatch(request({ username }));
//         userService.login(username, password)
//             .then(
//                 user => {
//                     if (user.access_token) {
//                         localStorage.setItem('user', JSON.stringify(user));
//                     }
//                     dispatch(success(user));
//                     if(!!callback){
//                         callback();
//                     }
//                 },
//                 error => {
//                     dispatch(failure(error.toString()));
//                     dispatch(alertActions.error(error.toString()));
//                 }
//             );
//     };

//     function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
//     function success(user) {return { type: userConstants.LOGIN_SUCCESS, user }}
//     function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
// }

// function _update(user, history) {
//   return (dispatch) => {
//     dispatch(request(user));
//     userService.update(user).then(
//       (user) => {
//         dispatch(success(user));
//         dispatch({ type: userConstants.UPDATE_SUCCESS, user });
//         history.push("/update-profile");
//         var msg = "Records updated successfully";
//         dispatch(alertActions.success(msg));
//       },
//       (error) => {
//         dispatch(failure(error.toString()));
//         dispatch(alertActions.error(error.toString()));
//       }
//     );
//   };

//   function request(user) {
//     return { type: userConstants.UPDATE_REQUEST, user };
//   }
//   function success(user) {
//     return { type: userConstants.UPDATE_SUCCESS, user };
//   }
//   function failure(error) {
//     return { type: userConstants.UPDATE_FAILURE, error };
//   }
// }

// function changePassword(user, history) {
//   return (dispatch) => {
//     let _temp = { ConsumerID: user.email };
//     dispatch(request(user));
//     userService.changePassword(user).then(
//       (user) => {
//         dispatch(success(user));
//         history.push("/change-password");
//         var msg = "Password Changed Successfully";
//         dispatch(alertActions.success(msg));
//       },
//       (error) => {
//         dispatch(failure(_temp));
//         dispatch(alertActions.error(error.toString()));
//       }
//     );
//   };

//   function request(user) {
//     return { type: userConstants.RESET_REQUEST, user };
//   }
//   function success(user) {
//     return { type: userConstants.RESET_SUCCESS, user };
//   }
//   function failure(user) {
//     return { type: userConstants.RESET_FAILURE, user };
//   }
// }

// function resetPassword(user, history) {
//   return (dispatch) => {
//     let _temp = { ConsumerID: user.email };
//     dispatch(request(user));
//     userService.resetPassword(user).then(
//       (response) => {
//         dispatch(success(response));
//         history.push("/");
//         var msg = "Password reset successfully";
//         dispatch(alertActions.success(msg));
//       },
//       (error) => {
//         dispatch(failure(_temp));
//         dispatch(alertActions.error(error.toString()));
//       }
//     );
//   };

//   function request(user) {
//     return { type: userConstants.RESET_REQUEST, user };
//   }
//   function success(user) {
//     return { type: userConstants.RESET_SUCCESS, user };
//   }
//   function failure(user) {
//     return { type: userConstants.RESET_FAILURE, user };
//   }
// }

// function sendResetLink(user, history) {
//   return (dispatch) => {
//     dispatch(request(user));
//     userService.sendResetLink(user).then(
//       (user) => {
//         dispatch(success(user.CustomerID));
//         history.push("/forgot-password");
//         var msg = "Password Reset steps have been sent to your mailbox.";
//         dispatch(alertActions.success(msg));
//       },
//       (error) => {
//         dispatch(failure(error.toString()));
//         dispatch(alertActions.error(error.toString()));
//       }
//     );
//   };

// function request(user) {
//   return { type: userConstants.RESET_REQUEST, user };
// }
// function success(user) {
//   return { type: userConstants.RESET_SUCCESS, user };
// }
// function failure(error) {
//   return { type: userConstants.RESET_FAILURE, error };
// }
//}

// function activate(token) {
//   return (dispatch) => {
//     dispatch(request(token));
//     userService.activate(token).then(
//       (user) => {
//         dispatch(success(user));
//       },
//       (error) => {
//         dispatch(failure(error.toString()));
//         dispatch(alertActions.error(error.toString()));
//       }
//     );
//   };

//   function request(user) {
//     return { type: userConstants.ACTIVATE_REQUEST, user };
//   }
//   function success(user) {
//     return { type: userConstants.ACTIVATE_SUCCESS, user };
//   }
//   function failure(error) {
//     return { type: userConstants.ACTIVATE_FAILURE, error };
//   }
// }

// function verifyResetToken(token) {
//   return (dispatch) => {
//     dispatch(request(token));
//     userService.verifyResetToken(token).then(
//       (user) => {
//         dispatch(success(user));
//       },
//       (error) => {
//         dispatch(failure(error.toString()));
//         dispatch(alertActions.error(error.toString()));
//       }
//     );
//   };

//   function request(user) {
//     return { type: userConstants.VERIFY_REQUEST, user };
//   }
//   function success(user) {
//     return { type: userConstants.VERIFY_SUCCESS, user };
//   }
//   function failure(error) {
//     return { type: userConstants.VERIFY_FAILURE, error };
//   }
// }
