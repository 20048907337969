import configuration from "../../config";

import React from "react";
import "./index.css";
//import ReactDOM from "react-dom";
import Form from "./form.jsx";

//import bootstrap from "bootstrap";

class GuessAndWin extends React.Component {
  state = {
    Status: false,
    UploadFrequency: 0,
    CampaignName: "",
    GuessRange: "",
  };

  //${configuration.apiUrl}
  componentDidMount() {
    fetch(`${configuration.apiUrl2}/api/GuessAndWin/GuessAndWinConfig`, {
      method: "GET",
      headers: new Headers({
        Authorization: "Basic " + btoa(`${configuration.apiUrl2Credentials}`),
        "Content-Type": "application/x-www-form-urlencoded",
        mode: "cors",
        cache: "default",
        credentials: "same-origin",
      }),
    })
      .then((response) => response.json())
      .then(
        (response) => {
          if (response.responseCode === "00") {
            this.setState({
              Status: response.responseData.Status,
              UploadFrequency: response.responseData.UploadFrequency,
              CampaignName: response.responseData.CampaignName,
              GuessRange: response.responseData.GuessRange,
            });
          }
        },
        (error) => {
          //this.setState({
          //isLoaded: true,
          //error;
          //});
        }
      );
  }

  render() {
    return this.state.Status === false ? "" : <Form {...this.state} />;
  }
}

export default GuessAndWin;
