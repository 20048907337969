import React from "react";
import PropTypes from "prop-types";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Tooltip from "@material-ui/core/Tooltip";
import Checkbox from "@material-ui/core/Checkbox";
import { makeStyles } from "@material-ui/core/styles";

const styles = {
  /* Styles applied to the root element. */
  background: "rgba(0, 109, 1, 0.2)",
  fontFamily: "Montserrat",
  fontWeight: "900",
  fontSize: "14px",
  padding: "10px",
  height: "50px", 
};
const styles2 = {
  fontFamily: "Montserrat",
  fontWeight: "900",
  height: "40px",
  fontSize: "14px",
  width: "200px",
};

class EnhanceTableHead extends React.Component {
  createSortHandler = (property) => (event) => {
    this.props.onRequestSort(event, property);
  };
  // classes = useStyles();
  render() {
    const {
      onSelectAllClick,
      order,
      orderBy,
      numSelected,
      rowCount,
      columns,
      classes,
      showCheckbox,
      showAction,
    } = this.props;

    return (
      <TableHead style={styles}>
        <TableRow>
          {!!showCheckbox && (
            <TableCell padding="checkbox">
              <Checkbox
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={numSelected > 0 && numSelected === rowCount}
                onChange={onSelectAllClick}
              />
            </TableCell>
          )}
          {columns.map((column) => {
            return (
              <TableCell
                key={column.Id}
                numeric={column.Numeric}
                className={classes.tableCell}
                padding={column.DisablePadding ? "none" : "default"}
                sortDirection={orderBy === column.Id ? order : false}
                // style={{ }}
                style={styles}
              >
                <Tooltip
                  title="Sort"
                  placement={column.Numeric ? "bottom-end" : "bottom-start"}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={orderBy === column.Id}
                    direction={order}
                    onClick={this.createSortHandler(column.Id)}
                  >
                    {column.Label}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
            );
          }, this)}
          {!!showAction && (
            <TableCell
              padding="checkbox"
              style={{
                fontFamily: "Montserrat",
                fontWeight: "900",
                fontSize: "13px",
                background: "rgba(0, 109, 1, 0.2)",
              }}
            >
              Action
            </TableCell>
          )}
        </TableRow>
      </TableHead>
    );
  }
}

EnhanceTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  //order: PropTypes.string.isRequired,
  //orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};
export default EnhanceTableHead;
