import React, { useRef, useContext, useEffect, useState } from "react";
import { Grid, Card } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import styles from "./dashboard.module.css";
import { FaUser, FaAngleRight, FaAngleLeft } from "react-icons/fa";
import SimpleSlider from "../../components/carousel/dashboard-carousel";
// import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ActivityLog from "../../components/activity-log/activity-log";
import { Link } from "react-router-dom";
import { UserContext } from "../../context/user-context";
import { TransactionContext } from "../../context/transaction-context";
import { getMonthName } from "../../helpers";
import Spinner from "../../components/spinner";

const useStyles = makeStyles((theme) => ({
  root: {
    borderBottom: "1px solid red",
    borderRadius: 3,
    color: "white",
    height: 48,
    padding: "0 30px",
    flexGrow: 1,
  },
  searchField: {
    width: "400px",
  },
}));

// const billers = [
//   {
//     id: 0,
//     url: "/airtel",
//     image: "/resources/images/airtel.jpg",
//   },
//   {
//     id: 1,
//     url: "/DSTV2",
//     image: "/resources/images/dstv.png",
//   },
//   {
//     id: 2,
//     url: "airtime/glo",
//     image: "/resources/images/glo.jpg",
//   },
//   {
//     id: 3,
//     url: "Bills/GOTV2",
//     image: "/resources/images/gotv.png",
//   },
//   {
//     id: 4,
//     url: "airtime/mtn",
//     image: "/resources/images/mtn.jpg",
//   },
//   {
//     id: 5,
//     url: "Bills/phedc",
//     image: "/resources/images/phed.png",
//   },
// ];

// const handleChange = (event) => {
//   setAge(event.target.value);
// };

// const handleClose = () => {
//   setOpen(false);
// };

// const handleOpen = () => {
//   setOpen(true);
// };

const Dashboard = () => {
  let slideRef = useRef(null);

  const prev = () => {
    slideRef &&
      slideRef.current &&
      slideRef.current.scrollTo({
        behavior: "smooth",
        top: 0,
        left: slideRef.current.scrollLeft - slideRef.current.clientWidth * 0.65,
      });
  };

  const next = () => {
    slideRef &&
      slideRef.current &&
      slideRef.current.scrollTo({
        behavior: "smooth",
        top: 0,
        left: slideRef.current.scrollLeft + slideRef.current.clientWidth * 0.65,
      });
  };

  const classes = useStyles();
  const { user } = useContext(UserContext);
  const { loadingActivity, transactions, getDashboardTransaction } = useContext(
    TransactionContext
  );

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    adaptiveHeight: true,
    autoplay: true,
    arrows: true,
  };

  const getSuccssCount = (transactions) => {
    const count =
      !!transactions &&
      transactions.filter(
        (transaction) =>
          transaction.ApprovalStatus == "Approved" &&
          transaction.PaymentStatus == "Approved"
      );
    return count.length;
  };

  const getFailedCount = (transactions) => {
    const count =
      !!transactions &&
      transactions.filter(
        (transaction) =>
          transaction.ApprovalStatus != "Approved" ||
          transaction.PaymentStatus != "Approved"
      );
    return count.length;
  };

  const [data, setData] = useState([]);
  const [billers, setBillers] = useState([]);
  const getData = () => {
    fetch("../resources/biller.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        setBillers(data);
      });
  };

  useEffect(() => {
    getDashboardTransaction();
    getData();
  }, []);

  return (
    <div className={styles.dashboardContainer}>
      {/* {loading ? (
        <Spinner message="Loading...please wait" />
      ) : ( */}
      <>
        <Grid container>
          <Grid item lg={12} sm={12} xs={12} md={12}>
            <div className={styles.welcomeAvatar}>
              <div className={styles.userAvatar}>
                <FaUser className={styles.avatar} />
              </div>
              <div className={styles.welcomeMsg}>
                <h3> Hi {user.Firstname}!</h3>
                <p>Welcome to your dashboard</p>
              </div>
            </div>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item lg={8} md={6} sm={12} xs={12} className={styles.leftSide}>
            <div className={styles.quickAccess}>
              <h4>Top Billers</h4>
              <div className={styles.accessContainer} ref={slideRef}>
                {billers.map(({ image, url, id }) => {
                  return (
                    // <div ref={slideRef} className={styles.slides}>
                    <Link
                      key={id}
                      to={`/${url}`}
                      style={{ textDecoration: "none" }}
                    >
                      <div className={styles.billers}>
                        <Card className={styles.card}>
                          <img src={image} alt="" />
                        </Card>
                      </div>
                    </Link>
                    // </div>
                  );
                })}
              </div>
              <div className={styles.controls}>
                <button onClick={prev} className={styles.left}>
                  <FaAngleLeft />
                </button>
                <button onClick={next} className={styles.right}>
                  <FaAngleRight />
                </button>
              </div>
            </div>
            <div className={styles.transactionAdverts}>
              <SimpleSlider />
            </div>
          </Grid>

          {/* activity log */}
          <Grid item lg={4} md={6} sm={12} xs={12}>
            <h6
              style={{
                textAlign: "center",
                color: "#006d01",
                lineHeight: "21px",
                letterSpacing: "0.16em",
                fontSize: "14px",
                fontWeight: "bold",
                fontFamily: "Montserrat",
              }}
            >
              ACTIVITY LOG
            </h6>
            <ActivityLog
              loading={loadingActivity}
              transactions={transactions}
            />
          </Grid>
        </Grid>
      </>
      {/* )} */}
    </div>
  );
};

export default Dashboard;
