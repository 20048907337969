import React, { useState, useContext, useEffect } from "react";
import { ProductContext } from "../../context/product-context";
import {
  Grid,
  TextField,
  Typography,
  Paper,
  Link,
  Tooltip,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Spinner from "../../components/spinner";

import { useHistory, useRouteMatch } from "react-router-dom";
import { UserContext } from "../../context/user-context";
import { AlertContext } from "../../context/alert-context";
import { alertConstants } from "../../_constants";
import OtherServices from "../other-transactions/other-services";

const useStyles = makeStyles((theme) => ({
  searchField: {
    width: "400px",
    marginTop: "10px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "15px",
      // display:'none'
    },
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  categoryName: {
    fontFamily: "Montserrat",
    fontWeight: "bold",
    fontSize: "25px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "17px",
      fontWeight: "bold",
    },
  },
  image: {
    width: 120,
    height: 120,
    borderRadius: 10,
    boxShadow: "0 7px 30px -10px rgba(150,170,180,0.5)",

    [theme.breakpoints.down("sm")]: {
      width: 90,
      height: 90,
      // display:'none'
    },
  },
  img: {
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
    [theme.breakpoints.down("sm")]: {
      width: 90,
      height: 90,
      // display:'none'
    },
  },
  tooltip: {
    textAlign: "center",
  },
}));

const Purchase = () => {
  const {
    categories,
    products,
    searchBillers,
    filteredPproducts,
    loading,
  } = useContext(ProductContext);
  const { loggedIn, userDispatch } = useContext(UserContext);
  const [state, setState] = useState({
    showProduct: "PRODUCT",
    products: products,
  });
  const [searchText, setSearchText] = useState("");
  const { dispatch } = useContext(AlertContext);
  let match = useRouteMatch();
  const pathUrl = window.location.pathname;
  const history = useHistory();
  const classes = useStyles();
  const pathName = decodeURI(pathUrl.split("/")[1]);

  const handleProduct = (
    UrlPath,
    CategoryCode,
    BillerCode,
    Accessibility,
    product
  ) => {
    if (Accessibility === "authenticated" && loggedIn === false) {
      dispatch({
        type: alertConstants.ERROR,
        message: "Please kindly login or register to continue",
      });
      return history.push(`/`);
    } else if (
      product.ExternalLink &&
      product.ProductType === "external-link" &&
      product.LinkType === "_blank"
    ) {
      handleRedirect(
        `${product.BillerCode}`,
        `${product.ExternalLink}`,
        `${product.LinkType}`
      );
    } else if (pathUrl === `/bulk-upload`) {
      history.push(`/airtime/bulk-upload`);
    } else {
      history.push(`/${UrlPath}/${BillerCode}`);
    }
  };

  const handleRedirect = (product, url, _type) => {
    window.open(url, "_blank");
  };

  useEffect(() => {
    setState({
      ...state,
      products: products,
    });
  }, [products]);

  const product1 = state.products.filter((p) => p.UrlPath == pathName);
  const handleSearchChange = (event) => {
    const searchText1 = event.target.value;
    if (state.showProduct == "PRODUCT") {
      setState({
        ...state,
        showProduct: "SEARCH",
      });
      searchBillers(searchText1);
    }
    setSearchText(searchText1);
    searchBillers(searchText1);
  };

  return (
    <div style={{ padding: 20, height: "600px" }}>
      <Grid container spacing={6}>
        <Grid item xs={6} sm={3} md={6}>
          <form noValidate autoComplete="off">
            <TextField
              className={classes.searchField}
              placeholder="Search for billers e.g mtn"
              fullWidth
              id="standard-basic"
              value={searchText}
              onChange={handleSearchChange}
            />
          </form>
        </Grid>
      </Grid>

      <Grid container spacing={6}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          {loading ? (
            <Spinner message="Loading...please wait" />
          ) : (
            categories &&
            categories.map(({ CategoryName, CategoryCode, UrlPath }) => {
              if (UrlPath === pathName) {
                return (
                  <Typography key={CategoryCode} variant="h4" component="h2">
                    {CategoryName}
                  </Typography>
                );
              }
            })
          )}
        </Grid>
      </Grid>
      <div className="purchase-container">
        <Grid container spacing={6}>
          {loading && <Spinner message="Loading...please wait" />}

          {state.showProduct === "PRODUCT" &&
          product1 &&
          product1.length > 0 ? (
            product1.map(
              ({
                Id,
                BillerCode,
                UrlPath,
                CategoryCode,
                ProductName,
                ProductImage,
                Accessibility,
                ...product
              }) => {
                return (
                  <Grid key={Id} item xs={6} sm={3} md={2}>
                    <Paper className={(classes.paper, classes.image)}>
                      <Link
                        onClick={() =>
                          handleProduct(
                            UrlPath,
                            CategoryCode,
                            BillerCode,
                            Accessibility,
                            product
                          )
                        }
                      >
                        <Tooltip
                          key={Id}
                          className={classes.tooltip}
                          title={ProductName}
                          aria-label={ProductName}
                        >
                          {ProductImage ? (
                            <img
                              className={classes.img}
                              alt={""}
                              src={"data:image/png;base64," + ProductImage}
                            />
                          ) : (
                            <div></div>
                          )}
                        </Tooltip>
                      </Link>
                    </Paper>
                  </Grid>
                );
              }
            )
          ) : (
            <>
              <OtherServices />
            </>
            // <Grid item xs={6} sm={3} md={2}>
            //   <Paper className={(classes.paper, classes.image)}>
            //     <p>ddddddddddddddddddddddddd </p>
            //   </Paper>
            // </Grid>
          )}

          {state.showProduct == "SEARCH" &&
            filteredPproducts &&
            filteredPproducts.length > 0 &&
            filteredPproducts.map(
              ({
                Id,
                BillerCode,
                UrlPath,
                CategoryCode,
                ProductName,
                ProductImage,
                Accessibility,
                ...product
              }) => {
                return (
                  <Grid key={Id} item xs={6} sm={3} md={2}>
                    <Paper className={(classes.paper, classes.image)}>
                      <Link
                        onClick={() =>
                          handleProduct(
                            UrlPath,
                            CategoryCode,
                            BillerCode,
                            Accessibility,
                            product
                          )
                        }
                      >
                        <Tooltip
                          key={Id}
                          className={classes.tooltip}
                          title={ProductName}
                          aria-label={ProductName}
                        >
                          <img
                            className={classes.img}
                            alt={""}
                            src={"data:image/png;base64," + ProductImage}
                          />
                        </Tooltip>
                      </Link>
                    </Paper>
                  </Grid>
                );
              }
            )}
        </Grid>
      </div>
    </div>
  );
};

export default Purchase;
