import React, { useContext, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import "../../index.css";
import FormInput from "../inputs/form-input";
import { MdSend, MdPhoneAndroid } from "react-icons/md";
import AccountCircle from "@material-ui/icons/AccountCircle";
import {
  Grid,
  TextField,
  ThemeProvider,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  Input,
} from "@material-ui/core";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";

import InputAdornment from "@material-ui/core/InputAdornment";
import { makeStyles } from "@material-ui/core/styles";
import { theme } from "../../theme";
import CTA from "../button/cta-button";
import { SupportContext } from "../../context/support-context";
import { AlertContext } from "../../context/alert-context";
import Spinner from "../../components/spinner";
import * as Yup from "yup";
import { useFormik } from "formik";
import { UserContext } from "../../context/user-context";
import OtherPagesSlider from "../carousel/other-pages-carousel";

const useStyles = makeStyles((theme) => ({
  root: {
    borderBottom: "1px solid red",
    borderRadius: 3,
    color: "white",
    height: 100,
    padding: "0 30px",
    flexGrow: 1,
  },
  commentBox: {
    margin: 8,
    width: "100%",
    fontSize: "14px",
    transition: "all 0.35s ease-in",
    border: "none",
    borderBottom: "2px solid #000",

    "&:focus": {
      outline: "none",
      borderBottom: "2px solid #FF6C00 !important",
    },
  },
}));

export default function Support() {
  const classes = useStyles();
  const { loading, subjects, getSupportSubjects, supportAction } = useContext(
    SupportContext
  );
  const { dispatch } = useContext(AlertContext);
  const { user } = useContext(UserContext);

  useEffect(() => {
    getSupportSubjects(dispatch);
  }, []);

  const validationSchema = Yup.object().shape({
    email: Yup.string("Enter Your Email")
      .email("Enter a Valid Email")
      .typeError("Enter Your Email")
      .required("Your Email is Required"),
    displayName: Yup.string("Enter Your Name")
      .typeError("Enter Your Name")
      .min(2, "Your Name must be at least 2 Characters long")
      .required("Your Name is Required"),
    subject: Yup.string("Enter Subject")
      .typeError("Enter Subject")
      .required("Subject is Required"),
    comment: Yup.string("Enter Comment")
      .typeError("Enter Comment")
      .min(5, "Comment must be at least 5 Characters long")
      .required("Comment is Required"),
  });
  const {
    handleSubmit,
    handleChange,
    values,
    setFieldValue,
    isValid,
    setFieldTouched,
    setSubmitting,
    touched,
    errors,
    dirty,
    resetForm,
  } = useFormik({
    initialValues: {
      displayName:
        !!user && Object.entries(user).length !== 0
          ? user.Firstname + " " + user.Surname
          : "",
      email: user ? user.ConsumerID : "",
      subject: "",
      comment: "",
    },
    validationSchema,
    onSubmit(values) {
      setSubmitting(true);
      supportAction(values, dispatch);
      setSubmitting(false);
      resetForm({
        displayName: "",
        email: "",
        subject: "",
        comment: "",
      });
    },
  });

  const change = (name, e) => {
    e.target.name = name;
    setFieldValue(name, e.target.value);
    handleChange(name, e.target.value);
    setFieldTouched(name, true, false);
  };

  return (
    <ThemeProvider theme={theme}>
      <div className="support-page-container">
        {loading && <Spinner message="Loading...please wait" />}
        <Row>
          <Col className="support-container" lg={8}>
            <Row>
              <Col lg={6} md={6}>
                <div className="support-contact">
                  <div className="overlay"></div>
                  <img src="/resources/images/support.jpg" alt="support" />
                  <div className="support-contact-lines">
                    <h2>SUPPORT & FEEDBACK</h2>
                    <p>
                      Reach out to us through any of the channels below if you
                      require Support.
                    </p>
                    <div className="email">
                      <img src="/resources/images/mail-icon.svg" />
                      <p>customercare@xpresspayments.com</p>
                    </div>
                    <div className="phone">
                      <img src="/resources/images/phone-icon.svg" />
                      <p>01-6312430</p>
                      <p>+234-8188866022</p>
                    </div>
                  </div>
                </div>
              </Col>
              <Col lg={6} md={6}>
                <div className="support-form">
                  <h6 className="support-mobile-header">
                    Support and Feedback
                  </h6>
                  <ThemeProvider theme={theme}>
                    <form onSubmit={handleSubmit}>
                      <Grid
                        className={classes.inputPadding}
                        padding={20}
                        xs={10}
                        sm={6}
                        md={10}
                        style={{ marginTop: "30px" }}
                      >
                        {!!user &&
                        typeof user.Firstname !== "undefined" &&
                        user.Firstname !== "" ? (
                          <Input
                            type="hidden"
                            name="displayName"
                            id="displayName"
                            value={values.displayName}
                          />
                        ) : (
                          <TextField
                            label="displayName"
                            style={{ margin: 8, marginTop: 60 }}
                            placeholder="Enter your Name"
                            fullWidth
                            margin="normal"
                            id="displayName"
                            name="displayName"
                            helperText={
                              touched.displayName ? errors.displayName : ""
                            }
                            error={
                              touched.displayName && Boolean(errors.displayName)
                            }
                            onChange={(e) => change("displayName", e)}
                            value={values.displayName}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        )}
                      </Grid>
                      <Grid
                        className={classes.inputPadding}
                        padding={20}
                        xs={10}
                        sm={6}
                        md={10}
                      >
                        {!!user &&
                        typeof user.ConsumerID !== "undefined" &&
                        user.ConsumerID !== "" ? (
                          <Input
                            type="hidden"
                            name="email"
                            id="email"
                            value={values.email}
                          />
                        ) : (
                          <TextField
                            label="Email Address"
                            style={{ margin: 8 }}
                            placeholder="Enter Email Address"
                            fullWidth
                            margin="normal"
                            id="email"
                            name="email"
                            helperText={touched.email ? errors.email : ""}
                            error={touched.email && Boolean(errors.email)}
                            onChange={(e) => change("email", e)}
                            value={values.email}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        )}
                      </Grid>
                      <Grid
                        className={classes.inputPadding}
                        xs={10}
                        sm={6}
                        md={10}
                        padding={20}
                      >
                        <FormControl
                          fullWidth
                          className={classes.formControl}
                          style={{ margin: 8 }}
                        >
                          {/* <InputLabel id="subject">
                      Subject
                    </InputLabel> */}
                          <TextField
                            label="Subject"
                            id="subject"
                            select
                            required
                            name="subject"
                            helperText={touched.subject ? errors.subject : ""}
                            error={touched.subject && Boolean(errors.subject)}
                            onChange={(e) => change("subject", e)}
                            value={values.subject}
                            SelectProps={{
                              displayEmpty: true,
                              MenuProps: {
                                classes: { paper: classes.dropdownStyle },
                              },
                            }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          >
                            <MenuItem key="initial" value="">
                              --select--
                            </MenuItem>
                            {!!subjects &&
                              subjects.map((option) => (
                                <MenuItem
                                  key={option.value}
                                  value={option.value}
                                >
                                  {option.label}
                                </MenuItem>
                              ))}
                          </TextField>
                        </FormControl>
                      </Grid>
                      <Grid
                        className={classes.inputPadding}
                        padding={20}
                        xs={10}
                        sm={6}
                        md={10}
                      >
                        {/* <TextField
                          label="comment"
                          style={{ margin: 8 }}
                          placeholder="Comment"
                          fullWidth
                          margin="normal"
                          id="comment"
                          name="comment"
                          helperText={touched.comment ? errors.comment : ""}
                          error={touched.comment && Boolean(errors.comment)}
                          onChange={(e) => change("comment", e)}
                          value={values.comment}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        /> */}
                        <TextareaAutosize
                          label="comment"
                          className={classes.commentBox}
                          style={{}}
                          rowsMin={3}
                          placeholder="Comment"
                          id="comment"
                          name="comment"
                          aria-label="empty textarea"
                          helperText={touched.comment ? errors.comment : ""}
                          error={touched.comment && Boolean(errors.comment)}
                          onChange={(e) => change("comment", e)}
                          value={values.comment}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>

                      <CTA
                        large
                        text="Send"
                        type="submit"
                        disabled={!(isValid && dirty)}
                        style={{
                          width: "85%",
                          marginLeft: "5px",
                          marginTop: "20px",
                        }}
                      />
                    </form>
                  </ThemeProvider>
                </div>
              </Col>
            </Row>
          </Col>
          <Col className="support-adverts" lg={4}>
            <OtherPagesSlider />
            {/* <img src="/resources/images/payxpress.jpg" alt="advert" /> */}
          </Col>
        </Row>
      </div>
    </ThemeProvider>
  );
}

// export default Support;
