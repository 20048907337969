import React, { useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import CTA from "../../components/button/cta-button";
import "../../index.css";
import { ModalContext } from "../../context/modal-context";
import PrivacyPolicyModal from "../../components/modal/privacy-policy-modal";
import CookiesPolicyModal from "../../components/modal/cookies-policy-modal";

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "fixed",
    width: 500,
    border: "none",
    outline: "none",
    padding: theme.spacing(2, 4, 3),
    background: "none",
    "&::webkitScrollBar": {
      background: "red",
    },
    [theme.breakpoints.down("sm")]: {
      // background:'green',
      position: "absolute",
      width: 320,
      left: "0px",
      top: "10%",
    },
    [theme.breakpoints.up("md")]: {
      // backgroundColor: 'blue',
    },
    [theme.breakpoints.up("lg")]: {},
  },
  modal: {
    // background: "rgba(32, 28, 27, 0.8)",
    // background: "#fff",
    background: "rgba(255,255,255,0.95)",
    height: "100%",
  },
  button: {
    marginLeft: "90%",
    marginTop: "10%",
    background: "none",
    "&:hover": {
      background: "none",
    },
    "&:focus": {
      outline: "none",
      background: "none",
    },
  },
  policyButton: {
    background: "yellow",
    border: "0",
    borderRadius: "3px",
    boxShadow: "none",
    color: "#000",
    cursor: "pointer",
    flex: "0 0 auto",
    padding: "5px 10px",
    width: "120px",
    height: "40px",
    fontWeight: "bold",
    position: "absolute",
    left: "75%",
    top: "15px",
    "&:focus": {
      outline: "none",
    },
  },
}));

export default function PolicyModal() {
  const classes = useStyles();
  const [openCookies, setOpenCookies] = React.useState(false);
  const [openPrivacy, setOpenPrivacy] = React.useState(false);

  const handleOpen = () => {
    setOpenPrivacy(false);
    setOpenCookies(true);
  };

  function handleClose() {
    setOpenCookies(false);
    setOpenPrivacy(false);
  }

  const handleCookies = () => {
    setOpenCookies(true);
  };

  const handlePrivacy = () => {
    setOpenPrivacy(true);
  };

  return (
    <div>
      {/* <CTA nav text="Policy" orange style={{}} action={handleOpen} /> */}
      <button onClick={handleOpen} className="policyButton">
        Find Out More
      </button>
      <Modal
        open={openCookies}
        // onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className={classes.modal}
      >
        {openPrivacy ? (
          <PrivacyPolicyModal
            handleClose={handleClose}
            handleCookies={handleCookies}
          />
        ) : (
          <CookiesPolicyModal
            handleClose={handleClose}
            handlePrivacy={handlePrivacy}
          />
        )}
      </Modal>
    </div>
  );
}
