import React, { createContext, useReducer } from "react";
import { alertConstants, tellAFriendConstant } from "../_constants";
import { tellAFriendreducer } from "../_reducers/tell-a-friend.reducer";
import { tellAFriendAction } from "../_actions/tell-a-friend.actions";


const initialState = {
  loading: false,
};

export const TellAFriendContext = createContext(initialState);

export const TellAFriendContextProvider = ({ children }) => {

  const [state, tellAFriendDispatch] = useReducer(tellAFriendreducer, initialState);

  const tellAFriend = (values, dispatch) => {
    tellAFriendDispatch({type: tellAFriendConstant.REQUEST});
    tellAFriendAction(values).then(data => {
      var msg = 'Your friend has been notified';
    tellAFriendDispatch({type: tellAFriendConstant.RESPONSE, message: msg});
    dispatch({type: alertConstants.SUCCESS, message: msg});
    },error => {
      tellAFriendDispatch({type: tellAFriendConstant.RESPONSE, message: error});
      dispatch({type: alertConstants.ERROR, message: error.toString()});
    });
  }
  return (
    <TellAFriendContext.Provider 
      value={{
        loading: state.loading,
        tellAFriend,
        tellAFriendDispatch
      }}
    >
      {children}
    </TellAFriendContext.Provider>
  );
};
