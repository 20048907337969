import React, { useState, useContext } from "react";
import {
  Grid,
  TextField,
  Paper,
  ThemeProvider,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputAdornment from "@material-ui/core/InputAdornment";
import { AccountBox, Money, Email, Phone, ListAlt } from "@material-ui/icons";
import { theme } from "../../theme";
import { useHistory } from "react-router-dom";
import CTA from "../../components/button/cta-button";
import * as Yup from "yup";
import { useFormik } from "formik";
import { logTransaction, fetchShowtimes } from "../../_actions/movie.actions";
import { getTotalAmount } from "../../_actions/event.actions";
import { FaArrowLeft } from "react-icons/fa";
import { AlertContext } from "../../context/alert-context";
import { alertConstants, productConstants } from "../../_constants";
import { ProductContext } from "../../context/product-context";
import { useEffect } from "react";
import { validatePhone } from "../../helpers";
import MovieTicketSummary from "./movie-ticket-summary";
import GateWay from "../../components/gate-way";

const useStyles = makeStyles((theme) => ({
  root: {
    borderBottom: "1px solid red",
    borderRadius: 3,
    color: "white",
    height: 48,
    padding: "0 30px",
    flexGrow: 1,
  },
  searchField: {
    width: "400px",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  paperForm: {
    marginTop: "20px",
    padding: "40px",
    height: "410px",
    overflowY: "auto",
    boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.3)",
    "&::-webkit-scrollbar": {
      width: "10px",
      borderRadius: "10px",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "green",
      borderRadius: "10px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "20px",
      marginLeft: "-10px",
    },
  },
  image: {
    width: 90,
    height: 90,
    marginTop: "5px",
    boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.3)",
    [theme.breakpoints.down("sm")]: {
      width: 90,
      height: 90,
    },
  },
  formTitle: {
    fontFamily: "Montserrat",
    fontWeight: "bold",
    fontSize: "25px",
    marginTop: "50px",
    marginLeft: "-30px",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "-30px",
      fontSize: "20px",
      fontWeight: "bold",
      marginTop: "40px",
    },
    [theme.breakpoints.down("xs")]: {
      marginLeft: "-20px",
      fontSize: "20px",
      fontWeight: "bold",
      marginTop: "40px",
    },
  },
  img: {
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
  },
  inputPadding: {
    paddingLeft: "40px",
    marginTop: "0px",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "0px",
    },
  },
  button: {
    display: "block",
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  formButtons: {
    marginTop: "20px",
    paddingLeft: "20px",
    marginLeft: "200px",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      marginLeft: "0px",
    },
  },
  productName: {
    fontSize: "25px",
    fontWeight: "bold",
    marginTop: "40px",
    marginLeft: "-80px",
    fontFamily: "Montserrat",
    textTransform: "uppercase",

    [theme.breakpoints.down("sm")]: {
      marginLeft: "-50px",
      fontSize: "15px",
    },
  },
  summaryFontBold: {
    fontWeight: "600",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      fontWeight: "bold",
    },
  },
  buttonDiv: {
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "5px",
    width: "250px",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

Yup.addMethod(Yup.string, "phone", function (msg) {
  return validatePhone(this, msg);
});
const validationSchema = Yup.object().shape({
  phone: Yup.string("Enter Your Phone Number")
    .phone("Enter a Valid Number")
    .typeError("Enter Your Phone Number")
    .required("Phone Number is Required"),
  email: Yup.string("Enter Your Email")
    .email("Enter a Valid Email")
    .typeError("Enter Your Email")
    .required("Email is Required"),
  fullName: Yup.string("Enter Your Full Name")
    .typeError("Enter Your Full Name")
    .min(5, "Your Full Name must be at least 5 Characters long")
    .required("Full Name is Required"),
  cinema: Yup.string("Select cinema")
    .typeError("Select cinema")
    .required("Select cinema"),
  day: Yup.string("Select day").typeError("Select day").required("Select day"),
  showtimeid: Yup.string("Select date and time")
    .typeError("Select date and time")
    .required("Select date and time"),
  amount: Yup.number("Enter valid amount")
    .typeError("Enter valid amount")
    .required("Amount is Required"),
  adult: Yup.number("Enter valid number")
    .min(1, "value must be at least 1")
    .required("Numbr of adult is required"),
});
const MovieTicketForm = (props) => {
  const { dispatch } = useContext(AlertContext);
  const { products } = useContext(ProductContext);
  const {
    user,
    loggedIn,
    movie,
    handleGoBack,
    handleStatus,
    formStatus,
  } = props;
  const [cinemas, setCinemas] = React.useState("--Select--");

  const [state, setState] = useState({
    amount: "",
    TranId: "",
    showTimes: [],
    gateWay: "PayXpress",
    days: [],
    allShowTimes: [],
    movie: movie,
    values: "",
    billerCode: "",
    formStatus: formStatus,
    parameters: {},
    products: products,
  });

  useEffect(() => {
    const pathUrl = decodeURI(window.location.pathname);
    const billerCode = decodeURI(pathUrl.split("/")[2]);
    if (movie) {
      _values = {
        cinema: "",
        adult: 1,
        children: 0,
        student: 0,
        fullName: loggedIn ? user.Firstname + " " + user.Surname : "",
        email: loggedIn ? user.ConsumerID : "",
        phone: loggedIn ? user.PhoneNumber : "",
      };

      setState({
        ...state,
        values: _values,
        billerCode: billerCode,
      });
    }
  }, [movie]);

  const history = useHistory();

  const classes = useStyles();

  const handleProceed = () => {
    history.push("/tickets/movie/order-summary");
  };

  let _values = {};

  const getShowDays = async (cinemaId) => {
    fetchShowtimes(movie.id, cinemaId).then(
      (response) => {
        let _days = response.days ? response.days : [];
        let _showTimes = response.showtimes ? response.showtimes : [];
        const _values = {
          ...state.values,
          cinema: cinemaId,
        };
        setState({
          ...state,
          values: _values,
          days: _days,
          allShowTimes: _showTimes,
        });
      },
      (error) => {
        dispatch({
          type: alertConstants.ERROR,
          message: error.toString(),
        });
      }
    );
  };

  const getShowTimes = async (_day) => {
    const { allShowTimes } = state;

    if (allShowTimes && allShowTimes.length > 0) {
      let _showTimes = allShowTimes.filter((s) => s.day === _day);
      const showTime = allShowTimes.filter((s) => s.day === _day)[0];
      if (showTime && _showTimes) {
        setState({
          ...state,
          showTimes: _showTimes,
          showtimeid: "",
          values: {
            ...state.values,
            day: _day,
            showtimeid: showTime.id,
            adultPrice: showTime.adult,
            studentPrice: showTime.student,
            childrenPrice: showTime.children,
          },
        });
      }
    }
  };

  const onCinemaChange = async (name, e) => {
    e.target.name = name;
    setFieldValue(name, e.target.value);
    handleChange(name, e.target.value);
    setFieldTouched(name, true, false);
    getShowDays(e.target.value);
  };

  const onDayChange = async (name, e) => {
    e.target.name = name;
    setFieldValue(name, e.target.value);
    handleChange(name, e.target.value);
    setFieldTouched(name, true, false);
    getShowTimes(e.target.value);
  };

  const onQtyChange = async (name, e) => {
    e.target.name = name;
    let _values = state.values;
    _values = {
      ..._values,
      [name]: e.target.value,
    };

    setState(
      {
        ...state,
        values: _values,
      },
      getAmt(_values)
    );
    setFieldValue(name, e.target.value);
    handleChange(name, e.target.value);
    setFieldTouched(name, true, false);
  };

  const handleChange1 = (name, value) => {
    let _values = state.values;
    _values = {
      ..._values,
      [name]: value,
    };
    setState({
      ...state,
      values: _values,
    });
    getAmt();
  };

  const onShowTimeChange = (name, e) => {
    e.target.name = name;
    setFieldValue(name, e.target.value);
    handleChange(name, e.target.value);
    setFieldTouched(name, true, false);

    const adult = e._targetInst.pendingProps["data-adult"];
    const student = e._targetInst.pendingProps["data-student"];
    const children = e._targetInst.pendingProps["data-children"];
    setFieldValue("adultPrice", adult);
    setFieldValue("studentPrice", student);
    setFieldValue("childrenPrice", children);

    handleChange("adultPrice", adult);
    handleChange("studentPrice", student);
    handleChange("childrenPrice", children);

    getAmt();
  };

  const getAmt = (_values = null) => {
    const { values, billerCode } = state;

    const _val = _values ? _values : values;

    let _amount = 0;
    if (_val.adultPrice && _val.adult) {
      _amount += parseFloat(values.adultPrice) * parseFloat(_val.adult);
    }
    if (_val.studentPrice && _val.student) {
      _amount += parseFloat(_val.studentPrice) * parseFloat(_val.student);
    }
    if (_val.childrenPrice && _val.children) {
      _amount += parseFloat(_val.childrenPrice) * parseFloat(_val.children);
    }
    setState({
      ...state,
      values: {
        ...values,
        ..._val,
        amount: "",
      },
    });
    //Get Amount
    if (_amount > 0) {
      setState({
        ...state,
        loading: true,
      });

      let ProductId = 0;
      const product = products.find((p) => p.BillerCode === billerCode);
      if (product) ProductId = product.Id;
      let _req = {
        TotalAmount: _amount,
        ProductId: ProductId,
      };

      getTotalAmount(_req).then(
        (response) => {
          if (response && !isNaN(response)) {
            _amount = response;
          }
          setState({
            loading: false,
            ...state,
            values: {
              ..._val,
              amount: _amount,
            },
          });
        },
        (error) => {}
      );
    }
  };

  const change = (name, e) => {
    e.persist();
    handleChange(e);
    setFieldTouched(name, true, false);
  };

  const handleProdceed = () => {
    const { tranId, gateWay, billerCode, values } = state;

    var _req = {
      TranId: tranId,
      PaymentMode: gateWay,
      BillerCode: billerCode,
      ...values,
    };
    logTransaction(_req).then(
      (response) => {
        setState({
          ...state,
          parameters: response,
          formStatus: productConstants.FORM_STATUS_CONFIRMED,
        });
      },
      (error) => {}
    );
  };

  const {
    handleSubmit,
    handleChange,
    setFieldValue,
    setFieldTouched,
    dirty,
    touched,
    isValid,
    isSubmitting,
    resetForm,
    setSubmitting,
    values,
    errors,
    amountRef,
  } = useFormik({
    initialValues: {
      ...state.values,
    },
    enableReinitialize: true,
    // validationSchema: validationSchema,
    onSubmit(values) {
      setState({
        ...state,
        values: values,
        formStatus: productConstants.FORM_STATUS_CONFIRMING,
      });
      // handleStatus(values);
    },
  });

  return (
    <>
      {movie && state.formStatus === productConstants.FORM_STATUS_INPUTTING && (
        <div style={{ padding: 20 }}>
          <ThemeProvider theme={theme}>
            <Grid container spacing={1}>
              <Grid item xs={6} sm={3} md={2}>
                <Paper className={(classes.paper, classes.image)}>
                  <img
                    className={classes.img}
                    alt={movie.title}
                    src={movie.artworkThumbnail}
                  />
                </Paper>
              </Grid>
              <Grid item xs={6} sm={9} md={10}>
                <Typography
                  variant="h4"
                  component="h2"
                  className={classes.productName}
                >
                  {movie.title}
                </Typography>
              </Grid>
            </Grid>
            <Grid>
              <Paper className={classes.paperForm}>
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={3}>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      className={classes.inputPadding}
                    >
                      <TextField
                        label="Full name"
                        style={{ margin: 8 }}
                        placeholder="Enter your full name"
                        fullWidth
                        id="fullName"
                        name="fullName"
                        margin="normal"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={(e) => change("fullName", e)}
                        // onChange={change.bind(null, "fullName")}
                        value={values.fullName}
                        helperText={touched.fullName ? errors.fullName : ""}
                        error={touched.fullName && Boolean(errors.fullName)}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      className={classes.inputPadding}
                    >
                      <TextField
                        label="Email Address"
                        style={{ margin: 8 }}
                        placeholder="example@u.com"
                        fullWidth
                        margin="normal"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        id="email"
                        name="email"
                        helperText={touched.email ? errors.email : ""}
                        error={touched.email && Boolean(errors.email)}
                        onChange={change.bind(null, "email")}
                        value={values.email}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      className={classes.inputPadding}
                    >
                      <TextField
                        label="Mobile Number"
                        style={{ margin: 8 }}
                        placeholder="enter your mobile number"
                        fullWidth
                        id="phone"
                        name="phone"
                        helperText={touched.phone ? errors.phone : ""}
                        error={touched.phone && Boolean(errors.phone)}
                        onChange={change.bind(null, "phone")}
                        margin="normal"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        value={values.phone}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      className={classes.inputPadding}
                    >
                      <TextField
                        id="cinema"
                        select
                        required
                        fullWidth
                        className={classes.textField}
                        value={values.cinema}
                        name="cinema"
                        label="Cinema"
                        helperText={touched.cinema ? errors.cinema : ""}
                        error={touched.cinema && Boolean(errors.cinema)}
                        onChange={(e) => onCinemaChange("cinema", e)}
                        SelectProps={{
                          displayEmpty: true,
                          MenuProps: {
                            classes: { paper: classes.dropdownStyle },
                          },
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <ListAlt />
                            </InputAdornment>
                          ),
                        }}
                        margin="normal"
                      >
                        <MenuItem value="0">--select--</MenuItem>
                        {movie.cinemas.map((option) => (
                          <MenuItem key={option.uid} value={option.uid}>
                            {option.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      className={classes.inputPadding}
                    >
                      <TextField
                        id="day"
                        select
                        required
                        className={classes.textField}
                        fullWidth
                        value={values.day}
                        name="day"
                        label="Day"
                        helperText={touched.day ? errors.day : ""}
                        error={touched.day && Boolean(errors.day)}
                        onChange={(e) => onDayChange("day", e)}
                        SelectProps={{
                          displayEmpty: true,
                          MenuProps: {
                            classes: { paper: classes.dropdownStyle },
                          },
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <ListAlt />
                            </InputAdornment>
                          ),
                        }}
                        margin="normal"
                      >
                        <option key="0" value="">
                          --select--
                        </option>
                        {state.days.map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      className={classes.inputPadding}
                    >
                      <TextField
                        id="showtimeid"
                        select
                        required
                        className={classes.textField}
                        value={values.showtimeid}
                        name="showtimeid"
                        fullWidth
                        label="Date and Time"
                        helperText={touched.showtimeid ? errors.showtimeid : ""}
                        error={touched.showtimeid && Boolean(errors.showtimeid)}
                        onChange={(e) => onShowTimeChange("showtimeid", e)}
                        SelectProps={{
                          displayEmpty: true,
                          MenuProps: {
                            classes: { paper: classes.dropdownStyle },
                          },
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <ListAlt />
                            </InputAdornment>
                          ),
                        }}
                        margin="normal"
                      >
                        <MenuItem value="">--select--</MenuItem>
                        {state.showTimes.map((option) => (
                          <MenuItem
                            key={option.id}
                            value={option.id}
                            data-adult={option.adult}
                            data-student={option.student}
                            data-children={option.children}
                          >
                            {option.date}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      className={classes.inputPadding}
                    >
                      {/* <TextField
                    id="standard-full-width"
                    label="Number of Adults"
                    style={{ margin: 8 }}
                    placeholder="enter no of adults"
                    fullWidth
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  /> */}
                      <TextField
                        autoComplete="off"
                        className={classes.textField}
                        value={values.adult}
                        type="number"
                        fullWidth
                        required
                        id="adult"
                        name="adult"
                        label="Number of Adults"
                        placeholder="Adult"
                        helperText={touched.adult ? errors.adult : ""}
                        error={touched.adult && Boolean(errors.adult)}
                        onChange={(e) => onQtyChange("adult", e)}
                        margin="normal"
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      className={classes.inputPadding}
                    >
                      <TextField
                        autoComplete="off"
                        className={classes.textField}
                        value={values.children}
                        type="number"
                        fullWidth
                        id="children"
                        name="children"
                        label="Number of Children"
                        placeholder="Children"
                        helperText={touched.children ? errors.children : ""}
                        error={touched.children && Boolean(errors.children)}
                        onChange={(e) => onQtyChange("children", e)}
                        margin="normal"
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      className={classes.inputPadding}
                    >
                      <TextField
                        autoComplete="off"
                        className={classes.textField}
                        value={values.student}
                        type="number"
                        id="student"
                        fullWidth
                        name="student"
                        label="Number of Students"
                        placeholder="Students"
                        helperText={touched.student ? errors.student : ""}
                        error={touched.student && Boolean(errors.student)}
                        onChange={(e) => onQtyChange("student", e)}
                        margin="normal"
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      className={classes.inputPadding}
                    >
                      <TextField
                        className={classes.textField}
                        required
                        disabled
                        label="Total Amount"
                        value={values.amount}
                        id="amount"
                        name="amount"
                        fullWidth
                        helperText={touched.amount ? errors.amount : ""}
                        error={touched.amount && Boolean(errors.amount)}
                        onChange={(e) => change("amount", e)}
                        margin="normal"
                        autoComplete="off"
                        InputProps={{
                          // inputComponent: NumberFormatCustom,
                          startAdornment: (
                            <InputAdornment position="start">
                              <Money />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <div className={classes.buttonDiv}>
                        <CTA
                          noBackground
                          type="button"
                          text="Back"
                          icon={<FaArrowLeft />}
                          action={(e) => {
                            e.preventDefault();
                            handleGoBack();
                          }}
                        />
                        <CTA
                          medium
                          text="proceed"
                          type="submit"
                          // action={handleProceed}
                        />
                      </div>
                    </Grid>
                  </Grid>
                </form>
              </Paper>
            </Grid>
          </ThemeProvider>
        </div>
      )}
      {movie &&
        state.formStatus === productConstants.FORM_STATUS_CONFIRMING && (
          <MovieTicketSummary {...state} handleProdceed={handleProdceed} />
        )}

      {state.formStatus == productConstants.FORM_STATUS_CONFIRMED && (
        <>
          <GateWay classes parameters={state.parameters} />
        </>
      )}
    </>
  );
};

export default MovieTicketForm;
