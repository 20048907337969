import React, { useState, useEffect, useContext } from "react";
import styles from "./home.module.css";
import DesignBtn from "../../components/design-btn/design-btn";
import Carousel from "../../components/carousel/carousel";
import Input from "../../components/inputs/input";
import { FaSearch } from "react-icons/fa";
import BorderlessInput from "../../components/inputs/input";
import { ProductContext } from "../../context/product-context";
import { AlertContext } from "../../context/alert-context";
import { Link } from "react-router-dom";
import { UserContext } from "../../context/user-context";
import { useHistory, useRouteMatch } from "react-router-dom";
import { alertConstants } from "../../_constants";

const Home = () => {
  const [searchTerm, setSearchTerm] = React.useState("");
  const [searchResults, setSearchResults] = React.useState([]);
  const [showContent, setShowContent] = React.useState(false);
  const {
    filteredPproducts,
    searchBillers,
    clearSearchResult,
    productDispatch,
  } = useContext(ProductContext);
  const { dispatch } = useContext(AlertContext);
  const { loggedIn, userDispatch } = useContext(UserContext);
  const [index, setIndex] = useState(0);
  const history = useHistory();

  useEffect(() => {
    setSearchResults(filteredPproducts);
  }, [filteredPproducts]);

  const handleChange = (event) => {
    searchBillers(event.target.value);
  };

  const handleProduct = (
    UrlPath,
    CategoryCode,
    BillerCode,
    Accessibility,
    ...product
  ) => {
    clearSearchResult();
    if (Accessibility === "authenticated" && loggedIn === false) {
      dispatch({
        type: alertConstants.ERROR,
        message: "Please kindly login or register to continue",
      });
      return history.push(`/`);
    } else if (
      product.ExternalLink &&
      product.ProductType === "external-link" &&
      product.LinkType === "_blank"
    ) {
      handleRedirect(
        `${product.BillerCode}`,
        `${product.ExternalLink}`,
        `${product.LinkType}`
      );
    } else {
      history.push(`/${UrlPath}/${BillerCode}`);
    }
  };

  const handleRedirect = (product, url, _type) => {
    window.open(url, "_blank");
  };
  return (
    <div>
      <div>
        <BorderlessInput
          placeholder="Search for billers e.g mtn"
          onChange={handleChange}
          style={{
            position: "absolute",
            marginTop: "15%",
            marginLeft: "25%",
            zIndex: 10,
          }}
        />
        { !!searchResults &&
            searchResults.length > 0 && <>
        <ul className="searchResults">
          {
            searchResults.map(
              ({
                ProductName,
                UrlPath,
                CategoryCode,
                BillerCode,
                Accessibility,
                product,
              }) => (
                <>
                  <Link
                    onClick={() =>
                      handleProduct(
                        UrlPath,
                        CategoryCode,
                        BillerCode,
                        Accessibility,
                        product
                      )
                    }
                    style={{ textDecoration: "none", color: "#000" }}
                  >
                    <li>{ProductName}</li>
                  </Link>
                </>
              )
            )}
        </ul>
        </>
        }
      </div>

      <Carousel activeIndex={index} />
    </div>
  );
};

export default Home;
