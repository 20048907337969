import React, { createContext, useReducer } from "react";
import { transactionReducer } from "../_reducers/transaction.reducer";
import { fetchTransactionHistory, fetchTransactionHistorySearch } from "../_actions/product.actions";
import { transactionConstants } from "../_constants";

let userDetails = JSON.parse(localStorage.getItem('user'));

const initialState = {
  user: !!userDetails ? userDetails : {},
  loading: true,
  transactions: [],


};

export const TranHistoryContext = createContext(initialState);

export const TranHistoryProvider = ({ children }) => {

  const [state, historyDispatch] = useReducer(transactionReducer, initialState);

  const getTransactionHistrory = () => {
    // try {
    fetchTransactionHistory({ type: transactionConstants.TRANSACTION_HISTORY_REQUEST })
    fetchTransactionHistory().then(resp => {
      historyDispatch({ type: transactionConstants.FETCH_TRANSACTION_HISTORY, payload: resp })
    }, error => {
      historyDispatch({ type: transactionConstants.FETCH_TRANSACTION_HISTORY, payload: [] })
    });
  };

  const getTransactionHistrorySearch = (req) => {
    historyDispatch({
      type: transactionConstants.TRANSACTION_HISTORY_SEARCH_REQUEST
    })
    fetchTransactionHistorySearch(req).then(resp => {
      historyDispatch({ type: transactionConstants.FETCH_TRANSACTION_HISTORY_SEARCH, payload: resp })
    }, error => {
      historyDispatch({
        type: transactionConstants.TRANSACTION_HISTORY_SEARCH_REQUEST
      })
      historyDispatch({ type: transactionConstants.FETCH_TRANSACTION_HISTORY_SEARCH, payload: [] })
    });
  };

  return (<TranHistoryContext.Provider value={
    {
      user: state.user,
      getTransactionHistrory,
      getTransactionHistrorySearch,
      historyDispatch,
      transactions: state.transactions,
      loading: state.loading,
    }
  }>
    {
      children
    } </TranHistoryContext.Provider>
  )
};