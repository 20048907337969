import React, { useContext } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Layout from "../src/pages/layout/layout";
import { ProductProvider } from "./context/product-context";
import { UserProvider } from "./context/user-context";
import { AlertProvider } from "./context/alert-context";
import { ModalProvider } from "./context/modal-context";
import PolicyModal from "./pages/Policy/policy-modal";
import CookieConsent, { Cookies } from "react-cookie-consent";
import { theme } from "./theme";
import { ThemeProvider } from "@material-ui/core";
function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <ProductProvider>
          <UserProvider>
            <ModalProvider>
              <AlertProvider>
                <Router>
                  <Route path="/*" render={(props) => <Layout {...props} />} />
                </Router>
              </AlertProvider>
            </ModalProvider>
          </UserProvider>
        </ProductProvider>
      </ThemeProvider>
      <CookieConsent
        location="bottom"
        onDecline={() => {
        }}
        debug={false}
        declineButtonText="Find out more"
        buttonText="Accept"
        cookieName="_pa_k"
        overlay
        style={{
          background: "#2B373B",
          fontFamily: "century gothic",
          fontSize: "13px",
          width: "100%",
        }}
        onAccept={() => {
          Cookies.set("_pa_k", "Payxpress")
        }}
        overlayStyle={{
          position: "fixed",
          left: 0,
          top: 0,
          width: "100%",
          height: "100%",
          zIndex: "1300",
          backgroundColor: "rgba(0,0,0,0.3)",
        }}
        buttonClasses="CookieContent-btn"
        contentClasses="cookie-consent"
        disableButtonStyles={true}
        expires={150}
      >
        <p
        // style={{ width: "80%" }}
        >
          By using our website(by clicking, navigating, or scrolling), you are
          consenting to our use of cookies in accordance with this Policy.If you
          do not agree to our use of cookies, you can prevent the installation
          of cookies through the settings of your browser or not use our website
          at all.
        </p>

        <PolicyModal />
      </CookieConsent>
    </div>
  );
}

export default App;
