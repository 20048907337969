import React, { useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { MdMap, MdInfo } from "react-icons/md";
import { alertConstants } from '../../_constants';
import {
  Grid,
  Paper,
  Typography,
  Link,
  Card,
  CardContent,
  CardActionArea,
  ThemeProvider,
  TextField,
  CardActions,
  Button,
  CardMedia,
} from "@material-ui/core";
import CTA from "../../components/button/cta-button";
// import EventDetails from "./event-details";
import { theme } from "../../theme";
import { useHistory } from "react-router";
import Modal from "@material-ui/core/Modal";
import { fetchEvents } from "../../_actions/event.actions";
import { AlertContext } from "../../context/alert-context";
import { UserContext } from "../../context/user-context";
import Spinner from "../../components/spinner";
import EventTicketForm from "./event-ticket-form";
import { ProductContext } from "../../context/product-context";
import { productConstants } from "../../_constants";
import { getMonthName, truncate } from "../../helpers";

const useStyles = makeStyles((theme) => ({
  containerDiv: {
    marginTop: "-20px",
  },
  paper: {
    position: "fixed",
    transform: "translate(50%,10%)",
    width: 700,
    height: "80vh",
    overflowY: "auto",
    border: "none",
    outline: "none",
    padding: theme.spacing(2, 4, 3),
    background: "#fff",
    [theme.breakpoints.down("sm")]: {
      // background:'green',
      position: "absolute",
      width: 380,
      left: "-48%",
      top: "2%",
    },
    [theme.breakpoints.down("xs")]: {
      width: 310,
      left: "-51%",
      top: "2%",
    },
    [theme.breakpoints.up("md")]: {
      // backgroundColor: 'blue',
    },
    [theme.breakpoints.up("lg")]: {
      // marginTop:'100%'
    },
    // marginTop:'-5%'
  },

  image: {
    width: 90,
    height: 90,
    marginTop: "5px",
    boxShadow: "0 7px 30px -10px rgba(150,170,180,0.5)",
    [theme.breakpoints.down("sm")]: {
      width: 90,
      height: 90,
    },
  },
  img: {
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
  },
  productName: {
    fontSize: "25px",
    fontWeight: "bold",
    marginTop: "40px",
    marginLeft: "-80px",
    fontFamily: "Montserrat",
    textTransform: "uppercase",
  },
  card: {
    borderRadius: "5px 5px 0px 0px",
    // boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.3)",
    boxShadow: "0 7px 30px -10px rgba(150,170,180,0.5)",
    height: "380px",
    maxHeight: "400px",
  },
  cardImg: {
    height: "230px",
    borderRadius: "5px 5px 0px 0px",
  },
  title: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "15px",
    color: "#FF6C00",
    textTransform: "uppercase",
  },
  typography: {
    fontSize: "11px",
    fontFamily: "Montserrat",

    // color: "rgba(0,0,0,0.6)",
  },
  bookTicketBtn: {
    width: "100%",
    background: "#FF6C00",
    margin: "0px",
    color: "#fff",
    height: "20px",
    fontFamily: "Montserrat",
    borderRadius: "0px",
    textTransform: "uppercase",
    transition: "all 0.35s ease-in-out",
    "&:hover": {
      background: "#fff",
      color: "#000",
    },
    "&:focus": {
      outline: "none",
    },
  },
  Img: {
    height: "400px",
    borderRadius: "5px 5px 0px 0px",

    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "250px",
    },
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "red !important",
  },
  paper2: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  paper3: {
    width: 700,
  },
  eventModal: {
    width: "700px",
    background: "none",

    position: "absolute",
    [theme.breakpoints.down("sm")]: {
      // background:'green',
      position: "absolute",
      width: "290px",
      left: "20%",
      top: "20%",
      zIndex: 100,
    },
  },
  eventTitle: {
    textAlign: "center",
    textTransform: "uppercase",
    fontFamily: "Montserrat",

    [theme.breakpoints.down("sm")]: {
      fontSize: "13px",
    },
  },
  events: {
    marginTop: "10px",
    maxHeight: "430px",
    width: "100%",
    overflowY: "auto",
    overflowX: "hidden",

    "&::-webkit-scrollbar": {
      width: "0.5em",
      borderRadius: "3px",
      background: "rgba(255,255,255,0.6)",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "green",
      borderRadius: "5px",
    },
  },
  eventDescription: {
    height: "350px",
    overflowY: "auto",
    fontFamily: "century gothic",
    fontSize: "12px",

    "&::-webkit-scrollbar": {
      width: "0.5em",
      borderRadius: "3px",
      background: "rgba(255,255,255,0.2)",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
      borderRadius: "3px",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "green",
      borderRadius: "5px",
    },
  },
}));

export default function Events() {
  const classes = useStyles();
  const history = useHistory();
  const [open, setOpen] = React.useState(false);
  const { dispatch } = useContext(AlertContext);
  const { loggedIn, user } = useContext(UserContext);
  const { products } = useContext(ProductContext);
  const [state, setState] = React.useState({
    events: [],
    event: {},
    showView: "EVENT_LIST",
    loading: true,
    products: products,
    formStatus: productConstants.FORM_STATUS_INPUTTING,
  });
  const handleProceed = () => {
    history.push(`/tickets/events/book-ticket`);
  };
  const handleOpen = (event) => {
    setState({
      ...state,
      event: event,
    });
    setOpen(true);
  };

  const hanadleEventForm = (event) => {
    setState({
      ...state,
      event: event,
      user: user,
      loggedIn: loggedIn,
      showView: "EVENT_FORM",
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleGoBack = () => {
    setState({
      ...state,
      showView: "EVENT_LIST",
      loading: false,
    });
  };
  const handleStatus = (formvalues) => {};

  useEffect(() => {
    setState({
      ...state,
      products: products,
    });
  }, [products]);

  useEffect(() => {
    fetchEvents().then((data) => {
      setState(
        {
          ...state,
          events: data,
          loading: false,
        },
        (error) => {
          setState({
            ...state,
            loading: false,
          });
          dispatch({
            type: alertConstants.ERROR,
            message: error.toString(),
          });
        }
      );
    });
  }, [fetchEvents]);

  return (
    <>
      <div className={classes.containerDiv} style={{ padding: 20 }}>
        <ThemeProvider theme={theme}>
          {state.loading ||
            (products.length === 0 && (
              <Spinner message="Loading...please wait" />
            ))}
          {state.showView == "EVENT_LIST" && (
            <>
              <Grid container spacing={1}>
                <Grid item xs={6} sm={3} md={2}>
                  <Paper className={(classes.paper, classes.image)}>
                    <img
                      className={classes.img}
                      alt="events"
                      src="/resources/images/events.png"
                    />
                  </Paper>
                </Grid>
                <Grid item xs={6} sm={9} md={10}>
                  <Typography
                    variant="h4"
                    component="h2"
                    className={classes.productName}
                  >
                    Events
                  </Typography>
                </Grid>
              </Grid>
              {/* { id, title, image, url, description, location, date, venue } */}
              <div className={classes.events}>
                <Grid container spacing={3}>
                  {state.events.map((props) => {
                    return (
                      <>
                        <Grid item md={3} sm={6} xs={12}>
                          <Card className={classes.card}>
                            <img
                              src={props.eventArtwork}
                              alt=""
                              className={classes.cardImg}
                            />
                            <CardContent>
                              <h6 className={classes.title}>
                                {truncate(`${props.title}`, 27, "...")}
                              </h6>
                              <Typography
                                className={classes.typography}
                                style={{ height: "30px", maxHeight: "30px" }}
                              >
                                <MdMap />{" "}
                                {truncate(
                                  `${props.ticketClassses[0].venue}`,
                                  62,
                                  "..."
                                )}
                              </Typography>
                              <Typography className={classes.info}>
                                <MdInfo />{" "}
                                <CTA
                                  noBackground
                                  text="event info"
                                  action={() => handleOpen(props)}
                                  style={{
                                    fontSize: "11px",
                                    textTransform: "capitalize",
                                    fontWeight: "normal",
                                    textDecoration: "underline",
                                    marginLeft: "-10px",
                                    marginBottom: "0px",
                                  }}
                                />
                              </Typography>
                            </CardContent>
                            <CardActionArea>
                              <Button
                                className={classes.bookTicketBtn}
                                onClick={() => hanadleEventForm(props)}
                              >
                                Book Ticket
                              </Button>
                            </CardActionArea>
                          </Card>
                        </Grid>
                      </>
                    );
                  })}
                  {open && (
                    <Modal
                      open={open}
                      onClose={handleOpen}
                      aria-labelledby="simple-modal-title"
                      aria-describedby="simple-modal-description"
                      className={classes.eventModal}
                      disableBackdropClick={true}
                      BackdropProps={{
                        style: { backgroundColor: "rgba(0,0,0,0.2)" },
                      }}
                    >
                      <div className={classes.paper}>
                        <h3 className={classes.eventTitle}>
                          {state.event.title}
                        </h3>
                        <Grid container spacing={1}>
                          <Grid item md={5} sm={12} xs={12}>
                            <img
                              src={state.event.eventArtwork}
                              alt=""
                              className={classes.Img}
                            />
                            <div className={classes.actions}>
                              <CTA
                                medium
                                green
                                action={() => hanadleEventForm(state.event)}
                                text="Buy Ticket"
                                style={{
                                  fontSize: "12px",
                                  marginTop: "10px",
                                }}
                              />{" "}
                              <CTA
                                noBackground
                                text="close"
                                action={handleClose}
                              />
                            </div>
                          </Grid>
                          <Grid item md={7} sm={12} xs={12}>
                            <div className={classes.eventDescription}>
                              <p>
                                <strong>Date: {state.event.date}</strong>
                              </p>
                              <p>
                                <strong>Venue: </strong>
                                {state.event.ticketClassses[0].venue}
                              </p>
                              <p>
                                {" "}
                                <strong> {state.event.title} </strong>
                                {state.event.description}
                              </p>
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </Modal>
                  )}
                </Grid>
              </div>
            </>
          )}
        </ThemeProvider>
      </div>
      {state.showView == "EVENT_FORM" && (
        <EventTicketForm
          handleStatus={handleStatus}
          handleGoBack={handleGoBack}
          {...state}
        />
      )}
    </>
  );
}
