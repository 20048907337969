import React from "react";
import styles from "./faq.module.css";
import { Row, Col } from "react-bootstrap";
import OtherPagesSlider from "../carousel/other-pages-carousel";

const Faq = () => {
  return (
    <div className={styles.faqContainer}>
      {/* <div className={styles.topDecor}></div> */}
      <h3 className="title">Frequently Asked Questions</h3>
      <Row>
        <Col className={styles.faqContent} lg={8}>
          <ul className={styles.faqs}>
            <li>
              {/* {questions.map(({ id, question, answer }) => ( */}
              <div
              // className={id}
              >
                <div className={styles.question}>
                  <h2>Q.</h2>
                  <p>What type of transactions can I carry out</p>
                </div>
                <div className={styles.answer}>
                  <h2>A.</h2>
                  <p>
                    PayXpress is a Value-Added Services (VAS) platform designed
                    to provide customers all but not limited to the following
                    services:{" "}
                    <ol>
                      <li>1. Pay Bills e.g. IKEDC, DSTV, GOTV, EKEDC etc.</li>
                      <li>2. Purchase Movie and Events tickets.</li>
                      <li>3. Single and bulk Purchase of Airtime and Data.</li>
                      <li>4. Fund transfers.</li>
                      <li>
                        5. Pay for collections and levies services e.g. Delta
                        State IGR, Oyo State IGR, Oyo State Security Trust Fund
                        Levies etc.
                      </li>
                    </ol>
                  </p>
                </div>
                <div className={styles.question}>
                  <h2>Q.</h2>
                  <p>What are the payment options accepted on the website?</p>
                </div>
                <div className={styles.answer}>
                  <h2>A.</h2>
                  <p>
                    The Payment Options available on PayXpress are:
                    <ol>
                      <li>
                        1. Debit Cards; Visa Cards, Master Cards and Verve
                        Cards.
                      </li>
                      <li>2. Bank Account</li>
                      <li>3. Bank Transfer</li>
                      <li>4. QR Code</li>
                      <li>5. USSD (Coming Soon)</li>
                      <li>6. Wallet (Coming Soon)</li>
                    </ol>
                  </p>
                </div>
                <div className={styles.question}>
                  <h2>Q.</h2>
                  <p>How can I start using the services on PayXpress</p>
                </div>
                <div className={styles.answer}>
                  <h2>A.</h2>
                  <p>
                    Unregistered users can only use the following
                    functionalities:{" "}
                    <ol>
                      <li>1. Buy Airtime</li>
                      <li>2.Buy Data</li>
                      <li>3. Pay Bills</li>
                    </ol>
                    To access the full functionalities of the platform, you
                    should register (see how to register below). After
                    registering, you can also:
                    <ol>
                      <li>1. View and download your transaction history</li>
                      <li>Carry out Bulk Airtime and Data purchases</li>
                      <li>Pay for Collections and Levies.</li>
                    </ol>
                  </p>
                </div>
                <div className={styles.question}>
                  <h2>Q.</h2>
                  <p>How do I register?</p>
                </div>
                <div className={styles.answer}>
                  <h2>A.</h2>
                  <p>
                    The steps to register are as follows:
                    <ol>
                      <li>
                        1. Click on “Sign Up” at the top right of the homepage
                        and fill the registration form as indicated
                      </li>
                      <li>
                        2. Successful registration initiates a mail to supplied
                        email address for profile verification.{" "}
                      </li>
                      <li>
                        3. Click on the activation link sent to the email
                        address profiled to activate profile.
                      </li>
                      <li>
                        4. Return to www.payxpress.com and login with your
                        userID (email address) and password.
                      </li>
                    </ol>
                  </p>
                </div>
                <div className={styles.question}>
                  <h2>Q.</h2>
                  <p>Will I be charged for registering on PayXpress?</p>
                </div>
                <div className={styles.answer}>
                  <h2>A.</h2>
                  <p>
                    <ol>
                      <li>
                        1. You can locate the services to pay for on the menu at
                        the left-hand side of the Homepage. Clicking on “More
                        Services” reveals services you can pay for once you are
                        a registered user.
                      </li>
                      <li>
                        2. Click on the search icon at the middle of the
                        homepage and enter desired service provider’s name in
                        the search box and search.
                      </li>
                    </ol>
                  </p>
                </div>

                <div className={styles.question}>
                  <h2>Q.</h2>
                  <p>
                    Who do I contact for services paid for and no receipt of
                    value?
                  </p>
                </div>
                <div className={styles.answer}>
                  <h2>A.</h2>
                  <p>
                    Contact customercare@xpresspayments.com or utilize the
                    online support chat at the bottom right side of the platform
                    to engage customer care personnel.
                  </p>
                </div>
                <div className={styles.question}>
                  <h2>Q.</h2>
                  <p>Is PayXpress safe and secure ?</p>
                </div>
                <div className={styles.answer}>
                  <h2>A.</h2>
                  <p>
                    PayXpress is very safe and secure. The website runs on a
                    Secure Socket Layer (SSL) and the platform is compliant with
                    international security standards.
                  </p>
                </div>
                <div className={styles.question}>
                  <h2>Q.</h2>
                  <p>
                    Can I get notifications when my transactions on web are
                    successful?
                  </p>
                </div>
                <div className={styles.answer}>
                  <h2>A.</h2>
                  <p>
                    Yes, notification E-mails are sent to the profiled email
                    address upon every transaction regardless of it was
                    Successful or Failed.
                  </p>
                </div>
                <div className={styles.question}>
                  <h2>Q.</h2>
                  <p>How do I view transaction history?</p>
                </div>
                <div className={styles.answer}>
                  <h2>A.</h2>
                  <p>
                    You can view your Transaction History following the steps
                    below:
                    <ol>
                      <li>
                        1. Log-in to the platform using your userID (email
                        address) and password
                      </li>
                      <li>
                        2. Click on your username which will be the last item on
                        the menu to the left of the Homepage.
                      </li>
                      <li>
                        3. Click on “View Transaction History” from the
                        drop-down menu to have an overview of transactions done.
                      </li>
                      <li>
                        4. You can now filter and view transactions by date and
                        reference number
                      </li>
                    </ol>
                  </p>
                </div>

                <div className={styles.question}>
                  <h2>Q.</h2>
                  <p>What happens if I cannot remember my password?</p>
                </div>
                <div className={styles.answer}>
                  <h2>A.</h2>
                  <p>
                    Click on “Forgot Password” at the bottom of the log-in page
                    and a link will be sent to your e-mail address. Click on
                    this link to profile a new password.
                  </p>
                </div>

                <div className={styles.question}>
                  <h2>Q.</h2>
                  <p>Can I update my profile ?</p>
                </div>
                <div className={styles.answer}>
                  <h2>A.</h2>
                  <p>
                    Yes, as a registered user, you can update your profile by
                    clicking on your username at the bottom of the menu,
                    navigating the drop-down menu and clicking on update
                    profile.
                  </p>
                </div>

                <div className={styles.question}>
                  <h2>Q.</h2>
                  <p>Can I change my password ?</p>
                </div>
                <div className={styles.answer}>
                  <h2>A.</h2>
                  <p>
                    Yes, as a registered user, you can change your password by
                    clicking on your username at the bottom of the menu,
                    navigating the drop-down menu and clicking on change
                    password.
                  </p>
                </div>

                <div className={styles.question}>
                  <h2>Q.</h2>
                  <p>
                    Who do I contact for support or more information about
                    PayXpress?
                  </p>
                </div>
                <div className={styles.answer}>
                  <h2>A.</h2>
                  <p>
                    {" "}
                    Contact Customer Care Centre:
                    <ol>
                      <li>
                        1. E-mail address: customercare@xpresspayments.com
                      </li>
                      <li>
                        2. Telephone numbers
                        <ol>
                          <li> +23416312430</li>
                          <li>+2348188866022</li>
                        </ol>
                      </li>
                      <li>
                        2. Click on support and feedback menu located at the
                        right side of the platform and send us a message.
                      </li>
                    </ol>
                  </p>
                </div>
              </div>
              {/* ))} */}
            </li>
          </ul>
        </Col>
        <Col lg={4}>
          <div className={styles.advert}>
            {/* <img src="/resources/images/payxpress.jpg" alt="adverts" /> */}
            <OtherPagesSlider />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Faq;
