import React, { useState, useContext,useEffect } from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import {
  Grid,
  TextField,
  Paper,
  Typography,
  ThemeProvider,
} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { makeStyles } from "@material-ui/core/styles";
import { MdReportProblem } from "react-icons/md";
import { theme } from "../../theme";
import { useHistory } from "react-router-dom";
import CTA from "../../components/button/cta-button";
import { useFormik } from "formik";
import * as Yup from "yup";
import { productConstants } from "../../_constants";
import { MdArrowBack } from "react-icons/md";
import { alertConstants } from "../../_constants";
import { uploadFile, logTransaction,fetchProviders } from "../../_actions/bulk-upload.actions";
import { UserContext } from "../../context/user-context";
import GateWay from "../../components/gate-way";
import Spinner from "../../components/spinner";
import { AlertContext } from "../../context/alert-context";

const useStyles = makeStyles((theme) => ({
  root: {
    borderBottom: "1px solid red",
    borderRadius: 3,
    color: "white",
    height: 48,
    padding: "0 30px",
    flexGrow: 1,
  },
  searchField: {
    width: "400px",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  paperForm: {
    marginTop: "10px",
    padding: "40px",
    maxHeight: "450px",
    overflow: "scroll",
    boxShadow: "0 7px 30px -10px rgba(150,170,180,0.5)",
    [theme.breakpoints.down("sm")]: {
      padding: "20px",
      marginLeft: "-10px",
    },
    "&::-webkit-scrollbar": {
      width: "10px",
      borderRadius: "10px",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "green",
      borderRadius: "10px",
    },
  },
  paperForm1: {
    marginTop: "0px",
    padding: "10px",
    boxShadow: "0 7px 30px -10px rgba(150,170,180,0.5)",
    [theme.breakpoints.down("sm")]: {
      padding: "20px",
      marginLeft: "-10px",
    },
  },
  image: {
    width: 90,
    height: 90,
    marginTop: "5px",
    boxShadow: "0 7px 30px -10px rgba(150,170,180,0.5)",
    [theme.breakpoints.down("sm")]: {
      width: 90,
      height: 90,
    },
  },
  formTitle: {
    fontFamily: "Montserrat",
    fontWeight: "bold",
    fontSize: "25px",
    marginTop: "50px",
    marginLeft: "-30px",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "-30px",
      fontSize: "20px",
      fontWeight: "bold",
      marginTop: "40px",
    },
    [theme.breakpoints.down("xs")]: {
      marginLeft: "-20px",
      fontSize: "20px",
      fontWeight: "bold",
      marginTop: "40px",
    },
  },
  img: {
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
  },
  inputPadding: {
    paddingLeft: "0px",
    marginTop: "5px",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "0px",
    },
  },
  productName: {
    fontSize: "25px",
    fontWeight: "bold",
    marginTop: "40px",
    marginLeft: "-80px",
    fontFamily: "Montserrat",
    textTransform: "uppercase",

    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      fontWeight: "bold",
      marginLeft: "-30px",
    },
  },
  summaryFontBold: {
    fontWeight: "600",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      fontWeight: "bold",
    },
  },
  tableContent: {
    "&:nth-of-type(even)": {
      backgroundColor: "rgba(196, 196, 196, 0.2)",
    },
  },
  downloadLink: {
    fontFamily: "Montserrat",
    color: "#FF6C00",
    fontWeight: 700,
    textDecoration: "underline",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  },
  caveat: {
    background: "#ffa000",
    padding: "10px",
    color: "white",
    borderRadius: "3px",
    boxShadow: "0 0px 8px rgba(0,0,0,0.1), 0 1px 10px rgba(0,0,0,0.1)",
    fontFamily: "Montserrat",
    [theme.breakpoints.down("sm")]: {
      fontSize: "11px",
    },
  },
  textField: {
    width: "90%",
  },
  button: {
    display: "flex",
    marginTop: "20px",
    marginRight: "0px",
    marginBottom: "15px",
    float: "right",
  },
  total: {
    display: "flex",
    justifyContent: "space-between",
    width: "86%",
    marginTop: "10px",
    paddingLeft: "20px",
    fontFamily: "Montserrat",
  },
}));

const template = "/resources/BulkUploadTemplate.xlsx";

const BulkUploadForm = ({ ...props }) => {
  const { loggedIn, user } = useContext(UserContext);
  const pathUrl = decodeURI(window.location.pathname);
  const billerCode = decodeURI(pathUrl.split("/")[2]);
  const { dispatch } = useContext(AlertContext);
  const classes = useStyles();

  let _values = {};
  if (!!loggedIn && user) {
    _values = {
      ..._values,
      email: user.ConsumerID,
    };
  }
  const [state, setState] = useState({
    loading: false,
    tranId: null,
    formStatus: productConstants.FORM_STATUS_INPUTTING,
    values: _values,
    providers: [],
    billerCode: billerCode,
    parameters: null,
  });

  useEffect(() => {
    handleFetchProviders()
  }, []);

  const [fileUpload, setFileUpload] = useState(null);

  const validationsSchema = Yup.object().shape({
    narration: Yup.string("Enter your narration")
      .typeError("Enter your narration")
      .required("Narration is Required"),
    email: Yup.string("Enter Your Email")
      .email("Enter a Valid Email")
      .typeError("Enter Your Email")
      .required("Email is Required"),
    file: Yup.string("Select file")
      .typeError("Select file")
      .required("File is Required"),
  });

  const change = (name, e) => {
    e.target.name = name;
    setFieldValue(name, e.target.value);
    handleChange(name, e.target.value);
    setFieldTouched(name, true, false);
  };

  const handleFileChange = async (e) => {
    if (!e.target.files) {
      return;
    }
    let file = e.target.files[0];
    setFileUpload(file);
  };

  const fileChange = async (name, e) => {
    e.target.name = name;
    setFieldValue(name, e.target.value);
    await handleFileChange(e);
    setFieldTouched(name, true, false);
  };

  const handleFetchProviders = () => {
    setState({ ...state, loading: true });
    fetchProviders().then(response => {
      setState({
        ...state,
        loading: false,
        providers: response
      });
    }, error => {
      setState({
        ...state,
        loading: false,
        providers: []
      });
    })
  }

  const handleBack = () => {
    setState({
      ...state,
      formStatus: productConstants.FORM_STATUS_INPUTTING,
    });
  };
  const handleProceed = () => {
    setState({
      ...state,
      loading: true,
    });
    const { billerCode, values } = state;
    var _req = {
      BillerCode: billerCode,
      narration: values.narration,
      email: values.email,
      fileName: values.fileName,
      TotalAmount: values.totalAmount,
      TranId: values.TranId,
    };
    logTransaction(_req).then(
      (data) => {
        setState({
          ...state,
          loading: false,
          formStatus: productConstants.FORM_STATUS_CONFIRMED,
          parameters: data,
        });
      },
      (error) => {
        setState({
          ...state,
          loading: false,
        });
        dispatch({
          type: alertConstants.ERROR,
          message: error.toString(),
        });
      }
    );
  };

  const {
    handleSubmit,
    handleChange,
    values,
    setFieldValue,
    isValid,
    setFieldTouched,
    setSubmitting,
    touched,
    errors,
    dirty,
    resetForm,
  } = useFormik({
    initialValues: {
      narration: "",
      email: state.values.email,
      file: "",
    },
    enableReinitialize: true,
    validationSchema: validationsSchema,
    onSubmit(values, { setSubmitting }) {
      if (values.narration && values.email) {
        let data = new FormData();
        // from state
        data.append("file", fileUpload);
        data.append("email", values.email);
        data.append("narration", values.narration);
        data.append("billerCode", billerCode);
        setState({
          ...state,
          loading: true,
        });
        uploadFile(data).then(
          (response) => {
            setState({
              ...state,
              loading: false,
              formStatus: productConstants.FORM_STATUS_CONFIRMING,
              values: {
                ...values,
                bulkDetails: response.BulkDetails,
                message: response.Message,
                totalAmount: response.TotalAmount,
                fileName: response.fileName,
                TranId: response.TranId,
              },
            });
            resetForm({});
          },
          (error) => {
            setState({
              ...state,
              loading: false,
            });
            dispatch({
              type: alertConstants.ERROR,
              message: error.toString(),
            });
          }
        );
      }
    },
  });

  return (
    <div style={{ padding: 20 }}>
      <ThemeProvider theme={theme}>
        <Grid container spacing={1}>
          <Grid item xs={6} sm={3} md={2}>
            <Paper className={(classes.paper, classes.image)}>
              <img
                src="/resources/images/bulk-airtime.png"
                className={classes.img}
                alt="bulk upload"
              />
            </Paper>
          </Grid>
          <Grid item xs={6} sm={9} md={10}>
            <Typography
              variant="h4"
              component="h2"
              className={classes.productName}
            >
              Bulk upload
            </Typography>
          </Grid>
        </Grid>
        {state.loading && (
          <>
            {" "}
            <Spinner message="Loading...please wait" />{" "}
          </>
        )}
        {state.formStatus == productConstants.FORM_STATUS_INPUTTING && (
          <>
            <Grid container>
              <Paper className={classes.paperForm}>
                <p className={classes.caveat}>
                  <MdReportProblem />
                  Before uploading with the downloadable template below, always
                  refer to the list below for updated Provider Code, Product
                  Code and Amount for Bulk Upload.
                </p>
                <Grid container>
                  <Grid item xs={12} sm={12} md={9}>
                    <Typography
                      component={Link}
                      to={"#"}
                      onClick={(e) => {
                        e.preventDefault();
                        window.open(`${template}`);
                      }}
                      className={classes.downloadLink}
                    >
                      <p>Click here to download Excel template</p>
                    </Typography>
                  </Grid>
                </Grid>

                <form onSubmit={handleSubmit} autoComplete="off">
                  <Grid key="bulk_upload_form" container>
                    <Grid
                      item
                      key="narration1"
                      className={classes.inputPadding}
                      xs={12}
                      sm={6}
                      md={4}
                    >
                      <TextField
                        className={classes.textField}
                        key="narration"
                        autoComplete="off"
                        required
                        id="narration"
                        label="Narration"
                        placeholder="Narration"
                        margin="normal"
                        name="narration"
                        value={values.narration}
                        onChange={(e) => change("narration", e)}
                        error={
                          touched.narration ||
                          (dirty.narration && Boolean(errors.narration))
                        }
                        helperText={touched.narration ? errors.narration : ""}
                      />
                    </Grid>
                    <Grid
                      item
                      className={classes.inputPadding}
                      xs={12}
                      sm={6}
                      md={4}
                      key="email1"
                    >
                      <TextField
                        className={classes.textField}
                        autoComplete="off"
                        key="email"
                        required
                        id="email"
                        name="email"
                        label="Email Address"
                        placeholder="Email Address"
                        margin="normal"
                        value={values.email}
                        onChange={(e) => change("email", e)}
                        error={touched.email && Boolean(errors.email)}
                        helperText={touched.email ? errors.email : ""}
                      />
                    </Grid>
                    <Grid
                      item
                      className={classes.inputPadding}
                      xs={12}
                      sm={6}
                      md={4}
                      key="file1"
                    >
                      <TextField
                        className={classNames(
                          classes.textField,
                          classes.fileStyle
                        )}
                        type="file"
                        key="file"
                        required
                        autoComplete="off"
                        id="file"
                        name="file"
                        label="Upload"
                        placeholder="Upload"
                        margin="normal"
                        InputProps={{
                          classes: { input: { height: "1.5em !important" } },
                        }}
                        value={values.file ? values.file : ""}
                        error={touched.file && Boolean(errors.file)}
                        onChange={(e) => fileChange("file", e)}
                        helperText={touched.file ? errors.file : ""}
                      />
                    </Grid>
                    <Grid key="upload_button" item xs={12} sm={12} md={3}>
                      <CTA
                        text="Preview Upload"
                        medium
                        style={{
                          width: "auto",
                          fontSize: "14px",
                          float: "left",
                          marginTop: "10px",
                          marginBottom: "10px",
                          fontSize: "12px",
                        }}
                        type="submit"
                        disabled={!(isValid && dirty)}
                      />
                    </Grid>
                  </Grid>
                </form>
                <Paper className={classes.paperForm1}>
                  <Table style={{ borderRadius: "10px" }}>
                    <TableHead style={{ background: "rgba(0, 109, 1, 0.2)" }}>
                      <TableRow>
                        <TableCell
                          style={{ fontWeight: 700, fontFamily: "Montserrat" }}
                        >
                          ProviderName
                        </TableCell>
                        <TableCell
                          style={{ fontWeight: 700, fontFamily: "Montserrat" }}
                        >
                          ProviderCode
                        </TableCell>
                        <TableCell
                          style={{ fontWeight: 700, fontFamily: "Montserrat" }}
                        >
                          Product
                        </TableCell>
                        <TableCell
                          style={{ fontWeight: 700, fontFamily: "Montserrat" }}
                        >
                          Amount
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    {!!state.providers && state.providers.length > 0 && state.providers.map(
                      (
                        { ProviderName, ProviderCode, Product, Amount },
                        index
                      ) => {
                        return (
                          <TableBody
                            key={index}
                            className={classes.tableContent}
                          >
                            <TableRow>
                              <TableCell style={{ fontFamily: "Montserrat" }}>
                                {ProviderName}
                              </TableCell>
                              <TableCell style={{ fontFamily: "Montserrat" }}>
                                {ProviderCode}
                              </TableCell>
                              <TableCell style={{ fontFamily: "Montserrat" }}>
                                {Product}
                              </TableCell>
                              <TableCell style={{ fontFamily: "Montserrat" }}>
                                {Amount}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        );
                      }
                    )}

                  </Table>
                </Paper>
              </Paper>
            </Grid>
          </>
        )}

        {state.formStatus == productConstants.FORM_STATUS_CONFIRMING && (
          <>
            <Paper className={classes.paperForm}>
              <h6 style={{ color: "#FF6C00", fontFamily: "Montserrat" }}>
                UPLOAD PREVIEW
              </h6>
              <Paper className={classes.paperForm1}>
                <Table style={{ borderRadius: "10px" }}>
                  <TableHead style={{ background: "rgba(0, 109, 1, 0.2)" }}>
                    <TableRow>
                      <TableCell
                        style={{ fontWeight: 700, fontFamily: "Montserrat" }}
                      >
                        S/N
                      </TableCell>
                      <TableCell
                        style={{ fontWeight: 700, fontFamily: "Montserrat" }}
                      >
                        Subscriber Number
                      </TableCell>
                      <TableCell
                        style={{ fontWeight: 700, fontFamily: "Montserrat" }}
                      >
                        Provider
                      </TableCell>
                      <TableCell
                        style={{ fontWeight: 700, fontFamily: "Montserrat" }}
                      >
                        Product
                      </TableCell>
                      <TableCell
                        style={{ fontWeight: 700, fontFamily: "Montserrat" }}
                      >
                        Amount
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  {state.values.bulkDetails.map(
                    (
                      { Id, SubscriberNo, Provider, Product, Amount },
                      index
                    ) => {
                      return (
                        <TableBody className={classes.tableContent}>
                          <TableRow>
                            <TableCell style={{ fontFamily: "Montserrat" }}>
                              {index + 1}
                            </TableCell>
                            <TableCell style={{ fontFamily: "Montserrat" }}>
                              {SubscriberNo}
                            </TableCell>
                            <TableCell style={{ fontFamily: "Montserrat" }}>
                              {Provider}
                            </TableCell>
                            <TableCell style={{ fontFamily: "Montserrat" }}>
                              {Product}
                            </TableCell>
                            <TableCell style={{ fontFamily: "Montserrat" }}>
                              {Amount.toLocaleString(navigator.language, {
                                minimumFractionDigits: 2,
                              })}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      );
                    }
                  )}
                </Table>
                <div className={classes.total}>
                  <h6 style={{ fontWeight: "bold" }}>Total</h6>
                  <p>
                    {state.values.totalAmount.toLocaleString(
                      navigator.language,
                      {
                        minimumFractionDigits: 2,
                      }
                    )}
                  </p>
                </div>
              </Paper>
              <div className={classes.button}>
                <CTA
                  noBackground
                  text="Back"
                  icon={<MdArrowBack />}
                  action={handleBack}
                />
                <CTA
                  medium
                  text="Proceed to Payments"
                  style={{
                    width: "auto",
                    fontSize: "12px",
                    marginTop: "-10px",
                  }}
                  action={handleProceed}
                />
              </div>
            </Paper>{" "}
          </>
        )}

        {state.formStatus == productConstants.FORM_STATUS_CONFIRMED && (
          <>
            <GateWay classes parameters={state.parameters} />
          </>
        )}
      </ThemeProvider>
    </div>
  );
};

export default BulkUploadForm;
