import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import { getReceipt } from "../_actions/product.actions";
import { Grid } from "@material-ui/core";

const logo = "/Resources/images/XPressPay_Logo.png";

// const imgRight: {
//     position: "absolute",
//     right: "150px",
//     width: "200px",
//     height: "120px",
//     border: "3px solid green",
//   };

export const getReceiptData = async (id, callback) => {
  //alert(id);
  let _receipt = <div></div>;
  let rowHeight = "30px";

  try {
    if (typeof id !== "undefined" && id !== "") {
      const transactionDetails = await getReceipt(id);
      if (transactionDetails) {
        _receipt = (
          <div>
            <div style={{ margin: 8 }}>
              <Typography component="div" variant="body1">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "80%",
                  }}
                >
                  <img src={logo} alt="" height={40} />
                  <img
                    src={
                      "data:image/png;base64," + transactionDetails.ProductImage
                    }
                    alt=""
                    height={40}
                    width={40}
                  />
                </div>
              </Typography>
              <Typography component="h4" variant="body1">
                {transactionDetails.TransactionDescription}
              </Typography>
            </div>
            <Table style={{ align: "left" }}>
              <TableBody>
                <TableRow style={{ height: rowHeight }}>
                  <TableCell scope="row" style={{ fontWeight: 700 }}>
                    Order Status
                  </TableCell>
                  <TableCell numeric>
                    {transactionDetails.OrderStatus}
                  </TableCell>
                </TableRow>
                {transactionDetails.ReceiptItems.map((row) => {
                  return (
                    <TableRow key={row.Label} style={{ height: rowHeight }}>
                      <TableCell scope="row" style={{ fontWeight: 700 }}>
                        {row.Label}
                      </TableCell>
                      <TableCell numeric>{row.Value}</TableCell>
                    </TableRow>
                  );
                })}
                <TableRow style={{ height: rowHeight }}>
                  <TableCell scope="row" style={{ fontWeight: 700 }}>
                    Order Details
                  </TableCell>
                  <TableCell numeric>
                    {transactionDetails.OrderDetails}
                  </TableCell>
                </TableRow>
                <TableRow style={{ height: rowHeight }}>
                  <TableCell scope="row" style={{ fontWeight: 700 }}>
                    Order Number
                  </TableCell>
                  <TableCell numeric>
                    {transactionDetails.OrderNumber}
                  </TableCell>
                </TableRow>
                <TableRow style={{ height: rowHeight }}>
                  <TableCell scope="row" style={{ fontWeight: 700 }}>
                    Order Amount
                  </TableCell>
                  <TableCell numeric>
                    {transactionDetails.OrderAmount}
                  </TableCell>
                </TableRow>
                <TableRow style={{ height: rowHeight }}>
                  <TableCell scope="row" style={{ fontWeight: 700 }}>
                    Convenience Fee
                  </TableCell>
                  <TableCell numeric>{transactionDetails.Fee}</TableCell>
                </TableRow>
                <TableRow style={{ height: rowHeight }}>
                  <TableCell scope="row" style={{ fontWeight: 700 }}>
                    Order Date
                  </TableCell>
                  <TableCell numeric>{transactionDetails.OrderDate}</TableCell>
                </TableRow>
                <TableRow style={{ height: rowHeight }}>
                  <TableCell scope="row" style={{ fontWeight: 700 }}>
                    E-mail
                  </TableCell>
                  <TableCell numeric>
                    {transactionDetails.EmailAddress}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>
        );
      }
    } else {
      _receipt = <div>Invalid Order Number</div>;
    }
  } catch (e) {
    _receipt = <div>Invalid Order Number</div>;
  }
  callback(_receipt);
};

class Receipt extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      completed: false,
    };
  }

  async componentDidMount() {
    const { id } = this.props;
    await getReceiptData(id, (_receipt) => {
      this.setState({
        receipt: _receipt,
        completed: true,
      });
    });
  }

  render() {
    const { receipt } = this.state;
    return (
      <React.Fragment>
        {receipt && <div ref={(comp) => (this.Receipt = comp)}>{receipt}</div>}
      </React.Fragment>
    );
  }
}

export default Receipt;
