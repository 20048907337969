import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Rating from "@material-ui/lab/Rating";
import Box from "@material-ui/core/Box";
import { Button } from "@material-ui/core";
import CTA from "../../components/button/cta-button";
import { FaArrowLeft } from "react-icons/fa";

function getModalStyle() {
  const top = 40;
  const left = 45;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  stars: {
    width: 300,
    display: "flex",
    alignItems: "center",
    marginLeft: "30%",
  },
  paper: {
    position: "absolute",
    width: 600,
    height: 350,
    background: "#fff",
    outline: "none",
    // border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    [theme.breakpoints.down("sm")]: {
      width: 320,
    },
  },
  modal: {
    background: "rgba(32, 28, 27, 0.8)",
  },
  ratingImg: {
    width: 300,
    height: 200,
    marginLeft: "100px",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "-15px",
      width: 300,
    },
  },
  paragraph: {
    fontSize: "15px",
    fontFamily: "century gothic",
    marginLeft: "60px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  },
  label: {
    display: "block",
    fontSize: "30px",
  },
  icon: {
    fontSize: "50px",
  },
}));

const labels = {
  1: "Very poor",
  2: "Poor",
  3: "Good",
  4: "Very good",
  5: "Excellent",
};

export default function RatingModal({ openModal, onChange, handleClose }) {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState(0);
  const [hover, setHover] = React.useState(-1);

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <div className={classes.ratingIllustration}>
        <img
          src="/resources/images/rating.png"
          alt="illustration"
          className={classes.ratingImg}
        />
        <p className={classes.paragraph}>
          Please rate the satisfaction derived using our platform
        </p>
        <div className={classes.stars}>
          <Rating
            name="hover-feedback"
            defaultValue={0}
            value={value}
            size="large"
            emptyIcon={0}
            precision={1}
            onChange={(event, newValue) => {
              // handleClose(newValue);
            }}
            onChangeActive={(event, newHover) => {
              setHover(newHover);
            }}
          />
          {value !== null && (
            <Box ml={2}>{labels[hover !== -1 ? hover : value]}</Box>
          )}
        </div>
      </div>
      <div style={{ marginLeft: "165px" }}>
        <CTA medium text="Not Now" action={handleClose} />
      </div>
    </div>
  );

  return (
    <div>
      <Modal
        open={openModal}
        // onClose={handleClose}
        onChange={onChange}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        disableBackdropClick="true"
      >
        {body}
      </Modal>
    </div>
  );
}
