import { supportConstants } from '../_constants';

// const initialState = {
//   subjects: [],
// } 

export function supportReducer(state, action) {
  switch (action.type) {
    case supportConstants.FETCH_SUBJECTS_REQUEST:
      return {
        loading: true,
      }
    case supportConstants.FETCH_SUBJECTS:
      return {
        ...state,
        subjects: action.payload,
        loading: false,
      }
    case supportConstants.SUPPORT_SAVE:
      return {
        ...state,
        message: action.message,
        loading: false,
      }
    default:
      return state;
  }
}