import React, { useContext, useEffect } from "react";
import { UserContext } from "../../context/user-context";
import { useLocation } from "react-router-dom";

import { Redirect, useHistory } from "react-router-dom";
const LogOut = () => {
  const history = useHistory();
  const { logout } = useContext(UserContext);

  const logoutUser = () => {
    logout(history);
  };

  return <div logoutUser={logoutUser()}></div>;
};

export default LogOut;
