import React from "react";
import styles from "./button.module.css";

const Btn = ({
  text,
  action,
  style,
  rounded,
  Icon,
  menu,
  green,
  orange,
  img,
  id,
}) => {
  return (
    <button
      className={`${styles.btn}
       ${menu ? styles.menu : ""}
       ${green ? styles.green : ""}
       ${orange ? styles.orange : ""}
       ${id % 2 === 0 ? styles.green : styles.orange}`}
      style={style}
      onClick={action}
    >
      {text}
      {/* <span
        style={{
          float: "right",
          fontSize: "25px",
          marginRight: "10px",
          marginTop: "-7px",
        }}
      >
        {Icon}
      </span> */}
      <img
        src={img}
        style={{
          height: "33px",
          width: "33px",
          float: "right",
          fontSize: "25px",
          marginRight: "10px",
          marginTop: "-7px",
          // position: "absolute",
        }}
      />
    </button>
  );
};

export default Btn;
