import React, { useState } from "react";
import {
  Grid,
  TextField,
  ButtonBase,
  Paper,
  Box,
  Container,
  CssBaseline,
  ThemeProvider,
  Toolbar,
  Typography,
  Link,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { theme } from "../../theme";
import { useHistory } from "react-router-dom";
import OtherPagesSlider from "../../components/carousel/other-pages-carousel";

const useStyles = makeStyles((theme) => ({
  root: {
    borderBottom: "1px solid red",
    borderRadius: 3,
    color: "white",
    height: 48,
    padding: "0 30px",
    flexGrow: 1,
  },
  searchField: {
    width: "400px",
    marginTop: "10px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "15px",
      // display:'none'
    },
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  categoryName: {
    fontFamily: "Montserrat",
    fontWeight: "bold",
    fontSize: "25px",
    marginTop: "20px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "17px",
      fontWeight: "bold",
    },
  },
  image: {
    width: 120,
    height: 120,
    borderRadius: 10,
    [theme.breakpoints.down("sm")]: {
      width: 90,
      height: 90,
      // display:'none'
    },
  },
  img: {
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
    [theme.breakpoints.down("sm")]: {
      width: 90,
      height: 90,
      // display:'none'
    },
  },
}));

// const categories = [
//   {
//     name: "fund transfer",
//     path: "/fund-transfer",
//     categoryName: "Fund Transfer",
//   },
//   {
//     name: "Xpress Wallet",
//     path: "/xpress-wallet",
//     categoryName: "Xpress Wallet",
//   },
//   {
//     name: "Collections & Levies",
//     path: "/collections",
//     categoryName: "Collections & Levies",
//   },
//   {
//     name: "micropension",
//     path: "/micro-pension",
//     categoryName: "Micro Pension",
//   },
// ];

const OtherServices = () => {
  const pathUrl = window.location.pathname;
  const history = useHistory();

  const classes = useStyles();

  const preventDefault = (path) => {
    history.push(path);
  };

  const handleChange = () => {};
  return (
    <div style={{ padding: 20 }} className="other-services-container">
      <Grid container>
        {/* <Grid item xs={12} sm={6} md={6}>
            <form noValidate autoComplete="off">
              <TextField
                className={classes.searchField}
                placeholder="Search for billers e.g mtn"
                fullWidth
                id="standard-basic"
              />
            </form>
          </Grid> */}

        <Grid item xs={12} sm={12} md={8} lg={8}>
          <div>
            <Typography
              variant="h4"
              component="h2"
              className={classes.categoryName}
            >
              {/* {categoryName} */}
            </Typography>
            <div className="other-services-illustration">
              <img src="/resources/images/comingsoon.svg" alt="illustration" />
            </div>
            <div className="other-services-info">
              <h4>COMING SOON</h4>
              <p>
                This service will be available soon. Thanks for using PayXpress
              </p>
            </div>
          </div>
        </Grid>
        <Grid item lg={4} sm={12} md={12}>
          <div className="other-services-advert">
            <img src="/resources/images/payxpress.jpg" alt="" />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default OtherServices;
