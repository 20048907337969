import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(2),
  },
  button: {
    border: "none",
    background: "none !important",
    textTransform: "capitalize",
    position: "absolute",
    marginTop: "-2px",
    marginLeft: "0px",
    fontFamily: "century gothic",
    fontSize: "13px",
    "&:focus": {
      outline: "none",
    },
    "&:hover": {
      color: "orange",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "-4px",
      marginLeft: "0px",
    },
  },
}));

export default function Policy() {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div>
      <button
        aria-describedby={id}
        className={classes.button}
        onClick={handleClick}
      >
        Policy
      </button>
      <Popover
        id={id}
        className={classes.popover}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Typography>
          <Link
            to={"/cookies-policy"}
            style={{ fontSize: "13px", textDecoration: "none", color: "#000" }}
          >
            Cookies Policy
          </Link>
        </Typography>
      </Popover>
    </div>
  );
}
