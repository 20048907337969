import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { AlertContext } from '../../context/alert-context';
import { useHistory } from "react-router-dom";
import { alertConstants } from '../../_constants';

export const PrivateRoute = ({ component: Component, loggedIn: LoggedIn, ...rest }) => {
    const { dispatch } = useContext(AlertContext);
    const history = useHistory();

    const handleRedirect = (UrlPath, CategoryCode, BillerCode, Accessibility) => {
        dispatch({
            type: alertConstants.ERROR,
            message: "Please kindly login or register to continue",
        });
        history.push(`/`);
    };
    return (
        <Route exact {...rest} render={props => (
            !!LoggedIn
                ? <Component {...props} />
                : handleRedirect()
            // <Redirect to={{ pathname: '/', state: { from: props.location } }} />
        )}
        />
    );
}