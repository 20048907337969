import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
// import { createMuiTheme } from 'material-ui/styles';
// import styles from "./input.module.css";


const useStyles = makeStyles((theme) => ({
    margin: {
      margin: theme.spacing(1),
    },
  }));

const FormInput = ({ icon, placeholder, label,type, style }) => {
  const classes = useStyles();

  return (
    <FormControl className={classes.margin}>
      <InputLabel htmlFor="input-with-icon-adornment">{label}</InputLabel>
      <Input
        id={label}
        type={type}
        placeholder={placeholder}
        style={style}
        startAdornment={
          <InputAdornment position="start">{icon}</InputAdornment>
        }
      />
    </FormControl>
  );
};

export default FormInput;
