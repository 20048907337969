import React from "react";
import styles from "./about.module.css";
import DesignBtn from "../../components/design-btn/design-btn";
import { Row, Col } from "react-bootstrap";

const About = () => {
  return (
    <div style={{}}>
      <h1 className="title" style={{ marginBottom: "40px", marginLeft: "36%" }}>
        About
      </h1>
      <div className={styles.aboutContainer}>
        <Row style={{}}>
          <Col lg={6} sm={12}>
            <div className={styles.vision}>
              <DesignBtn small />
              <div className={styles.other}>
                <h6 style={{}}>Our Vision</h6>
                <p>To be the Preferred choice of e-payment in Africa.</p>
              </div>
            </div>
          </Col>
          <Col lg={6} sm={12}>
            <div className={styles.about}>
              <DesignBtn big />
              <div className={styles.other2}>
                <h6>Who we are</h6>
                <p>
                  <strong>Xpress Payment Solutions Limited </strong>
                  is a leading Financial Technology Solution Company that
                  specializes in electronic payments, collections, bill payments
                  and funds disbursement. Xpress Payment Solutions Limited is a
                  proudly owned Nigerian Company and is licensed by the Central
                  Bank of Nigeria as a Payment Solution Service Provider (PSSP)
                  and as such able to drive financial and treasury management
                  reforms which include the TSA (Treasury Single Account) as a
                  major scheme and standalone collection services.
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={6} sm={12} xs={12}>
            <div className={styles.mission}>
              <DesignBtn big />
              <div className={styles.other3}>
                <h6>Our Mission</h6>
                <p>Our mission is to simplify and enable smarter living.</p>
              </div>
            </div>
          </Col>
          <Col lg={6} sm={12}>
            <div className={styles.values}>
              <DesignBtn small />
              <div className={styles.ourValues}>
                <h6>Our Core Values</h6>
                <p>
                  <ul>
                    <li>
                      <strong>Innovations</strong> We bring new ideas that will
                      advance the organization’s competitive position.
                    </li>
                    <li>
                      <strong>Integrity:</strong> At all times, what we say and
                      what we do are in alignment.
                    </li>
                    <li>
                      <strong>Excellence</strong> To be outstandingly good at
                      what we do.
                    </li>
                    <li>
                      <strong>Security:</strong> We think data and process. We
                      think security.
                    </li>
                    <li>
                      <strong>Customer Satisfaction:</strong> We meet and exceed
                      customer’s expectation
                    </li>
                  </ul>
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default About;
